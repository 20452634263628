/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from "react";
import { Navbar, Container, Nav, Badge, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import HeaderLogo from "../../uiComponents/logos/HeaderLogo";
import { LoginDropdownBtn, SimpleButton } from "../../uiComponents/button";
import NotificationIcon from "../../assets/images/svg/notification-icon-gray.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { URL } from "../../constants/index";
import "./_navbar.scss";
import { HeadingDescSmall } from "../Headings";
import Toast from "../notification/Toast";
import NotificationPop from "../notifications/NotificationPop";
import API from "../../utils/customAxios";
import { toast } from "react-toastify";

function Navabrr() {
  const [stickyClass, setStickyClass] = useState("relative");
  const [expanded, setExpanded] = useState(false);
  const [notificationPop, setNotificationPop] = useState(false);
  const [progress, setProgress] = useState();
  const [profileVerificationDoctor, setProfileVerificationDoctor] =
    useState(null);

  const myRef = useRef(null);
  const addClass = useRef(null);

  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      // windowHeight > 50 ? setStickyClass("active2") : setStickyClass("wait");
    }
  };

  // const navigate = useNavigate();

  // const handleLoginNavigation = () => {
  //   navigate('/login', { replace: true });
  // };

  useEffect(() => {
    var externalResource = document.getElementsByClassName(
      "videoask-embed__button_right--V-il1"
    );
    // console.log(externalResource.length, "len");
    for (var i = 0; i < externalResource.length; i++) {
      externalResource[i]?.remove();
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`doctor/progress`);
        if (response?.data?.code === 200) {
          setProgress(response?.data?.data);
          setProfileVerificationDoctor(
            response?.data?.data?.profile_verification
          );
        }
      } catch (error) { }
    })();
  }, []);

  const toggleHandler = (event) => {
    if (event === true) {
      addClass.current.classList.add("bg-white");
    } else {
      addClass.current.classList.remove("bg-white");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const openNotificaiton = () => {
    setNotificationPop(true);
  };

  const tooltip = (
    <Tooltip id="tooltip" className="tooltip-menu">
      Can only be accessed after profile completion and verification
    </Tooltip>
  );

  const handleClick = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Toast />
      <section id="navbar">
        <Navbar
          expand="md"
          onToggle={(event) => toggleHandler(event)}
          className={`stickyNavbar ${stickyClass}`}
          ref={myRef}
          expanded={expanded}
        >
          <Container fluid ref={addClass}>
            <Col lg={2} md={2}>
              <Link to="/">
                <HeaderLogo />
              </Link>
            </Col>
            <Col lg={10} md={10}>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="x navbar-toggle"
                onClick={() => setExpanded(expanded ? false : "expanded")}
              >
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                {profileVerificationDoctor === 0 ? (
                  <>
                    <Nav className="m-auto">
                      <OverlayTrigger
                        trigger="focus"
                        overlay={tooltip}
                        placement="bottom"
                      >
                        <NavLink to="" onClick={handleClick}>
                          <HeadingDescSmall text="Dashboard" />
                        </NavLink>
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger="focus"
                        overlay={tooltip}
                        placement="bottom"
                      >
                        <NavLink to="" onClick={handleClick}>
                          <HeadingDescSmall text="My Practice" />
                        </NavLink>
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger="focus"
                        overlay={tooltip}
                        placement="bottom"
                      >
                        <NavLink to="" onClick={handleClick}>
                          <HeadingDescSmall text="Appointments" />
                        </NavLink>
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger="focus"
                        overlay={tooltip}
                        placement="bottom"
                      >
                        <NavLink to="" onClick={handleClick}>
                          <HeadingDescSmall text="Earnings" />
                        </NavLink>
                      </OverlayTrigger>
                      <Badge bg="secondary hk_new_badge">Help</Badge>

                      <OverlayTrigger
                        trigger="focus"
                        overlay={tooltip}
                        placement="bottom"
                      >
                        <NavLink to="" onClick={handleClick}>
                          <HeadingDescSmall text="Tutorials & Guides" />
                        </NavLink>
                      </OverlayTrigger>
                    </Nav>
                    <OverlayTrigger
                      trigger="focus"
                      overlay={tooltip}
                      placement="bottom"
                    >
                      <NavLink
                        className="my_profile"
                        to=""
                        onClick={handleClick}
                      >
                        <p>My Profile</p>
                      </NavLink>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <Nav className="m-auto">
                      <NavLink
                        activeClassName="linkactive"
                        to={URL.dashboard}
                        onClick={() => setExpanded(false)}
                        exact
                      >
                        <HeadingDescSmall text="Dashboard" />
                      </NavLink>
                      <NavLink
                        activeClassName="linkactive"
                        to={{
                          pathname: URL.healthClinic,
                          state: { type: "physical" },
                        }}
                        onClick={() => setExpanded(false)}
                      >
                        <HeadingDescSmall text="My Practice" />
                      </NavLink>
                      <NavLink
                        activeClassName="linkactive"
                        to={URL.appointments}
                        onClick={() => setExpanded(false)}
                      >
                        <HeadingDescSmall text="Appointments" />
                      </NavLink>
                      <NavLink
                        activeClassName="linkactive"
                        to={URL.earnings}
                        onClick={() => setExpanded(false)}
                      >
                        <HeadingDescSmall text="Earnings" />
                      </NavLink>
                      <Badge bg="secondary hk_new_badge">Help</Badge>
                      <NavLink
                        className="lastLinkColor"
                        activeClassName="linkactive"
                        to={URL.tutorials}
                        onClick={() => setExpanded(false)}
                      >
                        <HeadingDescSmall text="Tutorials & Guides" />
                      </NavLink>
                    </Nav>
                    <div className="my_profile">
                      <Link to="/profile-update">My Profile</Link>
                    </div>
                  </>
                )}
                <img src={NotificationIcon} className="img-fluid"></img>
                <LoginDropdownBtn
                  profileVerificationDoctor={profileVerificationDoctor}
                />

                {/* <SimpleButton text="login" /> */}
              </Navbar.Collapse>
            </Col>
          </Container>
        </Navbar>
      </section>
    </>
  );
}

export default React.memo(Navabrr);
