import React, { useEffect, useState, useRef } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { v4 as uuidv4 } from 'uuid';
import "./profileUpdate.scss";
import { Button, Form, Input, Select, Radio, Space, Popover, DatePicker, Checkbox, TimePicker } from "antd";
import { Container, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { selectSpeciality, selectSpecialityLoader, selectUser } from "../updateProfile/redux/slice";
import API from "../../utils/customAxios";
import { useEffectOnce } from "react-use";
import moment from "moment";
import ImageUploader from "react-image-upload";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSpeciality } from "../updateProfile/redux/thunk";
import Cookies from "js-cookie";
import Loader from "../../uiComponents/loader/Loader";
import dayjs from 'dayjs';
import plusIcon from "../../assets/images/png/plus.png";
import deleteIcon from '../../assets/images/png/delete_icon.png';
import ClinicVisit from "./ClinicVisit";



const { Option } = Select;

const ConsultationTab = () => {

    // all states here start
    const [videoConsultCheckbox, setVideoConsultCheckbox] = useState(false);
    const [videoClinicCheckbox, setVideoClinicCheckbox] = useState(false);
    const [consultationFee, setConsultationFee] = useState();
    const [consultationDuration, setConsultationDuration] = useState();
    const [rows, setRows] = useState([]);
    const [rowsTwo, setRowsTwo] = useState([]);
    const [hospitalClinics, setHospitalClinics] = useState([]);
    const [consultationMinutes, setConsultationMinutes] = useState([]);
    const [submissionLoader, setSubmissionLoader] = useState(false);
    const [clinicRows, setClinicRows] = useState([{
        id: 1,
    }]);
    const [clinicRowsTwo, setClinicRowsTwo] = useState([]);
    const [startTimeTwo, setStartTimeTwo] = useState();
    const [endTimeTwo, setEndTimeTwo] = useState();
    const [userForm] = Form.useForm();
    const [daysValues, setDaysValues] = useState({});
    const [daysAndTimeSlots, setDaysAndTimeSlots] = useState([{
        clinicId: 1,
        slotsId: 1
    }])

    const [timeSlots, setTimeSlots] = useState([
        {
            clinicId: 1,
            slotsId: 1,
            timeSlotId: 1,
            day: ''
        }
    ])

    const [videoClinicRows, setVideoClinicRows] = useState([{
        id: 1
    }])

    const [videoDaysAndTimeSlots, setVideoDaysAndTimeSlots] = useState([{
        clinicId: 1,
        slotsId: 1
    }])

    const [videoTimeSlots, setVideoTimeSlots] = useState([
        {
            clinicId: 1,
            slotsId: 1,
            timeSlotId: 1,
            day: ''
        }
    ])

    const [profileData, setProfileData] = useState([]);
    const [isFieldVisible, setFieldVisibility] = useState(true);
    // all states here end

    // all onchange function and useEffects start

    const format = 'HH:mm:ss';
    let scheduleTwo = [];


    const onConsultationDurationChange = (value, id) => {
        setConsultationDuration(value);
    }

    const onConsultationFeeChange = (event, id) => {
        setConsultationFee(event.target.value)
    }

    const handleDayChange = (event, id, index) => {
        const { name, checked } = event.target;

        setRows((prevRows) => {
            return prevRows.map((row) => {
                if (row.id === id) {
                    let updatedSchedule = row.schedule;

                    if (checked) {
                        const existingItem = updatedSchedule.find((item) => item.days === '');

                        if (existingItem) {
                            existingItem.days = name;
                        } else {
                            const firstItem = updatedSchedule[0];
                            const newItem = {
                                days: name,
                                start_time: firstItem.start_time,
                                end_time: firstItem.end_time,
                            };
                            scheduleTwo.push(newItem);
                        }
                    } else {
                        // Remove the specific schedule object based on the days value
                        updatedSchedule = updatedSchedule.filter((item) => item.days !== name);
                    }

                    // Remove previous index days values
                    for (let i = 0; i < index; i++) {
                        updatedSchedule = updatedSchedule.filter(
                            (item) => !daysValues[`days${i}`]?.includes(item.days)
                        );
                    }

                    // Update the daysValues state
                    const updatedDaysValues = {
                        ...daysValues,
                        [`days${index}`]: updatedSchedule.map((item) => item.days).join(', '), // Convert array to string
                    };
                    setDaysValues(updatedDaysValues);

                    return { ...row, schedule: updatedSchedule };
                }

                return row;
            });
        });
    };

    // Update the days fields using setFieldsValue
    userForm.setFieldsValue(daysValues);

    const onTimeStartChange = async (time, timeString, rowId, scheduleId) => {
        setRows((prevRows) => {
            const updatedRows = prevRows.map((row) => {
                if (row.id === rowId) {
                    const updatedSchedule = row.schedule.map((scheduleItem) => {
                        if (scheduleItem.id === scheduleId) {
                            return { ...scheduleItem, start_time: timeString };
                        }
                        return scheduleItem;
                    });
                    return { ...row, schedule: updatedSchedule };
                }
                return row;
            });
            return updatedRows;
        });

        setRowsTwo((prevRowsTwo) => {
            const updatedRowsTwo = prevRowsTwo.map((row) => {
                if (row.id === rowId) {
                    setStartTimeTwo(timeString)
                    const updatedSchedule = row.schedule.map((scheduleItem) => {
                        if (scheduleItem.id === scheduleId) {
                            return { ...scheduleItem, start_time: startTimeTwo };
                        }
                        return scheduleItem;
                    });
                    return { ...row, schedule: updatedSchedule };
                }
                return row;
            });
            return updatedRowsTwo;
        });
    };

    const onTimeEndChange = (time, timeString, rowId, scheduleId) => {
        setRows((prevRows) => {
            const updatedRows = prevRows.map((row) => {
                if (row.id === rowId) {
                    const updatedSchedule = row.schedule.map((scheduleItem) => {
                        if (scheduleItem.id === scheduleId) {
                            return { ...scheduleItem, end_time: timeString };
                        }
                        return scheduleItem;
                    });
                    return { ...row, schedule: updatedSchedule };
                }
                return row;
            });
            return updatedRows;
        });

        setRowsTwo((prevRowsTwo) => {
            const updatedRowsTwo = prevRowsTwo.map((row) => {
                if (row.id === rowId) {
                    setEndTimeTwo(timeString)
                    const updatedSchedule = row.schedule.map((scheduleItem) => {
                        if (scheduleItem.id === scheduleId) {
                            return { ...scheduleItem, end_time: endTimeTwo };
                        }
                        return scheduleItem;
                    });
                    return { ...row, schedule: updatedSchedule };
                }
                return row;
            });
            return updatedRowsTwo;
        });
    };

    const handleAddDaysRow = () => {
        handleAddTime(null, videoDaysAndTimeSlots.length + 1);

        setVideoDaysAndTimeSlots([
            ...videoDaysAndTimeSlots,
            {
                clinicId: 1,
                slotsId: videoDaysAndTimeSlots.length + 1
            }
        ])
    };



    // const handleAddDaysRow = () => {
    //     setRows((prevRows) => {
    //         const updatedRows = prevRows.map((row) => {
    //             const updatedSchedule = [
    //                 ...row.schedule,
    //                 {
    //                     id: new Date().getTime(), // Generate a unique ID for the new schedule item
    //                     days: '',
    //                     start_time: '', // Set initial value to an empty string
    //                     end_time: '', // Set initial value to an empty string
    //                 },
    //             ];
    //             return {
    //                 ...row,
    //                 schedule: updatedSchedule,
    //             };
    //         });
    //         return updatedRows;
    //     });
    // };

    const handleConsultCheckbox = () => {
        setVideoConsultCheckbox(!videoConsultCheckbox);
    };

    const handleRemoveDaysRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };

    const handleRemoveTime = (id) => {
        setRowsTwo((prevRowsTwo) => {
            const updatedRowsTwo = prevRowsTwo.filter((row) => row.id !== id);
            setRows((prevRows) => {
                const updatedRows = prevRows.map((row) => {
                    const updatedSchedule = row.schedule.filter((scheduleItem) => scheduleItem.id !== id);
                    return {
                        ...row,
                        schedule: updatedSchedule,
                    };
                });
                return updatedRows;
            });
            return updatedRowsTwo;
        });
    };

    const handleAddTime = (e, slotId) => {
        console.log("handler called");

        setVideoTimeSlots([
            ...videoTimeSlots,
            {
                clinicId: 1,
                slotsId: slotId,
                timeSlotId: videoTimeSlots.length + 1
            }

        ])
    };

    useEffect(() => {
        const currentYear = moment().year();
        const minutesArray = [];
        const minutesInterval = 5;
        const maxMinutes = 30;

        for (let minutes = minutesInterval; minutes <= maxMinutes; minutes += minutesInterval) {
            minutesArray.push({ id: minutes, minutes: minutes });
        }

        setConsultationMinutes(minutesArray);
    }, []);

    useEffect(() => {
        setRows([
            {
                id: new Date().getTime(),
                consultation_duration: consultationDuration ? consultationDuration : '',
                consultation_fee: consultationFee ? consultationFee : '',
                schedule: [
                    {
                        days: '',
                        start_time: '',
                        end_time: ''
                    }
                ]
            }
        ]);
    }, [consultationDuration, consultationFee]);


    const hasConsultationFee = clinicRows?.some((item) => Boolean(item.consultation_fee));
    const hasConsultationFeeVideo = rows?.some((item) => Boolean(item.consultation_fee));

    //Visit Clinic Data funcs

    function addAnotherClinicVisit() {
        addVisitTimeSlot(null, clinicRows.length + 1, daysAndTimeSlots.length + 1)
        addVisitDaysAndTimeSlot(null, clinicRows.length + 1);

        setClinicRows([
            ...clinicRows,
            { id: clinicRows.length + 1 }
        ])
    }

    function addVisitDaysAndTimeSlot(e, clinicId) {
        addVisitTimeSlot(null, clinicId, daysAndTimeSlots.length + 1)

        setDaysAndTimeSlots([
            ...daysAndTimeSlots,
            {
                clinicId: clinicId,
                slotsId: daysAndTimeSlots.length + 1
            }
        ])
    }

    function addVisitTimeSlot(e, clinicId, slotId) {
        console.log("zayk")

        setTimeSlots([
            ...timeSlots,
            {
                clinicId: clinicId,
                slotsId: slotId,
                timeSlotId: timeSlots.length + 1
            }

        ])
    }

    // all onchange function and useEffects end

    useEffect(() => {
        API.get(`/doctor/profile-details?clinics=1`)
            .then((res) => {
                if (res?.data?.code === 200) {
                    setProfileData(res?.data?.data);
                }
            })
    }, [])

    // prefilled video consultation data
    useEffect(() => {
        if (Object.keys(profileData)?.length > 0) {
            const doctorClinics = profileData?.doctor_clinics;
            if (doctorClinics?.length > 0) {
                for (let i = 0; i < doctorClinics?.length; i++) {
                    if (doctorClinics?.[i]?.clinic_id === 0) {
                        userForm.setFieldsValue({
                            ['mins']: doctorClinics?.[i]?.consultation_duration,
                            ['consultation_fee']: doctorClinics?.[i]?.consultation_fee
                        })
                        let days = [];
                        for (let j = 0; j < doctorClinics?.[i]?.clinic_timings?.length; j++) {
                            days.push(doctorClinics?.[i]?.clinic_timings?.[j]?.day);
                            if (doctorClinics?.[i]?.clinic_timings?.[j]?.disable == 1) {
                                continue;
                            }
                            if (j > 0) {
                                setVideoTimeSlots([
                                    ...videoTimeSlots,
                                    {
                                        clinicId: 1,
                                        slotsId: i + 1,
                                        timeSlotId: videoTimeSlots.length + 1
                                    }

                                ])
                            }
                            userForm.setFieldsValue({
                                [`start_time${j + 1}`]: moment(doctorClinics?.[i]?.clinic_timings?.[j]?.start_time, 'HH:mm:ss'),
                                [`end_time${j + 1}`]: moment(doctorClinics?.[i]?.clinic_timings?.[j]?.end_time, 'HH:mm:ss'),
                                // [`visit-day${i+1}`]: [doctorClinics?.[i]?.clinic_timings?.[j]?.day]
                            })
                        }
                        userForm.setFieldsValue({
                            [`days${i + 1}`]: days
                        })
                    }
                }
            }
        }

    }, [JSON.stringify(profileData)])


    // prefilled clinic visit data
    useEffect(() => {
        if (Object.keys(profileData)?.length > 0) {
            const doctorClinics = profileData?.doctor_clinics;
            if (doctorClinics?.length > 0) {
                for (let i = 0; i < doctorClinics?.length; i++) {
                    if (i > 0) {
                        addAnotherClinicVisit();
                    }

                    userForm.setFieldsValue({
                        [`visit-clinic_id${i + 1}`]: doctorClinics?.[i]?.clinic_id,
                        [`visit-consultation_fee${i + 1}`]: doctorClinics?.[i]?.consultation_fee,
                        [`visit-mins${i + 1}`]: doctorClinics?.[i]?.consultation_duration,
                    });

                    let days = [];

                    for (let j = 0; j < doctorClinics?.[i]?.clinic_timings?.length; j++) {

                        days.push(doctorClinics?.[i]?.clinic_timings?.[j]?.day);
                        if (doctorClinics?.[i]?.clinic_timings?.[j]?.disable == 1) {
                            continue;
                        }
                        if (j > 0) {
                            setTimeSlots([
                                ...timeSlots,
                                {
                                    clinicId: i + 1,
                                    slotsId: i + 1,
                                    timeSlotId: timeSlots.length + 1
                                }

                            ])
                        }

                        userForm.setFieldsValue({
                            [`visit-start_time${j + 1}`]: moment(doctorClinics?.[i]?.clinic_timings?.[j]?.start_time, 'HH:mm:ss'),
                            [`visit-end_time${j + 1}`]: moment(doctorClinics?.[i]?.clinic_timings?.[j]?.end_time, 'HH:mm:ss'),
                            // [`visit-day${i+1}`]: [doctorClinics?.[i]?.clinic_timings?.[j]?.day]
                        })
                    }

                    userForm.setFieldsValue({
                        [`visit-day${i + 1}`]: days
                    })
                }
            }
        }

    }, [JSON.stringify(profileData)])

    const handleUpdate = async () => {

        // console.log(scheduleTwo, "scheduleTwo")
        const values = userForm.getFieldsValue();

        //Calculation for video form...

        let videoPayload = {};

        let fieldsToValidate = [];

        if (videoClinicCheckbox) {
            fieldsToValidate.push('visit-clinic_id1');
            fieldsToValidate.push('visit-mins1');
            fieldsToValidate.push('visit-consultation_fee1');
            fieldsToValidate.push('visit-start_time1');
            fieldsToValidate.push('visit-end_time1');
        }

        if (videoConsultCheckbox) {
            fieldsToValidate.push('mins');
            fieldsToValidate.push('consultation_fee');
            fieldsToValidate.push('start_time1');
            fieldsToValidate.push('end_time1');
        }

        await userForm.validateFields(fieldsToValidate)

        if (values.hasOwnProperty('mins')) {
            videoPayload.consultation_duration = values['mins'];
            videoPayload.consultation_fee = values['consultation_fee'];

            let schedule = [];

            for (let i = 0; i < videoDaysAndTimeSlots.length; i++) {
                for (let j = 0; j < videoTimeSlots.length; j++) {
                    if (videoDaysAndTimeSlots[i].slotsId === videoTimeSlots[j].slotsId) {
                        schedule?.push({
                            days: timeSlots[j].day ? timeSlots[j].day : "monday",
                            start_time: moment(values[`start_time${j + 1}`]).format('HH:mm:ss'),
                            end_time: moment(values[`end_time${j + 1}`]).format('HH:mm:ss'),
                        })
                    }
                }
            }

            videoPayload.schedule = schedule;

        }

        //Calculation for clinicVisit form...

        let clinicVisit = [];

        let scheduleForVisit = [];

        if (values.hasOwnProperty('visit-clinic_id1')) {
            console.log('hasOwnProperty');

            let clinicLocal = [];
            for (let i = 0; i < clinicRows.length; i++) {
                // console.log(values[`visit-clinic_id${i+1}`], "monis")
                // clinicVisit.push({clinic_id: values[`visit-clinic_id${i+1}`]});
                // clinicVisit.push({consultation_duration: values[`visit-mins${i+1}`]});
                // clinicVisit.push({consultation_fee: values[`visit-consultation_fee${i+1}`]});
                // clinic.push({consultation_fee: values[("visit-consultation_fee"+(i+1))]});
                clinicLocal.push({
                    clinic_id: values[`visit-clinic_id${i + 1}`],
                    consultation_duration: values[`visit-mins${i + 1}`],
                    consultation_fee: values[`visit-consultation_fee${i + 1}`]
                })

                let scheduleLocal = [];

                for (let j = 0; j < daysAndTimeSlots.length; j++) {
                    if (daysAndTimeSlots[j].clinicId === clinicRows[i].id) {
                        for (let k = 0; k < timeSlots.length; k++) {
                            if (timeSlots[k].slotsId === daysAndTimeSlots[j].clinicId) {
                                scheduleLocal?.push({
                                    days: timeSlots[k].day ? timeSlots[k].day : "monday",
                                    start_time: moment(values[`visit-start_time${k + 1}`]).format('HH:mm:ss'),
                                    end_time: moment(values[`visit-end_time${k + 1}`]).format('HH:mm:ss'),
                                })
                            }

                        }
                    }
                }


                clinicVisit?.push({
                    clinic_id: values[`visit-clinic_id${i + 1}`],
                    consultation_duration: values[`visit-mins${i + 1}`],
                    consultation_fee: values[`visit-consultation_fee${i + 1}`],
                    schedule: scheduleLocal
                })
            }
        }

        // clinic.push(scheduleForVisit);



        try {
            let payload = {};

            if (clinicVisit.length > 0) {
                payload.is_clinic_visit = clinicVisit
            }

            if (Object.keys(videoPayload).length > 0) {
                payload.is_video_consultation = videoPayload;
            }

            // if (hasConsultationFee) {
            //     payload.is_clinic_visit = clinicRows?.map((item) => (item));
            // } else if (hasConsultationFeeVideo) {
            //     payload.is_video_consultation = {
            //         consultation_duration: updatedVideoConsultationData[1],
            //         consultation_fee: updatedVideoConsultationData[0],
            //         schedule: updatedVideoConsultationData?.[2]?.map((i) => (i))
            //     };
            // }

            // console.log({payload});
            try {
                setSubmissionLoader(true);
                const response = await API.post('/doctor/time-slots', payload);
                setSubmissionLoader(false);
                if (response?.data?.code === 200) {
                    toast.success(response?.data?.message);
                } else {
                    toast.error(response?.data?.message)
                }
            } catch (error) {
                setSubmissionLoader(false);
            }

        } catch (error) {
            console.log(error, "error")
        }
    }

    function setLocalSlots(checked, day, clinicId, slotId) {
        console.log(checked, day, slotId);

        if (checked && day !== "monday") {
            console.log("add")

            setVideoTimeSlots([
                ...videoTimeSlots,
                {
                    clinicId: clinicId,
                    slotsId: slotId,
                    timeSlotId: timeSlots.length + 1,
                    day: day
                }

            ])
        }

        else if (!checked) {
            const slots = videoTimeSlots?.filter((slot) => {
                return slot?.slotsId !== slotId || slot?.day !== day
            })

            setVideoTimeSlots(slots);
        }

        else { }
    }

    useEffect(() => {
        // if (profileData?.doctor_clinics?.[0]?.clinic_id == 0) {
        //     setVideoConsultCheckbox(true)
        // }
        if (profileData?.doctor_clinics?.[0]?.clinic_timings?.length > 0) {
            setVideoClinicCheckbox(true)
        }
    }, [profileData])


    return (
        <>
            {submissionLoader && (
                <Loader />
            )}
            <Form
                form={userForm}>
                <Row className="main_step_box">
                    <Col lg={12} md={12} className="checkbox_style consultation_checkbox">
                        <div className="mb-24">
                            <Checkbox checked={videoConsultCheckbox} onChange={handleConsultCheckbox}> <span className="video">Video Consultations</span> <span className="consult_p">Consult patients online through video calls. </span></Checkbox>
                        </div>
                        {videoConsultCheckbox && (
                            <div className="video_consult">
                                <Row className="">
                                    <Col md={12} lg={12} className=" position-relative">
                                        <Row>
                                            <Col md={6} lg={6} className="selectBox ">
                                                <Form.Item
                                                    name={`mins`}
                                                    label="Consultation duration in mins* "
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Consultation duration is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Please select" value={consultationDuration} onChange={(value) => onConsultationDurationChange(value)}>
                                                        {consultationMinutes?.length > 0 && consultationMinutes?.map((item) => (
                                                            <>
                                                                <Option value={item?.id}>{item?.minutes} mins</Option>
                                                            </>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={6} lg={6} className="selectBox">
                                                <Form.Item
                                                    name={`consultation_fee`}
                                                    label="Consultation fees*"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Consultation fee is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Enter fee" onChange={(event) => onConsultationFeeChange(event)} />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <div className="add_more mb-0 pb-0">
                                            <Button type="primary" className="btn btn_add" onClick={handleAddDaysRow}><img src={plusIcon} ></img> Add more slots</Button>
                                        </div>
                                        {videoDaysAndTimeSlots?.map((row, index) => (
                                            <Row key={row?.id} className="duplicate_row_days">
                                                <Col md={12} lg={12} className="">
                                                    <Form.Item
                                                        name={`days${row?.slotsId}`}
                                                        label="Consultation days "
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Consultation day is required',
                                                                type: 'array'
                                                            },
                                                        ]}
                                                    >
                                                        <Checkbox.Group className="checkbox_blue">
                                                            <Checkbox
                                                                name="Monday"
                                                                value="monday"
                                                                onChange={(e) => setLocalSlots(e.target.checked, "monday", 1, row?.slotsId)}
                                                            >
                                                                <span className="days">Monday</span>
                                                            </Checkbox>
                                                            <Checkbox
                                                                name="Tuesday"
                                                                value="tuesday"
                                                                onChange={(e) => setLocalSlots(e.target.checked, "tuesday", 1, row?.slotsId)}
                                                            >
                                                                <span className="days">Tuesday</span>
                                                            </Checkbox>
                                                            <Checkbox
                                                                name="Wednesday"
                                                                value="wednesday"
                                                                onChange={(e) => setLocalSlots(e.target.checked, "wednesday", 1, row?.slotsId)}
                                                            >
                                                                <span className="days">Wednesday</span>
                                                            </Checkbox>
                                                            <Checkbox
                                                                name="Thursday"
                                                                value="thursday"
                                                                onChange={(e) => setLocalSlots(e.target.checked, "thursday", 1, row?.slotsId)}
                                                            >
                                                                <span className="days">Thursday</span>
                                                            </Checkbox>
                                                            <Checkbox
                                                                name="Friday"
                                                                value="friday"
                                                                onChange={(e) => setLocalSlots(e.target.checked, "friday", 1, row?.slotsId)}
                                                            >
                                                                <span className="days">Friday</span>
                                                            </Checkbox>
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col md={12} lg={12} className="position-relative">

                                                    <div className="add_more r-p2 text-end" onClick={(e) => handleAddTime(e, row?.slotsId)}>
                                                        <Button type="primary" className="btn btn_add ms-auto  t-nt" >
                                                            <img src={plusIcon} alt="Add" />Add Time
                                                        </Button>
                                                    </div>

                                                    {videoTimeSlots?.map((timeSlot, index) => {
                                                        if (timeSlot?.slotsId === row?.slotsId) {
                                                            return (
                                                                <>
                                                                    <Row key={timeSlot?.timeSlotId} className="duplicate_row_time" >
                                                                        <Col md={6} lg={6} className="selectBox">
                                                                            <Form.Item
                                                                                name={`start_time${timeSlot?.timeSlotId}`}
                                                                                label="Start time "
                                                                                rules={[
                                                                                    {
                                                                                        required: false,
                                                                                        message: 'Start time is required',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <TimePicker
                                                                                    className="custom-timepicker"

                                                                                    format={format}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col md={6} lg={6} className="selectBox">
                                                                            <Form.Item
                                                                                name={`end_time${timeSlot?.timeSlotId}`}
                                                                                label="End time"
                                                                                rules={[
                                                                                    {
                                                                                        required: false,
                                                                                        message: 'End time is required',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <TimePicker
                                                                                    className="custom-timepicker"
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        }
                                                    })}
                                                </Col>
                                                <Col lg={12}>
                                                    {index === 0 ? (
                                                        <Col lg={12} md={12} className="text-end ">
                                                            <Row>
                                                                <Col lg={9} md={9} className="mx-auto">

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    ) : (
                                                        <Col lg={12} md={12} className="text-end ">
                                                            <Row>
                                                                <Col lg={9} md={9} className="mx-auto">
                                                                    <hr />
                                                                </Col>
                                                            </Row>
                                                            <button type="button" className="text-end">
                                                                <img src={deleteIcon} alt="Delete" onClick={() => handleRemoveDaysRow(row?.id)} className="img-fluid" />
                                                            </button>
                                                        </Col>
                                                    )}
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                    <ClinicVisit videoClinicCheckbox={videoClinicCheckbox} setVideoClinicCheckbox={setVideoClinicCheckbox} setClinicRows={setClinicRows} clinicRows={clinicRows} daysAndTimeSlots={daysAndTimeSlots} setDaysAndTimeSlots={setDaysAndTimeSlots} timeSlots={timeSlots} setTimeSlots={setTimeSlots} />
                </Row>
                <Col lg={12} md={12} className="text-end mt-26">
                    <Button type="submit" className="update_btn btn" onClick={handleUpdate}>Update</Button>
                </Col>
            </Form>
        </>
    )
}

export default ConsultationTab