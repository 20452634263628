import React, { useEffect, useState } from "react";
import "./DeductionList.scss";
import "../paymentHistory/paymentHistory.scss";
import { Table, DatePicker } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import { Button, Form, Input, Modal, Popconfirm, Select, Switch } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import TablePaymentHistory from "../../uiComponents/tableComponent/tablePaymentHistory/TablePaymentHistory";
import { Row, Col, Container, InputGroup, FormControl, } from "react-bootstrap";
import searchIcon from "../../assets/images/png/search.png";
import downloadIcon from "../../assets/images/png/download.png";
import { Link } from 'react-router-dom';
import API from "../../utils/customAxios";
import Loader from "../../uiComponents/loader/Loader";
import Accordion from 'react-bootstrap/Accordion';
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";


function DeductionList(props) {
    const { RangePicker } = DatePicker;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [modal2Open, setModal2Open] = useState(false);
    const [deductionHistory, setDeductionHistory] = useState([])
    const [deductionHistoryLoading, setDeductionHistoryLoading] = useState(false)
    const [selectedDeductionId, setSelectedDeductionId] = useState(null);
    const [deductionLoading, setDeductionLoading] = useState(false)
    const [deductionTable, setDeductionTable] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [deductionPage, setDeductionPage] = useState(1);
    const [deductionTotal, setDeductionTotal] = useState(null);
    const [perPage, setPerPage] = useState(0);



  const dateFormatList = ['DD/MM/YYYY'];


    const fetchDeductionData = async (DeductionId) => {
        try {
            setDeductionLoading(true)
            const response = await API.get(`/doctor/deduction-list/${DeductionId}`);
            if (response?.data?.code === 200) {
                setDeductionLoading(false)
                setDeductionTable(response?.data?.data);
            } else {
                setDeductionLoading(false)
                // console.log('Error: Unable to fetch transaction history.');
            }
        } catch (error) {
            setDeductionLoading(false)
            console.error('Error fetching transaction history:', error);
        }
    }

    let baseUrl = process.env.REACT_APP_BASE_URL;

    async function downloadDeductionList() {
      setDownloadLoader(true);
      fetch(`${baseUrl}/doctor/deducation-download?is_html=1&is_download=1`, {
        method: 'GET',
        headers: {
          'Authorization': Cookies.get("token"),
          'Access-Control-Allow-Origin': '*'
        },
      })
        .then(response => response.blob())
        .then(response => {
          const blob = new Blob([response], { type: 'application/csv' });
          const downloadUrl = URL.createObjectURL(blob);
          setDownloadLoader(false);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "file.csv";
          document.body.appendChild(a);
          a.click();
        })
    }

    useEffect(() => {
        if (selectedDeductionId) {
            fetchDeductionData(selectedDeductionId);
        }
    }, [selectedDeductionId]);

    // console.log(deductionTable)

    const handleDateRangeChange = (dates, dateStrings) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
    };

    const paymentHistoryPages = {
        pageSize: perPage,
        current: deductionPage,
        total: deductionTotal,
        onChange: (page) => {
            setDeductionPage(page)
            setDeductionHistoryLoading(true);
            API.get(`/doctor/deduction-list?page=${page}`)
                .then((response) => {
                    if (response?.data?.code === 200) {
                        setDeductionHistoryLoading(false);
                        setDeductionHistory(response?.data?.data);
                    } else {
                        setDeductionHistoryLoading(false);
                    }
                })
                .catch((err) => {
                    setDeductionHistoryLoading(false);
                });
        },
    }


    useEffect(() => {
        setDeductionHistoryLoading(true);
        API.get(`/doctor/deduction-list?=1`)
            .then((response) => {
                if (response?.data?.code === 200) {
                    setDeductionHistoryLoading(false);
                    setDeductionHistory(response?.data?.data);
                    setDeductionTotal(response?.data?.page?.total)
                    setPerPage(response?.data?.page?.per_page)


                } else {
                    setDeductionHistoryLoading(false);
                }
            })
            .catch((err) => {
                setDeductionHistoryLoading(false);
            });
    }, []);


    const columns = [
        {
            title: 'Date of Deduction',
            dataIndex: 'date',
            key: 'date',
            // sorter: (a, b) => a.id - b.id,

        },
        {
            title: 'Deducted Amount (Rs.)',
            dataIndex: 'amount',
            key: 'amount',
            // sorter: (a, b) => a.amount - b.date,
        },
        ,

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // sorter: (a, b) => a.id - b.action,
        },
    ];


    const filteredData = deductionHistory.filter((item) => {
        const date = moment(item.date);
        return startDate === null || endDate === null || (date >= startDate && date <= endDate);
    });

    const dataSource = filteredData.map((item) => ({

        id: item?.id,
        amount: item?.amount,
        date: moment(item?.date).format('DD/MM/YYYY'),
        action: <a style={{textDecoration:'underline'}} onClick={() => {
            setSelectedDeductionId(item?.id);
            setModal2Open(true);
        }} >View List</a>,
    }));

    console.log({dataSource})

    return (
        <>
            <div className="Payment_history deduction_list cover_space">
                {(deductionHistoryLoading || downloadLoader) && <Loader />}
                <Container>
                    {isMobile ? (
                             <Row className="align-items-center pb-30">
                             <Col md={6} lg={6}>
                                 <h1>
                                     <Link to="/payment-history">
                                         <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="#313131" />
                                             <path d="M10.3915 17.8928L15.5075 12.8018C15.6918 12.6199 15.9407 12.5184 16.1997 12.5195C16.4587 12.5207 16.7066 12.6244 16.8893 12.808C17.0719 12.9916 17.1744 13.2401 17.1743 13.4991C17.1741 13.7581 17.0714 14.0064 16.8885 14.1898L13.4535 17.6078H25.5835C25.8431 17.6078 26.0922 17.711 26.2758 17.8946C26.4594 18.0782 26.5625 18.3272 26.5625 18.5868C26.5625 18.8465 26.4594 19.0955 26.2758 19.2791C26.0922 19.4627 25.8431 19.5658 25.5835 19.5658H13.4545L16.8895 22.9838C17.0724 23.1672 17.1751 23.4156 17.1753 23.6746C17.1754 23.9335 17.0729 24.182 16.8903 24.3656C16.7076 24.5492 16.4597 24.6529 16.2007 24.6541C15.9417 24.6553 15.6928 24.5538 15.5085 24.3718L10.3925 19.2808C10.3011 19.1898 10.2286 19.0817 10.1791 18.9627C10.1296 18.8436 10.104 18.716 10.1039 18.587C10.1038 18.4581 10.1292 18.3304 10.1785 18.2113C10.2279 18.0921 10.3003 17.9839 10.3915 17.8928Z" fill="#313131" />
                                         </svg>
                                     </Link>
                                     DEDUCTION LIST</h1>
                                 <div className="d-block d-sm-none">
                                     <div className="date_filter mobile_history">
                                         <h5>History</h5>
                                         <span onClick={downloadDeductionList} className="download_btn">
                                             <img src={downloadIcon} className="img-fluid" />
                                             Download
                                         </span>
                                     </div>
                                 </div>
                                 <Col md={3} lg={3} className="search-bar  or3">
                                 <div className="btn_search d-lg-none marginTopSearchBar ">
                                     <InputGroup>
                                         <FormControl
                                         // onKeyDown={earningSearch}
                                         // value={earningSearchValue}
                                         // onChange={(e) => setEarningSearchValue(e.target.value)} 
                                         placeholder="Search by Transaction ID..."
                                         />
                                     </InputGroup>
                                     <span className="icon_search">
                                         <img  src={searchIcon} className="img-fluid" />
                                     </span>
                                 </div>
     
                             </Col>
     
                             </Col>
                             <Col md={6} lg={6} className="text-end ">
     
                                 <div className="date_filter d-none d-sm-block">
                                     <span className="from_01">From</span>
                                     <DatePicker.RangePicker
                                         value={[startDate, endDate]}
                                         onChange={handleDateRangeChange}
                                         format={dateFormatList}
                                         clearIcon={null}
                                         style={{ marginBottom: 8 }} />
                                     <span onClick={downloadDeductionList} className="download_btn">
                                         <img src={downloadIcon} className="img-fluid" />
                                         Download
                                     </span>
                                 </div>
                                 <div className="accordian_earning_mobile accordian_filter">
                                     <Accordion defaultActiveKey="0" className="d-block d-sm-none mobile_filt">
     
                                         <Accordion.Item eventKey="1">
                                             <Accordion.Header>Filter</Accordion.Header>
                                             <Accordion.Body>
                                                 <div className="date_filter " >
                                                     <span className="from_01">From</span>
                                                     <DatePicker.RangePicker
                                                         value={[startDate, endDate]}
                                                         format={dateFormatList}
                                                         clearIcon={null}
                                                         onChange={handleDateRangeChange}
                                                         style={{ marginBottom: 8 }} />
     
                                                 </div>
                                             </Accordion.Body>
                                         </Accordion.Item>
                                     </Accordion>
                                 </div>
     
     
     
                             </Col>
     
     
                         </Row>
                    ) : (
                        <Row className="align-items-center mt-5 pb-30">
                        <Col md={6} lg={6}>
                            <h1>
                                <Link to="/payment-history">
                                    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="#313131" />
                                        <path d="M10.3915 17.8928L15.5075 12.8018C15.6918 12.6199 15.9407 12.5184 16.1997 12.5195C16.4587 12.5207 16.7066 12.6244 16.8893 12.808C17.0719 12.9916 17.1744 13.2401 17.1743 13.4991C17.1741 13.7581 17.0714 14.0064 16.8885 14.1898L13.4535 17.6078H25.5835C25.8431 17.6078 26.0922 17.711 26.2758 17.8946C26.4594 18.0782 26.5625 18.3272 26.5625 18.5868C26.5625 18.8465 26.4594 19.0955 26.2758 19.2791C26.0922 19.4627 25.8431 19.5658 25.5835 19.5658H13.4545L16.8895 22.9838C17.0724 23.1672 17.1751 23.4156 17.1753 23.6746C17.1754 23.9335 17.0729 24.182 16.8903 24.3656C16.7076 24.5492 16.4597 24.6529 16.2007 24.6541C15.9417 24.6553 15.6928 24.5538 15.5085 24.3718L10.3925 19.2808C10.3011 19.1898 10.2286 19.0817 10.1791 18.9627C10.1296 18.8436 10.104 18.716 10.1039 18.587C10.1038 18.4581 10.1292 18.3304 10.1785 18.2113C10.2279 18.0921 10.3003 17.9839 10.3915 17.8928Z" fill="#313131" />
                                    </svg>
                                </Link>
                                DEDUCTION LIST</h1>
                            <div className="d-block d-sm-none">
                                <div className="date_filter mobile_history">
                                    <h5>History</h5>
                                    <span onClick={downloadDeductionList} className="download_btn">
                                        <img src={downloadIcon} className="img-fluid" />
                                        Download
                                    </span>
                                </div>
                            </div>
                            <Col md={3} lg={3} className="search-bar  or3">
                            <div className="btn_search d-lg-none marginTopSearchBar ">
                                <InputGroup>
                                    <FormControl
                                    // onKeyDown={earningSearch}
                                    // value={earningSearchValue}
                                    // onChange={(e) => setEarningSearchValue(e.target.value)} 
                                    placeholder="Search by Transaction ID..."
                                    />
                                </InputGroup>
                                <span className="icon_search">
                                    <img  src={searchIcon} className="img-fluid" />
                                </span>
                            </div>

                        </Col>

                        </Col>
                        <Col md={6} lg={6} className="text-end ">

                            <div className="date_filter d-none d-sm-block">
                                <span className="from_01">From</span>
                                <DatePicker.RangePicker
                                    value={[startDate, endDate]}
                                    onChange={handleDateRangeChange}
                                    format={dateFormatList}
                                    clearIcon={null}
                                    style={{ marginBottom: 8 }} />
                                <span onClick={downloadDeductionList} className="download_btn">
                                    <img src={downloadIcon} className="img-fluid" />
                                    Download
                                </span>
                            </div>
                            <div className="accordian_earning_mobile accordian_filter">
                                <Accordion defaultActiveKey="0" className="d-block d-sm-none mobile_filt">

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Filter</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="date_filter " >
                                                <span className="from_01">From</span>
                                                <DatePicker.RangePicker
                                                    value={[startDate, endDate]}
                                                    format={dateFormatList}
                                                    clearIcon={null}
                                                    onChange={handleDateRangeChange}
                                                    style={{ marginBottom: 8 }} />

                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>



                        </Col>


                    </Row>
                    )}
                   
                    <Row>
                        <Col md={12} lg={12} className="d-none webDeduction d-sm-block">

                            <TablePaymentHistory
                                header={columns}
                                data={dataSource}
                                pagination={paymentHistoryPages}
                                emptyMessage="No deductions to show"
                            />
                        </Col>
                        <Col md={12} lg={12} className="d-block d-sm-none">
                            {dataSource?.length < 1 ? (
                                <div className="no_data_mobile d-none">
                                <p>No Deduction to show</p>
                            </div>
                            ):  <>
                                {dataSource.map((item) => (
                                        <div className="mobile_record">
                                        <ul className="mobile_record_list list-unstyled">
                                            <li><p>Date of Transaction</p><span>{item?.date}</span></li>
        
                                            <li><h6 className="priceWeb">{`Rs. ${item?.amount}`}</h6>  <a>{item?.action}</a></li>
                                        </ul>
                                    </div> 
                                ))}
                                </>
                             }
                            
                            
                        </Col>
                    </Row>
                </Container>

                <div className="modal_appointment">
                    <Modal
                        className='payment_history_modal'
                        title="Cancelled Appointments"
                        centered
                        visible={modal2Open}
                        onOk={() => setModal2Open(false)}
                        onCancel={() => setModal2Open(false)}
                    >

                        <div className="appointment_list_mobile d-block d-sm-none">
                            {deductionTable.map((deductions) => (
                                <ul className="list_appoint_mob  list-unstyled">
                                <li>
                                    <p><span>Appointment ID: </span>{deductions?.id}</p>
                                    <p>{deductions?.date}</p>
                                </li>
                                <li>
                                    <h6 className="price1">{`Rs. ${deductions?.consultation_penalty}`}</h6>
                                </li>
                            </ul>
                            ))}
                            
                        </div>
                        <ul className="list_appoint d-none d-sm-block">
                            <li className="header_list">
                                <ul>
                                    <li>Date </li>
                                    <li>Appointment ID </li>
                                    <li>Cancellation Penalty </li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="list_appoint d-none d-lg-block fix-height">
                            {deductionTable.map((deduction) => (
                                <li className="listing" key={deduction.id}>
                                    <ul>
                                        <li>{deduction?.date}</li>
                                        <li>{deduction?.id}</li>
                                        <li>{deduction?.consultation_penalty}</li>
                                    </ul>
                                </li>
                            ))}
                        </ul>

                    </Modal>
                </div>

            </div >
        </>
    );
}

export default DeductionList;