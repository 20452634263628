import React, { useEffect, useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { v4 as uuidv4 } from "uuid";
import "./profileUpdate.scss";
import {
  Button,
  Form,
  Input,
  Select,
  Radio,
  Space,
  Popover,
  DatePicker,
  Checkbox,
  TimePicker,
} from "antd";
import { Container, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  selectSpeciality,
  selectSpecialityLoader,
  selectUser,
} from "../updateProfile/redux/slice";
import API from "../../utils/customAxios";
import { useEffectOnce } from "react-use";
import moment from "moment";
import ImageUploader from "react-image-upload";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSpeciality } from "../updateProfile/redux/thunk";
import Cookies from "js-cookie";
import Loader from "../../uiComponents/loader/Loader";
import dayjs from "dayjs";
import plusIcon from "../../assets/images/png/plus.png";
import deleteIcon from "../../assets/images/png/delete_icon.png";

const { Option } = Select;

const ClinicVisit = ({
  clinicRows,
  setClinicRows,
  daysAndTimeSlots,
  setDaysAndTimeSlots,
  timeSlots,
  setTimeSlots,
  videoClinicCheckbox,
  setVideoClinicCheckbox,
}) => {
  // all states here start
  const [videoConsultCheckbox, setVideoConsultCheckbox] = useState(false);

  const [hospitalClinics, setHospitalClinics] = useState([]);
  const [consultationMinutes, setConsultationMinutes] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState([]);
  // all states here end

  // all onchange function and useEffects start

  const clinicHospital = async () => {
    try {
      const response = await API.get("/doctor/all-clinic-listing");
      if (response?.data?.code === 200) {
        setHospitalClinics(response?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffectOnce(() => {
    clinicHospital();
  });

  useEffect(() => {
    const currentYear = moment().year();
    const minutesArray = [];
    const minutesInterval = 5;
    const maxMinutes = 30;

    for (
      let minutes = minutesInterval;
      minutes <= maxMinutes;
      minutes += minutesInterval
    ) {
      minutesArray.push({ id: minutes, minutes: minutes });
    }

    setConsultationMinutes(minutesArray);
  }, []);

  const handleClinicCheckbox = () => {
    setVideoClinicCheckbox(!videoClinicCheckbox);
  };

  function addAnotherClinic() {
    addTimeSlot(null, clinicRows.length + 1, daysAndTimeSlots.length + 1);
    addDaysAndTimeSlot(null, clinicRows.length + 1);

    setClinicRows([...clinicRows, { id: clinicRows.length + 1 }]);
  }

  function deleteClinic(e, clinicId) {
    setClinicRows(clinicRows?.filter((clinic) => clinic?.id != clinicId));
  }

  function addDaysAndTimeSlot(e, clinicId) {
    addTimeSlot(null, clinicId, daysAndTimeSlots.length + 1);

    setDaysAndTimeSlots([
      ...daysAndTimeSlots,
      {
        clinicId: clinicId,
        slotsId: daysAndTimeSlots.length + 1,
      },
    ]);
  }

  function deleteDaysAndTimeSlot(e, slotId) {
    const slots = daysAndTimeSlots?.filter((item) => {
      return item?.slotsId !== slotId;
    });

    setDaysAndTimeSlots(slots);
  }

  function addTimeSlot(e, clinicId, slotId) {
    console.log("zayk");

    setTimeSlots([
      ...timeSlots,
      {
        clinicId: clinicId,
        slotsId: slotId,
        timeSlotId: timeSlots.length + 1,
      },
    ]);
  }

  function deleteTimeSlot(e, clinicId, slotId, timeSlotId) {
    const slots = timeSlots?.filter((item) => {
      return item?.timeSlotId !== timeSlotId;
    });

    setTimeSlots(slots);
  }

  function deleteTimeSlotBySlotId(slotId) {
    const slots = timeSlots?.filter((item) => {
      return item?.slotsId !== slotId;
    });

    setTimeSlots(slots);
  }

  function setLocalSlots(checked, day, clinicId, slotId) {
    console.log(checked, day, slotId);

    if (checked && day !== "monday") {
      console.log("add");

      setTimeSlots([
        ...timeSlots,
        {
          clinicId: clinicId,
          slotsId: slotId,
          timeSlotId: timeSlots.length + 1,
          day: day,
        },
      ]);
    } else if (!checked) {
      const slots = timeSlots?.filter((slot) => {
        return slot?.slotsId !== slotId || slot?.day !== day;
      });

      setTimeSlots(slots);
    } else {
    }
  }

  const validateLocationNumber = (_, value) => {
    const alphanumericRegex = /^[a-zA-Z0-9\s]*[a-zA-Z0-9][a-zA-Z0-9\s]*$/; // Regular expression to allow alphanumeric characters and spaces, with at least one alphanumeric character

    const isAlphanumeric = alphanumericRegex.test(value);

    if (!isAlphanumeric) {
      return Promise.reject("Location should be alphanumeric");
    }

    return Promise.resolve();
  };

  // all onchange function and useEffects end

  return (
    <>
      <Col lg={12} md={12} className="checkbox_style consultation_checkbox">
        <Checkbox checked={videoClinicCheckbox} onChange={handleClinicCheckbox}>
          {" "}
          <span className="video">Clinic Visits</span>{" "}
          <span className="consult_p">
            Consult patients in-person at your clinic.{" "}
          </span>
        </Checkbox>

        {videoClinicCheckbox && (
          <div className="video_consult ">
            <Row className="">
              <Col lg={12}>
                <div className="add_more position-relative r-1">
                  <Button
                    className="btn btn_add ms-auto btn-dark"
                    onClick={addAnotherClinic}
                  >
                    + Add more consulting clinic
                  </Button>
                </div>
              </Col>

              <Col
                lg={12}
                md={12}
                className="checkbox_style consultation_checkbox"
              >
                <div className="video_consult pt-5">
                  <Row className="">
                    <Col md={12} lg={12} className="position-relative">
                      {clinicRows?.map((row, index) => (
                        <Row key={row?.id} className="duplicate_row_days">
                          <Col md={6} lg={6} className="selectBox ">
                            <Form.Item
                              name={`visit-clinic_id${row?.id}`}
                              label="Clinic/Hospital* "
                              rules={[
                                {
                                  required: true,
                                  message: "Clinic/Hospital is required",
                                },
                              ]}
                            >
                              <Select placeholder="Please select">
                                {hospitalClinics?.length > 0 &&
                                  hospitalClinics?.map((item) => (
                                    <>
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    </>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={6} lg={6} className=" ">
                            <Form.Item
                              name={`visit-clinic_location${row?.id}`}
                              label="Location* "
                              rules={[
                                {
                                  // required: true,
                                  message: "Location is required",
                                },
                                { validator: validateLocationNumber },
                              ]}
                            >
                              <Input placeholder="Enter location" />
                            </Form.Item>
                          </Col>
                          <Col md={6} lg={6} className="selectBox  ">
                            <Form.Item
                              name={`visit-mins${row?.id}`}
                              label="Consultation duration in mins* "
                              rules={[
                                {
                                  required: true,
                                  message: "Consultation duration is required",
                                },
                              ]}
                            >
                              <Select placeholder="Please select">
                                {consultationMinutes?.length > 0 &&
                                  consultationMinutes?.map((item) => (
                                    <>
                                      <Option value={item?.id}>
                                        {item?.minutes} mins
                                      </Option>
                                    </>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={6} lg={6} className="selectBox">
                            <Form.Item
                              name={`visit-consultation_fee${row?.id}`}
                              label="Consultation fees* "
                              rules={[
                                {
                                  required: true,
                                  message: "Consultation fee is required",
                                },
                              ]}
                            >
                              <Input placeholder="Enter fee" />
                            </Form.Item>
                          </Col>

                          <Col lg={12}>
                            <div className="add_more position-relative r-1 ddss">
                              <Button
                                className="btn btn_add ms-auto btn-dark"
                                onClick={(e) => addDaysAndTimeSlot(e, row?.id)}
                              >
                                + Add more slots
                              </Button>
                            </div>
                          </Col>

                          {daysAndTimeSlots?.map((slot, idx) => {
                            if (slot?.clinicId == row?.id) {
                              return (
                                <>
                                  <Col md={12} lg={12} className="">
                                    <Form.Item
                                      name={`visit-day${slot?.slotsId}`}
                                      label="Consultation days "
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            "At least one consultation day is required",
                                          type: "array",
                                        },
                                      ]}
                                    >
                                      <Checkbox.Group className="checkbox_blue">
                                        <Checkbox
                                          name="Monday"
                                          value="monday"
                                          onChange={(e) =>
                                            setLocalSlots(
                                              e.target.checked,
                                              "monday",
                                              row?.id,
                                              slot?.slotsId
                                            )
                                          }
                                        >
                                          <span className="days">Monday</span>
                                        </Checkbox>
                                        <Checkbox
                                          name="Tuesday"
                                          value="tuesday"
                                          onChange={(e) =>
                                            setLocalSlots(
                                              e.target.checked,
                                              "tuesday",
                                              row?.id,
                                              slot?.slotsId
                                            )
                                          }
                                        >
                                          <span className="days">Tuesday</span>
                                        </Checkbox>
                                        <Checkbox
                                          name="Wednesday"
                                          value="wednesday"
                                          onChange={(e) =>
                                            setLocalSlots(
                                              e.target.checked,
                                              "wednesday",
                                              row?.id,
                                              slot?.slotsId
                                            )
                                          }
                                        >
                                          <span className="days">
                                            Wednesday
                                          </span>
                                        </Checkbox>
                                        <Checkbox
                                          name="Thursday"
                                          value="thursday"
                                          onChange={(e) =>
                                            setLocalSlots(
                                              e.target.checked,
                                              "thursday",
                                              row?.id,
                                              slot?.slotsId
                                            )
                                          }
                                        >
                                          <span className="days">Thursday</span>
                                        </Checkbox>
                                        <Checkbox
                                          name="Friday"
                                          value="friday"
                                          onChange={(e) =>
                                            setLocalSlots(
                                              e.target.checked,
                                              "friday",
                                              row?.id,
                                              slot?.slotsId
                                            )
                                          }
                                        >
                                          <span className="days">Friday</span>
                                        </Checkbox>
                                      </Checkbox.Group>
                                    </Form.Item>
                                  </Col>

                                  <Col
                                    md={12}
                                    lg={12}
                                    className="position-relative"
                                  >
                                    <div className="add_more r-p2 text-end">
                                      <Button
                                        type="primary"
                                        className="btn btn_add ms-auto  t-nt"
                                        onClick={(e) =>
                                          addTimeSlot(e, row?.id, slot?.slotsId)
                                        }
                                      >
                                        <img src={plusIcon} alt="Add" /> Add
                                        Time
                                      </Button>
                                    </div>

                                    {timeSlots?.map(
                                      (timeSlot, timeSlotIndex) => {
                                        if (
                                          timeSlot?.slotsId === slot?.slotsId &&
                                          timeSlot?.clinicId === row?.id
                                        ) {
                                          return (
                                            <>
                                              <Row
                                                key={row?.id}
                                                className="duplicate_row_time"
                                              >
                                                <Col
                                                  xs={6}
                                                  md={6}
                                                  lg={6}
                                                  className="selectBox"
                                                >
                                                  <Form.Item
                                                    name={`visit-start_time${timeSlot?.timeSlotId}`}
                                                    label="Start time "
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          "Start time is required",
                                                      },
                                                    ]}
                                                  >
                                                    <TimePicker
                                                      className="custom-timepicker"
                                                      format="HH:mm:ss"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col
                                                  xs={6}
                                                  md={6}
                                                  lg={6}
                                                  className="selectBox"
                                                >
                                                  <Form.Item
                                                    name={`visit-end_time${timeSlot?.timeSlotId}`}
                                                    label="End time "
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message:
                                                          "End time is required",
                                                      },
                                                    ]}
                                                  >
                                                    <TimePicker
                                                      className="custom-timepicker"
                                                      format="HH:mm:ss"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              </Row>

                                              <div className="text-end">
                                                <button type="button">
                                                  <img
                                                    src={deleteIcon}
                                                    onClick={(e) =>
                                                      deleteTimeSlot(
                                                        e,
                                                        row?.id,
                                                        slot?.slotsId,
                                                        timeSlot?.timeSlotId
                                                      )
                                                    }
                                                    alt="Delete"
                                                    className="img-fluid"
                                                  />
                                                </button>
                                              </div>
                                            </>
                                          );
                                        }
                                      }
                                    )}

                                    <Col lg={12} md={12} className="text-end">
                                      <Row>
                                        <Col lg={9} md={9} className="mx-auto">
                                          <hr />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Col>
                                  <button type="button">
                                    <img
                                      src={deleteIcon}
                                      onClick={(e) =>
                                        deleteDaysAndTimeSlot(e, slot?.slotsId)
                                      }
                                      alt="Delete"
                                      className="img-fluid"
                                    />
                                  </button>
                                </>
                              );
                            }
                          })}
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Col>
    </>
  );
};

export default ClinicVisit;
