import React, { useEffect, useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { v4 as uuidv4 } from "uuid";
import plusIcon from "../../assets/images/png/plus.png";
import deleteIcon from "../../assets/images/png/delete_icon.png";
import "./profileUpdate.scss";
import "./../../uiComponents/ProfileSetup/Steps/Steps.scss";
import {
  Button,
  Form,
  Input,
  Select,
  Radio,
  Space,
  Popover,
  DatePicker,
  Checkbox,
  TimePicker,
  Upload,
  Modal,
} from "antd";
import { ConsoleIcon, Label } from "evergreen-ui";
import infoIcon from "../../assets/images/png/info.png";
import { Container, Col, Row } from "react-bootstrap";
import editProfile from "../../assets/images/png/edit.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  selectSpeciality,
  selectSpecialityLoader,
  selectUser,
} from "../updateProfile/redux/slice";
import API from "../../utils/customAxios";
import { useEffectOnce } from "react-use";
import moment from "moment";
import ImageUploader from "react-image-upload";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSpeciality } from "../../pages/updateProfile/redux/thunk";
import Cookies from "js-cookie";
import Loader from "../../uiComponents/loader/Loader";
import QualificationTab from "./QualificationTab";
import ConsultationTab from "./ConsultationTab";

const { Option } = Select;
let index = 0;
let index1 = 0;

const ProfileUpdates = () => {
  const dispatch = useAppDispatch();

  const [addname, setAddName] = useState("");
  const [showAddItem, setShowAddItem] = useState(false); // State to control the visibility of Add item field and button
  const [itemsdegree, setItemsDegree] = useState([
    "Mbbs",
    "Mbbs1",
    "Hypertension",
    "Other",
  ]);
  const [showAddDegree, setShowAddDigree] = useState(false); // State to control the visibility of Add item field and button
  const [addnamedegree, setAddNameDegree] = useState("");
  const [addSpecialties, setAddSpecialties] = useState([]);
  const [userForm] = Form.useForm();
  const [cities, setCities] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [bankDetails, setBankDetails] = useState();
  const [totalExperience, setTotalExperience] = useState();

  // on change fields states start

  const [name, setName] = useState("");
  const [assistantMobileNumber, setassistantMobileNumber] = useState("");
  const [doctorNumber, setDoctorNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [cityId, setCityId] = useState(null);
  const [experience, setExperience] = useState(null);
  const [about, setAbout] = useState("");
  const [pmc, setPmc] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountIban, setAccountIban] = useState("");
  const [accountBankName, setAccountBankName] = useState("");
  const [cnic, setCnic] = useState("");
  const [servicesLoader, setServicesLoader] = useState(false);
  const [diseasesLoader, setDiseasesLoader] = useState(false);
  const [secondService, setSecondService] = useState();
  const [thirdService, setThirdService] = useState();
  const [specialityLists, setSpecialityLists] = useState([]);
  const [specialityServices, setSpecialityServices] = useState([]);
  const [conditionsTreatLists, setConditionsTreatLists] = useState(null);
  const [prefilledDisease, setPrefilledDisease] = useState(null);
  const [prefilledServices, setPrefilledServices] = useState(null);

  // on change fields states end

  // const user = useSelector(selectUser);
  const specialities = useAppSelector(selectSpeciality);
  const specialityLoader = useAppSelector(selectSpecialityLoader);
  const [specialityValue, setSpecialityValue] = useState([]);
  const [specialityService, setSpecialityService] = useState([]);
  const [specialityServiceFull, setSpecialityServiceFull] = useState([]);
  const [updatedData, setUpdatedData] = useState({});
  const [diseaseData, setDiseaseData] = useState({});
  // const [prefilledConditionId, setPrefilledConditionId] = useState(null);
  const [forUpdateConditions, setforUpdateConditions] = useState(null);
  const [forUpdateServices, setforUpdateServices] = useState(null);
  const [user, setUser] = useState({});
  const [profileVerificationDoctor, setProfileVerificationDoctor] =
    useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`doctor/progress`);
        if (response?.data?.code === 200) {
          setProfileVerificationDoctor(
            response?.data?.data?.profile_verification
          );
        }
      } catch (error) { }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`/user`);

        if (response?.data?.code === 200) {
          setUser(response?.data?.data);
          setEmail(response?.data?.data?.user?.email)
        }
      } catch (error) { }
    })();
  }, []);

  console.log(user, "setUsersetUser")

  console.log({ email })

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`doctor/profile-details?specialities=1`);

        if (response?.data?.code === 200) {
          setUpdatedData(response?.data?.data);
          // setPrefilledConditionId(response?.data?.data?.doctor_specialities?.[0]?.speciality_id)
        }
      } catch (error) { }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`doctor/profile-details?education=1`);

        if (response?.data?.code === 200) {
          setUpdatedData(response?.data?.data);
          // setPrefilledConditionId(response?.data?.data?.doctor_specialities?.[0]?.speciality_id)
        }
      } catch (error) { }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`doctor/profile-details?experiences=1`);

        if (response?.data?.code === 200) {
          setUpdatedData(response?.data?.data);
          // setPrefilledConditionId(response?.data?.data?.doctor_specialities?.[0]?.speciality_id)
        }
      } catch (error) { }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(
          `doctor/profile-details?certification=1`
        );

        if (response?.data?.code === 200) {
          setUpdatedData(response?.data?.data);
          // setPrefilledConditionId(response?.data?.data?.doctor_specialities?.[0]?.speciality_id)
        }
      } catch (error) { }
    })();
  }, []);

  //    ;speciality start
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [selectPairs, setSelectPairs] = useState([
    {
      id: 1,
      firstSelectValue: "",
      secondSelectOptions: [],
      thirdSelectOptions: [],
    },
  ]);


  function handleSpecialityChange(value) {
    setSelectedSpecialities([...selectedSpecialities, value]);
  }

  function handleServiceChange(value) {
    setSelectedServices([...selectedServices, value]);
  }

  function handleDiseaseChange(value) {
    setSelectedDiseases([...selectedDiseases, value]);
  }

  const handleFirstSelectChange = (id, value) => {
    setSelectPairs((prevPairs) =>
      prevPairs.map((pair) => {
        if (pair.id === id) {
          // fetchSecondSelectOptions(id, value);
          return { ...pair, firstSelectValue: value };
        }
        return pair;
      })
    );
    fetchSecondSelectOptions(id, value);
    fetchThirdSelectOptions(id, value);
  };

  const handleConditionsChange = (value) => {
    setShowAddItem(!showAddItem);
    setShowAddItem(value.includes("Other"));
    handleDiseaseChange(value);
  };

  const onConditionNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (selectPairs?.length > 0) {
      Cookies.set("specialityElementLength", selectPairs?.length);
    }
  }, [selectPairs?.length]);

  // Function to fetch options for the second select based on the first select value
  const fetchSecondSelectOptions = async (id, firstSelectValue) => {
    try {
      setServicesLoader(true);
      const response = await API.get(
        `/services?speciality_id=${firstSelectValue}`
      );
      setServicesLoader(false);
      // Update the options for the second select
      setSelectPairs((prevPairs) =>
        prevPairs.map((pair) => {
          if (pair.id === id) {
            return {
              ...pair,
              secondSelectOptions: response?.data?.data?.services,
            };
          }
          return pair;
        })
      );
    } catch (error) {
      setServicesLoader(false);
    }
    // Calling the API here with the first select value
  };

  console.log(selectedServices, "setSelectedServices")


  const fetchThirdSelectOptions = async (id, firstSelectValue) => {
    try {
      setDiseasesLoader(true);
      const response = await API.get(
        `/disease?speciality_id=${firstSelectValue}&all=true`
      );
      setDiseasesLoader(false);

      setSelectPairs((prevPairs) =>
        prevPairs.map((pair) => {
          if (pair.id === id) {
            return {
              ...pair,
              thirdSelectOptions: response?.data?.data?.disease,
            };
          }
          return pair;
        })
      );
    } catch (error) {
      setDiseasesLoader(false);
    }
  };

  // Function to handle change in the first select

  //   ;speciality end

  useEffect(() => {
    dispatch(getSpeciality());
  }, []);
  // on change functions

  const onNameChanged = (e) => {
    setName(e.target.value);
  };

  const onAssistantMobileChange = (event) => {
    setassistantMobileNumber(event.target.value);
  };

  const onDoctorMobileChange = (event) => {
    setDoctorNumber(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onDobChange = (date, dateString) => {
    setDob(dateString);
  };

  const onGenderChange = (value) => {
    setGender(value);
  };

  const onCityChange = (value) => {
    setCityId(value);
  };

  const onExperienceChange = (value) => {
    setExperience(value);
  };

  const onAboutChange = (event) => {
    setAbout(event.target.value);
  };

  const onPmcChange = (event) => {
    setPmc(event.target.value);
  };

  const onAccountNameChange = (event) => {
    setAccountName(event.target.value);
  };

  const onAccountIbanChange = (event) => {
    setAccountIban(event.target.value);
  };

  const onBankNameChange = (event) => {
    setAccountBankName(event.target.value);
  };

  const onCnicChange = (event) => {
    setCnic(event.target.value);
  };

  //koke

  const handleSpecChange = async (value, id) => {
    try {
      setServicesLoader(true);
      const response = await API.get(`/services?speciality_id=${value}`);
      setAddSpecialties((prevRows) => {
        const updatedRows = prevRows.map((row) =>
          row.id === id ? { ...row, speciality_id: value } : row
        );
        return updatedRows;
      });
      setSecondService(response?.data?.data?.services);
      handleSpecChangeArea(value);
      setServicesLoader(false);
    } catch (error) {
      setServicesLoader(false);
    }
  };

  const handleSpecChangeArea = async (value) => {
    try {
      const response = await API.get(
        `/disease?speciality_id=${value}&all=true`
      );
      setThirdService(response?.data?.data?.disease);
      // const optionss = [...thirdService?.map((item) => ({ label: item?.name, value: item?.id })), // Dynamic options
      //     { label: 'Other', value: 'other' },
      // ];
    } catch (error) {
      setDiseasesLoader(false);
    }
  };

  const handleSpecChange2 = (value, id) => {
    setAddSpecialties((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.id === id ? { ...row, service_id: value } : row
      );
      return updatedRows;
    });
  };

  const handleSpecChange3 = (value, id) => {
    const updatedRows = addSpecialties?.map((row) =>
      row.id === id ? { ...row, conditions: value } : row
    );
    setAddSpecialties(updatedRows);
  };
  // onchange function end

  // Function to fetch options for the second select based on the first select value
  const inputRef = useRef(null);
  const inputRefdegree = useRef(null);

  const handleConditionsChangeDigree = (value) => {
    setShowAddDigree(!showAddDegree);
    setShowAddDigree(value.includes("Other"));
  };

  const onNameChange = (event) => {
    setAddName(event.target.value);
  };

  const onNameChangedegree = (event) => {
    setAddNameDegree(event.target.value);
  };

  const addItemdegree = (e) => {
    e.preventDefault();
    if (addnamedegree !== "Other") {
      // setItems([...items, name || `New item ${index++}`]);// Add new item at the end
      setItemsDegree([addnamedegree || `New item ${index1++}`, ...itemsdegree]); // Add new item at the beginning
    }
    setAddNameDegree("");
    setTimeout(() => {
      inputRefdegree.current?.focus();
    }, 0);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (addname !== "other") {
      // Add new item at the beginning of the services array
      setThirdService([{ label: addname, value: index }, ...thirdService]);
    }
    setAddName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const { TextArea } = Input;
  const content = (
    <div>
      <p className="info">
        In case you don't make a selection, we will assign the most common
        <br></br> diseases related to your speciality to boost your visibility
        on our<br></br> platform.
      </p>
    </div>
  );

  const contentcnic = (
    <div>
      <p className="info"> CNIC of the account holder</p>
    </div>
  );

  // const handleAddSpecialties = () => {
  //     const newRow = {
  //         id: new Date().getTime(), // Generate a unique ID for the new row
  //         speciality_id: '',
  //         service_id: '',
  //         conditions: '',
  //     };

  //     setAddSpecialties((prevRows) => {
  //         const updatedRows = [...prevRows, newRow];
  //         const updatedRowsWithUniqueIds = updatedRows.map((row, index) => ({
  //             ...row,
  //             id: index === updatedRows.length - 1 ? new Date().getTime() : row.id,
  //         }));
  //         return updatedRowsWithUniqueIds;
  //     });
  // };

  const addSelectPair = () => {
    const newId = selectPairs.length + 1;
    setSelectPairs((prevPairs) => [
      ...prevPairs,
      { id: newId, firstSelectValue: "", secondSelectOptions: [] },
    ]);
  };

  // const handleRemoveSpecialties = (id) => {
  //     const updatedRows = addSpecialties.filter((row) => row.id !== id);
  //     setAddSpecialties(updatedRows);
  // };

  const removeSelectPair = (id) => {
    if (id != 1) {
      setSelectPairs((prevPairs) => prevPairs.filter((pair) => pair.id !== id));
    }
  };

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await API.get(`/cities`);
        if (response?.data?.code === 200) {
          setCities(response?.data?.data);
        } else {
          toast.error(response?.data?.message, response?.data?.code);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, error?.response?.code);
      }
    })();
  });

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await API.get(`/total-experience`);
        if (response?.data?.code === 200) {
          setTotalExperience(response?.data?.data);
        } else {
          toast.error(response?.data?.message, response?.data?.code);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, error?.response?.code);
      }
    })();
  });

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await API.get(`/bank-detail`);
        if (response?.data?.code === 200) {
          setBankDetails(response?.data?.data?.bank_detail);
          setCnic(bankDetails?.doctor_detail?.cnic);
        } else {
          toast.error(response?.data?.message, response?.data?.code);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, error?.response?.code);
      }
    })();
  });

  const conditionMap = {};

  updatedData?.doctor_conditions?.forEach((condition) => {
    const { speciality_id } = condition;
    conditionMap[speciality_id] = condition.disease_id;
  });

  updatedData?.doctor_specialities?.forEach((speciality) => {
    const { speciality_id, name } = speciality;
    const condition = conditionMap[speciality_id];
    userForm.setFieldsValue({
      speciality: name,
      condition: condition ? condition : "",
    });
  });

  useEffect(() => {
    setName(user?.user?.name);
    // setCnic(bankDetails?.doctor_detail?.cnic);
    // setSpecialityValue(user?.data?.user?.doctor_specialities?.map((spec) => (spec)));
    // setSpecialityService(user?.data?.user?.doctor_services?.map((spec) => (spec)));
    // setSpecialityServiceFull(user?.data?.user?.doctor_specialities_full?.map((spec) => (spec?.name)));
    userForm.setFieldsValue({
      doctorId: user?.user?.id,
      phone: user?.user?.phone,
      prefix: user?.user?.prefix,
      fullname: user?.user?.name,
      assistantphone: updatedData?.doctor_detail?.assistant_phone,
      email: user?.user?.email,
      dob: user?.user?.birth_date
        ? moment(user?.user?.birth_date, "YYYY-MM-DD")
        : "",
      gender: user?.user?.gender,
      city_id: user?.user?.city_id,
      experience: user?.user?.experience_year,
      about: user?.user?.about,
      pmdc: user?.user?.pmc_no,
      // speciality: user?.data?.user?.doctor_specialities?.map((spec) => (spec)),
      image: user?.user?.image,
      accounttitle: bankDetails?.account_name,
      ibannumber: bankDetails?.iban_number,
      bankname: bankDetails?.bank_name,
      cnic: bankDetails?.doctor_detail?.cnic,
    });
  }, [user, bankDetails]);


  const onFinish = async () => {
    await userForm.validateFields();

    const values = userForm.getFieldsValue();

    let selectLength = Cookies.get("specialityElementLength");

    let speciality = [];

    for (let i = 1; i <= selectLength; i++) {
      let specialtyObj = {
        speciality_id: values["speciality" + i],
        services: [],
        conditions: []
        // service_id: values["service" + i]
      };

      if (forUpdateServices?.length === 1) {
        let specialtyObjX = forUpdateServices.map((item) => {
          const serviceId = item;
          specialtyObj.services.push({ speciality_id: values["speciality" + i], service_id: serviceId });
          // specialtyObj.service_id = serviceId;
          return serviceId;
        });
      } else if (forUpdateServices?.length > 0) {
        forUpdateServices?.forEach((item) => {
          specialtyObj.services.push({ speciality_id: values["speciality" + i], service_id: item });
        })

      } else {
        specialtyObj.services.push({ speciality_id: values["speciality" + i], service_id: values["service" + i] });
      }

      if (forUpdateConditions?.length > 0) {
        console.log({ forUpdateConditions })
        // specialtyObj.conditions = forUpdateConditions;
        forUpdateConditions?.forEach((condition) => {
          specialtyObj.conditions.push({ speciality_id: values["speciality" + i], condition_id: condition });
        })
      } else {
        const conditionsArray = values["condition" + i];

        for (let j = 0; j < conditionsArray?.length; j++) {
          specialtyObj.conditions.push({ speciality_id: values["speciality" + i], condition_id: values[`condition${i}`]?.[j] });
        }

      }

      speciality.push(specialtyObj);
    }

    try {
      const payload = {
        // image: profileImage ? profileImage : user?.data?.user?.image,
        name: values?.fullname,
        phone: values?.phone,
        assistant_phone: values?.assistant_phone,
        email: values?.email,
        birth_date: values?.dob,
        gender: values?.gender,
        city_id: values?.city_id,
        experience_years: values?.experience,
        about: values?.about,
        pmc_no: values?.pmdc,
        // speciality: addSpecialties?.length > 0 && addSpecialties?.map((spec) => (spec)),
        speciality: speciality,
        // speciality: speciality,
        // condition: condition,
        // service: service,
        account_name: accountName ? accountName : bankDetails?.account_name,
        iban_number: accountIban ? accountIban : bankDetails?.iban_number,
        account_number: accountIban ? accountIban : bankDetails?.account_number,
        bank_name: accountBankName ? accountBankName : bankDetails?.bank_name,
        cnic: cnic ? cnic : bankDetails?.doctor_detail?.cnic,
      };

      const response = await API.post("/doctor/update-profile", payload);

      // Handle success response
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message, response?.data?.code);
      } else {
        toast.error(response?.data?.message, response?.data?.code);
      }
    } catch (error) {
      console.log(error, "error");
      // Handle error
    }
  };


  function getImageFileObject(imageFile) {
    setProfileImage(imageFile?.file?.name);
  }

  function runAfterImageDelete(file) {
    // console.log({ onDele: file });
  }

  // useEffect(() => {
  //     setAddSpecialties([
  //         {
  //             id: new Date().getTime(),
  //             speciality_id: '',
  //             service_id: '',
  //             conditions: '',
  //         },
  //     ]);
  // }, []);

  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  async function handleFormSubmit(e) {
    console.log("damn");
  }

  async function captureImage() {
    // Get access to the user's webcam.
    var constraints = {
      video: {
        facingMode: "user",
      },
    };

    var stream = await navigator.mediaDevices.getUserMedia(constraints);

    // Create a <video> element to display the camera stream.
    var video = document.getElementById("video");
    video.srcObject = stream;

    // Create a <canvas> element to capture the video frame.
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");

    // Capture a video frame from the camera and draw it to the <canvas> element.
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Get the data URL of the image.
    var imageDataUrl = canvas.toDataURL("image/jpeg");

    // Display the image in an <img> element.
    var img = document.getElementById("img");
    img.src = imageDataUrl;
  }

  useEffect(() => {
    if (updatedData.doctor_specialities?.length > 0) {
      const initialValues = {};

      const newSelectPairs = updatedData.doctor_specialities.map(
        (speciality, index) => {
          const { speciality_id } = speciality;

          const selectPair = {
            id: index + 1,
            firstSelectValue: "", // Set the initial value for firstSelectValue
            secondSelectOptions: [], // Set the initial value for secondSelectOptions
            thirdSelectOptions: [], // Set the initial value for thirdSelectOptions
            [`speciality${speciality_id}`]: speciality_id,
            [`service${speciality_id}`]: null,
            [`condition${speciality_id}`]: [],
          };

          userForm.setFieldsValue({
            [`service${index + 1}`]: speciality?.doctor_services?.id,
            [`speciality${index + 1}`]: speciality_id,
          });

          let conditions = [];

          // Check if the speciality ID exists in the conditionsMap
          if (speciality.doctor_conditions.length > 0) {
            speciality.doctor_conditions.forEach((condition) => {
              conditions.push(condition?.disease_id);
              const conditionName = condition?.name;
              const conditionId = condition?.disease_id;
              selectPair[`condition${speciality_id}`].push(conditionId);
              initialValues[`condition${speciality_id}`] =
                initialValues[`condition${speciality_id}`] || [];
              initialValues[`condition${speciality_id}`].push(conditionId);
              selectPair?.thirdSelectOptions.push({
                name: conditionName,
                id: conditionId,
              });
            });
            userForm.setFieldsValue({
              [`condition${index + 1}`]: conditions,
            });
          } else {
            initialValues[`condition${speciality_id}`] = [];
          }

          // Check if the speciality ID exists in the servicesMap
          if (speciality.doctor_services) {
            selectPair[`service${speciality_id}`] =
              speciality.doctor_services.id;
            initialValues[`service${speciality_id}`] =
              speciality.doctor_services.id; // Set the initial value for the service field
            selectPair?.secondSelectOptions.push({
              name: speciality.doctor_services.name,
              id: speciality.doctor_services.id,
            });
          }

          Object.assign(initialValues, selectPair); // Merge selectPair into initialValues

          return selectPair;
        }
      );

      setSelectPairs(newSelectPairs);
      userForm.setFieldsValue(initialValues);
    }
  }, [userForm, updatedData?.doctor_specialities]);

  //   hook to get set prefilled data

  //   useEffect(() => {
  //     if (updatedData?.doctor_specialities?.length > 0) {
  //       const initialValues = {};

  //       updatedData?.doctor_specialities?.forEach((specialityId) => {
  //         initialValues[`speciality${specialityId?.speciality_id}`] = specialityId?.speciality_id;
  //         initialValues[`condition${specialityId?.speciality_id}`] = [];
  //         initialValues[`service${specialityId?.speciality_id}`] = [];

  //         // Check if the speciality ID exists in the conditionsMap
  //         if (conditionsMap && conditionsMap[specialityId?.speciality_id]) {
  //           initialValues[`condition${specialityId?.speciality_id}`] = conditionsMap[specialityId?.speciality_id];
  //         }

  //         // Check if the speciality ID exists in the servicesMap
  //         if (servicesMap && servicesMap[specialityId?.speciality_id]) {
  //           initialValues[`service${specialityId?.speciality_id}`] = servicesMap[specialityId?.speciality_id];
  //         }
  //       });

  //       console.log({initialValues})
  //       userForm.setFieldsValue(initialValues);
  //     }
  //   }, [userForm, updatedData?.doctor_specialities, conditionsMap, servicesMap]);

  return (
    <div className="pt-121 profile_update">
      {(specialityLoader || servicesLoader || diseasesLoader) && <Loader />}
      <Container>
        {profileVerificationDoctor === 0 ? (
          <>
            <div className="unableClickView">
              <span>
                Your profile changes have been submitted and are pending
                approval
              </span>
            </div>
          </>
        ) : null}

        <Row>
          <Col lg={12} className="mobile_center">
            <h2 className="mb-3">UPDATE PROFILE</h2>
            <div className="user_name mb-45">
              {user?.data?.user?.image ? (
                <ImageUploader
                  style={{
                    borderRadius: "100px",
                    height: "85px",
                    width: "85px",
                    backgroundImage: `url("${user?.data?.user?.image}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  onFileAdded={(img) => getImageFileObject(img)}
                  onFileRemoved={(img) => runAfterImageDelete(img)}
                  deleteIcon={
                    <img
                      src={editProfile}
                      alt="editProfile"
                      className="editProfileIcon"
                    />
                  }
                />
              ) : (
                <div className="doc_name_div flex_center"></div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className=" profile_tabs mb-74">
            <Tabs
              defaultActiveKey="about"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="about" title="About">
                <h3 className="heading_profile mb-5">Basic Information</h3>
                <Form form={userForm} name="basic" onFinish={onFinish}>
                  <Row className="main_step_box">
                    <Col lg={4} md={4}>
                      <Form.Item
                        name="doctorId"
                        label="Doctor ID"
                        rules={[{ required: false }]}
                      >
                        <Input disabled placeholder="Your ID" />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4}>
                      <Form.Item
                        name="fullname"
                        label="Full Name*"
                        rules={[
                          { required: true, message: " Full name is required" },
                        ]}
                      >
                        <div className="d-flex selectBox box_dr_name">
                          <Form.Item name="prefix" noStyle>
                            <Select placeholder="Dr.">
                              <Option value="dr">Dr.</Option>
                              <Option value="prof">Prof.</Option>
                            </Select>
                          </Form.Item>
                          <Input
                            placeholder="Enter your name"
                            onChange={(e) => onNameChanged(e)}
                            value={name}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4}>
                      <Form.Item
                        name="phone"
                        label="Mobile Number*"
                      // rules={[
                      //   { required: true, message: "Phone is required" },
                      //   {
                      //     pattern: /^\d+$/,
                      //     message:
                      //       "Phone number should only contain numeric values",
                      //   },
                      //   {
                      //     max: 11,
                      //     message: "Phone number cannot exceed 11 characters",
                      //   },
                      // ]}
                      >
                        <Input
                          disabled
                          value={doctorNumber}
                          maxLength="11"
                          // onChange={onDoctorMobileChange}
                          // onKeyDown={(evt) => {
                          //   const allowedKeys = [
                          //     "Backspace",
                          //     "ArrowLeft",
                          //     "ArrowRight",
                          //     "Delete",
                          //   ];
                          //   if (
                          //     !/\d/.test(evt.key) &&
                          //     !allowedKeys.includes(evt.key)
                          //   ) {
                          //     evt.preventDefault();
                          //   }
                          // }}
                          placeholder="Your phone number"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4}>
                      <Form.Item
                        name="assistantphone"
                        label="Assistant Mobile Number"
                        rules={[
                          { required: true, message: "Phone is required" },
                          {
                            pattern: /^\d+$/,
                            message:
                              "Phone number should only contain numeric values",
                          },
                          {
                            max: 11,
                            message: "Phone number cannot exceed 11 characters",
                          },
                        ]}
                      >
                        <Input
                          value={assistantMobileNumber}
                          maxLength="11"
                          onChange={onAssistantMobileChange}
                          onKeyDown={(evt) => {
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                            ];
                            if (
                              !/\d/.test(evt.key) &&
                              !allowedKeys.includes(evt.key)
                            ) {
                              evt.preventDefault();
                            }
                          }}
                          placeholder="Assistant Mobile Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4}>
                      <Form.Item
                        name="email"
                        label="Email Address*"
                        rules={[
                          { required: true, message: "Email is required" },
                          { type: "email", message: "Invalid email format" },
                        ]}
                      >
                        <Input
                          value={email}
                          onChange={onEmailChange}
                          placeholder="Email Address*"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4}>
                      <Form.Item
                        name="dob"
                        label="Date of Birth*"
                        rules={[
                          {
                            required: true,
                            message: "Date of birth is required",
                          },
                        ]}
                      >
                        <DatePicker value={dob} onChange={onDobChange} />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4} className="selectBox">
                      <Form.Item
                        name="gender"
                        label="Gender*"
                        rules={[
                          {
                            required: true,
                            message: "Gender is required",
                          },
                        ]}
                      >
                        <Select
                          onChange={(value) => onGenderChange(value)}
                          options={[
                            {
                              value: "male",
                              label: "Male",
                            },
                            {
                              value: "female",
                              label: "Female",
                            },
                            {
                              value: "other",
                              label: "Other",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4} className="selectBox">
                      <Form.Item
                        name="city_id"
                        label="City*"
                        rules={[
                          {
                            required: true,
                            message: "City is required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Select City"
                          onChange={(value) => onCityChange(value)}
                        >
                          {cities &&
                            cities?.length > 0 &&
                            cities?.map((city) => (
                              <Option value={city.id} key={city.id}>
                                {city.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4} className="selectBox">
                      <Form.Item
                        name="experience"
                        label="Total Experience in years*"
                        rules={[
                          {
                            required: true,
                            message: "Experience is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select an option"
                          onChange={(value) => onExperienceChange(value)}
                        >
                          {totalExperience?.length > 0 &&
                            totalExperience?.map((item) => (
                              <>
                                <Option value={item}>{item}</Option>
                              </>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <h3 className="heading_profile mb-5">Practice Details</h3>
                  <Row className="main_step_box">
                    <Col lg={12} md={12}>
                      <Form.Item
                        name="about"
                        label="About me"
                        className="textarea_style"
                      >
                        <TextArea
                          onChange={(event) => onAboutChange(event)}
                          value={about}
                          rows={4}
                          placeholder="Please share a brief bio to provide additional information about yourself..."
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Item
                        name="pmdc"
                        label="PMDC Number  (Not mandatory for certain specialties)* "
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "PMDC number is required",
                      //   },
                      //   {
                      //     pattern: /^\d+$/,
                      //     message:
                      //       "PMDC number should only contain numeric values",
                      //   },
                      //   {
                      //     max: 13,
                      //     message: "PMDC number cannot exceed 13 characters",
                      //   },
                      // ]}
                      >
                        <Input
                          disabled
                          value={pmc}
                          onChange={(event) => onPmcChange(event)}
                          maxLength="7"
                          // onKeyDown={(evt) => {
                          //   const allowedKeys = [
                          //     "Backspace",
                          //     "ArrowLeft",
                          //     "ArrowRight",
                          //     "Delete",
                          //   ];
                          //   if (
                          //     !/\d/.test(evt.key) &&
                          //     !allowedKeys.includes(evt.key)
                          //   ) {
                          //     evt.preventDefault();
                          //   }
                          // }}
                          placeholder="Enter PMDC number"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      className="btn-top-position position-relative"
                    >
                      <button
                        onClick={addSelectPair}
                        type="button"
                        class="ant-btn ant-btn-default add-btn btn"
                      >
                        <span>+ Add More</span>
                      </button>
                    </Col>
                    <Col>
                      {specialities &&
                        selectPairs?.map((pair) => (
                          <Row className="row_duplicate01">
                            <Col lg={6} md={6} className="selectBox">
                              <Form.Item
                                name={`speciality${pair?.id}`}
                                label="Select your speciality*"
                                rules={[
                                  {
                                    required: true,
                                    message: "Speciality is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select service"
                                  onChange={(e) => {
                                    handleFirstSelectChange(pair?.id, e);
                                    handleSpecialityChange(e);
                                  }}
                                >
                                  {specialities?.data?.specialities?.length >
                                    0 &&
                                    specialities?.data?.specialities?.map(
                                      (option) => (
                                        <Option
                                          key={option?.id}
                                          value={option?.id}
                                        >
                                          {option?.name}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col lg={6} md={6} className="selectBox">
                              <Form.Item
                                name={`service${pair?.id}`}
                                label="Select your service*"
                                rules={[
                                  {
                                    required: true,
                                    message: "Service is required",
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(e) => handleServiceChange(e)}
                                  dropdownAlign={{ offset: [0, 4] }}

                                >
                                  {pair?.secondSelectOptions?.map((option) => (
                                    <Option key={option?.id} value={option?.id}>
                                      {option?.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              className="selectBox condition_box"
                            >
                              <Form.Item
                                label={
                                  <Label>
                                    Select conditions you treat{" "}
                                    <div className="popover_box1">
                                      <Popover
                                        content={content}
                                        placement="topLeft"
                                        overlayClassName="popover_box"
                                      >
                                        <img
                                          src={infoIcon}
                                          className="img-fluid"
                                          alt="info"
                                        ></img>
                                      </Popover>
                                    </div>
                                  </Label>
                                }
                                name={`condition${pair?.id}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "conditions is required",
                                  },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  placeholder="Select conditions"
                                  className="select_des"
                                  onChange={(value) =>
                                    handleConditionsChange(value)
                                  } // Call the handleConditionsChange function on conditions change
                                  dropdownClassName="dropdown_box_checkbox"
                                  dropdownRender={(menu) => <>{menu}</>}
                                  options={pair.thirdSelectOptions?.map(
                                    (item) => ({
                                      label: item?.name,
                                      value: item?.id,
                                    })
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            {pair?.id != 1 && (
                              <>
                                <Col lg={12} md={12} className="text-end ">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeSelectPair(pair?.id)}
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt="Delete"
                                      className="img-fluid"
                                    />
                                  </span>
                                </Col>
                              </>
                            )}
                          </Row>
                        ))}
                    </Col>
                  </Row>

                  <h3 className="heading_profile mb-5">Basic Information</h3>
                  <Row className="main_step_box">
                    <Col lg={6} md={6}>
                      <Form.Item
                        value={accountName}
                        onChange={(event) => onAccountNameChange(event)}
                        name="accounttitle"
                        label="Account name"
                        rules={[
                          {
                            // required: true,
                            message: "Account name is required",
                          },
                          {
                            pattern: /^[A-Za-z\s]+$/,
                            message: "Account name should alphabets",
                          },
                        ]}
                      >
                        <Input placeholder="Enter account name" />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Item
                        value={accountIban}
                        onChange={(event) => onAccountIbanChange(event)}
                        name="ibannumber"
                        label="Account / IBAN number"
                        rules={[
                          {
                            // required: true,
                            message: "IBAN number is required",
                          },
                          {
                            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
                            message: "IBAN number should be alphanumeric.",
                          },
                        ]}
                      >
                        <Input maxLength="24" placeholder="Enter IBAN number" />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Item
                        value={accountBankName}
                        onChange={(event) => onBankNameChange(event)}
                        name="bankname"
                        label="Bank name "
                        rules={[
                          { required: false, message: "Bank name is required" },
                        ]}
                      >
                        <Input placeholder="Enter bank name" />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6} className="condition_box">
                      <Form.Item
                        value={cnic}
                        onChange={(event) => onCnicChange(event)}
                        label={
                          <span>
                            CNIC
                            <div className="popover_box1">
                              <Popover
                                content={contentcnic}
                                placement="topLeft"
                                overlayClassName="popover_box"
                              >
                                <img
                                  src={infoIcon}
                                  className="img-fluid"
                                  alt="info"
                                />
                              </Popover>
                            </div>
                          </span>
                        }
                        name="cnic"
                        rules={[
                          {
                            required: false,
                            message: "CNIC number is required",
                          },
                        ]}
                      >
                        {/* <Label>CNIC* <div className="popover_box1">
                                                    <Popover content={contentcnic} placement="topLeft" overlayClassName="popover_box">
                                                        <img src={infoIcon} className="img-fluid" alt="info"></img>
                                                    </Popover>
                                                </div></Label> */}

                        <Input
                          maxLength="13"
                          onKeyDown={(evt) => {
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                            ];
                            if (
                              !/\d/.test(evt.key) &&
                              !allowedKeys.includes(evt.key)
                            ) {
                              evt.preventDefault();
                            }
                          }}
                          placeholder="Enter CNIC"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} className="text-end mt-26">
                      <Button
                        disabled={
                          profileVerificationDoctor
                            ? profileVerificationDoctor === 0
                            : null
                        }
                        type="submit"
                        className="update_btn btn"
                        onClick={onFinish}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Tab>

              <Tab eventKey="qualification" title="QUALIFICATIONS">
                <QualificationTab updatedData={updatedData} />
              </Tab>

              <Tab eventKey="consultationdetails" title="Consultation Details">
                <ConsultationTab />
                {/* <div className="toast_blue"><ToastContainer /></div> */}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default ProfileUpdates;
