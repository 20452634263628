import {
  createSlice,
  current,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";

import { postPasswordUpdate } from "./thunk";

const thunks = [postPasswordUpdate];

const initialState = {
  status: "idle",
  // Login: {},
  // userEmail: "",
};

export const slice = createSlice({
  name: "updatePassword",
  initialState,
  reducers: {
 
    // setEmail: (state, email) => {
    //   state.userEmail = email.payload
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPasswordUpdate.fulfilled, (state, action) => {
        state.status = "idle";
        // state.Login = action.payload;
      })
      .addMatcher(isPending(...thunks), (state) => {})
      .addMatcher(isFulfilled(postPasswordUpdate), (state) => {})
      .addMatcher(isRejected(...thunks), (state, action) => {});
  },
});

// export const { setEmail } = slice.actions;


// export const selectUser = (state) => state.login.Login;
// export const selectEmail = (state) => state.login.userEmail;


export default slice.reducer;
