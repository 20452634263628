import React, { useEffect, useState, useRef, memo } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { v4 as uuidv4 } from 'uuid';
import "./profileUpdate.scss";
import { Button, Form, Input, Select, Radio, Space, Checkbox } from "antd";
import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { selectSpeciality, selectSpecialityLoader, selectUser } from "../updateProfile/redux/slice";
import API from "../../utils/customAxios";
import { useEffectOnce } from "react-use";
import moment from "moment";
import { getDegreesByInstituteWithYearOfCompletion } from "../../helpers/utilityHelper";


const { Option } = Select;

const QualificationTab = ({ updatedData }) => {

    // all states here start
    const [userForm] = Form.useForm();
    const [itemsdegree, setItemsDegree] = useState([]);
    const [institute, setInstitute] = useState([]);
    const [jobDesignations, setJobDesignations] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [jobInstitutes, setJobInstitutes] = useState([]);
    const [showAddDegree, setShowAddDigree] = useState(false); // State to control the visibility of Add item field and button
    const [years, setYears] = useState([]);

    const [selectedDegrees, setSelectedDegrees] = useState();
    const [selectedInstitute, setSelectedInstitute] = useState([]);
    const [yearCompletion, setYearCompletion] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [instituteJob, setInstituteJob] = useState([]);
    const [startYears, setStartYears] = useState([]);
    const [endYears, setEndYears] = useState([]);
    const [memberships, setMemberships] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsTwo, setRowsTwo] = useState([]);
    const [rowsThree, setRowsThree] = useState([]);
    const [updatedEducation, setUpdatedEducation] = useState([]);
    const [updatedExperience, setUpdatedExperience] = useState([]);
    const [degrees, setDegrees] = useState();
    const [rowCount, setRowCount] = useState(1);
    const [doctorCertification, setDoctorCertification] = useState([]);




    const inputRef = useRef(null);


    // all states here end


    // all onchange function and useEffects start



    const duplicateRow = () => {
        setRowCount(rowCount + 1);
    };
    const deleteeRow = () => {
        setRowCount(rowCount - 1);
    };

    const handleConditionsChangeDigree = (value) => {
        setShowAddDigree(!showAddDegree);
        setShowAddDigree(value.includes("Other"));
    };

    const handleDegreeChange = (value, id) => {
        const updatedRows = rows.map((row) =>
            row.id === id ? { ...row, degree: value } : row
        );
        setRows(updatedRows);

        const selectedDegrees = updatedRows.map((row) => row.degree).filter(Boolean);
        setSelectedDegrees(selectedDegrees);

        handleConditionsChangeDigree(updatedRows); // Call the appropriate function to handle visibility
    };

    const onInstituteChange = (value, id) => {
        const updatedRows = rows.map((row) =>
            row.id === id ? { ...row, institute: value } : row
        );
        setRows(updatedRows);
    };

    const onYearChange = (value, id) => {
        const updatedRows = rows.map((row) =>
            row.id === id ? { ...row, completion: value } : row
        );
        setRows(updatedRows);
    };

    const onDesignationChange = (value, id) => {
        const updatedRows = rowsTwo.map((row) =>
            row.id === id ? { ...row, position: value } : row
        );
        setRowsTwo(updatedRows);

        const selectedDesignations = updatedRows
            .map((row) => row.designation)
            .filter(Boolean);
        setDesignations(selectedDesignations);
    };

    const onInstituteJobChange = (value, id) => {
        const updatedRows = rowsTwo?.map((row) =>
            row.id === id ? { ...row, experience_institute: value } : row
        );
        setRowsTwo(updatedRows);
    };

    const onStartYearChange = (value, id) => {
        const updatedRows = rowsTwo.map((row) =>
            row.id === id ? { ...row, start_year: value } : row
        );
        setRowsTwo(updatedRows);
    };

    const onEndYearChange = (value, id) => {
        const updatedRows = rowsTwo.map((row) =>
            row.id === id ? { ...row, end_year: value } : row
        );
        setRowsTwo(updatedRows);
    };

    const onMembershipChange = (value, id) => {
        const updatedRows = rowsThree.map((row) =>
            row.id === id ? { ...row, certification: value } : row
        );
        setRowsThree(updatedRows);

        const selectedMembership = updatedRows
            .map((row) => row.designation)
            .filter(Boolean);
        setMemberships(selectedMembership);
    };

    const getDegreeList = async () => {
        try {
            const response = await API.get('/degrees');
            if (response?.data?.code === 200) {
                setItemsDegree(response?.data?.data);
            }
        } catch (error) {
            console.log(error, "err")
        }
    }

    const getInstituteList = async () => {
        try {
            const response = await API.get('/universities');
            if (response?.data?.code === 200) {
                setInstitute(response?.data?.data);
            }
        } catch (error) {
            console.log(error, "err")
        }
    }

    const doctorJobDesignation = async () => {
        try {
            const response = await API.get('/doctor/designation');
            if (response?.data?.code === 200) {
                setJobDesignations(response?.data?.data);
            }
        } catch (error) {
            console.log(error, "err")
        }
    }

    const doctorJobInstitutes = async () => {
        try {
            const response = await API.get('/doctor/institute');
            if (response?.data?.code === 200) {
                setJobInstitutes(response?.data?.data);
            }
        } catch (error) {
            console.log(error, "err")
        }
    }

    const doctorCertifications = async () => {
        try {
            const response = await API.get('/doctor/certifications');
            if (response?.data?.code === 200) {
                setCertifications(response?.data?.data);
            }
        } catch (error) {
            console.log(error, "err")
        }
    }

    // const handleAddRow = () => {
    //     const newRow = {
    //         id: new Date().getTime(), // Generate a unique ID for the new row
    //         degree: '',
    //         institute: '',
    //         completion: '',
    //     };

    //     setRows([...rows, newRow]);
    // };

    const handleRemoveRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };

    const handleAddRowTwo = () => {
        const newRow = {
            id: new Date().getTime(), // Generate a unique ID for the new row
            position: '',
            experience_institute: '',
            start_year: '',
            end_year: '',
        };

        setRowsTwo([...rowsTwo, newRow]);
    };

    const handleRemoveRowTwo = (id) => {
        const updatedRows = rowsTwo.filter((row) => row.id !== id);
        setRowsTwo(updatedRows);
    };

    const handleAddRowThree = () => {
        const newRow = {
            id: new Date().getTime(), // Generate a unique ID for the new row
            certification: ''
        };

        setRowsThree([...rowsThree, newRow]);
    };

    const handleRemoveRowThree = (id) => {
        const updatedRows = rowsThree.filter((row) => row.id !== id);
        setRowsThree(updatedRows);
    };

    useEffect(() => {
        getDegreeList();
        getInstituteList();
        doctorJobDesignation();
        doctorJobInstitutes();
        doctorCertifications()
    }, [])

    useEffect(() => {
        const currentYear = moment().year();
        const yearsArray = [];
        for (let year = 1950; year <= currentYear; year++) {
            yearsArray.push({ id: year, year: year });
        }
        setYears(yearsArray);
    }, []);

    useEffect(() => {
        setRows([
            {
                id: new Date().getTime(),
                degree: '',
                institute: '',
                completion: '',
            },
        ]);
    }, []);

    useEffect(() => {
        setRowsTwo([
            {
                id: new Date().getTime(),
                position: '',
                experience_institute: '',
                start_year: '',
                end_year: '',
            },
        ]);
    }, []);

    useEffect(() => {
        setRowsThree([
            {
                id: new Date().getTime(),
                certification: '',
            },
        ]);
    }, []);

    // all onchange function and useEffects end
    useEffect(() => {

        if (Object.keys(updatedData).length > 0) {
            const doctorEducation = updatedData?.doctor_education;
            // console.log(updatedData, "doctorEducation")
            if (doctorEducation?.length > 0) {
                const degreesByInstituteWithYearOfCompletion = getDegreesByInstituteWithYearOfCompletion(doctorEducation);
                setRowCount(degreesByInstituteWithYearOfCompletion.length);

                for (let i = 0; i < degreesByInstituteWithYearOfCompletion.length; i++) {
                    userForm.setFieldsValue({
                        [`degree${i + 1}`]: degreesByInstituteWithYearOfCompletion[i]?.map((item) => {
                            return item?.degree
                        }),
                        [`institute${i + 1}`]: degreesByInstituteWithYearOfCompletion?.[i]?.[0]?.institute,
                        [`completion${i + 1}`]: degreesByInstituteWithYearOfCompletion?.[i]?.[0]?.yearOfCompletion


                    })
                }

            }
        }

    }, [JSON.stringify(updatedData)])

    useEffect(() => {

        if (Object.keys(updatedData).length > 0) {
            const doctorExperiences = updatedData?.doctor_experiences;
            if (doctorExperiences?.length > 0) {
                setRowsTwo(doctorExperiences)

                for (let i = 0; i < doctorExperiences?.length; i++) {
                    userForm.setFieldsValue({
                        [`position${i + 1}`]: doctorExperiences?.[i]?.position_id,
                        [`experience_institute${i + 1}`]: doctorExperiences?.[i]?.institute_id,
                        [`start_year${i + 1}`]: doctorExperiences?.[i]?.start_year,
                        [`end_year${i + 1}`]: doctorExperiences?.[i]?.end_year
                    })
                }

            }
        }

    }, [JSON.stringify(updatedData)])


    useEffect(() => {

        if (Object.keys(updatedData).length > 0) {
            const doctorCertification = updatedData?.doctor_certification;
            if (doctorCertification?.length > 0) {
                setRowsThree(doctorCertification);
                for (let i = 0; i < doctorCertification?.length; i++) {
                    userForm.setFieldsValue({
                        [`certification${i + 1}`]: doctorCertification?.[i]?.certification_id,
                    })
                }

            }
        }

    }, [JSON.stringify(updatedData)])

    const onFinish = async (values) => {

        try {

            await userForm.validateFields();
            const values = userForm.getFieldsValue();

            let educ = [];

            for (let i = 1; i <= rowCount; i++) {
                educ.push({
                    degree: values['degree' + i],
                    institute: values['institute' + i],
                    completion: values['completion' + i]
                })
            }

            let experience = [];

            for (let i = 1; i <= rowsTwo.length; i++) {
                experience.push({
                    position: values['position' + i],
                    experience_institute: values['experience_institute' + i],
                    start_year: values['start_year' + i],
                    end_year: values['end_year' + i]
                })
            }

            let certification = [];


            for (let i = 1; i <= rowsThree.length; i++) {
                certification.push(values['certification' + i])
            }

            // const payload = {
            //     education: educ,
            //     experience: experience,
            //     certification: certification,
            // };

            let payload = {};

            if (educ?.length > 0) {
                payload.education = educ
            }
            if (experience?.length > 0) {
                payload.experience = experience
            }
            if (typeof certification[0] !== 'undefined') {
                payload.certification = certification;
            }

            const response = await API.post('/doctor/register/doctor/education-experience-api', payload);

            // Handle success response
            if (response?.data?.code === 200) {
                toast.success(response?.data?.message, response?.data?.code)
                setTimeout(() => {
                    // window.location.reload();
                }, 3000)
            }
            else {
                toast.error(response?.data?.message, response?.data?.code)

            }
        } catch (error) {
            console.log(error, 'error');
            // Handle error
        }
    };

    return (
        <>
            <Form
                form={userForm}
                onFinish={onFinish}
            >
                <h3 className='heading_profile '>Education</h3>
                <Row className="mt-7">
                    <Col lg={12} md={12} className="text-end">
                        <Button className="add-btn btn" onClick={duplicateRow}>+ Add More</Button>
                    </Col>
                </Row>
                {/* first */}
                {/* {rows?.map((row, index) => ( */}
                {Array.from({ length: rowCount }).map((_, index) => (
                    <Row className="duplicate_row main_step_box" >
                        <Col lg={6} md={6} className="selectBox condition_box ">
                            <Form.Item
                                name={`degree${index + 1}`}
                                label="Degree*"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Degree is required',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Please select"
                                    className="select_des"
                                    dropdownClassName="dropdown_box_checkbox"
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            {showAddDegree && ( // Show Add item field and button only when showAddItem is true
                                                <Space style={{ padding: "0 8px 4px" }} className="add_item">
                                                    <Input
                                                        placeholder="Enter your degree"
                                                        // ref={inputRefdegree}
                                                        // value={addnamedegree} // Pass the addnamedegree variable as the value
                                                        // onChange={onNameChangedegree}
                                                        className="textfield"
                                                    />
                                                    {/* <Button type="text" onClick={addItemdegree} className={addnamedegree ? "activebtn" : ""}>
                                                Add
                                            </Button> */}
                                                </Space>
                                            )}
                                        </>
                                    )}
                                    options={itemsdegree?.length > 0 && itemsdegree.map((item) => ({ label: item?.name, value: item?.id }))}
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={6} md={6} className="selectBox ">
                            <Form.Item
                                name={`institute${index + 1}`}
                                label="Institute / University"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Institute is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select"
                                // onChange={(value) => onInstituteChange(value, row?.id)}
                                >
                                    {institute?.length > 0 && institute?.map((item) => (
                                        <Option value={item?.id} key={item?.id}>
                                            {item?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={6} md={6} className="selectBox ">
                            <Form.Item
                                name={`completion${index + 1}`}
                                label="Year of completion*"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Year of completion is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select"
                                // onChange={(value) => onYearChange(value, row?.id)}
                                >
                                    {years?.length > 0 && years?.map((item) => (
                                        <Option value={item?.id} key={item?.id}>{item?.year}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {index === 0 ? null : (
                            <>
                                <Col lg={12} md={12} className="text-end">
                                    <Button className="add-btn btn"
                                    // onClick={() => handleRemoveRow(row?.id)}
                                    >
                                        Remove</Button>
                                </Col>
                            </>
                        )}
                    </Row>
                ))}

                {/* firstEnd */}


                <h3 className='heading_profile'>Experience</h3>
                <Row className="">
                    <Col lg={12} md={12} className="text-end">
                        <Button className="add-btn btn" onClick={handleAddRowTwo}>+ Add More</Button>
                    </Col>
                </Row>
                {rowsTwo?.map((row, index) => (
                    <Row className="duplicate_row main_step_box" key={row?.id}>
                        <Col lg={6} md={6} className="selectBox ">
                            <Form.Item
                                name={`position${index + 1}`}
                                label="Designation*"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Designation is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select" onChange={(value) => onDesignationChange(value, row?.id)}>
                                    {jobDesignations?.length > 0 && jobDesignations?.map((item) => (
                                        <>
                                            <Option value={item?.id}>{item?.name}</Option>
                                        </>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={6} className="selectBox ">
                            <Form.Item
                                name={`experience_institute${index + 1}`}
                                label="Institute* "
                                rules={[
                                    {
                                        required: true,
                                        message: 'Institute is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select" onChange={(value) => onInstituteJobChange(value, row?.id)}>
                                    {jobInstitutes?.length > 0 && jobInstitutes?.map((item) => (
                                        <>
                                            <Option value={item?.id}>{item?.name}</Option>
                                        </>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col lg={6} md={6} className="selectBox">
                            <Form.Item
                                name={`start_year${index + 1}`}
                                label="Start year*"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Start Year  is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select" onChange={(value) => onStartYearChange(value, row?.id)}>
                                    {years?.length > 0 && years?.map((item) => (
                                        <Option value={item?.id} key={item?.id}>{item?.year}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={6} md={6} className="selectBox ">
                            <Form.Item
                                name={`end_year${index + 1}`}
                                label="End year"
                                rules={[
                                    {
                                        required: false,
                                        message: 'End Year is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select" onChange={(value) => onEndYearChange(value, row?.id)}>
                                    {years?.length > 0 && years?.map((item) => (
                                        <Option value={item?.id} key={item?.id}>{item?.year}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={6} className="checkbox_style ms-auto text-end">
                            <Checkbox>Currently working here</Checkbox>
                        </Col>

                        {index === 0 ? null : (
                            <>
                                <Col lg={12} md={12} className="text-end">
                                    <Button className="add-btn btn" onClick={() => handleRemoveRowTwo(row?.id)}>Remove</Button>
                                </Col>
                            </>
                        )}

                    </Row>
                ))}


                <Row className="mt-4" >
                    <Col lg={12} md={12} className="text-end">
                        <Button className="add-btn btn" onClick={handleAddRowThree}>+ Add More</Button>
                    </Col>
                </Row>
                {rowsThree?.map((row, index) => (
                    <Row className=" main_step_box" key={row?.id}>
                        <Col lg={12} md={12} className="selectBox ">
                            <Form.Item
                                name={`certification${index + 1}`}
                                label="Professional Membership / Certification"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Start Year  is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select" onChange={(value) => onMembershipChange(value, row?.id)}>
                                    {certifications?.length > 0 && certifications?.map((item) => (
                                        <Option value={item?.id} key={item?.id}>
                                            {item?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {index === 0 ? null : (
                            <>
                                <Col lg={12} md={12} className="text-end">
                                    <Button className="add-btn btn" onClick={() => handleRemoveRowThree(row?.id)}>Remove</Button>
                                </Col>
                            </>
                        )}
                    </Row>
                ))}
                <Row>
                    <Col lg={12} md={12} className="text-end mt-26">
                        <Button type="submit" className="update_btn btn" onClick={onFinish} >Update</Button>
                    </Col>
                </Row>
            </Form>

        </>
    )
}

export default memo(QualificationTab);