import React, { useEffect, useState } from "react";
import "./paymentHistory.scss";
import { Table, DatePicker } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import { Button, Form, Input, Modal, Popconfirm, Select, Switch } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import TablePaymentHistory from "../../uiComponents/tableComponent/tablePaymentHistory/TablePaymentHistory";
import { Row, Col, Container, InputGroup, FormControl, } from "react-bootstrap";
import searchIcon from "../../assets/images/png/search.png";
import downloadIcon from "../../assets/images/png/download.png";
import { Link } from 'react-router-dom';
import Loader from "../../uiComponents/loader/Loader";
import API from "../../utils/customAxios";
import Accordion from 'react-bootstrap/Accordion';
import Cookies from "js-cookie";


function PaymentHistory(props) {
    const { RangePicker } = DatePicker;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [modal2Open, setModal2Open] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState([])
    const [paymentHistoryLoading, setPaymentHistoryLoading] = useState(false)
    const [transactionHistoryLoading, setTransactionHistoryLoading] = useState(false)
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [earningSearchValue, setEarningSearchValue] = useState("");
    const [earningLoading, setEarningLoading] = useState(false);
    const [historyPage, setHistoryPage] = useState(1);
    const [historyTotal, setHistoryTotal] = useState(null);
    const [perPage, setPerPage] = useState(0);


    

    


    const fetchTransactionData = async (id) => {
        try {
            setTransactionHistoryLoading(true)
            const response = await API.get(`/doctor/payment-history/${id}`);
            if (response?.data?.code === 200) {
                setTransactionHistoryLoading(false)
                setTransactionHistory(response?.data?.data);
            } else {
                setTransactionHistoryLoading(false)
                console.log('Error: Unable to fetch transaction history.');
            }
        } catch (error) {
            setTransactionHistoryLoading(false)
            console.error('Error fetching transaction history:', error);
        }
    };


  const dateFormatList = ['DD/MM/YYYY'];

  let baseUrl = process.env.REACT_APP_BASE_URL;

  async function downloadHistory() {
    setDownloadLoader(true);
    fetch(`${baseUrl}/doctor/payment-history-download?is_html=1&is_download=1`, {
      method: 'GET',
      headers: {
        'Authorization': Cookies.get("token"),
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(response => response.blob())
      .then(response => {
        const blob = new Blob([response], { type: 'application/csv' });
        const downloadUrl = URL.createObjectURL(blob);
        setDownloadLoader(false);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "file.csv";
        document.body.appendChild(a);
        a.click();
      })
  }



    useEffect(() => {
        if (selectedTransactionId) {
            fetchTransactionData(selectedTransactionId);
        }
    }, [selectedTransactionId]);

    const handleDateRangeChange = (dates, dateStrings) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
    };

    const paymentHistoryPages = {
        pageSize: perPage,
        current: historyPage,
        total: historyTotal,
        onChange: (page) => {
            setHistoryPage(page)

            setPaymentHistoryLoading(true);
            API.get(`/doctor/payment-history?page=${page}`)
                .then((response) => {
                    if (response?.data?.code === 200) {
                        setPaymentHistoryLoading(false);
                        setPaymentHistory(response?.data?.data);
                    } else {
                        setPaymentHistoryLoading(false);
                    }
                })
                .catch((err) => {
                    setPaymentHistoryLoading(false);
                });
        },
    }

    useEffect(() => {

        setPaymentHistoryLoading(true);
        API.get(`/doctor/payment-history?page=1`)
            .then((response) => {
                if (response?.data?.code === 200) {
                    setPaymentHistoryLoading(false);
                    setPaymentHistory(response?.data?.data);
                    setHistoryTotal(response?.data?.page?.total)
                    setPerPage(response?.data?.page?.per_page)
                } else {
                    setPaymentHistoryLoading(false);
                }
            })
            .catch((err) => {
                setPaymentHistoryLoading(false);
            });
    
       
    }, []);


 

    const columns = [
        {
            title: 'Date of Transaction',
            dataIndex: 'date',
            key: 'date',
            // sorter: (a, b) => a.id - b.id,

        },
        {
            title: 'Amount (Rs.)',
            dataIndex: 'amount',
            key: 'amount',
            // sorter: (a, b) => a.amount - b.date,
        },
        {
            title: 'Transaction ID',
            dataIndex: 'id',
            key: 'id',
            // sorter: (a, b) => a.id - b.id,
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // sorter: (a, b) => a.id - b.action,
        },
    ];


    const filteredData = paymentHistory.filter((item) => {
        const date = moment(item.date);
        return startDate === null || endDate === null || (date >= startDate && date <= endDate);
    });

    const dataSource = filteredData.map((item) => ({

        id: item?.transaction_id        ,
        amount: item?.amount,
        action: <a onClick={() => {
            setSelectedTransactionId(item?.id);
            setModal2Open(true);
        }} >View List</a>,
        date: item?.date,

    }));

    async function earningSearch(event) {

        if (
          event.type === "click" ||
          (event.type === "keydown" && event.key === "Enter")
        ) {
          // if mouse click or enter key
          // console.log(event.target.value, "logan")
    
          // get input value from state earningSearchValue
          if (earningSearchValue) {
            setEarningLoading(true);
    
            let apiUrl = `/doctor/all-appointment?past&search=${earningSearchValue}`;
    
            if (startDate && endDate) {
              let from = moment(startDate).format().toString().substring(0, 10);
              let to = moment(endDate).format().toString().substring(0, 10);
    
              apiUrl.concat(`&from=${from}&to=${to}`);
            }
            try {
                const response = await API.get(apiUrl);
      
                setEarningLoading(false);
      
                if (response?.data?.code === 200) {
                  setPaymentHistory(response?.data?.data?.data);
                //   setTotalEarningCount(response?.data?.data?.total);
                }
              } catch (error) {
                setEarningLoading(false);
              }
                setEarningLoading(false);
    
          }
        }
      }

    return (
        <>
            <div className="Payment_history cover_space">
                {(paymentHistoryLoading || earningLoading || downloadLoader) && <Loader />}
                <Container>
                    <Row className="align-items-center  pb-30">
                        <Col md={9} lg={9} className="or1">
                            <h1>
                                <Link to="/earnings">
                                    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z" stroke="#313131" />
                                        <path d="M10.3915 17.8928L15.5075 12.8018C15.6918 12.6199 15.9407 12.5184 16.1997 12.5195C16.4587 12.5207 16.7066 12.6244 16.8893 12.808C17.0719 12.9916 17.1744 13.2401 17.1743 13.4991C17.1741 13.7581 17.0714 14.0064 16.8885 14.1898L13.4535 17.6078H25.5835C25.8431 17.6078 26.0922 17.711 26.2758 17.8946C26.4594 18.0782 26.5625 18.3272 26.5625 18.5868C26.5625 18.8465 26.4594 19.0955 26.2758 19.2791C26.0922 19.4627 25.8431 19.5658 25.5835 19.5658H13.4545L16.8895 22.9838C17.0724 23.1672 17.1751 23.4156 17.1753 23.6746C17.1754 23.9335 17.0729 24.182 16.8903 24.3656C16.7076 24.5492 16.4597 24.6529 16.2007 24.6541C15.9417 24.6553 15.6928 24.5538 15.5085 24.3718L10.3925 19.2808C10.3011 19.1898 10.2286 19.0817 10.1791 18.9627C10.1296 18.8436 10.104 18.716 10.1039 18.587C10.1038 18.4581 10.1292 18.3304 10.1785 18.2113C10.2279 18.0921 10.3003 17.9839 10.3915 17.8928Z" fill="#313131" />
                                    </svg>
                                </Link>
                                PAYMENT HISTORY</h1>
                        </Col>
                        <Col md={3} lg={3} className="search-bar  or3">
                            <div className="btn_search marginTopSearchBar ">
                                <InputGroup>
                                    <FormControl
                                    onKeyDown={earningSearch}
                                    value={earningSearchValue}
                                    onChange={(e) => setEarningSearchValue(e.target.value)} 
                                    placeholder="Search by Transaction ID..."
                                    />
                                </InputGroup>
                                <span className="icon_search">
                                    <img onClick={earningSearch} src={searchIcon} className="img-fluid" />
                                </span>
                            </div>

                        </Col>
                        <Col md={8} lg={8} className="or4 accordian_earning_mobile ">

                            <Accordion defaultActiveKey="0" className="d-block d-sm-none">

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Filter</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="date_filter " >
                                            <span className="from_01">From</span>
                                            <DatePicker.RangePicker
                                                value={[startDate, endDate]}
                                                format={dateFormatList}
                                                clearIcon={null}
                                                onChange={handleDateRangeChange}
                                                style={{ marginBottom: 8 }} />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className="date_filter d-none d-sm-block" >
                                <span className="from_01">From</span>
                                <DatePicker.RangePicker
                                    value={[startDate, endDate]}
                                    onChange={handleDateRangeChange}
                                    format={dateFormatList}
                                    clearIcon={null}
                                    style={{ marginBottom: 8 }} />
                                <span onClick={downloadHistory} className="download_btn">
                                    <img src={downloadIcon} className="img-fluid" />
                                    Download
                                </span>
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="text-end or2 mobile_download">
                            <div>
                                <Row>
                                    <Col xs={6} lg={12}>
                                        <div className="date_filter text-start d-block  d-sm-none">

                                            <span onClick={downloadHistory}  className="download_btn">
                                                <img onClick={downloadHistory} src={downloadIcon} className="img-fluid" />
                                                Download
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={12}>
                                        <Link to="/deduction-list" className="view_deduction">  View Deduction List</Link>
                                    </Col>
                                </Row>
                            </div>

                        </Col>


                    </Row>
                    <Row>
                        <Col md={12} lg={12} className="d-none d-sm-block">
                            <TablePaymentHistory
                                header={columns}
                                data={dataSource}
                                pagination={paymentHistoryPages}
                                emptyMessage="No payment history"
                            />
                        </Col>
                        <Col md={12} lg={12} className="d-block d-sm-none">
                            {dataSource?.length < 1 ? (
                                     <div className="no_data_mobile d-none">
                                     <p>No Payment history to show</p>
                                 </div>
                            ):
                            <>
                            {dataSource.map((item) => (
                                <div className="mobile_record">
                                <ul className="mobile_record_list list-unstyled">
                                    <li><p>Date of Transaction</p><span>{item?.date}</span></li>
                                    <li><p>Transaction ID</p><span>{item?.id}</span></li>
                                    <li><h6 className="price">{`Rs. ${item?.amount}`}</h6>  <a>{item?.action}</a></li>
                                </ul>
                            </div>
                        ))} 
                            </>
                        }
                           
                              
                        </Col>
                    </Row>
                    {console.log({transactionHistory})}
                    <div className="modal_appointment">
                        <Modal
                            className='payment_history_modal'
                            title="Appointment List"
                            centered
                            visible={modal2Open}
                            onOk={() => setModal2Open(false)}
                            onCancel={() => setModal2Open(false)}
                        >
                            <div className="appointment_list_mobile d-block d-sm-none">
                                {transactionHistory.map((transactionMobile) => (
                                    
                                    <ul className="list_appoint_mob  list-unstyled">
                                           <li>
                                               <p><span>Appointment ID: </span>{transactionMobile?.appointment_id}</p>
                                               <p>{transactionMobile?.date}</p>
                                           </li>
                                           <li>
                                               <h6 className="price1">{`Rs. ${transactionMobile?.consultation_fees}`}</h6>
                                           </li>
                                       </ul>
                                ))}
                            </div>
                            <ul className="list_appoint d-sm-block d-none">
                                <li className="header_list">
                                    <ul>
                                        <li>Date </li>
                                        <li>Appointment ID </li>
                                        <li>Consultation Fee </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="list_appoint d-none d-lg-block fix-height">
                                {transactionHistory.map((transaction) => (
                                    <li className="listing" key={transaction.id}>
                                        <ul>
                                            <li>{transaction?.date}</li>
                                            <li>{transaction?.appointment_id}</li>
                                            <li>{transaction?.consultation_fees}</li>
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </Modal>
                    </div>

                </Container >



            </div >
        </>
    );
}

export default PaymentHistory;