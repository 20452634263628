import {
    createSlice,
    current,
    isFulfilled,
    isPending,
    isRejected,
  } from "@reduxjs/toolkit";
  
  import { getUniversities, getDegrees, getCities, getSpeciality, getServices, postUpdateProfile, getProfile } from "./thunk";
  
  const thunks = [getUniversities, getDegrees, getCities, getSpeciality, getServices, postUpdateProfile, getProfile];
  
  const initialState = {
    status: "idle",
    User: {},
    Universities: {},
    Degrees: {},
    Cities: {},
    Speciality: {},
    Services: {},
    citiesLoading: false,
    specialityLoading: false,
    servicesLoading: false,
    degreesLoading: false
  };
  
  export const slice = createSlice({
    name: "update",
    initialState,
    reducers: {

      // setClinic: (state, clinic) => {
      //   // state.clinic_rec = clinic.payload
      //   state.Clinics = {...state.Clinics, ...clinic.payload}
      //   // console.log(state.Clinics)
      // }
   
    //   setBank: (state, bank) => {
    //     state.userBank = bank.payload
    //   }
    },
    extraReducers: (builder) => {
      builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = "idle";
        state.User = action.payload;
      })
        .addCase(getUniversities.fulfilled, (state, action) => {
          state.status = "idle";
          state.Universities = action.payload;
        })
        .addCase(getDegrees.pending, (state, action) => {
          state.degreesLoading = true;
        })
        .addCase(getDegrees.fulfilled, (state, action) => {
          state.status = "idle";
          state.Degrees = action.payload;
          state.degreesLoading = false;
        })
        .addCase(getSpeciality.pending, (state, action) => {
          state.specialityLoading = true;
        })
        .addCase(getSpeciality.fulfilled, (state, action) => {
          state.status = "idle";
          state.Speciality = action.payload;
          state.specialityLoading = false;
        })
        .addCase(getCities.pending, (state, action) => {
          state.citiesLoading = true;
        })
        .addCase(getCities.fulfilled, (state, action) => {
          state.status = "idle";
          state.Cities = action.payload;
          state.citiesLoading = false;
        })
        .addCase(getServices.pending, (state, action) => {
          state.servicesLoading = true;
        })
        .addCase(getServices.fulfilled, (state, action) => {
          state.status = "idle"; 
          state.Services = action.payload;
          state.servicesLoading = false;
        })
        .addMatcher(isPending(...thunks), (state) => {})
        .addMatcher(isFulfilled(getUniversities, getDegrees, getCities, getServices), (state) => {})
        .addMatcher(isRejected(...thunks), (state, action) => {});
    },
  });
  
  // export const { setClinic } = slice.actions;
  
  
  export const selectUser = (state) => state.update.User;
  export const selectUniversities = (state) => state.update.Universities;
  export const selectDegrees = (state) => state.update.Degrees;
  export const selectCities = (state) => state.update.Cities;
  export const selectSpeciality = (state) => state.update.Speciality;
  export const selectServices = (state) => state.update.Services;

  export const selectCityLoader = (state) => state.update.citiesLoading;
  export const selectSpecialityLoader = (state) => state.update.specialityLoading;
  export const selectServicesLoader = (state) => state.update.servicesLoading;

  export const selectDegreesLoader = (state) => state.update.degreesLoading;
  
  export default slice.reducer;
  