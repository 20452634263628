import React, { useCallback, useEffect, useState } from "react";
// import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import { Row, Col, Container } from "react-bootstrap";
import { HeadingWithSpaceLarge } from "../../uiComponents/Headings";
// import { Select, TimePicker, Form } from "antd";
// import { HiOutlineArrowDown } from "react-icons/hi";
// import checkIcon from "../../assets/images/svg/check-green.svg";
// import CheckboxComponent from "../../uiComponents/checkboxComponent/checkboxComponent";
import { SimpleButton, SimpleButtonSmall } from "../../uiComponents/button";
import addIcon from "../../assets/images/svg/add.svg";
import arrowRight from "../../assets/images/svg/arrow-right-white.svg";
import AddTimeForm from "../../uiComponents/addTimeForm/AddTimeForm";
import CancelModal from "../../uiComponents/modal/cancelModal/CancelModal";
import { useAppDispatch } from "./../../redux/hooks";
import { useSelector } from "react-redux";
import { setClinic, selectClinics } from "./redux/slice";
import { postClinicRecord } from "./redux/thunk";
import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Form, Input } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa"
import { useForm } from "antd/lib/form/Form";

function OnlineTime(props) {
  const [onForm] = useForm()
  const history = useHistory();
  const type = [];
  const others = [];
  try {
    type.push(props?.location?.state?.type);
    others.push(props?.location?.state?.duration, null);
  } catch {
    type.push(props?.type);
  }
  const dispatch = useAppDispatch();
  const clinic = useSelector(selectClinics);
  const [consultation_duration, setConsultation_duration] = useState(null);
  const [consultation_fee, setConsultation_fee] = useState(null);

  const [modal, setModal] = useState("false");
  const [timing, setTiming] = useState(null);
  const [submitState, setSubmitState] = useState([]);
  const [duration, setDuration] = useState(null);
  const [fee, setFee] = useState(null);
  const [checkbox, setCheckbox] = useState();
  const [out, setOut] = useState([]);

  const [mobile, setMobile] = useState(false)
  useEffect(()=> {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 600) {
        setMobile(true)
      }
    }
  },[])

  const updateTime = (time, checkboxes) => {
    setCheckbox(checkboxes);
    setTiming(time);
    if (props?.location?.state?.clinic_timing?.length > 0 || props?.location?.state?.prop?.time_slots?.length > 0) {
      setOut(old => [...old, {
        day: time?.day,
        start_time: time?.start_time,
        end_time: time?.end_time,
        is_physical: time?.is_physical
      }]);
    }
  };

  const removeTime = (type, id) => {
    if (type === 'physical') {
      let filter = timeSlot.filter((item) => parseInt(item.key) !== id)
      setTimeSlot(filter)
    }
    else {
      let filter = timeSlot2.filter((item) => parseInt(item.key) !== id)
      setTimeSlot(filter)
    }
  }
  const [timeSlot2, setTimeSlot2] = useState([])
  const [timeSlot, setTimeSlot] = useState([<AddTimeForm
    key={0}
    id={0}
    updateTime={updateTime}
    removeTime={removeTime}
    type="physical"
    time={others[0]}
    // time={others?.length === 0 ? props?.time : others[0]}
    timings={props?.location?.state?.clinic_timing?.length > 0 ? [props?.location?.state?.clinic_timing?.[0]] : others[1]}
  />]);


  let c = 1
  let i = 0
  useEffect(() => {
    if (i < c) {
      i++
      if (props?.location?.state?.clinic_timing) {
        for (let i = 1; i < props?.location?.state?.clinic_timing?.length; i++) {
          setTimeSlot((old) => [...old,
          <AddTimeForm
            key={i}
            id={i}
            updateTime={updateTime}
            removeTime={removeTime}
            type="physical"
            time={others[0]}
            timings={props?.location?.state?.clinic_timing?.length > 0 ? [props?.location?.state?.clinic_timing?.[i]] : others[1]}
          />
          ])
        }
      }
    }
  }, [])

  let output = [];
  const getChecked = (checked) => {
    for (let i = 0; i < checked.length; i++) {
      var inDate = new Date("2020/01/01 " + checked[i]);
      let start_time = inDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      if (i === checked.length - 1) {
        var outDate = new Date("2020/01/01 " + checked[i]);
        var newTime = new Date();
        if (others.length !== 0) {
          newTime.setTime(outDate.getTime() + others[0] * 60000);
          let end_time = newTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          });
          output.push({
            day: timing.day,
            start_time: start_time,
            end_time: end_time,
            is_physical: props?.location?.state?.type === 'physical' ? 1 : 0,
          });
        } else {
          newTime.setTime(outDate.getTime() + props.time * 60000);
          let end_time = newTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          });
          output.push({
            day: timing.day,
            start_time: start_time,
            end_time: end_time,
            is_physical: props?.location?.state?.type === 'physical' ? 1 : 0,
          });
        }
      } else {
        let diff =
          checked[i + 1].split(":")[1].split(" ")[0] -
          checked[i].split(":")[1].split(" ")[0];
        if (diff > 0) {
          var outDate = new Date("2020/01/01 " + checked[i + 1]);
          let end_time = outDate.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          });
          output.push({
            day: timing.day,
            start_time: start_time,
            end_time: end_time,
            is_physical: props?.location?.state?.type === 'physical' ? 1 : 0,
          });
        } else {
          var outDate = new Date("2020/01/01 " + checked[i]);
          var newTime = new Date();
          if (others.length !== 0) {
            newTime.setTime(outDate.getTime() + others[0] * 60000);
            let end_time = newTime.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            });
            output.push({
              day: timing.day,
              start_time: start_time,
              end_time: end_time,
              is_physical: props?.location?.state?.type === 'physical' ? 1 : 0,
            });
          } else {
            newTime.setTime(outDate.getTime() + props.time * 60000);
            let end_time = newTime.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            });
            output.push({
              day: timing.day,
              start_time: start_time,
              end_time: end_time,
              is_physical: props?.location?.state?.type === 'physical' ? 1 : 0,
            });
          }
        }
      }
    }
    // console.log(output);
    return output;
  };
  let handleAddTime = (e) => {
    // console.log('others')
    try {
      let checked = [...new Set(timing.checkboxes)];
      e.preventDefault();
      if (props?.location?.state?.type === 'online') {
        others[0] = duration
        setTimeSlot2([
          ...timeSlot2,
          <AddTimeForm
            key={timeSlot2.length}
            id={timeSlot2.length}
            removeTime={removeTime}
            type="online"
            updateTime={updateTime}
            time={duration}
          // timings={others[1]}
          />,
        ])
      }
      else {

        setTimeSlot([
          ...timeSlot,
          <AddTimeForm
            key={timeSlot.length}
            id={timeSlot.length}
            removeTime={removeTime}
            updateTime={updateTime}
            time={others[0]}
            type="physical"
          // timings={others[1]}
          />,
        ])
      }
      // checked.sort(function (a, b) {
      //   return new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b);
      // });
      // // console.log(checked)

      if (props?.location?.state?.clinic_timing?.length > 0 || props?.location?.state?.prop?.time_slots?.length > 0) {

      } else {
        output = getChecked(checked);
        let temp = [...submitState];
        temp.push(...output);
        setSubmitState(temp);
      }
    } catch (error) {
      toast.error("please add previous time slot for new one")
    }
  };

  // // console.log(submitState)
  // const onChange = (value, timeString, toState) => {
  //   // console.log("Time", value, timeString);
  //   toState(timeString);
  // };


  const handleSubmit = async () => {
    try {

      if (props?.location?.state?.type === 'online') {

        others[0] = duration
        let new_clinic
        if (props?.location?.state?.prop?.time_slots?.length > 0) {
          // let clin = { clinic_id: props?.location?.state?.id, consultation_duration: props?.location?.state?.duration, consultation_fee: props?.location?.state?.fee }
          let outt = out.filter((v, i, a) => a.findIndex(v2 => ['start_time', 'end_time'].every(k => v2[k] === v[k])) === i)
          output = out;
          let temp = [...new Set([...submitState, ...output])];
          let timing_data = { clinic_timing: temp };
          dispatch(setClinic(timing_data));
          const online_clinic = { clinic_id: 0 };
          new_clinic = { ...online_clinic, ...timing_data, consultation_duration: duration, consultation_fee: fee };
        } else {
          let checked = [...new Set(timing.checkboxes)];
          output = getChecked(checked);
          let temp = [...new Set([...submitState, ...output])];
          let timing_data = { clinic_timing: temp };
          dispatch(setClinic(timing_data));
          const online_clinic = { clinic_id: 0 };
          new_clinic = { ...online_clinic, ...timing_data, consultation_duration: duration, consultation_fee: fee };
        }

        await dispatch(postClinicRecord(new_clinic)).then(data => {
          if (data.payload.code === 200) {
            toast.success("Clinic is added successfully");
            setTimeout(() => {
              history.push("/health-clinics", {
                location: { state: { type: 'physical' } }
              });
            }, 3000);
          }
          else {
            toast.error(data.payload.message)
          }
        });
      } else {
        let new_clinic
        if (props?.location?.state?.clinic_timing?.length > 0) {
          let clin = { clinic_id: props?.location?.state?.id, consultation_duration: props?.location?.state?.duration, consultation_fee: props?.location?.state?.fee }
          let outt = out.filter((v, i, a) => a.findIndex(v2 => ['start_time', 'end_time'].every(k => v2[k] === v[k])) === i)
          output = out;
          let temp = [...new Set([...submitState, ...output])];
          let timing_data = { clinic_timing: temp };
          dispatch(setClinic(timing_data));
          new_clinic = { ...clin, ...timing_data };
          // await dispatch(editClinicRecord(new_clinic)).then(data => {
          //   if (data.payload.code === 200) {
          //     toast.success("Clinic is updated successfully");
          //     setTimeout(() => {
          //       history.push("/health-clinics", {
          //         location: { state: { type: 'physical' } }
          //       });
          //     }, 3000);
          //   }
          //   else {
          //     toast.error(data.payload.message)
          //   }
          // });
        } else {
          let checked = [...new Set(timing.checkboxes)];
          output = getChecked(checked);
          let temp = [...new Set([...submitState, ...output])];
          var resArr = [];
          temp.filter(function (item) {
            var i = resArr.findIndex(x => (x.day == item.day && x.start_time == item.start_time && x.end_time == item.end_time));
            if (i <= -1) {
              resArr.push(item);
            }
            return null;
          });

          let timing_data = { clinic_timing: resArr };
          dispatch(setClinic(timing_data));
          new_clinic = { ...clinic, ...timing_data };
        }
        dispatch(postClinicRecord(new_clinic)).then(data => {
          if (data.payload.code === 200) {
            toast.success("Clinic is added successfully");
            setTimeout(() => {
              history.push("/health-clinics", {
                location: { state: { type: 'physical' } }
              });
            }, 3000);
          }
          else {
            toast.error(data.payload.message)
          }
        });
      }
    } catch (error) {
      toast.error("Please fill all details of timing to add.")
    }
  };

  const AddTime = () => {
    props?.location?.state?.prop?.time_slots?.map((times, i) => {
      setTimeout(() => {
        setTimeSlot2((oldVal) => [...oldVal,
        <AddTimeForm
          id={i}
          key={i}
          removeTime={removeTime}
          updateTime={updateTime}
          type="online"
          time={duration}
          timings={[times]}
        />,
        ])
        setTimeout(() => {
          let checked = [...new Set(timing.checkboxes)];
          output = getChecked(checked);
          let temp = [...submitState];
          temp.push(...output);
          setSubmitState(temp);
        }, 1000);
      }, 1000);
    })
  }
  const durationSubmit = () => {

    setConsultation_duration(duration)
    setConsultation_fee(fee)
    others[0] = duration;
    if (props?.location?.state?.prop?.time_slots?.length > 0) {
      AddTime()
    } else {
      setTimeSlot2(
        [<AddTimeForm
          id={0}
          key={0}
          removeTime={removeTime}
          updateTime={updateTime}
          type="online"
          time={duration}
          timings={others[1]}
        />
        ])
    }
    // props?.location?.state?.prop?.time_slots?.map((times, i) => {
    //   // if (i === 0) {
    //   //   setTimeSlot2([<AddTimeForm
    //   //     key={i}
    //   //     id={i}
    //   //     removeTime={removeTime}
    //   //     updateTime={updateTime}
    //   //     type="online"
    //   //     time={duration}
    //   //     timings={props?.location?.state?.prop ? [props?.location?.state?.prop?.time_slots?.[0]] : others[1]}
    //   //   />])
    //   //   console.log(times)
    //   // } 
    //   // else {
    //   console.log(timeSlot2)
    //   setTimeSlot2([...timeSlot2,
    //   <AddTimeForm
    //     key={i}
    //     id={i}
    //     removeTime={removeTime}
    //     updateTime={updateTime}
    //     type="online"
    //     time={duration}
    //     // time={others?.length === 0 ? props?.time : others[0]}
    //     timings={props?.location?.state?.prop ? [times] : others[1]}
    //   />,
    //   ])
    //   // }
    // })
  }

  useEffect(() => {

    if (props?.location?.state?.prop) {
      setFee(props?.location?.state?.prop?.consultation_fee)
      setDuration(props?.location?.state?.prop?.consultation_duration2)
      onForm.setFieldsValue({
        consultation_fee: props?.location?.state?.prop?.consultation_fee,
        consultation_duration: props?.location?.state?.prop?.consultation_duration2,
      });
    }
  }, [props?.location?.state?.prop])

  return (
    <div className="infoClinic cover_space2">
      <StickyTab menuList={props?.location?.state?.menu} />
      <div className="online_time cover_space3">
        <Container>
          <div className="addSlots">
            <SimpleButton
              onClick={() => history.goBack()}
              bgColor="transparent"
              text="Go Back"
            >
              <FaArrowAltCircleLeft className="back_arrow" />
            </SimpleButton>
          </div>
          <div className="column_flex justify_between">
            <div className="w-100">



              {props?.location?.state?.type === 'online' ? (
                <Form layout="vertical" className="form_end" form={onForm}>
                  <Row>
                    <Col lg={4}>
                      <Form.Item
                        name="consultation_fee"
                        label="Consultation Fees"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Consultation Fee!",
                          },
                          {
                            pattern: /^(?:\d*)$/,
                            message: "Value should contain just number",
                          },
                        ]}
                      >
                        {props?.location?.state?.prop ? (
                          <Input
                            type="text"
                            className="c_input"
                            required
                            value={props?.location?.state?.prop?.consultation_fee}
                            onChange={(e) => setFee(e.target.value)}
                          />
                        ) : (
                          <Input type="text" className="c_input" required onChange={(e) => setFee(e.target.value)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={4}>
                      <Form.Item
                        name="consultation_duration"
                        label="Each Time Slot Duration (in minutes)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Consultation Duration!",
                          },
                          {
                            pattern: /^(?:\d*)$/,
                            message: "Value should contain just number",
                          },
                        ]}
                      >
                        {props?.location?.state?.prop ? (
                          <Input
                            type="text"
                            className="c_input"
                            required
                            value={props?.location?.state?.prop?.consultation_duration2}
                            onChange={(e) => setDuration(e.target.value)}
                          />
                        ) : (
                          <Input type="text" className="c_input" required onChange={(e) => setDuration(e.target.value)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={3}>
                      <SimpleButton
                        text="Select"
                        onClick={durationSubmit}
                        type="submit"
                      >
                      </SimpleButton>
                    </Col>
                  </Row>
                </Form>
              ) : (<></>)}

              {props?.location?.state?.type === 'online' ? (consultation_duration ? <>
                <div className="timeForm">{timeSlot2}</div>
                <Row>
                  <div className="addSlots">
                    <SimpleButton
                      onClick={handleAddTime}
                      bgColor="transparent"
                      text="Add more slots"
                    >
                      <img src={addIcon} alt="addIcon" />
                    </SimpleButton>
                  </div>
                </Row></> : <></>) : (<>
                  <Row>
                    <Col lg={12}>
                      <HeadingWithSpaceLarge
                        text="ADD DATE AND TIME"
                        color="black"
                      />
                    </Col>
                  </Row>
                  <div className="timeForm">{timeSlot}</div>

                  <Row>
                    <div className="addSlots">
                      <SimpleButton
                        onClick={handleAddTime}
                        bgColor="transparent"
                        text="Add more slots"
                      >
                        <img src={addIcon} alt="addIcon" />
                      </SimpleButton>
                    </div>
                  </Row></>
              )}
            </div>
            <div className="confirm_btn">
              <Row>
                <Col lg={12}>
                  <SimpleButton
                    text="Confirm"
                    onClick={handleSubmit}
                    type="submit"
                  >
                    {!mobile && <img src={arrowRight} alt="arrowRight" />}
                  </SimpleButton>
                  {/* <LinkContainerSmall text="Confirm" onClick={() => setModal('m1')}>
                  <img src={arrowRight} alt="arrowRight" />
                </LinkContainerSmall> */}
                  <CancelModal
                    show={modal === "m1"}
                    close={() => setModal(false)}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
        <ToastContainer />
      </div>
    </div>
  );
}

export default OnlineTime;
