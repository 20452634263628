import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Checkbox,
} from "antd";
import { Col, Collapse, Container, Row, Table } from "react-bootstrap";
import MedCancelCard from "../../uiComponents/card/medCancelCard/MedCancelCard";
import {
  HeadingDesc,
  HeadingDescSmall,
  HeadingDescVsmall,
  HeadingWithSpaceLarge,
} from "../../uiComponents/Headings";
import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import { TableComponent } from "../../uiComponents/tableComponent";
import deleteIconPink from "../../assets/images/svg/delete_icon_pink.svg";
import arrowdropdown from "../../assets/images/svg/dropdown-icon.svg";
import fileSrc from "../../assets/images/svg/fileShape.svg";
import fileIcon from "../../assets/images/png/view.png";
import {
  FiArrowRightCircle,
  FiChevronRight,
  FiCircle,
  FiCheckCircle,
} from "react-icons/fi";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getAppointmentData,
  getDiseases,
  getMedicine,
  getLab,
  postConsult,
  getVitalScan,
} from "./redux/thunk";
import { useAppDispatch, useAppSelector } from "./../../redux/hooks";
import arrowDownload from "../../assets/images/svg/arrow-download-circle.svg";
import logo from "../../assets/images/svg/meri-sehat-logo.svg";
import logoMob from "../../assets/images/svg/logo-mobile.svg";
import featuredDoc from "../../assets/images/png/featured_doc.png";
import drSaad from "../../assets/images/png/dr-saad.jpg";
import FeaturedTick from "../../assets/images/png/featured_doc.png";
import DrPicture from "../../assets/images/png/dr-saad.jpg";
import LoaderTimer from "../../assets/images/gif/loading_spinner.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  selectAppointmentData,
  selectDiseases,
  selectMedicine,
  selectLabs,
  selectMedical,
  selectAppointmentToken,
  selectVitalScan,
  selectInstantMedical,
  selectAppointmentDataSuccess,
  selectMedicineLoading,
  selectLabLoading,
} from "./redux/slice";
import "./Appointments.scss";
import LabForm from "../../uiComponents/form/LabForm";
import MedForm from "../../uiComponents/form/MedForm";
import Agora from "../../uiComponents/agora/Agora";
import { ToastContainer, toast } from "react-toastify";
import Countdown from "react-countdown";
// import FsLightbox from 'fslightbox-react';
import "yet-another-react-lightbox/styles.css";
import { downloadPrescription, getAge } from "../../helpers/utilityHelper";
import { RouterPrompt } from "../../uiComponents/RouterPrompt";
import disableConsultation from "../../assets/images/svg/instant-disable-modal.svg";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import RemovePre from "../../assets/images/svg/removePre.svg";
import EditPre from "../../assets/images/svg/EditPre.svg";
import stressLevel from "../../assets/images/svg/stressLevel.svg";
import { useEffectOnce } from "react-use";
import API from "../../utils/customAxios";
import { selectDashboard } from "../dashboard/redux/slice";
import { getDashboardDetails } from "../dashboard/redux/thunk";
import { acquireApi, startApi, stopApi } from "../../utils/agoraEndpoints";
import axios from "axios";
import "./appointmentFirstTime.css";
import { AiOutlineFile } from "react-icons/ai";
import AgoraRTM from "agora-rtm-sdk";
import TablePreviousHistory from "../../uiComponents/tableComponent/TablePreviousHistory/TablePreviousHistory";
import TabItem from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Nav from "react-bootstrap/Nav";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import { isMobile } from "react-device-detect";
import smilyFace from "../../assets/images/png/smily.png";
import stressFace from "../../assets/images/png/stress-emojy.png";
import closeTable from "../../assets/images/png/close-table.png";
import lowStress from "../../assets/images/png/low-stress.png";
import normalStress from "../../assets/images/png/normal-stress.png";
import highStress from "../../assets/images/png/high-stress.png";
import { isJsonString, isProduction } from "../../utils/helperFunctions";
import { getUserId } from "../../helpers/authHelper";
import Cookies from "js-cookie";
import Loader from "../../uiComponents/loader/Loader";
import Agoraa from "../../uiComponents/agora/Agoraa";

let arr = new Set();

function AppointmentFirstTime(props) {

  let days = [];

  for (let i = 1; i < 32; i++) {
    days.push(i);
  }

  const months = [
    { label: 'Jan', value: '01' },
    { label: 'Feb', value: '02' },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'Aug', value: '08' },
    { label: 'Sept', value: '09' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' }
  ];

  const years = [];
  const currentYear = new Date().getFullYear();

  for (let i = 1900; i <= currentYear; i++) {
    years.unshift(i);
  }

  const doctorId = getUserId();

  let stagingName = `S${doctorId}`;
  let prodName = `L${doctorId}`;

  let firstPrefix = process.env.REACT_APP_BASE_URL?.includes("staging")
    ? stagingName
    : prodName;

  const history = useHistory();
  const params = useParams();
  const textareaRef = useRef();

  const [tabValue, setTabValue] = React.useState("1");
  const [tabSwitchState, setTabSwitchState] = useState(0);
  const [rtmToken, setRtmToken] = useState("");

  const handleTabs = (event, newValue) => {
    if (tabSwitchState === 1 && newValue === "2") {
    } else if (tabSwitchState === 2 && newValue === "1") {
    } else {
      setTabValue(newValue);
    }
  };

  const dispatch = useAppDispatch();
  const location = useLocation();
  const selectDashboardDetails = useAppSelector(selectDashboard);

  if (location?.state?.user_id) {
    sessionStorage.setItem("user_id", location?.state?.user_id);
  }

  if (location?.state?.visit_count) {
    sessionStorage.setItem("visit_count", location?.state?.visit_count);
  }

  const AppointmentData = useAppSelector(selectAppointmentData);
  const vitalScan = useAppSelector(selectVitalScan);
  const conditions = useAppSelector(selectDiseases);
  const medicine = useAppSelector(selectMedicine);
  const medicineLoading = useAppSelector(selectMedicineLoading);
  const labLoading = useAppSelector(selectLabLoading);
  const labs = useAppSelector(selectLabs);
  // const medical = useAppSelector(selectMedical);
  const medical = useAppSelector(selectInstantMedical);
  const [appForm] = Form.useForm();
  const [form] = Form.useForm();
  const appointmentToken = useAppSelector(selectAppointmentToken);
  let id = params.id;
  let user_id = sessionStorage.getItem("user_id");
  let visit_count = sessionStorage.getItem("visit_count");
  const [startCall, setStartCall] = useState(false);
  const [config, setConfig] = useState(null);
  const [toggler, setToggler] = useState(false);
  const [toggler1, setToggler1] = useState(false);
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState("");
  const [gnote, setGNote] = useState("");
  const [med, setMed] = useState([]);
  const [cond, setCond] = useState([]);
  const [editMedicineStatus, setEditMedicineStatus] = useState(false);
  const [editMedicine, setEditMedicine] = useState();
  const [patient, setPatient] = useState({
    name: "",
    age: "",
    gender: "",
    reason: "",
    prescription: "",
    previous_consultation: "",
  });
  const [medData, setMedData] = useState([]);
  const [medTable, setMedTable] = useState([]);
  const [vitalTable, setVitalTable] = useState([]);
  const [medicalRec, setMedicalRec] = useState([]);
  const [recFiles, setRecFiles] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [timeRem, setTimeRem] = useState(0);
  const [prescribeData, setPrescribeData] = useState([]);
  const [count, setCount] = useState(0);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [modal4Visible, setModal4Visible] = useState(false);
  const [endAnnounced, setendAnnounced] = useState(false);
  const [blockUserExit, setBlockUserExit] = useState(false);
  const [formValues, setFormValues] = useState({});
  const { Option } = Select;
  const { TextArea } = Input;
  const [mobile, setMobile] = useState(false);
  const [writePrescriptionInfo, setWritePrescriptionInfo] = useState([]);
  const [isOnline, setIsOnline] = useState(true);
  const [errorPrescription, setErrorPrescription] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [channelToken, setChannelToken] = useState("");
  const [resourceId, setResourceId] = useState("");
  const [stopId, setStopId] = useState("");
  const [instantMedicalRecords, setInstantMedicalRecords] = useState([]);
  const [prev_consultation, setPrevConsultation] = useState([]);
  const [appointmentCompleted, setAppointmentCompleted] = useState(false);
  const [shouldDispatchMedicine, setShouldDispatchMedicine] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState(false);
  const [userCity, setUserCity] = useState("");
  const [activeTab, setActiveTab] = useState("");

  const [startNextConsultation, setStartNextConsultation] = useState(false);
  const [viewSingleReport, setViewSingleReport] = useState(false);
  const [viewSinglePresc, setViewSinglePresc] = useState(false);
  const [viewSingleReportFile, setViewSingleReportFile] = useState();
  const [modal2Open, setModal2Open] = useState(false);
  const [modal2OpenData, setModal2OpenData] = useState();

  const [csvLoading, setCsvLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showPrevConsultationModal, setShowPrevConsultationModal] = useState();
  const [completeAppointmentLoader, setCompleteAppointmentLoader] =
    useState(false);
  const [cancelAppointmentLoader, setCancelAppointmentLoader] = useState(false);
  const [showAddDiseaseBtn, setShowAddDiseaseBtn] = useState(false);
  const [shouldDispatchDiseases, setShouldDispatchDiseases] = useState(false);
  const [addDiseaseLoader, setAddDiseaseLoader] = useState(false);
  const [addMedicineLoader, setAddMedicineLoader] = useState(false);
  const [addLabLoader, setAddLabLoader] = useState(false);
  const [shouldDispatchLabs, setShouldDispatchLabs] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [downloadPastUrl, setDownloadPastUrl] = useState("");
  const [patientQueueCount, setPatientQueueCount] = useState(null);
  const [heightValues, setHeightValues] = useState([]);
  const [weightValues, setWeightValues] = useState([]);
  const [reasonList, setReasonList] = useState();
  const diseaseRef = useRef();
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [reasonForVisit, setReasonForVisit] = useState("");
  const [key, setKey] = useState("home");
  const [dateOfBirthError, setDateOfBirthError] = useState(null);


  // console.log(location?.search?.split('?'));
  useEffect(() => {
    setLoader(false);
    // dispatch(getMedicalRecord(user_id));
    // dispatch(getInstantMedicalRecord(1035));
    if (id) {
      dispatch(getAppointmentData(id));
    }

    dispatch(getVitalScan(user_id));
    dispatch(getMedicine());
    dispatch(getLab());
    dispatch(getDiseases());
    if (typeof window !== "undefined") {
      if (window.innerWidth < 600) {
        setMobile(true);
      }
    }
  }, [id]);

  // useEffect(() => {
  //   (async() => {
  //     const response = await API.get(`/generate-agora-rtm-token`);

  //     if (response?.data?.code === 200) {
  //       setRtmToken(response?.data?.data?.token);
  //     }
  //   })()
  // }, [])

  useEffect(() => {
    if (shouldDispatchDiseases === true) {
      dispatch(getDiseases());
    }
  }, [shouldDispatchDiseases]);

  useEffect(() => {
    if (shouldDispatchLabs === true) {
      dispatch(getLab());
      setShouldDispatchLabs(false);
    }
  }, [shouldDispatchLabs]);

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(
          `/generate-agora-link?appointment_id=${params.id}`
        );

        if (response?.data?.code === 200) {
          setChannelName(response?.data?.data?.channel_name);
          setChannelToken(response?.data?.data?.agora_token);
        }
      } catch (error) {
        // console.log(error, 'agorrerror');
      }
    })();
  }, [channelName]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get('/doctor/patient-count');

        if (response?.data?.code === 200) {
          setPatientQueueCount(response.data?.data);
        }
      } catch (error) {
        // Handle the error here
        console.error('Error fetching patient count:', error);
      }
    };

    // Call fetchData immediately and then every 5 seconds
    fetchData();
    const intervalId = setInterval(fetchData, 15000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);



  useEffect(() => {
    if (channelName && isProduction()) {
      (async () => {
        const body = {
          cname: channelName,
          uid: params?.id?.toString(),
          clientRequest: {
            resourceExpiredHour: 24,
            scene: 0,
          },
        };

        const customerKey = process.env.REACT_APP_AGORA_CUSTOMER_KEY;
        const customerSecret = process.env.REACT_APP_AGORA_CUSTOMER_SECRET;

        const response = await axios.post(acquireApi, body, {
          auth: {
            username: customerKey,
            password: customerSecret,
          },
        });

        if (response?.status === 200) {
          const startAgoraAPI = startApi(response?.data?.resourceId);

          const payload = {
            cname: channelName,
            uid: params?.id?.toString(),
            clientRequest: {
              token: channelToken,
              recordingConfig: {
                // https://docs.agora.io/en/cloud-recording/reference/rest-api/start#recording-configuration
                maxIdleTime: 120,
                streamTypes: 2,
                audioProfile: 1,
                channelType: 0, //used for communication. if live broadcast, set to 1
                videoStreamType: 0,
                transcodingConfig: {
                  width: 640,
                  height: 480,
                  fps: 30,
                  bitrate: 600,
                  mixedVideoLayout: 1,
                },
              },

              recordingFileConfig: {
                avFileType: ["hls", "mp4"],
              },
              storageConfig: {
                vendor: 1, //Here 1 means AWS S3
                region: 8, // AP_SOUTHEAST_1
                bucket: "agora-recording-s",
                accessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
                secretKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                fileNamePrefix: [firstPrefix, params?.id?.toString()],
              },
            },
          };
          try {
            const res = await axios.post(startAgoraAPI, payload, {
              auth: {
                username: customerKey,
                password: customerSecret,
              },
            });

            setResourceId(res?.data?.resourceId);
            setStopId(res?.data?.sid);
          } catch (error) {
            // console.log(error);
          }
        }
      })();
    }
  }, [channelName]);

  useEffectOnce(() => {
    dispatch(getDashboardDetails());
  });

  useEffect(() => {
    let interval = setInterval(async () => {
      const res = await API.get(`/instant-medical-record?user_id=${user_id}`);

      setMedicalRec(res?.data?.data);
    }, 10000);

    return function () {
      clearInterval(interval);
    };
  }, []);



  useEffect(() => {
    setVitalTable(vitalScan?.data);
  }, [vitalScan]);

  // useEffect(() => {
  //   setMedicalRec(medical?.data)
  // }, [medical]);

  useEffect(() => {
    window.addEventListener("unload", function (e) {
      // console.log('cancelled appointment');
    });
  }, []);

  useEffect(() => {
    if (AppointmentData?.data) {
      setUserCity(AppointmentData?.data?.user?.city);

      let data = {};
      data.patient = AppointmentData?.data?.user;
      data.reason = AppointmentData?.data?.reason;
      data.type = AppointmentData?.data?.type;
      data.prescription = AppointmentData?.data?.prescription;
      data.previous_consultation = AppointmentData?.data?.previous_consultation;

      setActiveTab(AppointmentData?.data?.previous_consultation?.[0]?.id);

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/patient-info?user_id=${user_id}`,
          {
            headers: {
              // 'user-id': AppointmentData?.data?.user_id
              Authorization: Cookies.get("token"),
            },
          }
        )
        .then((res) => {
          // console.log(res, 'promise')
          console.log(res?.data?.data, 'ascascasvr')
          setPatient({
            name: res?.data?.data?.name,
            age: getAge(res?.data?.data?.date_of_birth),
            dateOfBirth: res?.data?.data?.date_of_birth,
            gender: res?.data?.data?.gender,
            reason: data?.reason,
            type: AppointmentData?.data?.type,
            weight: res?.data?.data?.weight,
            height: res?.data?.data?.height,
            prescription: data?.prescription,
            previous_consultation: AppointmentData?.data?.previous_consultation,
            doctorName: AppointmentData?.data?.doctor?.name,
          });
        });

      setStartCall(data.type == "in-person" ? false : true);
      setLoader(true);
    }


    if (AppointmentData?.data?.remaining_time) {
      let currentDateTime = new Date();
      setTimeRem(
        new Date(
          currentDateTime.getTime() +
          parseInt(AppointmentData?.data?.remaining_time) * 1000
        )
      );
      // setTimeRem(new Date(currentDateTime.getTime() + parseInt(9400) * 1000));
      // setTimeRem(new Date(currentDateTime.getTime() + parseInt(60) * 60 * 1000));

    }
  }, [AppointmentData]);

  // const ErrorNull = () => {
  //   setErrorPrescription('')
  // }

  function writePrescription(e) {
    setErrorPrescription("");
    e.preventDefault();
    if (textareaRef.current.value === "") {
      setErrorPrescription("Field cannot be left empty");
    }

    if (textareaRef.current.value.length < 5) {
      setErrorPrescription("Prescription cannot be less than 5 characters");
    } else {
      setWritePrescriptionInfo([
        ...writePrescriptionInfo,
        textareaRef.current.value,
      ]);
      textareaRef.current.value = "";
    }
  }

  function removeWrittenPrescription(e, pres) {
    let filtered = writePrescriptionInfo.filter((info) => info !== pres);
    setWritePrescriptionInfo(filtered);
  }

  function editPrescriptionInfo(e, pres) {
    textareaRef.current.value = pres;

    let filtered = writePrescriptionInfo.filter((info) => info !== pres);
    setWritePrescriptionInfo(filtered);
  }

  function activeListHandler(e, elementId) {
    setBlockUserExit(false);

    const element = document.querySelector(elementId);
    const yOffset = -140;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    if (element) {
      // element.scrollIntoView({ behavior: 'smooth', alignToTop: true, block: "nearest" });
      window.scrollTo({ top: y, behavior: "smooth" });
    }

    setTimeout(() => {
      setBlockUserExit(false);
    }, 4000);
  }

  // function consultationFinishedHandler() {
  //   const button = document.querySelector(".submit-btn-completed");

  //   button.click();
  // }



  const handleDelete = (key, data, change) => {
    const newData = data?.filter((item) => item.key !== key);
    setPrescribeData(newData);
    change(newData);
  };


  async function downloadPastPrescription(e, url) {
    setDownloadLoader(true);
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': Cookies.get("token"),
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(response => response.blob())
      .then(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(blob);
        setDownloadLoader(false);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "file.pdf";
        document.body.appendChild(a);
        a.click();
      })
      .catch((err) => {
        console.log({ err })
      })
  }

  let medHeader = [
    { title: "Medicine", dataIndex: "medicine" },
    { title: "Dosage", dataIndex: "dosage" },
    { title: "Frequency", dataIndex: "per_day" },
    { title: "Duration", dataIndex: "number_of_days" },
    { title: "Instruction", dataIndex: "is_after_meal" },
    {
      title: "",
      dataIndex: "buttons",
      render: (_, record) => (
        <div className="flex_start">
          {/* <a>
              <img src={editIConGreen} alt="edit"></img>
            </a> */}
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              handleDelete(record.key, medData2, setMedTable);
            }}
          >
            <a>
              <img src={deleteIconPink} alt="delete"></img>
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];

  let vitalHeader = [
    { title: "Date", dataIndex: "date" },
    { title: "Heart Rate", dataIndex: "heart_rate" },
    { title: "Respiratory Rate", dataIndex: "respiratory_rate" },
    { title: "Blood Pressure", dataIndex: "blood_pressure" },
    { title: "Stress Level", dataIndex: "stress_level" },
    { title: "SDNN", dataIndex: "sdnn" },
    { title: "SPO2", dataIndex: "spo2" },
  ];

  let previousConsultationHeader = [
    { title: "Date", dataIndex: "date" },
    {
      title: "Prescription",
      dataIndex: "prescription",
      render(text, record) {
        // console.log(record, "record")

        return {
          children: (
            <div onClick={(e) => downloadPastPrescription(e, record?.downloadUrl)}>
              {" "}
              {record?.prescription}{" "}
            </div>
          ),
        };
      },
    },
  ];

  let medRecordHeader = [
    { title: "Date", dataIndex: "med_rec_date" },
    {
      title: "Records",
      dataIndex: "buttons",
      render(text, record) {
        // console.log(text, "zain");
        let files = [];
        let first = text?.[0]?.file;
        text?.map((f) => {
          files.push({ src: f?.file });
        });

        const slides = [
          // {
          //   src: first,
          //   width: "100%",
          //   height: "100%",
          //   srcSet: files
          // }
          files,
        ];
        let idd = record?.key;
        return {
          children: (
            <>
              <div className="row d-block medicalRecordBox _flex_start">
                <div>
                  {text?.length ? (
                    <p className="reportsFor">
                      Reports for{" "}
                      {AppointmentData?.data?.doctor?.prefix
                        ? `${AppointmentData?.data?.doctor?.prefix}. ${AppointmentData?.data?.doctor?.name}`
                        : AppointmentData?.data?.doctor?.name}
                    </p>
                  ) : null}
                </div>
                {text?.map((item, index) => (
                  <>
                    <a
                      key={index}
                      className="consult_now fs-16 text-decoration-none col-6 justify-content-start"
                      onClick={() => window.open(item?.file, "_blank")}
                    >
                      {/* <a className="consult_now" > */}
                      {/* <FiArrowRightCircle className="arrow_black" /> */}
                      <AiOutlineFile />
                      <img src={fileSrc} alt="" />
                      <p className="black_text text-capitalize ms-2">
                        {/* {cutFromMiddle(item?.filename)}{" "} */}
                        {item?.filename}
                      </p>
                    </a>

                    {/* {toggler1 && <LightboxModal id={idd} files={files} toggler1={toggler1} setToggler1={setToggler1} />} */}
                  </>
                ))}
              </div>
            </>
          ),
        };
      },
    },
    { title: "", dataIndex: "", className: "w-40" },
  ];

  let prescribeHeader = [
    { title: "Lab Test", dataIndex: "lab_test" },
    {
      title: "",
      dataIndex: "buttons",

      render: (_, record) => (
        <div className="flex_start">
          {/* <a>
            <img src={editIConGreen} alt="edit"></img>
          </a> */}
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              handleDelete(record.key, prescribeData, setPrescribeData);
            }}
          >
            <a>
              <img src={deleteIconPink} alt="delete"></img>
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const menuList = [
    {
      link: "#medical_history",
      name: "Medical History",
    },
    {
      link: "#medical_record",
      name: "Medical Records",
    },
    {
      link: "#vitals",
      name: "Vitals & Notes",
    },
    {
      link: "#prescribe_med",
      name: "Prescribe Medicines",
    },
    {
      link: "#prescribe_lab",
      name: "Prescribe Labs",
    },
  ];

  const cutFromMiddle = (longText) => {
    const maxLength = 20;
    let shortText = longText;

    if (longText?.length > maxLength) {
      const middle = Math.floor(longText.length / 2);
      const beginning = longText.slice(0, middle - 5);
      const end = longText.slice(middle + 30);
      shortText = `${beginning}...${end}`;
    }
    return shortText;
  };

  let medical_record_table = [];
  medicalRec?.map((med, index) => {
    let imgs = [];
    med?.instant_medical_record_files.length && med?.instant_medical_record_files?.map((f) => {
      imgs.push(f?.file);
    });
    med?.instant_medical_record_files.length && medical_record_table.push({
      key: index + 1,
      // key: med?.instant_medical_record_files
      med_rec_date: moment(med?.date).format('DD/MM/YYYY'),
      med_rec_reports: med?.total_reports,
      buttons: med?.instant_medical_record_files,
    });
  });
  // console.log(medicalRec,'imgs')

  const previous_record_table = [];

  let vitalTableLocale = {
    emptyText: "No vitals measured",
  };

  let vitalData = [];
  vitalTable?.health_scans?.map((scan, index) => {
    if (index === 0) {
      vitalData.push({
        key: index + 1,
        date: <><div className="date text-left">{`${scan?.date} `}</div><div className="time text-left">{`  ${scan?.dateTime}`}</div></>,
        sehat_score: scan?.sehat_score,
        heart_rate: scan?.heart_rate && <><div className="textVital">{` ${scan.heart_rate}`}<span> BPM </span></div>   <div className="text-center"><span className="heigh tags">High</span></div></>,
        respiratory_rate:
          scan?.respiratory_rate && <><div className="textVital">{`${scan?.respiratory_rate}`}<span> BPM </span></div><div className="text-center"><span className="normal tags">Normal</span></div></>,
        blood_pressure: scan?.blood_pressure && <><div className="textVital">{`${scan?.blood_pressure}`}<span> mmHG </span></div>  <div className="text-center"><span className="normal tags">Normal</span></div> </>,
        stress_level: <>{scan?.stress_level} <div> <img src={stressLevel} className="stressLevel mb-2" alt="Stress Level Image" /> </div> <div className="text-center"><span className="normal tags">Low</span></div></>,
        sdnn: scan?.sdnn && <><div className="textVital">{`${scan?.sdnn}`}<span> MS </span></div>  <div className="text-center"><span className="normal tags">Normal</span></div></>,
        spo2: scan.spo2 && <><div className="textVital">{`${scan?.spo2}`}<span> % </span></div> <div className="text-center"><span className="normal tags">Normal</span></div></>,
        dateValue: scan?.date,
        timeValue: scan?.dateTime,
        heart_rate_value: scan?.heart_rate_value,
        respiratory_rate_value: scan?.respiratory_rate_value,
        stress_level_value: scan?.stress_level_value,
        spo2_value: scan?.spo2_value,
        sdnn_value: <><div className="textVital">{`${scan?.sdnn_value}`}<span> MS </span></div>  <div className="text-center"><span className="normal tags">Normal</span></div></>,
        blood_pressure_value: scan?.blood_pressure_value,

      });
    }
  });

  // console.log({ vitalData });

  let previousConsultationData = [];

  patient.previous_consultation.length > 0 &&
    patient.previous_consultation.forEach((item) => {
      previousConsultationData.push({
        date: item?.date,
        prescription: `Prescription-${item?.id}`,
        url: `/appointment/download-prescription/${item?.id}?is_html=1&is_download=1`,
        downloadUrl: `${process.env.REACT_APP_BASE_URL}/appointment/download-prescription/${item?.id}?is_html=1&is_download=1`
      });
    });

  // console.log({ previousConsultationData });

  const setDiseaseFunc = (type, value) => {
    if (type === "add") {
      arr.add(value);
      setCond([...arr]);
    } else {
      const index = arr.has(value);
      if (index === true) {
        arr.delete(value);
      }
      setCond([...arr]);
    }
  };

  const handleOnChange = (value, event) => {
    if (arr.has(value) === false) {
      setDiseaseFunc("add", value);
      setMed([
        ...med,
        <MedCancelCard
          text={value}
          id={med.length}
          cancel={true}
          setDiseaseFunc={setDiseaseFunc}
        />,
      ]);
    }
  };

  let completeApp = (values) => {
    // console.log(values, "valuesss");
    setFormValues(values);
    setModal2Visible(true);
    // setCanUserLeave(false);
    setBlockUserExit(false);
  };

  const completeAppointment = async () => {
    setBlockUserExit(false);
    setCompleteAppointmentLoader(true);
    let appointmentsInQueue = [];


    const response = await API.get("/doctor/pending-appointment");

    if (response?.data?.code === 200) {
      appointmentsInQueue = response?.data?.data;
    }

    if (appointmentsInQueue?.length > 1) {
      appointmentsInQueue?.sort((a, b) => a?.id - b?.id);
    }

    let prescription_here = JSON.stringify(writePrescriptionInfo);

    // setBlockUserExit(true);
    setModal2Visible(false);
    setModal3Visible(false);
    let values = formValues;

    values.prescription_here = prescription_here;
    values.condition = cond;

    delete values.prevRecord;
    values?.medicine?.map((item) => {
      delete item.medicine;
      delete item.medName;
      delete item.key;
    });
    delete values.medicalRecord;

    delete values.vitalTable;

    let labData = prescribeData.slice(0);
    let lab_info = [];
    labData?.map((labs) => {
      lab_info.push({
        prescription_element_id: labs.prescription_element_id,
        description: labs?.description,
      });
    });
    values.lab = lab_info;

    values.medicine = medTable;
    values.name = name;
    values.gender = gender;
    values.birth_date = `${year}-${month}-${date}`;
    values.height = height;
    values.weight = weight;
    values.reason_for_visit = reasonForVisit;
    values.patient_id = user_id && parseInt(user_id)

    let payload = {
      id: id,
      data: values,
      patient_id: user_id && parseInt(user_id),

    };

    const stopAgoraApi = stopApi(resourceId, stopId);
    const stopApiPayload = {
      cname: channelName,
      uid: params?.id?.toString(),
      clientRequest: {},
    };

    const customerKey = process.env.REACT_APP_AGORA_CUSTOMER_KEY;
    const customerSecret = process.env.REACT_APP_AGORA_CUSTOMER_SECRET;

    if (isProduction()) {
      try {
        const response = await axios.post(stopAgoraApi, stopApiPayload, {
          auth: {
            username: customerKey,
            password: customerSecret,
          },
        });
      } catch (error) { }
    }


    await dispatch(postConsult(payload)).then((data) => {
      if (data.payload.code === 200) {
        setStartCall(false);

        // leaveChannel()
        toast.success("Appointment is completed.");
        // setBlockUserExit(false);

        const appID = process.env.REACT_APP_AGORA_TEST_APP_ID;
        const appointmentId = params?.id;

        const channelName = `${appointmentId}`;
        let token;

        (async () => {
          try {
            const response = await API.get(`/generate-agora-rtm-token`);
            if (response?.data?.code === 200) {
              token = response?.data?.data?.token;

              if (appID && channelName) {
                const client = AgoraRTM.createInstance(appID);
                setBlockUserExit(false);

                await client.login({ token, uid: `${doctorId}` });

                let channel = client.createChannel(channelName);

                await channel.join();

                await channel
                  .sendMessage({ text: "appointment-ended" })
                  .then(() => {
                    setCompleteAppointmentLoader(false);
                    // console.log({ startNextConsultation });
                    // console.log({ appointmentsInQueue });
                    // console.log("message_sent");
                    if (startNextConsultation) {
                      if (appointmentsInQueue?.length > 1) {
                        history.push({
                          pathname: `/appointment/${appointmentsInQueue?.[1]?.id}`,
                          state: {
                            id: appointmentsInQueue?.[1]?.id,
                            type: appointmentsInQueue?.[1]?.type,
                            user_id: appointmentsInQueue?.[1]?.user_id,
                            visit_count: 0,
                          },
                        });
                      } else {
                        window.location.href = "/";
                      }
                    } else {
                      window.location.href = "/";
                    }
                  })
                  .catch((err) => {
                    setBlockUserExit(false);
                    setCompleteAppointmentLoader(false);
                    window.location.href = "/";
                  });
              }
            } else {
              setCompleteAppointmentLoader(false);
              window.location.href = "/";
            }
          } catch (error) {
            if (startNextConsultation) {
              if (appointmentsInQueue?.length > 1) {
                history.push({
                  pathname: `/appointment/${appointmentsInQueue?.[1]?.id}`,
                  state: {
                    id: appointmentsInQueue?.[1]?.id,
                    type: appointmentsInQueue?.[1]?.type,
                    user_id: appointmentsInQueue?.[1]?.user_id,
                    visit_count: 0,
                  },
                });
              } else {
                window.location.href = "/";
              }
            } else {
              window.location.href = "/";
            }
          }

        })();
        if (startNextConsultation) {
          if (appointmentsInQueue?.length > 1) {
            history.push({
              pathname: `/appointment/${appointmentsInQueue?.[1]?.id}`,
              state: {
                id: appointmentsInQueue?.[1]?.id,
                type: appointmentsInQueue?.[1]?.type,
                user_id: appointmentsInQueue?.[1]?.user_id,
                visit_count: 0,
              },
            });
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/";
        }

        // setBlockUserExit(false);
        // setCompleteAppointmentLoader(false);
        // window.location.href = "/";
      } else {
        setCompleteAppointmentLoader(false);
        toast.error(data.payload.message);
      }
    });
  };

  const addMed = (values, values2) => {
    values.key = medData.length + 1;
    values2.key = medData.length + 1;
    let found = [];
    medicine?.data?.map((item) => {
      if (item.id === values.medicine) {
        found.push(item.name);
        found.push(item.id);
      }
    });
    values.medName = found[0];
    values2.medName = found[0];
    values.prescription_element_id = found[1];
    values2.prescription_element_id = found[1];

    let temp = [...new Set([...medData, values])];
    setMedData(temp);

    let temp2 = [...new Set([...medTable, values2])];
    setMedTable(temp2);
  };

  const addLab = (values) => {
    values.key = prescribeData.length + 1;
    let found = [];
    labs?.data?.map((item) => {
      if (item.id === values.lab_test) {
        found.push(item.name);
        found.push(item.id);

        item?.category?.map((elem) => {
          if (elem?.id === values?.description) {
            found.push(elem?.name);
          }
        });
      }
    });
    if (found.length) {
      values.lab_name = found[0];
      values.prescription_element_id = found[1];
      values.description = found?.[2];


      let temp = [...new Set([...prescribeData, values])];
      setPrescribeData(temp);
    }
  };

  let medData2 = [];
  medTable?.map((item) => {
    item.medicine = item?.medName;
    medData2.push(item);
  });

  let prescribeData2 = [];
  prescribeData?.map((item) => {
    prescribeData2.push({ key: item.key, lab_test: item.lab_name });
  });

  appForm.setFieldsValue({
    vitalTable: vitalData,
    medicine: medData,
    lab: prescribeData,
  });

  useEffect(() => {
    if (config !== null && count <= 1) {
      setCount(count + 1);
      setStartCall(true);
    }
  }, [config]);

  const renderer = ({ minutes, seconds, completed }) => {
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    if (completed) {
      if (!endAnnounced) {
        setModal3Visible(true);

        setTimeout(() => {
          // completeAppointment();
          setAppointmentCompleted(true);
        }, 5000);

        setendAnnounced(true);
      }
      return <h3 className="fs-20">Time Ended</h3>;
    } else {
      return (
        <h3 className="rem-time">
          {minutes}:{seconds}
        </h3>
      );
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      let remainingTime = document.querySelector(".rem-time");

      if (remainingTime?.innerHTML == "02:00") {
        // Stringified time remaining
        setModal4Visible(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    API.get(`/doctor/pending-appointment`)
      .then((res) => {
        if (res?.data?.code === 200) {
          setPendingAppointments(res?.data?.data);
        }
      })
      .catch((err) => { });
  }, []);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 650);
    });
  }, []);

  async function onCheckboxChange(e) {
    // setIsOnline(e);

    if (e === true) {
      const data = {
        is_instant_consultation: 1,
      };

      const response = await API.post(`/doctor/instant-online-offline`, data);

      if (response?.data?.code === 200) {
        setIsOnline(true);
      } else {
        setIsOnline(false);
      }
    } else {
      let instant = pendingAppointments;


      if (instant?.length > 0) {
        setModal1Visible(true);
        setIsOnline(isOnline);
      }

      const data = {
        is_instant_consultation: 0,
      };

      const response = await API.post(`/doctor/instant-online-offline`, data);

      if (response?.data?.code === 200) {
        setIsOnline(false);
      } else {
        setIsOnline(true);
      }
    }
  }

  useEffect(() => {
    if (medTable.length > 0) {
      setTabSwitchState(1);
    }

    if (writePrescriptionInfo.length > 0) {
      setTabSwitchState(2);
    }

    if (writePrescriptionInfo.length === 0 && medTable.length === 0) {
      setTabSwitchState(0);
    }
  }, [medTable.length, writePrescriptionInfo.length]);

  useEffect(() => {
    if (appointmentCompleted) {
      completeAppointment();
    }
  }, [appointmentCompleted]);

  useEffect(() => {
    if (shouldDispatchMedicine) {
      dispatch(getMedicine());
      setShouldDispatchMedicine(false);
    }
  }, [shouldDispatchMedicine]);

  const forStartNextConsultation = (e) => {
    setStartNextConsultation(e.target.checked);
  };

  const forStartNextConsultation2 = (e) => {
    setStartNextConsultation(e.target.checked);
  };

  // previous consultation
  const columnsprev = [
    {
      title: (
        <div className="consultation_detail">
          <th>Doctor Name</th>
          <th>Reason for visit</th>
          <th>Date</th>
        </div>
      ),
      dataIndex: "drname",
      key: "drname",
    },

    {
      title: "Prescription",
      dataIndex: "prescription",
      key: "prescription",
      render: (text, record) => (
        <ul className="prescription_list">
          <li className="list_header">
            <ul>
              <li>Drug Name</li>
              <li>Medicine/Day</li>
              <li></li>
              <li></li>
              <li></li>
              <li> Duration</li>
              <li>Instructions</li>
            </ul>
          </li>
          <li className="list_pres">
            <ul>
              <li>Tylenol Syrup (100mg)</li>
              <li>
                <p>2 Teaspoon</p>
                <h6>Morning</h6>
              </li>
              <li>
                <p>2 Teaspoon</p>
                <h6>Afternoon</h6>
              </li>
              <li>
                <p>2 Teaspoon</p>
                <h6>Evening</h6>
              </li>
              <li>
                <p>2 Teaspoon</p>
                <h6>Night</h6>
              </li>
              <li>5 days</li>
              <li>Before meal</li>
            </ul>
          </li>
        </ul>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
  ];

  const dataprev = [
    {
      drname: (
        <div className="consultation_detai_listing">
          <p className="dr_name">Dr. Ismail Siddiqui</p>
          <p className="reason_name">Migraine issue</p>
          <p className="reason_name">10/4/2023</p>
        </div>
      ),

      details: (
        <Button className="view_more" onClick={() => setModal2Open(true)}>
          {" "}
          <img src={fileIcon} /> View More
        </Button>
      ),
    },
    {
      drname: (
        <div className="consultation_detai_listing">
          <p className="dr_name">Dr. Ismail Siddiqui</p>
          <p className="reason_name">Migraine issue</p>
          <p className="reason_name">10/4/2023</p>
        </div>
      ),
    },
    {
      drname: (
        <div className="consultation_detai_listing">
          <p className="dr_name">Dr. Ismail Siddiqui</p>
          <p className="reason_name">Migraine issue</p>
          <p className="reason_name">10/4/2023</p>
        </div>
      ),
    },
  ];

  const handleModalData = async (item) => {
    setModal2Open(true);
    setDownloadPastUrl(`${process.env.REACT_APP_BASE_URL}/appointment/download-prescription/${item?.get_prescription?.appointment_id}?is_html=1&is_download=1`)
    try {
      const response = await API.get(
        `/appointment/download-prescription/${item?.get_prescription?.appointment_id}?is_html=0`
      );
      if (response.data?.code === 200) {
        setModal2OpenData(response?.data?.data);
      }
    } catch (e) {
      console.log(e, "e");
    }
  };

  async function downloadCSVHandler() {
    downloadPrescription(id).then((res) => {
      // window.open(res?.data?.pdf_download_link, "_blank");
    });
  }

  const [visibleItems, setVisibleItems] = useState(1);

  const handleViewMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 1);
  };

  const viewAllPrevConsultation = () => {
    setShowAll(true);
  };

  const handleFileView = (file) => {
    setViewSingleReportFile(file?.file);
    setViewSingleReport(true);
  };

  const hanldeConsultationView = (item) => {
    setShowPrevConsultationModal(item);
    setViewSinglePresc(true);
  };

  async function cancelAppointment(e) {
    setBlockUserExit(false);

    try {
      setCancelAppointmentLoader(true);

      const response = await API.get(`/appointment/cancel/${id}`);
      setCancelAppointmentLoader(false);

      if (response?.data?.code === 200) {
        window.location.href = "/";
      }
    } catch (error) {
      setCancelAppointmentLoader(false);
    }
  }

  function handleDiseaseSearch(value) {
    const filteredOptions = conditions.data?.disease?.filter((item) => {
      return item?.name?.toLowerCase()?.startsWith(value?.toLowerCase());
    });

    if (filteredOptions.length === 0) {
      setShowAddDiseaseBtn(true);
    } else {
      setShowAddDiseaseBtn(false);
    }
  }

  async function addCustomDisease(e) {
    if (showAddDiseaseBtn) {
      const data = {
        name: e.target.value,
      };
      try {
        setAddDiseaseLoader(true);
        const response = await API.post("/disease", data);
        if (response?.data?.code === 200) {
          setAddDiseaseLoader(false);
          diseaseRef.current?.blur();
          handleOnChange(e.target.value);
          setShouldDispatchDiseases(true);
          setShowAddDiseaseBtn(false);
        } else {
          setAddDiseaseLoader(false);
        }
      } catch (error) {
        setAddDiseaseLoader(false);
      }
    }
  }

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await API.get('/get-weight-height', {});
        if (response?.status == 200) {
          setHeightValues(response?.data?.data?.height);
          setWeightValues(response?.data?.data?.weight);
        }
      } catch (error) {
        console.log(error)
      }
    })();
  });

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await API.get('/reson-for-visits', {});

        if (response?.status == 200) {
          setReasonList(response?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  });

  const handleReasonChange = (value) => {
    setReasonForVisit(value); // Update the state with selected value
  };


  const validateDate = (newDate, newMonth, newYear) => {
    if (newDate && newMonth && newYear) {
      const selectedDate = moment(`${newYear}-${newMonth}-${newDate}`, "YYYY-MM-DD");
      const currentDate = moment();

      if (selectedDate.isAfter(currentDate)) {
        setDateOfBirthError("You cannot select a future date");
        return false;
      } else {
        setDateOfBirthError(null); // Clear the error if valid
        return true;
      }
    }
    return false;
  };

  const handleDateChange = (value) => {
    setDate(value);
    validateDate(value, month, year);
  };

  const handleMonthChange = (value) => {
    setMonth(value);
    validateDate(date, value, year);
  };

  const handleYearChange = (value) => {
    setYear(value);
    validateDate(date, month, value);
  };

  const parseDateOfBirth = (dob) => {
    console.log('Input to parseDateOfBirth:', dob);

    if (!dob || typeof dob !== 'string') {
      console.log('Invalid date format. Returning nulls.');
      return { year: null, month: null, day: null };
    }

    const [year, month, day] = dob.split('-');
    return {
      year: year || null,
      month: month || null,
      day: day || null,
    };
  };

  const parseDateOfBirthFromAge = (age) => {
    if (!age || typeof age !== 'number') {
      return { year: null, month: null, day: null };
    }

    const today = new Date();
    const birthYear = today.getFullYear() - age;
    const birthMonth = today.getMonth() + 1; // Months are 0-indexed, so add 1
    const birthDay = today.getDate();
    console.log('Parsed Date from Age:', { birthYear, birthMonth, birthDay });
    return {
      year: birthYear.toString(),
      month: birthMonth.toString().padStart(2, '0'),
      day: birthDay.toString().padStart(2, '0'),
    };
  };

  useEffect(() => {
    // Assume apiData is fetched data from the API
    const fetchedData = patient; // Replace with actual API call
    console.log({ fetchedData })
    if (fetchedData) {
      // Parse the date_of_birth
      const { year, month, day } = parseDateOfBirth(fetchedData?.dateOfBirth);

      // Pre-fill the form with fetched data
      form.setFieldsValue({
        name: fetchedData.name,
        gender: fetchedData.gender,
        date: day,
        month: month,
        year: year,
        height: fetchedData.height,
        weight: fetchedData.weight,
        reason_for_visit: fetchedData.reasonForVisit,
      });

      // Set the state variables as well
      setName(fetchedData.name);
      setGender(fetchedData.gender);
      setDate(day);
      setMonth(month);
      setYear(year);
      setHeight(fetchedData.height);
      setWeight(fetchedData.weight);
      setReasonForVisit(fetchedData.reasonForVisit);
    }
  }, [patient, form]);


  return (
    <div className="appointment_first cover_space appointmentVideoCall">
      {(completeAppointmentLoader ||
        cancelAppointmentLoader ||
        addDiseaseLoader ||
        addMedicineLoader ||
        medicineLoading ||
        labLoading ||
        addLabLoader) && <Loader />}
      {/* <Prompt when={setBlockUserExit}
              message={(location, action)=> {
                console.log(action, 'action');
                return "The following action will end the appointment. Are you sure you want to end the call?"
              }}></Prompt> */}
      {/* <NavigationPrompt when={setBlockUserExit}>
      {({ onConfirm, onCancel }) => (
    <ConfirmNavigationModal
      when={true}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  )}
      </NavigationPrompt> */}
      <RouterPrompt
        when={blockUserExit}
        title="Mark as Complete?"
        cancelText=""
        okText="Ok"
        okFunction={completeAppointment}
        onOK={() => {
          return false;
        }}
        onCancel={() => false}
      />

      {loader && (
        <Form form={appForm} layout="vertical" onFinish={completeApp}>
          <Container fluid className="mt-5">
            {patient?.type !== "in-person" ? (
              <Row>
                {!viewSingleReport || viewSinglePresc ? (
                  <>
                    <Col md={4} className="d-block d-sm-none">
                      <div className="mt-6 box_mobile_call">
                        <Row>
                          <Col xs={7}>
                            <div className="pat_info_mob">
                              <HeadingDescVsmall text={patient?.name} />
                              <HeadingDescVsmall
                                text={
                                  patient?.age === null || patient?.age === ""
                                    ? "-"
                                    : patient?.age + " years"
                                }
                              />

                              <HeadingDescVsmall
                                text={
                                  patient?.gender === null
                                    ? "-"
                                    : patient?.gender
                                }
                              />
                            </div>
                            <div
                              className="instant_consult mb-2"
                              style={{ display: "flex" }}
                            >
                              <HeadingDescSmall text="DoctorNow" />
                              <Switch
                                className="ms-2 is-online-switch"
                                checked={isOnline}
                                onChange={onCheckboxChange}
                              />
                            </div>
                          </Col>
                          <Col xs={5}>
                            <div className=" ">
                              <div className="  heading-captalize mobile_time01">
                                <Countdown date={timeRem} renderer={renderer} />
                                <HeadingWithSpaceLarge text="  Remaining" />
                              </div>
                              <div className="heading-captalize  mobile_time01 pt-mob">
                                <HeadingWithSpaceLarge text="Patient In Queue" />
                                <h3>01</h3>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </>
                ) : null}

                <Col
                  md={8}
                  id="mobile_vid"
                  className={scroll ? "fixedSameVideoTop" : ""}
                >
                  {startCall && (
                    <div
                      className={`video_div position-relative ${isMobile && "noPadding"
                        } ${(viewSingleReport === true && "currentViewState") ||
                        (viewSinglePresc === true && "currentViewState")
                        }`}
                    >
                      {/* <img src={patient_img} alt="patient_img" className="img_vid" /> */}
                      <Agoraa
                        doctorName={AppointmentData?.data?.doctor?.name}
                        setShowCancelModal={setShowCancelModal}
                      />
                      {/* doctor Name for small frame */}
                      <p className="topNameDoctorNow">
                        {AppointmentData?.data?.doctor?.name}
                      </p>
                      {/* doctor Name for small frame */}
                      <div className="fixedScreenHeading withoutScrollFixed">
                        <HeadingDescVsmall text={patient?.name} />
                        <div className="onlyForFixedRemainingTime">
                          <Countdown date={timeRem} renderer={renderer} />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div
                    className={
                      mobile
                        ? "d-none"
                        : "white_color_div patient-info-set hk_white_video_right_area"
                    }
                  >
                    <div
                      className="instant_consult mb-3"
                      style={{ display: "flex" }}
                    >
                      <HeadingDescSmall text="DoctorNow" />
                      <Switch
                        className="ms-2 is-online-switch"
                        checked={isOnline}
                        onChange={onCheckboxChange}
                      />
                    </div>

                    <HeadingWithSpaceLarge text="PATIENT INFORMATION" />
                    <div className="column_flex">
                      <HeadingDescVsmall text="Patient Name:" />
                      <HeadingDescVsmall text={patient?.name ? patient?.name : ' MS - Patient'} />
                    </div>

                    <div className="column_flex">
                      <HeadingDescVsmall text="Age" />
                      <HeadingDescVsmall
                        text={
                          patient?.age === null || patient?.age === ""
                            ? "-"
                            : patient?.age + " years"
                        }
                      />
                    </div>
                    <div
                      className="column_flex"
                      style={{ textTransform: "capitalize" }}
                    >
                      <HeadingDescVsmall text="Gender" />
                      <HeadingDescVsmall
                        text={patient?.gender === null ? "-" : patient?.gender}
                      />
                    </div>

                    <div
                      className="column_flex"
                      style={{ textTransform: "capitalize" }}
                    >
                      <HeadingDescVsmall text="City" />
                      <HeadingDescVsmall
                        text={
                          !userCity || typeof userCity === "undefined"
                            ? "-"
                            : userCity
                        }
                      />
                    </div>
                    {parseInt(visit_count) > 0 ? (
                      <div className="patient_status follow_up">
                        <HeadingDescVsmall text="Follow Up Appointment" />
                      </div>
                    ) : (
                      <div className="patient_status first_time">
                        <HeadingDescVsmall text="First Time Appointment" />
                      </div>
                    )}
                    <div className="column_flex patient_reason_visit">
                      <HeadingDescVsmall text="Reason for Visit" />
                      <HeadingDescVsmall
                        text={
                          AppointmentData?.data?.reason_for_visit
                            ? AppointmentData?.data?.reason_for_visit
                            : "No reason given"
                        }
                      />

                      <HeadingDescVsmall
                        text={
                          AppointmentData?.data?.additional_detail
                            ? AppointmentData?.data?.additional_detail
                            : ""
                        }
                      />
                    </div>

                    {/* {AppointmentData?.data?.reason_for_visit || !AppointmentData?.data?.reason_for_visit && (
                      <div className="column_flex patient_reason_visit">
                        <HeadingDescVsmall text="Reason for Visit" />
                        <HeadingDescVsmall
                          text={AppointmentData?.data?.reason_for_visit ? AppointmentData?.data?.reason_for_visit : 'No reason given'}
                        />
                        <HeadingDescVsmall
                          text={AppointmentData?.data?.additional_detail ? AppointmentData?.data?.additional_detail : ''}
                        />
                      </div>
                    )} */}

                    <hr className="break-lines d-none d-lg-block mt-0 mb-0" />
                    <div className="flex_center boxTimeQue">
                      <div className="column_flex heading-captalize">
                        <HeadingWithSpaceLarge text="Time Remaining" />
                        <Countdown date={timeRem} renderer={renderer} />
                      </div>
                      <div className="column_flex heading-captalize">
                        <HeadingWithSpaceLarge text="Patient Queue" />
                        <h3>{patientQueueCount?.patient_count}</h3>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={24}>
                  <div className="past_consultation">
                    <div className={mobile ? "d-none" : "white_color_div"}>
                      <HeadingWithSpaceLarge text="PATIENT INFORMATION" />
                      <div className="flex_center boxTimeQue">
                        <div className="column_flex">
                          <HeadingDescVsmall text="Patient Name" />
                          <HeadingDescVsmall text={patient?.name} />
                        </div>
                        <div className="column_flex">
                          <HeadingDescVsmall text="Age" />
                          <HeadingDescVsmall
                            text={
                              patient?.age === null || patient?.age === ""
                                ? "-"
                                : patient?.age + " years"
                            }
                          />
                        </div>
                        <div className="column_flex">
                          <HeadingDescVsmall text="Gender" />
                          <HeadingDescVsmall
                            text={
                              patient?.gender === null ? "-" : patient?.gender
                            }
                          />
                        </div>
                        <div
                          className="column_flex"
                          style={{ textTransform: "capitalize" }}
                        >
                          <HeadingDescVsmall text="City" />
                          <HeadingDescVsmall
                            text={
                              !userCity || typeof userCity === "undefined"
                                ? "-"
                                : userCity
                            }
                          />
                        </div>
                        {patient?.reason && (
                          <div className="column_flex">
                            <HeadingDescVsmall text="Reason for Visiting" />
                            <HeadingDescVsmall text={patient?.reason} />
                          </div>
                        )}
                        {parseInt(visit_count) > 0 ? (
                          <div className="patient_status follow_up">
                            <HeadingDescVsmall text="Follow Up Appointment" />
                          </div>
                        ) : (
                          <div className="patient_status first_time">
                            <HeadingDescVsmall text="First Time Appointment" />
                          </div>
                        )}
                        <div className="column_flex">
                          <HeadingDescVsmall text="Appointment Remaining Time" />
                          <Countdown
                            className="time-rem"
                            date={timeRem}
                            renderer={renderer}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
          {!isMobile && (
            <>
              <div
                className={
                  scroll
                    ? "stickyonScroll also container-fluid mt-0 fixedVideoOnScroll text-left"
                    : "container-fluid mt-5 whenNotFixedVideo"
                }
              >
                <ul className="records-inline  ps-0 my-2">
                  <li
                    onClick={(e) => activeListHandler(e, "#patientInfo")}
                    className="rec"
                  >
                    Patient Info
                  </li>
                  <li
                    onClick={(e) => activeListHandler(e, "#vitals")}
                    className="rec"
                  >
                    Vitals
                  </li>
                  <li
                    onClick={(e) => activeListHandler(e, "#medical-records")}
                    className="rec"
                  >
                    Medical Records
                  </li>
                  <li
                    onClick={(e) => activeListHandler(e, "#medical-history")}
                    className="rec"
                  >
                    Medical History
                  </li>

                  <li
                    onClick={(e) => activeListHandler(e, "#prescription")}
                    className="rec"
                  >
                    Prescription
                  </li>
                  <li
                    onClick={(e) => activeListHandler(e, "#prescribe_lab")}
                    className="rec"
                  >
                    Lab Tests
                  </li>
                </ul>

                {/* <Container className="containerHideScreen">
              {patient?.type !== "in-person" ? (
                <Row>
                  <Col md={2}>
                    {startCall && (
                      <div className="video_div">
                        <Agora />
                        <div
                          className={mobile ? "d-none" : "fixedScreenHeading"}
                        >
                          <HeadingDescVsmall text={patient?.name} />
                          <Countdown date={timeRem} renderer={renderer} />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={2}>
                    <div className="past_consultation">
                      <div className={mobile ? "d-none" : "white_color_div"}>
                        <div className="flex_center">
                          <div className="column_flex">
                            <HeadingDescVsmall text="Patient Name" />
                            <HeadingDescVsmall text={patient?.name} />
                          </div>
                          <div className="column_flex">
                            <HeadingDescVsmall text="Appointment Remaining Time" />
                            <Countdown
                              className="time-rem"
                              date={timeRem}
                              renderer={renderer}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Container> */}
              </div>
            </>
          )}

          <div
            className={
              mobile
                ? location?.search?.split("?")[1] === "#patientInfo"
                  ? "tab_data cover_space3"
                  : "d-none"
                : "tab_data cover_space3"
            }
            id="patientInfo"
          >
            <Container fluid>
              <div className="white_color_div">
                <div className={`appointment_div_data`}
                >
                  <Form form={form} layout="vertical">
                    <Row>
                      <Col md={4}>
                        <Form.Item name="name" label="Name" rules={[{ required: true },
                        { pattern: /^[A-Za-z\s]+$/, message: 'Name must contain only alphabets' },]}>
                          <Input
                            placeholder="Enter your Name"
                            value={name}
                            maxLength={30}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={4}>
                        <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
                          <Select
                            placeholder="Select a gender"
                            allowClear
                            value={gender}
                            onChange={(value) => setGender(value)}
                          >
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={4} className="birth_date_area">
                        <Form.Item
                          name="date"
                          label="Date"
                          validateStatus={dateOfBirthError ? "error" : ""}
                          help={dateOfBirthError && <span style={{ whiteSpace: 'nowrap' }} className="error-message">{dateOfBirthError}</span>}
                          rules={[{ required: true }]}>
                          <Select
                            placeholder="Date"
                            allowClear
                            value={date}
                            onChange={handleDateChange}
                          >
                            {days.map((day) => (
                              <Option key={day} value={day}>
                                {day}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="month"
                          label="Month"
                          // validateStatus={dateOfBirthError ? "error" : ""}
                          // help={dateOfBirthError}
                          rules={[{ required: true }]}>
                          <Select
                            placeholder="Month"
                            allowClear
                            value={month}
                            onChange={handleMonthChange}
                          >
                            {months.map((month, index) => (
                              <Option key={index} value={month.value}>
                                {month.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          // validateStatus={dateOfBirthError ? "error" : ""}
                          //   help={dateOfBirthError} 
                          name="year"
                          label="Year"
                          rules={[{ required: true }]}>
                          <Select
                            placeholder="Year"
                            allowClear
                            onChange={handleYearChange}
                            value={year}
                          >
                            {years.map((year) => (
                              <Option key={year} value={year}>
                                {year}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={4} className="height_weight">
                        <Form.Item name="height" label="Height">
                          <Select
                            placeholder="Select Height"
                            allowClear
                            value={height}
                            onChange={(value) => setHeight(value)}
                          >
                            {heightValues?.length > 0 && heightValues.map((item) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item name="weight" label="Weight">
                          <Select
                            placeholder="Select Weight"
                            allowClear
                            value={weight}
                            onChange={(value) => setWeight(value)}
                          >
                            {weightValues?.length > 0 && weightValues.map((item) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={4}>
                        <Form.Item
                          name="reason_for_visit"
                          label="Reason for Visit"
                          rules={[{ required: true }]}
                        >
                          <Select
                            placeholder="Select Reason for Visit"
                            allowClear
                            value={reasonForVisit}
                            onChange={handleReasonChange}
                          >
                            {reasonList?.length > 0 && reasonList.map((item) => (
                              <Option key={item?.id} value={item?.id}>
                                {item?.reason}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>

                </div>
              </div>
            </Container>
          </div>
          <div
            className={
              mobile
                ? location?.search?.split("?")[1] === "#vitals"
                  ? "tab_data cover_space3"
                  : "d-none"
                : "tab_data cover_space3"
            }
            id="vitals"
          >
            <Container fluid>
              {mobile ? (
                <div className="column_flex justify_between">
                  <div className="white_color_div">
                    <div className="flex_end justify_between">
                      <div className="column_flex gap_div">
                        <div className="column_flex">
                          <HeadingDescSmall text="Heart Rate" />
                          <HeadingDescSmall text="20 BPM" />
                        </div>
                        <div className="column_flex">
                          <HeadingDescSmall text="Heart Rate" />
                          <HeadingDescSmall text="20 BPM" />
                        </div>
                      </div>
                      <div className="column_flex gap_div">
                        <div className="column_flex">
                          <HeadingDescSmall text="Breathing Rate" />
                          <HeadingDescSmall text="20 BPM" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white_color_div">
                    <div className="flex_end justify_between">
                      <div className="column_flex gap_div">
                        <div className="column_flex">
                          <HeadingDescSmall text="Heart Rate" />
                          <HeadingDescSmall text="20 BPM" />
                        </div>
                        <div className="column_flex">
                          <HeadingDescSmall text="Heart Rate" />
                          <HeadingDescSmall text="20 BPM" />
                        </div>
                      </div>
                      <div className="column_flex gap_div">
                        <div className="column_flex">
                          <HeadingDescSmall text="Breathing Rate" />
                          <HeadingDescSmall text="20 BPM" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="white_color_div">
                  {vitalData.length > 0 ? (
                    <div className="gap_div">
                      {/* <HeadingWithSpaceLarge text="VITALS" /> */}
                    </div>
                  ) : null}
                  <div
                    className={`appointment_div_data ${vitalData.length > 0 ? "vitaldataHave" : "noVitalsCurrently "
                      }`}
                  >
                    <Form.Item name="vitalTable" className="vital_table">
                      <Form.Item>
                        <TableComponent
                          locale={vitalTableLocale}
                          header={vitalData?.length > 0 ? vitalHeader : null}
                          data={vitalData}
                          pagination={false}
                        />
                      </Form.Item>
                    </Form.Item>
                  </div>
                </div>
              )}
            </Container>
          </div>
          {/* previous box start */}
          {!isMobile && !patient.previous_consultation?.length <= 0 && (
            <>
              <Container fluid id="previous consultation">
                <Row>
                  <Col md={12} lg={12} className="prev_consultation">
                    <div className="tab_data cover_space3">
                      <div className="white_color_div">
                        <div className="gap_div">
                          <HeadingWithSpaceLarge text="PREVIOUS CONSULTATION" />
                        </div>
                        {/* <div className="table_pt">
                      <TablePreviousHistory columns={columnsprev}
                        data={dataprev}
                        pagination={false} />
                    </div> */}
                        <div className="table_pt position-relative">
                          <div className="d-flex align-items-center like_row_bg">
                            <div className="hk_align-by-align">
                              <h4>Doctor Name</h4>
                            </div>
                            <div className="hk_align-by-align">
                              <h4>Reason for visit</h4>
                            </div>
                            <div className="hk_align-by-align">
                              <h4>Date</h4>
                            </div>
                            <div className="hk_align-by-align alignForRight">
                              <h4>Details</h4>
                            </div>
                          </div>
                          {!isMobile && patient?.previous_consultation?.length > 0 ? (
                            <>
                              <Row>
                                <div className="acc_button_area table_previous">
                                  <TabItem.Container
                                    id="left-tabs-example"
                                    defaultActiveKey={activeTab}
                                  >
                                    <Row>
                                      <Col sm={4}>
                                        {patient.previous_consultation?.length >
                                          0 &&
                                          patient.previous_consultation?.map(
                                            (item) => {
                                              return (
                                                <>
                                                  <Nav
                                                    variant="pills"
                                                    className="flex-column hk_bg_cover"
                                                  >
                                                    <Nav.Item>
                                                      <Nav.Link eventKey={item?.id}>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                          <div className="f-items_hk">
                                                            <h6 className="diff_color">
                                                              Dr.{" "}
                                                              {item?.doctor?.name}
                                                            </h6>
                                                          </div>
                                                          <div className="f-items_hk">
                                                            <h6>
                                                              {
                                                                item?.reason_for_visit
                                                              }
                                                            </h6>
                                                          </div>
                                                          <div className="f-items_hk">
                                                            <h6>{moment(item?.date).format('DD/MM/YYYY')}</h6>
                                                          </div>
                                                        </div>
                                                      </Nav.Link>
                                                    </Nav.Item>
                                                  </Nav>
                                                </>
                                              );
                                            }
                                          )}
                                      </Col>
                                      <Col sm={8}>
                                        <TabItem.Content>

                                          {patient?.previous_consultation?.length >
                                            0 &&
                                            patient?.previous_consultation?.map(
                                              (item) => {
                                                return (
                                                  <>
                                                    <TabItem.Pane
                                                      defaultActiveKey={
                                                        patient
                                                          .previous_consultation[0]
                                                          ?.id
                                                      }
                                                      eventKey={item?.id}
                                                    >
                                                      <div className="hk_align-by-align in_tabs">
                                                        <h4>
                                                          Prescription{" "}
                                                          {/* <span className="dr_name_header_date">
                                                        (Dr.{" "}
                                                        {item?.doctor?.name} |{" "}
                                                        {item?.appointment_date}
                                                      </span> */}
                                                        </h4>
                                                      </div>
                                                      {item?.get_prescription
                                                        ?.prescribed_medicine
                                                        ?.length > 0 ? (
                                                        <>
                                                          <table className="in_table_view">
                                                            <thead>
                                                              <tr>
                                                                <th>Drug Name</th>
                                                                <th>
                                                                  Medicine/Day
                                                                </th>
                                                                <th>Duration</th>
                                                                <th>
                                                                  Instructions
                                                                </th>
                                                              </tr>
                                                            </thead>

                                                            <tbody>
                                                              {item?.get_prescription?.prescribed_medicine?.length > 0 &&
                                                                item?.get_prescription?.prescribed_medicine?.map(
                                                                  (
                                                                    itemMed,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <>
                                                                        <tr>
                                                                          <td>
                                                                            {
                                                                              itemMed
                                                                                ?.prescription_element
                                                                                ?.name
                                                                            }{" "}
                                                                          </td>
                                                                          <td>
                                                                            <div className="d-flex align-items-center med_schedule_hk">
                                                                              <div className="hanlde_wrapper_schedule">
                                                                                {
                                                                                  itemMed?.morning
                                                                                }{" "}
                                                                                {
                                                                                  itemMed?.unit
                                                                                }{" "}
                                                                                <span>
                                                                                  Morning
                                                                                </span>
                                                                              </div>
                                                                              <div className="hanlde_wrapper_schedule">
                                                                                {
                                                                                  itemMed?.afternoon
                                                                                }{" "}
                                                                                {
                                                                                  itemMed?.unit
                                                                                }{" "}
                                                                                <span>
                                                                                  Afternoon
                                                                                </span>
                                                                              </div>
                                                                              <div className="hanlde_wrapper_schedule">
                                                                                {
                                                                                  itemMed?.evening
                                                                                }{" "}
                                                                                {
                                                                                  itemMed?.unit
                                                                                }{" "}
                                                                                <span>
                                                                                  Evening
                                                                                </span>
                                                                              </div>
                                                                              <div className="hanlde_wrapper_schedule">
                                                                                {
                                                                                  itemMed?.night
                                                                                }{" "}
                                                                                {
                                                                                  itemMed?.unit
                                                                                }{" "}
                                                                                <span>
                                                                                  Night
                                                                                </span>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              itemMed?.number_of_days
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {itemMed?.is_after_meal
                                                                              ? "After meal"
                                                                              : "Before meal"}
                                                                          </td>
                                                                          <td>
                                                                            {index ===
                                                                              0 && (
                                                                                <div class="flex_end pt-0 pb-2">
                                                                                  <div>
                                                                                    <button
                                                                                      type="button"
                                                                                      className="simple_btn_small"
                                                                                      onClick={(
                                                                                        e
                                                                                      ) =>
                                                                                        handleModalData(
                                                                                          item
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        src={
                                                                                          fileIcon
                                                                                        }
                                                                                      />{" "}
                                                                                      View
                                                                                      More
                                                                                    </button>
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                          </td>
                                                                        </tr>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                            </tbody>
                                                          </table>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {isJsonString(item?.prescription_here) && JSON.parse(item?.prescription_here)?.length > 0 &&
                                                            isJsonString(
                                                              item?.prescription_here
                                                            ) &&
                                                            JSON.parse(
                                                              item?.prescription_here
                                                            ) ? (
                                                            <>
                                                              <table className="in_written">
                                                                <thead>
                                                                  <tr>
                                                                    <th>
                                                                      Prescription
                                                                    </th>
                                                                    <th>
                                                                      View full
                                                                      prescription
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      {item?.prescription_here &&
                                                                        isJsonString(
                                                                          item?.prescription_here
                                                                        ) && (
                                                                          <p>
                                                                            {JSON.parse(
                                                                              item?.prescription_here
                                                                            )}
                                                                          </p>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                      {/* {index ===
                                                                              0 && ( */}
                                                                      <div class="flex_end">
                                                                        <div>
                                                                          <button
                                                                            type="button"
                                                                            className="simple_btn_small"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              handleModalData(
                                                                                item
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={
                                                                                fileIcon
                                                                              }
                                                                            />{" "}
                                                                            View
                                                                            More
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                      {/* )} */}
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <p className="no_pres_prev_cons">
                                                                No Prescription
                                                                Here
                                                              </p>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </TabItem.Pane>
                                                  </>
                                                );
                                              }
                                            )}
                                        </TabItem.Content>
                                      </Col>
                                    </Row>
                                  </TabItem.Container>
                                </div>
                              </Row>
                            </>
                          ) : !isMobile && patient.previous_consultation.length === 0(
                            <>
                              <Row>
                                <p className="text-center mt-5"> No medicine prescribed</p>
                              </Row>
                            </>
                          )}

                        </div>

                        <div className="modal_appointment">
                          <Modal
                            className="instantModalpopup prescripModalSet"
                            title=""
                            centered
                            visible={modal2Open}
                            onOk={() => setModal2Open(false)}
                            onCancel={() => setModal2Open(false)}
                          >
                            {(downloadLoader || !downloadPastUrl) && (
                              <Loader />
                            )}
                            <div className="modal-to-print">
                              <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
                                <h4 className="prescriptionHeading">
                                  Prescription
                                </h4>
                                <p
                                  style={{ cursor: "pointer" }}
                                  className="text-uppercase d-flex donwloadKey align-items-center"
                                  onClick={(e) => downloadPastPrescription(e, downloadPastUrl)}
                                >
                                  <img
                                    src={arrowDownload}
                                    alt="download"
                                    className="img-fluid me-2"
                                  />
                                  Download
                                </p>
                              </div>

                              {csvLoading ? (
                                <div className="doctor-search-loader"></div>
                              ) : (
                                <div className="border rounded p-2 mb-2 pdf-body hk_bt_remove px-3 py-4">
                                  <div className="d-md-none d-flex justify-content-end">
                                    <img
                                      src={logo}
                                      alt="logo"
                                      className="img-fluid logo_small"
                                      style={{ height: "25px" }}
                                    />
                                  </div>
                                  <div className="d-md-flex justify-content-between">
                                    <div className="order-2 order-md-1 position-relative">
                                      {modal2OpenData?.doctor?.is_featured && (
                                        <img
                                          src={FeaturedTick}
                                          alt="Featured"
                                          className="img-fluid realCheck"
                                        />
                                      )}

                                      <div className="d-flex align-items-center">
                                        <div className="doctorImg">
                                          {modal2OpenData?.doctor?.image && (
                                            <img
                                              className="img-fluid"
                                              src={
                                                modal2OpenData?.doctor?.image
                                              }
                                              alt="profile"
                                            />
                                          )}
                                        </div>
                                        <div className="doctorInstantInfo text-left ms-3">
                                          <h5 className="fw-600 instant-doc-name">
                                            {modal2OpenData?.doctor?.prefix}.{" "}
                                            {modal2OpenData?.doctor?.name}
                                          </h5>
                                          <p className="instant-doc-specialities">
                                            {modal2OpenData?.doctor?.doctor_specialities
                                              ?.toString()
                                              ?.replace(/,/g, ", ")}
                                          </p>
                                          <p>
                                            {modal2OpenData?.doctor?.doctor_educations
                                              ?.toString()
                                              ?.replace(/,/g, ", ")}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <img
                                      src={logo}
                                      alt="logo"
                                      className="img-fluid order-1 order-md-2 logo_small d-none d-md-block"
                                    />
                                  </div>
                                  <div className="d-md-flex mt-3 justify-content-between">
                                    <div className="d-flex ms-0 justify-content-between">
                                      <div className="me-3">
                                        <p>
                                          <strong>Patient:</strong>{" "}
                                          {modal2OpenData?.patient_info?.name ||
                                            "Patient"}
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <strong>Patient Number: </strong>
                                          {modal2OpenData?.patient_info?.id}
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      <p>
                                        <strong>Prescribed on:</strong>
                                        {moment(
                                          modal2OpenData?.formatted_date_time
                                        )
                                          .format("DD/MM/YYYY")
                                          .slice(0, 10)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="for_bor_only_instant__f border rounded table-responsive">
                                {modal2OpenData?.prescription
                                  ?.prescribed_medicine?.length > 0 ? (
                                  <Table
                                    striped
                                    className="table instant-feedback-table"
                                  >
                                    <>
                                      <thead>
                                        <tr>
                                          <th className="text-left secondHeadWords">
                                            Drug Name
                                          </th>
                                          <th>&nbsp;</th>
                                          <th className="secondHeadWords">
                                            Medicine/Day
                                          </th>
                                          <th>&nbsp;</th>
                                          <th>&nbsp;</th>
                                          <th className="text-center secondHeadWords">
                                            Duration
                                          </th>
                                          <th className="text-center secondHeadWords">
                                            Instructions
                                          </th>
                                        </tr>
                                        <tr>
                                          <th className="text-left">&nbsp;</th>
                                          <th className="text-left">Morning</th>
                                          <th className="text-left">
                                            Afternoon
                                          </th>
                                          <th className="text-left">Evening</th>
                                          <th className="text-left">Night</th>
                                          <th className="text-center">
                                            &nbsp;
                                          </th>
                                          <th className="text-center">
                                            &nbsp;
                                          </th>

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {modal2OpenData?.prescription?.prescribed_medicine?.map(
                                          (item) => {
                                            return (
                                              <tr>
                                                <td className="text-left">
                                                  {
                                                    item?.prescription_element
                                                      ?.name
                                                  }
                                                </td>
                                                {item?.morning ? (
                                                  <>
                                                    <td className="text-center fs-13 bg-change-tdd">
                                                      {item?.morning}{" "}
                                                      {item?.unit}
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="text-center"></td>
                                                  </>
                                                )}

                                                {item?.afternoon ? (
                                                  <>
                                                    <td className="text-center fs-13 bg-change-tdd">
                                                      {item?.afternoon}{" "}
                                                      {item?.unit}
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="text-center"></td>
                                                  </>
                                                )}

                                                {item?.evening ? (
                                                  <>
                                                    <td className="text-center fs-13 bg-change-tdd">
                                                      {item?.evening}{" "}
                                                      {item?.unit}
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="text-center"></td>
                                                  </>
                                                )}

                                                {item?.night ? (
                                                  <>
                                                    <td className="text-center fs-13 bg-change-tdd">
                                                      {item?.night} {item?.unit}
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="text-center"></td>
                                                  </>
                                                )}

                                                <td className="text-center fs-13">
                                                  {item?.number_of_days}
                                                </td>
                                                <td>
                                                  {item?.is_after_meal ===
                                                    true ? (
                                                    <>
                                                      <p className="withMeal">
                                                        After meal
                                                      </p>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <p className="withMeal">
                                                        Before meal
                                                      </p>
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </>
                                  </Table>
                                ) : modal2OpenData?.prescription_here ? (
                                  <Table
                                    striped
                                    className="table prescriptionHere instant-feedback-table "
                                  >
                                    <>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              color: "#078A8E",
                                              fontSize: "15px",
                                              marginLeft: "20px",
                                            }}
                                            className="text-left"
                                          >
                                            Prescription
                                          </th>
                                          <th>&nbsp;</th>
                                          <th></th>
                                          <th>&nbsp;</th>
                                          <th>&nbsp;</th>
                                          <th className="text-center"></th>
                                          <th className="text-center"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <p
                                          style={{ whiteSpace: "pre-line" }}
                                          disabled
                                          className="prescription-textBox"
                                        >
                                          {modal2OpenData?.prescription_here}
                                        </p>
                                      </tbody>
                                    </>
                                  </Table>
                                ) : null}

                                {modal2OpenData?.prescription.prescribed_lab
                                  ?.length > 0 && (
                                    <Table
                                      striped
                                      className="table instant-feedback-table lab-test-table border_bottom_rem"
                                    >
                                      <>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                color: "#078A8E",
                                                fontSize: "15px",
                                              }}
                                              className="text-left"
                                              colSpan={2}
                                            >
                                              Lab Test
                                            </th>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                            <th className="text-center">
                                              &nbsp;
                                            </th>
                                            <th className="text-center">
                                              &nbsp;
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {modal2OpenData?.prescription.prescribed_lab?.map(
                                            (test) => {
                                              return (
                                                <tr>
                                                  <td className="med-test-type text-left">
                                                    {" "}
                                                    {test?.description}
                                                  </td>
                                                  <td
                                                    className="text-left labTestName"
                                                    colSpan={2}
                                                  >
                                                    {
                                                      test?.prescription_element
                                                        ?.name
                                                    }
                                                  </td>
                                                  <td>&nbsp;</td>
                                                  <td>&nbsp;</td>
                                                  <td>&nbsp;</td>
                                                  <td>&nbsp;</td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </>
                                    </Table>
                                  )}
                              </div>
                              {/* <div className='sharedReportBox'>
                            <h4 className="sharedReports text-left pb-3">Shared Reports</h4>
                            <div className="bg-gray rounded">
                              <div className="d-flex align-items-center justify-content-between border-bottom anchorInstantModal p-3">
                                <a href="#"  >
                                  <FontAwesomeIcon icon={'fa fa-file'} className="me-2 " />
                                  https://ms-image...t1r2uItsJ3U1Z2adsElJFkQNa3U.png
                                </a>
                                <a href="#"
                                  className="removeInstantLink">
                                  Remove
                                </a>
                              </div>
                            </div>
                          </div> */}
                            </div>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          )}

          {/* previous box end */}

          {/* {mobile && <StickyTab menuList={menuList} type="a" />} */}
          {!isMobile && (
            <>
              <Container fluid id="medical-history">
                <Row>
                  <div
                    className={
                      mobile
                        ? location?.search?.split("?")[1] === "#medical_record"
                          ? "tab_data cover_space3"
                          : "d-none"
                        : "tab_data cover_space3"
                    }
                    id="medical-records"
                  >
                    {mobile ? (
                      <div className="column_flex justify_between">
                        <div className="white_color_div">
                          <div className="flex_end justify_between">
                            <div className="column_flex gap_div">
                              <div className="column_flex">
                                <HeadingDescSmall text="Tests Taken on" />
                                <HeadingDescSmall text="Mar 01, 2022" />
                              </div>
                              <div className="column_flex">
                                <HeadingDescSmall text="Total Reports" />
                                <HeadingDescSmall text="3 Reports, 1 Prescription" />
                              </div>
                            </div>
                            <div className="flex_end gap_div">
                              <a className="consult_now">
                                <FiArrowRightCircle className="arrow_black" />
                                <p className="black_text">VIEW</p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="white_color_div">
                          <div className="flex_end justify_between">
                            <div className="column_flex gap_div">
                              <div className="column_flex">
                                <HeadingDescSmall text="Tests Taken on" />
                                <HeadingDescSmall text="Mar 01, 2022" />
                              </div>
                              <div className="column_flex">
                                <HeadingDescSmall text="Total Reports" />
                                <HeadingDescSmall text="3 Reports, 1 Prescription" />
                              </div>
                            </div>
                            <div className="flex_end gap_div">
                              <a className="consult_now">
                                <FiArrowRightCircle className="arrow_black" />
                                <p className="black_text">VIEW</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="white_color_div pb-0">
                        <div className="gap_div">
                          <HeadingWithSpaceLarge text="MEDICAL RECORDS" />
                        </div>
                        <div className="appointment_div_data medicalRec text-center medicalRecordsTableCall">
                          {medical_record_table.length === 0 ? (
                            <HeadingDesc text="No Medical Records Shared"></HeadingDesc>
                          ) : (
                            <Form.Item name="medicalRecord">
                              <Form.Item>
                                <TableComponent
                                  header={medRecordHeader}
                                  data={medical_record_table}
                                  pagination={false}
                                />
                              </Form.Item>
                            </Form.Item>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      mobile
                        ? location?.search?.split("?")[1] ===
                          "#medical_history" ||
                          typeof location?.search?.split("?")[1] === "undefined"
                          ? "tab_data cover_space3"
                          : "d-none"
                        : "tab_data cover_space3"
                    }
                  >
                    <div className="white_color_div">
                      <div className="gap_div">
                        {!mobile && (
                          <HeadingWithSpaceLarge text="MEDICAL HISTORY" />
                        )}
                        <div
                          className="appointment_div_data"
                          style={{ flexDirection: "column" }}
                        >
                          <Row>
                            <Col md={4}>
                              <div className="column_flex">
                                <div className="column_flex2">
                                  <Form.Item
                                    name="blood_group"
                                    label="Blood Group"
                                  >
                                    {/* <p className="labelText">Blood Group</p> */}
                                    <Select
                                      defaultValue={
                                        patient?.prescription?.blood_group
                                      }
                                      dropdownAlign={{ offset: [0, 4] }}
                                      placeholder="Select blood group"
                                      suffixIcon={
                                        // <HiOutlineArrowDown color="#29BCC1" />
                                        <img src={arrowdropdown} alt />
                                      }
                                      className="c_select"
                                    >
                                      {/* <Option selected >{patient?.prescription?.blood_group}</Option> */}
                                      <Option value="A+">A+</Option>
                                      <Option value="A-">A-</Option>
                                      <Option value="B+">B+</Option>
                                      <Option value="B-">B-</Option>
                                      <Option value="O+">O+</Option>
                                      <Option value="O-">O-</Option>
                                      <Option value="AB+">AB+</Option>
                                      <Option value="AB-">AB-</Option>
                                    </Select>
                                  </Form.Item>
                                </div>

                                <div className="column_flex2">
                                  <Form.Item
                                    name="condition"
                                    label="Add Existing Condition"
                                  >
                                    {/* <p className="labelText">Add Existing Condition</p> */}
                                    <Select
                                      ref={diseaseRef}
                                      dropdownAlign={{ offset: [0, 4] }}
                                      suffixIcon={
                                        showAddDiseaseBtn ? (
                                          <button className="add-custom-med">
                                            Add
                                          </button>
                                        ) : (
                                          <img src={arrowdropdown} alt />
                                        )
                                      }
                                      className="c_select"
                                      onSelect={(value, event) => {
                                        handleOnChange(value, event);
                                      }}
                                      showSearch
                                      placeholder="Select condition"
                                      onSearch={handleDiseaseSearch}
                                      onClick={(e) => addCustomDisease(e)}
                                      filterOption={(input, option) => {
                                        return option?.children
                                          ?.toLowerCase()
                                          ?.startsWith(input?.toLowerCase());
                                      }}
                                    >
                                      {conditions?.data?.disease?.map(
                                        (disease, index) => (
                                          <Option
                                            value={disease.name}
                                            key={index}
                                          >
                                            {disease.name}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </div>
                              </div>
                              {mobile && (
                                <div className="flex_start">{med}</div>
                              )}
                            </Col>

                            <Col md={8}>
                              <Form.Item name="cosultation_note" label="Notes">
                                {/* <p className="labelText">Notes</p> */}
                                <TextArea
                                  placeholder="Write something here..."
                                  defaultValue={
                                    AppointmentData?.data?.prescription
                                      ?.patient_consultation_note
                                  }
                                  onChange={(e) => {
                                    setNote({ note: e.target.value });
                                  }}
                                  rows={6}
                                  className="c_input"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {!mobile && <Row>{med}</Row>}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Previous Consultation Table */}
                  {previousConsultationData?.length > 0 && (
                    <div className="column_flex justify_between">
                      <div className="white_color_div mt-5 d-none">
                        {previousConsultationData?.length > 0 && (
                          <div className="gap_div">
                            <HeadingWithSpaceLarge text="VITALS" />
                          </div>
                        )}
                        <div className="appointment_div_data">
                          <Form.Item name="vitalTable" className="vital_table">
                            <Form.Item>
                              <TableComponent
                                header={previousConsultationHeader}
                                data={previousConsultationData}
                                pagination={false}
                              />
                            </Form.Item>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={
                      mobile
                        ? location?.search?.split("?")[1] === "#prescribe_med"
                          ? "tab_data cover_space3"
                          : "d-none"
                        : "tab_data cover_space3"
                    }
                    id="prescribe_med"
                  >
                    <div className="appointment_div_data">
                      {/* {!mobile && <div className="white_color_div">
                    <div className="gap_div">
                      <HeadingWithSpaceLarge text="PRESCRIBE MEDICINES" />
                    </div>
                  </div>
                  } */}
                      {/* <Form.Item name="medicine">
                    <Form.Item>
                      {mobile ?
                        <div className="column_flex justify_between">
                          <div className="white_color_div">
                            <div className="flex_end justify_between">
                              <div className="column_flex gap_div">
                                <div className="column_flex">
                                  <HeadingDescSmall text="Medicine" />
                                  <HeadingDescSmall text="Tramal" />
                                </div>
                                <div className="column_flex">
                                  <HeadingDescSmall text="Frequency" />
                                  <HeadingDescSmall text="Thrice a day" />
                                </div>
                                <div className="column_flex">
                                  <HeadingDescSmall text="Instruction" />
                                  <HeadingDescSmall text="After Meal" />
                                </div>
                              </div>
                              <div className="column_flex gap_div">
                                <div className="column_flex">
                                  <HeadingDescSmall text="Dosage" />
                                  <HeadingDescSmall text="100 mg" />
                                </div>
                                <div className="column_flex">
                                  <HeadingDescSmall text="Duration" />
                                  <HeadingDescSmall text="3 Day(s)" />
                                </div>
                                <div className="flex_start">
                                  <a>
                                    <img src={editIConGreen} alt="edit"></img>
                                  </a>
                                  <a>
                                    <img src={deleteIconPink} alt="delete"></img>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="white_color_div">
                            <div className="flex_end justify_between">
                              <div className="column_flex gap_div">
                                <div className="column_flex">
                                  <HeadingDescSmall text="Medicine" />
                                  <HeadingDescSmall text="Tramal" />
                                </div>
                                <div className="column_flex">
                                  <HeadingDescSmall text="Frequency" />
                                  <HeadingDescSmall text="Thrice a day" />
                                </div>
                                <div className="column_flex">
                                  <HeadingDescSmall text="Instruction" />
                                  <HeadingDescSmall text="After Meal" />
                                </div>
                              </div>
                              <div className="column_flex gap_div">
                                <div className="column_flex">
                                  <HeadingDescSmall text="Dosage" />
                                  <HeadingDescSmall text="100 mg" />
                                </div>
                                <div className="column_flex">
                                  <HeadingDescSmall text="Duration" />
                                  <HeadingDescSmall text="3 Day(s)" />
                                </div>
                                <div className="flex_start">
                                  <a>
                                    <img src={editIConGreen} alt="edit"></img>
                                  </a>
                                  <a>
                                    <img src={deleteIconPink} alt="delete"></img>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="white_color_div">
                          <TableComponent
                            header={medHeader}
                            data={medData2}
                            pagination={false}
                            bold={true}
                          />
                        </div>
                      }
                    </Form.Item>
                  </Form.Item> */}
                      {/* =================================choose/write prescription==========================*/}

                      <div className="prescription-parent" id="prescription">
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <TabContext value={tabValue}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleTabs}
                                aria-label="lab API tabs example"
                              >
                                <Tab
                                  className="tab-1"
                                  label="Choose Prescription"
                                  value="1"
                                />
                                <Tab
                                  className="tab-2"
                                  label="Write Prescription"
                                  value="2"
                                />
                              </TabList>
                            </Box>
                            <TabPanel value="1">
                              <div className="white_color_div">
                                <div className="gap_div">
                                  <Form.Item>
                                    <MedForm
                                      medicine={medicine}
                                      complete={addMed}
                                      medTable={medTable}
                                      setMedTable={setMedTable}
                                      setEditMedicineStatus={
                                        setEditMedicineStatus
                                      }
                                      editMedicineStatus={editMedicineStatus}
                                      setEditMedicine={setEditMedicine}
                                      editMedicine={editMedicine}
                                      setShouldDispatchMedicine={
                                        setShouldDispatchMedicine
                                      }
                                      setAddMedicineLoader={
                                        setAddMedicineLoader
                                      }
                                    />
                                    {/* <MedForm
                                      medicine={medicine}
                                      complete={addMed}
                                      medTable={medTable}
                                      setMedTable={setMedTable}
                                      setShouldDispatchMedicine={
                                        setShouldDispatchMedicine
                                      }
                                    /> */}
                                  </Form.Item>
                                </div>
                              </div>
                              <div>
                                {medTable?.length > 0 && (
                                  <Table
                                    style={{ backgroundColor: "#fff" }}
                                    className="table instant-feedback-table"
                                  >
                                    <>
                                      <thead>
                                        <tr className="first-table-row">
                                          <th
                                            style={{ color: "#313131" }}
                                            className="fw-400 text-left"
                                          >
                                            Medicine
                                          </th>
                                          <th
                                            colSpan={4}
                                            style={{ color: "#313131" }}
                                            className="fw-400 text-center"
                                          >
                                            Frequency
                                          </th>
                                          <th
                                            style={{ color: "#313131" }}
                                            className="fw-400 text-center"
                                          >
                                            Duration
                                          </th>
                                          <th
                                            style={{ color: "#313131" }}
                                            className="fw-400 text-center"
                                          >
                                            With Meal
                                          </th>
                                          <th className=""></th>
                                        </tr>
                                        <tr>
                                          <th className="text-left">&nbsp;</th>
                                          <th
                                            className="text-center"
                                            style={{ color: "#078A8E" }}
                                          >
                                            Morning
                                          </th>
                                          <th
                                            className="text-center"
                                            style={{ color: "#078A8E" }}
                                          >
                                            Afternoon
                                          </th>
                                          <th
                                            className="text-center"
                                            style={{ color: "#078A8E" }}
                                          >
                                            Evening
                                          </th>
                                          <th
                                            className="text-center"
                                            style={{ color: "#078A8E" }}
                                          >
                                            Night
                                          </th>
                                          <th className="text-center">
                                            &nbsp;
                                          </th>
                                          <th className="text-center">
                                            &nbsp;
                                          </th>
                                          <th className="text-center">
                                            &nbsp;
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {medTable?.map((item, index) => (
                                          <tr key={index}>
                                            <td className="text-left">
                                              {" "}
                                              {item?.medicine}{" "}
                                            </td>
                                            <td className="me-2 text-center">
                                              {item?.morning && (
                                                <span className="bg-change-td">
                                                  {item?.morning}{" "}
                                                  {item?.morning && item?.unit}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item?.afternoon && (
                                                <span className="bg-change-td">
                                                  {item?.afternoon}{" "}
                                                  {item?.afternoon &&
                                                    item?.unit}
                                                </span>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item?.evening && (
                                                <span className="bg-change-td">
                                                  {item?.evening}{" "}
                                                  {item?.evening && item?.unit}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item?.night && (
                                                <span className="bg-change-td">
                                                  {item?.night}{" "}
                                                  {item?.night && item?.unit}{" "}
                                                </span>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item?.number_of_days}
                                            </td>
                                            {/* <td ><img  src={greenIcon} alt='' className='img-fluid ms-4' /></td> */}
                                            <td className="text-center">
                                              {" "}
                                              {item?.is_after_meal == "1"
                                                ? "After Meal"
                                                : "Before Meal"}{" "}
                                            </td>
                                            <td className="text-center">
                                              <img
                                                style={{ cursor: "pointer" }}
                                                src={RemovePre}
                                                alt=""
                                                onClick={(e) => {
                                                  let modified =
                                                    medTable?.filter(
                                                      (med, idx) => {
                                                        return (
                                                          med?.medicine !=
                                                          item?.medicine
                                                        );
                                                      }
                                                    );
                                                  setMedTable(modified);
                                                }}
                                              />
                                              <img
                                                style={{ cursor: "pointer" }}
                                                src={EditPre}
                                                alt=""
                                                disabled={editMedicineStatus}
                                                className={`img-fluid ms-2 ${editMedicineStatus &&
                                                  "disabled_edit"
                                                  }`}
                                                onClick={(e) => {
                                                  let modified =
                                                    medTable?.filter(
                                                      (med, idx) => {
                                                        return (
                                                          med?.medicine !=
                                                          item?.medicine
                                                        );
                                                      }
                                                    );
                                                  setMedTable(modified);
                                                  setEditMedicine(item);
                                                  setEditMedicineStatus(true);
                                                  // setEditMedicine(modified);
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </>
                                  </Table>
                                )}
                              </div>
                            </TabPanel>
                            <TabPanel value="2">
                              {" "}
                              <div className="white_color_div">
                                <div className="gap_div" style={{ paddingBottom: '30px' }}>
                                  <div className="p-0">
                                    <textarea
                                      maxLength={150}
                                      ref={textareaRef}
                                      placeholder="Write prescription here (Max: 150 characters)"
                                      className={`textArea-w  ${errorPrescription ? "error" : ""
                                        }`}
                                    ></textarea>
                                    {errorPrescription && (
                                      <div
                                        className={`prescriptionError ${errorPrescription ? "error" : ""
                                          }`}
                                      >
                                        <p>{errorPrescription}</p>
                                      </div>
                                    )}
                                    <span className="hint">
                                      E.g. Distalgesic (375 mg)<br></br>1 tablet
                                      1 + 0 + 1 + 1 to be taken with meals for 3
                                      days.
                                    </span>
                                  </div>
                                  <Col
                                    md={3}
                                    style={{ marginRight: "0", marginLeft: 'auto' }}
                                    className="offset-md-8"
                                  >
                                    <button
                                      style={{ width: '313px', height: '42px' }}
                                      onClick={(e) => writePrescription(e)}
                                      className="review-button mt-3 me-2 text-uppercase MedBtn-btn"
                                    >
                                      ADD PRESCRIPTION
                                    </button>
                                  </Col>
                                </div>

                                {(writePrescriptionInfo?.length > 0 ||
                                  medTable?.length > 0) && (
                                    <>
                                      <div className="prescriptionBox dede">
                                        <h4>Prescription</h4>
                                      </div>
                                      <div
                                        style={{ marginBottom: "1rem" }}
                                        className="row w-100"
                                      >
                                        {writePrescriptionInfo?.map((pres) => (
                                          <>
                                            <Col md={11} className="mt-2">
                                              <p
                                                style={{
                                                  marginLeft: "3rem",
                                                  wordBreak: "break-all",
                                                }}
                                              >
                                                {" "}
                                                {pres}{" "}
                                              </p>
                                            </Col>
                                            <Col className="mt-2" md={1}>
                                              <img
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) =>
                                                  removeWrittenPrescription(
                                                    e,
                                                    pres
                                                  )
                                                }
                                                src={RemovePre}
                                                alt=""
                                                className="img-fluid"
                                              />
                                              <img
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) =>
                                                  editPrescriptionInfo(e, pres)
                                                }
                                                src={EditPre}
                                                alt=""
                                                className="img-fluid ms-2"
                                              />
                                            </Col>
                                          </>
                                        ))}
                                      </div>
                                    </>
                                  )}
                              </div>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      mobile
                        ? location?.search?.split("?")[1] === "#prescribe_lab"
                          ? "tab_data cover_space3"
                          : "d-none"
                        : "tab_data cover_space3"
                    }
                    id="prescribe_lab"
                  >
                    <div className="appointment_div_data">
                      <div className="white_color_div">
                        <div className="prescribe-lab">
                          <h4>PRESCRIBE LAB TESTS</h4>
                        </div>
                        <div className="gap_div pt-0">
                          <Form.Item>
                            <LabForm
                              labs={labs}
                              complete={addLab}
                              prescribeData={prescribeData}
                              setAddLabLoader={setAddLabLoader}
                              setShouldDispatchLabs={setShouldDispatchLabs}
                            />
                          </Form.Item>
                        </div>
                        {prescribeData.length > 0 ? (
                          <div className="prescriptionBox">
                            <h4>Lab Test </h4>
                          </div>
                        ) : null}
                        <Table className="table instant-feedback-table lab-test-table border_bottom_rem">
                          <>
                            <tbody>
                              {prescribeData?.map((item, index) => {
                                return (
                                  <tr>
                                    <td className="med-test-type text-left w-25">
                                      {item?.lab_name}
                                    </td>
                                    <td className="text-left w-15">
                                      {item?.description}
                                    </td>
                                    <td className="w-50">&nbsp;</td>

                                    <td className="w-5 text-center">
                                      <img
                                        style={{ cursor: "pointer" }}
                                        src={RemovePre}
                                        alt=""
                                        className="img-fluid"
                                        onClick={(e) => {
                                          let modified = prescribeData?.filter(
                                            (med, idx) => {
                                              return (

                                                med?.description != item?.description
                                              );
                                            }
                                          );
                                          setPrescribeData(modified);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </>
                        </Table>
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </>
          )}

          <div className="bottom_btn bg-transparent">
            <a
              className="appoitment-cancel-btn fixed_app_button bg-transparent text-uppercase d-flex align-items-center justify-content-center color-313131"
              onClick={(e) => setShowCancelModal(true)}
            >
              Cancel
            </a>

            <button
              className="submit-btn-completed add-record-btn text-uppercase w-100"
              htmlType="submit"
            >
              <span className="hk_for_center"> MARK AS COMPLETE</span>
              <span className="add-record-chevron">
                <FiChevronRight />
              </span>
            </button>
          </div>

          {isMobile ? (
            <>
              <main className="forall_mobile_section">
                {viewSingleReport || viewSinglePresc ? (
                  <>
                    {!viewSinglePresc && (
                      <>
                        <button
                          className="image_cross"
                          onClick={(e) => setViewSingleReport(false)}
                        >
                          <img src={closeTable} alt="cross" />
                        </button>
                        <div className="reportViewer">
                          <img src={viewSingleReportFile} alt="Report" />
                        </div>
                      </>
                    )}
                    {!viewSingleReport && (
                      <>
                        <button
                          className="image_cross"
                          onClick={(e) => setViewSinglePresc(false)}
                        >
                          <img src={closeTable} alt="cross" />
                        </button>
                        <div className="prev_consult_mobile_view">
                          <div className="text-end">
                            <img
                              src={logoMob}
                              className="img-fluid"
                              alt="logo"
                            />
                          </div>
                          <div className="box_dr d-flex align-items-center">
                            <div className="box_dr_img">
                              {showPrevConsultationModal?.doctor
                                ?.is_featured && (
                                  <>
                                    <img
                                      src={featuredDoc}
                                      className="img-fluid dr_image_icon"
                                      alt="logo"
                                    />
                                  </>
                                )}
                              <img
                                src={
                                  showPrevConsultationModal?.doctor?.image_url
                                }
                                className="img-fluid dr_image"
                                alt="dr"
                              />
                            </div>
                            <div>
                              <p className="drName">
                                {showPrevConsultationModal?.doctor?.name}
                              </p>
                              <h6>
                                {showPrevConsultationModal?.doctor.doctor_speciality_details?.map(
                                  (item) => {
                                    return <span>{item?.name}</span>;
                                  }
                                )}
                              </h6>
                              <h6>
                                {showPrevConsultationModal?.doctor.doctor_education?.map(
                                  (item) => {
                                    return (
                                      <>
                                        {item?.degree
                                          ?.toString()
                                          ?.replace(/,/g, ", ")}
                                      </>
                                    );
                                  }
                                )}
                              </h6>
                            </div>
                          </div>

                          <div className="batient_bio">
                            <div className="d-flex flex-wrap">
                              <div className="w-50">
                                <h6>
                                  <span>Patient:</span>
                                  <br />
                                  {
                                    showPrevConsultationModal?.patient_detail
                                      ?.name
                                  }
                                </h6>
                              </div>
                              <div className="w-50">
                                <h6>
                                  <span>Patient Number:</span>
                                  <br />
                                  {
                                    showPrevConsultationModal?.patient_detail
                                      ?.id
                                  }
                                </h6>
                              </div>
                              <div className="w-100">
                                <h6 className="d-flex lh-1 align-items-center">
                                  <span>Prescribed on:</span>
                                  {showPrevConsultationModal?.appointment_date}
                                </h6>
                              </div>
                            </div>
                          </div>

                          {showPrevConsultationModal?.get_prescription
                            ?.prescribed_medicine?.length > 0 ? (
                            showPrevConsultationModal?.get_prescription?.prescribed_medicine?.map(
                              (item) => {
                                return (
                                  <>
                                    <Accordion className="medicine_accordian">
                                      <Accordion.Item eventKey={item?.id}>
                                        <Accordion.Header>
                                          {item?.prescription_element?.name}
                                          <span className="duratiion_med">
                                            Duration: {item?.number_of_days}
                                          </span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="medicine_instuction">
                                            <ul>
                                              <li>
                                                <p>Morning:</p>
                                                <div className="box_blue">
                                                  <h6>
                                                    {item?.morning}
                                                    <br />
                                                    {item?.unit}
                                                  </h6>
                                                </div>
                                              </li>
                                              <li>
                                                <p>Afternoon:</p>
                                                <div className="box_blue">
                                                  <h6>
                                                    {item?.afternoon}
                                                    <br />
                                                    {item?.unit}
                                                  </h6>
                                                </div>
                                              </li>
                                              <li>
                                                <p>Evening:</p>
                                                <div className="box_blue">
                                                  <h6>
                                                    {item?.evening}
                                                    <br />
                                                    {item?.unit}
                                                  </h6>
                                                </div>
                                              </li>
                                              <li>
                                                <p>Night:</p>
                                                <div className="box_blue">
                                                  <h6>
                                                    {item?.night}
                                                    <br />
                                                    {item?.unit}
                                                  </h6>
                                                </div>
                                              </li>
                                            </ul>
                                            <h6>
                                              Instructions:{" "}
                                              <span>
                                                {item?.is_after_meal === true
                                                  ? "After Meal"
                                                  : "Before Meal"}
                                              </span>
                                            </h6>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  </>
                                );
                              }
                            )
                          ) : isJsonString(showPrevConsultationModal?.prescription_here) && JSON.parse(
                            showPrevConsultationModal?.prescription_here
                              ?.length <= 0
                          ) ? (
                            <>
                              <Accordion className="medicine_accordian">
                                <Accordion.Item>
                                  <Accordion.Header>
                                    <p style={{ textAlign: "left" }}>
                                      Prescription
                                    </p>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="subs_table_inner_mobile">
                                        {showPrevConsultationModal?.prescription_here &&
                                          isJsonString(
                                            showPrevConsultationModal?.prescription_here
                                          ) && (
                                            <p
                                              style={{
                                                textAlign: "left",
                                                whiteSpace: "pre-line",
                                              }}
                                            >
                                              {JSON.parse(
                                                showPrevConsultationModal?.prescription_here
                                              )}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </>
                          ) : null}

                          {showPrevConsultationModal?.get_prescription
                            ?.prescribed_lab?.length > 0 ? (
                            <>
                              <Accordion className="medicine_accordian lab_test_prescription_customr labTestMobileModal">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header
                                    style={{ backgroundColor: "#D0F4F5" }}
                                  >
                                    <p>Lab Test</p>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="subs_table_inner_mobile">
                                        {showPrevConsultationModal?.get_prescription?.prescribed_lab?.map(
                                          (test) => {
                                            return (
                                              <div className="desc-prescription">
                                                <div className="labDescription">
                                                  <h5>{test?.description}</h5>
                                                </div>
                                                <div className="labPrescription">
                                                  <h5>
                                                    {
                                                      test?.prescription_element
                                                        ?.name
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </>
                          ) : null}

                          {showPrevConsultationModal?.get_prescription
                            ?.prescribed_medicine?.length <= 0 &&
                            isJsonString(
                              showPrevConsultationModal?.prescription_here
                            ) &&
                            JSON.parse(
                              showPrevConsultationModal?.prescription_here
                            ) &&
                            showPrevConsultationModal?.get_prescription
                              ?.prescribed_lab?.length <= 0 ? (
                            <>
                              <div>
                                <p
                                  style={{ padding: "20px 0" }}
                                  disabled
                                  className="text-center pt-5"
                                >
                                  No Prescription found
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <section className="Reason_for_visit_mobile for_accordion_style">
                      <Accordion>
                        <Accordion.Item
                          eventKey="0"
                          className={`${AppointmentData?.data?.reason_for_visit &&
                            "data_true"
                            }`}
                        >
                          <Accordion.Header>
                            {!AppointmentData?.data?.reason_for_visit ? (
                              <>
                                <FontAwesomeIcon icon="fa-solid fa-circle" />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon icon="fas fa-check-circle" />
                              </>
                            )}
                            Reason for Visit
                          </Accordion.Header>
                          <Accordion.Body>
                            {!AppointmentData?.data?.reason_for_visit ? (
                              <>
                                <p className="text-center no_data_mob_acc_state">
                                  No reason given
                                </p>
                              </>
                            ) : (
                              <>
                                <h5>
                                  {AppointmentData?.data?.reason_for_visit}
                                </h5>
                                <p>
                                  {AppointmentData?.data?.additional_detail}
                                </p>
                              </>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </section>
                    <section className="vitals_mobile for_accordion_style">
                      <Accordion>
                        <Accordion.Item
                          eventKey="0"
                          className={`${vitalData?.length > 0 && "data_true"}`}
                        >
                          <Accordion.Header className="vital_header">
                            {!vitalData?.length > 0 ? (
                              <>
                                <FontAwesomeIcon icon="fa-solid fa-circle" />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon icon="fas fa-check-circle" />
                              </>
                            )}{" "}
                            Vitals
                            {vitalData?.length > 0 && (
                              <>
                                <span>
                                  {" "}
                                  {vitalData?.[0]?.dateValue}{" "}
                                  <i>| {vitalData?.[0]?.timeValue}</i>
                                </span>
                              </>
                            )}
                          </Accordion.Header>
                          <Accordion.Body>
                            {vitalData?.length <= 0 ? (
                              <>
                                <p className="text-center no_data_mob_acc_state">
                                  {vitalTableLocale?.emptyText}
                                </p>
                              </>
                            ) : (
                              <>
                                <div className="vitals_area_mob">
                                  <div className="d-flex align-items-center justify-content-center mb-4">
                                    <h4>
                                      <span>Sehat Score:</span>{" "}
                                      {vitalData?.[0]?.sehat_score}{" "}
                                      <img src={smilyFace} alt="Score Emoji" />
                                    </h4>
                                  </div>
                                  <div className="d-flex align-items-center flex-wrap">
                                    {vitalData?.[0]?.blood_pressure &&
                                      vitalData?.[0]?.blood_pressure_value && (
                                        <div className="vital_rate">
                                          <h4>Blood Pressure</h4>
                                          <p>
                                            <span className="scor">
                                              {vitalData?.[0]?.blood_pressure}
                                            </span>{" "}
                                          </p>
                                          <span
                                            className={`score_measure score ${vitalData?.[0]?.blood_pressure_value?.toLowerCase() ===
                                              "normal"
                                              ? "blue_score"
                                              : "red_score"
                                              }`}
                                          >
                                            {
                                              vitalData?.[0]
                                                ?.blood_pressure_value
                                            }
                                          </span>
                                        </div>
                                      )}

                                    {vitalData?.[0]?.heart_rate && (
                                      <div className="vital_rate">
                                        <h4>Heart Rate</h4>
                                        <p>
                                          <span className="scor">
                                            {vitalData?.[0]?.heart_rate}
                                          </span>{" "}
                                        </p>
                                        <span
                                          className={`score_measure score ${vitalData?.[0]?.heart_rate_value?.toLowerCase() ===
                                            "normal"
                                            ? "blue_score"
                                            : "red_score"
                                            }`}
                                        >
                                          {vitalData?.[0]?.heart_rate_value}
                                        </span>
                                      </div>
                                    )}

                                    {vitalData?.[0]?.spo2 && (
                                      <div className="vital_rate">
                                        <h4>Oxygen Saturation</h4>
                                        <p>
                                          <span className="scor">
                                            {vitalData?.[0]?.spo2}
                                          </span>{" "}
                                        </p>
                                        <span
                                          className={`score_measure score ${vitalData?.[0]?.spo2_value?.toLowerCase() ===
                                            "normal"
                                            ? "blue_score"
                                            : "red_score"
                                            }`}
                                        >
                                          {vitalData?.[0]?.spo2_value}
                                        </span>
                                      </div>
                                    )}

                                    {vitalData?.[0]?.respiratory_rate && (
                                      <div className="vital_rate">
                                        <h4>Respiration Rate</h4>
                                        <p>
                                          <span className="scor">
                                            {vitalData?.[0]?.respiratory_rate}
                                          </span>{" "}
                                        </p>
                                        <span
                                          className={`score_measure score ${vitalData?.[0]?.respiratory_rate_value?.toLowerCase() ===
                                            "normal"
                                            ? "blue_score"
                                            : "red_score"
                                            }`}
                                        >
                                          {
                                            vitalData?.[0]
                                              ?.respiratory_rate_value
                                          }
                                        </span>
                                      </div>
                                    )}

                                    {vitalData?.[0]?.sdnn && (
                                      <div className="vital_rate">
                                        <h4>SDNN</h4>
                                        <p>
                                          <span className="scor">
                                            {vitalData?.[0]?.sdnn}
                                          </span>{" "}
                                        </p>
                                        <span
                                          className={`score_measure score ${vitalData?.[0]?.sdnn_value?.toLowerCase() ===
                                            "normal"
                                            ? "blue_score"
                                            : "red_score"
                                            }`}
                                        >
                                          {vitalData?.[0]?.sdnn_value}
                                        </span>
                                      </div>
                                    )}

                                    {vitalData?.[0]?.stress_level_value && (
                                      <div className="vital_rate">
                                        <h4>Stress Level</h4>
                                        {vitalData?.[0]?.stress_level_value?.toLowerCase() ===
                                          "low" ? (
                                          <p>
                                            <img
                                              src={lowStress}
                                              alt="Stress Emoji"
                                            />
                                          </p>
                                        ) : vitalData?.[0]?.stress_level_value?.toLowerCase() ===
                                          "normal" ? (
                                          <p>
                                            <img
                                              src={normalStress}
                                              alt="Stress Emoji"
                                            />
                                          </p>
                                        ) : (
                                          <p>
                                            <img
                                              src={highStress}
                                              alt="Stress Emoji"
                                            />
                                          </p>
                                        )}

                                        <span
                                          className={`score_measure score ${vitalData?.[0]?.stress_level_value?.toLowerCase() ===
                                            "normal"
                                            ? "blue_score"
                                            : "red_score"
                                            }`}
                                        >
                                          {" "}
                                          {
                                            vitalData?.[0]?.stress_level_value
                                          }{" "}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </section>

                    <section className="medical_records_mobile for_accordion_style">
                      <Accordion>
                        <Accordion.Item
                          eventKey="0"
                          className={`${medicalRec?.length !== 0 && "data_true"
                            }`}
                        >
                          <Accordion.Header>
                            {medicalRec?.length === 0 ||
                              medicalRec?.length < 0 ? (
                              <>
                                <FontAwesomeIcon icon="fa-solid fa-circle" />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon icon="fas fa-check-circle" />
                              </>
                            )}
                            Medical Records
                          </Accordion.Header>
                          <Accordion.Body>
                            {medicalRec?.length === 0 ||
                              medicalRec?.length < 0 ? (
                              <>
                                <p className="text-center no_data_mob_acc_state pt-3">
                                  No medical records shared
                                </p>
                              </>
                            ) : (
                              <>
                                <table className="med-record_data">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>
                                        {AppointmentData?.data?.doctor?.name}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {medicalRec?.map((item) => (
                                      <tr>
                                        {item?.instant_medical_record_files
                                          ?.length > 0 && <td>{item?.date}</td>}
                                        {item?.instant_medical_record_files
                                          ?.length > 0 && (
                                            <>
                                              <td>
                                                {item?.instant_medical_record_files.map(
                                                  (file) => (
                                                    <>
                                                      <div
                                                        className="d-flex align-items-center mb-2"
                                                        onClick={(e) =>
                                                          handleFileView(file)
                                                        }
                                                      >
                                                        <FontAwesomeIcon icon="fas fa-file" />
                                                        <p>
                                                          {" "}
                                                          {cutFromMiddle(
                                                            file?.filename
                                                          )}{" "}
                                                        </p>
                                                      </div>
                                                    </>
                                                  )
                                                )}
                                              </td>
                                            </>
                                          )}
                                      </tr>
                                    ))}
                                    {/* {medicalRec?.length > 0 ? medicalRec?.map((item) => {
                                  { console.log(item, "itemitemitem") }
                                  item?.instant_medical_record_files.map((file) => {
                                    { console.log(file, "filefile") }
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {item?.date}
                                            <td>
                                              <h3 className="file-name">Reports for Dr. Madeeha Asad</h3>
                                              <div className="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon="fas fa-file" /><p>  {cutFromMiddle(file?.filename)}{" "}</p>
                                              </div>
                                            </td>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  })
                                }) : null} */}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </section>

                    <section className="medical_history_mobile for_accordion_style">
                      <Accordion>
                        <Accordion.Item eventKey="0" className={`data_true`}>
                          <Accordion.Header>
                            <FontAwesomeIcon icon="fa-solid fa-circle" />
                            Medical History
                          </Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div
                                className="appointment_div_data"
                                style={{ flexDirection: "column" }}
                              >
                                <Row>
                                  <Col md={4}>
                                    <div className="column_flex">
                                      <div className="column_flex2">
                                        <Form.Item
                                          name="blood_group"
                                          label="Blood Group"
                                        >
                                          {/* <p className="labelText">Blood Group</p> */}
                                          <Select
                                            defaultValue={
                                              patient?.prescription?.blood_group
                                            }
                                            dropdownAlign={{ offset: [0, 4] }}
                                            placeholder="Select blood group"
                                            suffixIcon={
                                              // <HiOutlineArrowDown color="#29BCC1" />
                                              <img src={arrowdropdown} alt />
                                            }
                                            className="c_select"
                                          >
                                            {/* <Option selected >{patient?.prescription?.blood_group}</Option> */}
                                            <Option value="A+">A+</Option>
                                            <Option value="A-">A-</Option>
                                            <Option value="B+">B+</Option>
                                            <Option value="B-">B-</Option>
                                            <Option value="O+">O+</Option>
                                            <Option value="O-">O-</Option>
                                            <Option value="AB+">AB+</Option>
                                            <Option value="AB-">AB-</Option>
                                          </Select>
                                        </Form.Item>
                                      </div>

                                      <div className="column_flex2">
                                        <Form.Item
                                          name="condition"
                                          label="Add Existing Condition"
                                        >
                                          {/* <p className="labelText">Add Existing Condition</p> */}
                                          <Select
                                            dropdownAlign={{ offset: [0, 4] }}
                                            suffixIcon={
                                              // <HiOutlineArrowDown color="#29BCC1" />
                                              <img src={arrowdropdown} alt />
                                            }
                                            className="c_select"
                                            onSelect={(value, event) => {
                                              handleOnChange(value, event);
                                            }}
                                            showSearch
                                            placeholder="Select condition"
                                            filterOption={(input, option) => {
                                              return option?.children
                                                ?.toLowerCase()
                                                ?.startsWith(
                                                  input?.toLowerCase()
                                                );
                                            }}
                                          >
                                            {conditions?.data?.disease?.map(
                                              (disease, index) => (
                                                <Option
                                                  value={disease.name}
                                                  key={index}
                                                >
                                                  {disease.name}
                                                </Option>
                                              )
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </div>
                                    {mobile && (
                                      <div className="button_container">
                                        <div className="flex_start">{med}</div>
                                      </div>
                                    )}
                                  </Col>

                                  <Col md={8}>
                                    <Form.Item
                                      name="cosultation_note"
                                      label="Notes"
                                    >
                                      {/* <p className="labelText">Notes</p> */}
                                      <TextArea
                                        defaultValue={
                                          AppointmentData?.data?.prescription
                                            ?.patient_consultation_note
                                        }
                                        onChange={(e) => {
                                          setNote({ note: e.target.value });
                                        }}
                                        rows={6}
                                        className="c_input"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                {!mobile && <Row>{med}</Row>}
                              </div>
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </section>

                    <section className="prescriptions_mobile for_accordion_style">
                      <Accordion>
                        <Accordion.Item eventKey="0" className={`'data_true`}>
                          <Accordion.Header>
                            <FontAwesomeIcon icon="fa-solid fa-circle" />
                            Prescription
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <>
                              <TabItem.Container
                                defaultActiveKey="1"
                                id="left-tabs-example"
                              >
                                <Nav
                                  variant="pills"
                                  className="hk_bg_cover prescripMobileAccor"
                                >
                                  <Nav.Item className="w-50 ps-0">
                                    <Nav.Link eventKey={`1`}>
                                      <h6>Choose</h6>
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="w-50 ps-0">
                                    <Nav.Link eventKey={`2`}>
                                      <h6>Write</h6>
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                                <TabItem.Content className="paddingContentBody mobile_medicine_box">
                                  <TabItem.Pane eventKey={`1`}>
                                    <>
                                      <Form.Item>
                                        <MedForm
                                          medicine={medicine}
                                          complete={addMed}
                                          medTable={medTable}
                                          setMedTable={setMedTable}
                                          setEditMedicineStatus={
                                            setEditMedicineStatus
                                          }
                                          editMedicineStatus={
                                            editMedicineStatus
                                          }
                                          setEditMedicine={setEditMedicine}
                                          editMedicine={editMedicine}
                                          setShouldDispatchMedicine={
                                            setShouldDispatchMedicine
                                          }
                                          setAddMedicineLoader={
                                            setAddMedicineLoader
                                          }
                                        />
                                        {/* <MedForm
                                          medicine={medicine}
                                          complete={addMed}
                                          medTable={medTable}
                                          setShouldDispatchMedicine={
                                            setShouldDispatchMedicine
                                          }
                                        /> */}
                                      </Form.Item>
                                      <Accordion className="medicine_accordian prescription_box001">
                                        {medTable.map((item) => (
                                          <Accordion.Item
                                            eventKey={
                                              item?.prescription_element_id
                                            }
                                          >

                                            <Accordion.Header>
                                              {item?.medName}
                                              <span className="duratiion_med">
                                                {" "}
                                                <img
                                                  style={{ cursor: "pointer" }}
                                                  src={RemovePre}
                                                  alt=""
                                                  onClick={(e) => {
                                                    let modified =
                                                      medTable?.filter(
                                                        (med, idx) => {
                                                          return (
                                                            med?.medicine !=
                                                            item?.medicine
                                                          );
                                                        }
                                                      );
                                                    setMedTable(modified);
                                                  }}
                                                  className="img-fluid"
                                                />
                                                <img
                                                  style={{ cursor: "pointer" }}
                                                  src={EditPre}
                                                  alt=""
                                                  disabled={editMedicineStatus}
                                                  className={`img-fluid ms-2 ${editMedicineStatus &&
                                                    "disabled_edit"
                                                    }`}
                                                  onClick={(e) => {
                                                    let modified =
                                                      medTable?.filter(
                                                        (med, idx) => {
                                                          return (
                                                            med?.medicine !=
                                                            item?.medicine
                                                          );
                                                        }
                                                      );
                                                    setMedTable(modified);
                                                    setEditMedicine(item);
                                                    setEditMedicineStatus(true);
                                                    // setEditMedicine(modified);
                                                  }}
                                                />
                                              </span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="medicine_instuction">
                                                <ul>
                                                  <li>
                                                    {item.morning && (
                                                      <>
                                                        <p>Morning:</p>
                                                        <div className="box_blue">
                                                          <h6>
                                                            {item?.morning}
                                                            <br />
                                                            {item?.unit}
                                                          </h6>
                                                        </div>
                                                      </>
                                                    )}
                                                  </li>
                                                  <li>
                                                    {item?.afternoon && (
                                                      <>
                                                        <p>Afternoon:</p>
                                                        <div className="box_blue">
                                                          <h6>
                                                            {item?.afternoon}
                                                            <br />
                                                            {item?.unit}
                                                          </h6>
                                                        </div>
                                                      </>
                                                    )}
                                                  </li>
                                                  <li>
                                                    {item?.evening && (
                                                      <>
                                                        <p>Evening:</p>
                                                        <div className="box_blue">
                                                          <h6>
                                                            {item?.evening}
                                                            <br />
                                                            {item?.unit}
                                                          </h6>
                                                        </div>
                                                      </>
                                                    )}
                                                  </li>
                                                  <li>
                                                    {item?.night && (
                                                      <>
                                                        <p>Night:</p>
                                                        <div className="box_blue">
                                                          <h6>
                                                            {item?.evening}
                                                            <br />
                                                            {item?.unit}
                                                          </h6>
                                                        </div>
                                                      </>
                                                    )}
                                                  </li>
                                                </ul>
                                                {item?.number_of_days && (
                                                  <h6 className="instuction">
                                                    Duration:{" "}
                                                    <span>
                                                      {item?.number_of_days}
                                                    </span>
                                                  </h6>
                                                )}
                                                {item?.is_after_meal && (
                                                  <h6 className="instuction">
                                                    Instructions:{" "}
                                                    <span>
                                                      {item?.is_after_meal ===
                                                        "1"
                                                        ? "After meal"
                                                        : "Before meal"}
                                                    </span>
                                                  </h6>
                                                )}
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        ))}
                                      </Accordion>
                                    </>
                                  </TabItem.Pane>
                                  <TabItem.Pane eventKey={`2`}>
                                    <>
                                      <textarea
                                        maxLength={150}
                                        ref={textareaRef}
                                        placeholder="Write prescription here (Max: 150 characters)"
                                        className="textArea-w"
                                      ></textarea>
                                      {errorPrescription && (
                                        <div className="prescriptionError">
                                          <p className="position-initial">
                                            {errorPrescription}
                                          </p>
                                        </div>
                                      )}

                                      <div className="w-100">
                                        <button
                                          onClick={(e) => writePrescription(e)}
                                          className="review-button mt-3 me-2 text-uppercase MedBtn-btn"
                                        >
                                          ADD PRESCRIPTION
                                        </button>
                                      </div>

                                      <div className="prescriptionBox">
                                        <h4>Prescription</h4>
                                      </div>
                                      <Col xs={12} className="pd-1">
                                        <div
                                          style={{ marginBottom: "1rem" }}
                                          className="row w-100"
                                        >
                                          {writePrescriptionInfo?.map(
                                            (pres) => (
                                              <>
                                                <Col
                                                  xs={9}
                                                  md={8}
                                                  className="mt-2"
                                                >
                                                  <p
                                                    style={{
                                                      wordBreak: "break-all",
                                                    }}
                                                  >
                                                    {" "}
                                                    {pres}{" "}
                                                  </p>
                                                </Col>
                                                <Col
                                                  xs={3}
                                                  className="mt-2 text-end d-flex"
                                                  md={4}
                                                >
                                                  <img
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      removeWrittenPrescription(
                                                        e,
                                                        pres
                                                      )
                                                    }
                                                    src={RemovePre}
                                                    alt=""
                                                    className="img-fluid"
                                                  />
                                                  <img
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      editPrescriptionInfo(
                                                        e,
                                                        pres
                                                      )
                                                    }
                                                    src={EditPre}
                                                    alt=""
                                                    className="img-fluid ms-2"
                                                  />
                                                </Col>
                                              </>
                                            )
                                          )}
                                        </div>
                                      </Col>
                                    </>
                                  </TabItem.Pane>
                                </TabItem.Content>
                              </TabItem.Container>
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </section>

                    <section className="labTests for_accordion_style">
                      <Accordion>
                        <Accordion.Item
                          eventKey="0"
                          className={`${vitalData?.length > 0 && "data_true"}`}
                        >
                          <Accordion.Header className="vital_header">
                            {prescribeData ? (
                              <>
                                <FontAwesomeIcon icon="fa-solid fa-circle" />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon icon="fas fa-check-circle" />
                              </>
                            )}
                            Lab Tests
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="gap_div">
                              <Form.Item>
                                <LabForm
                                  labs={labs}
                                  complete={addLab}
                                  setAddLabLoader={setAddLabLoader}
                                  setShouldDispatchLabs={setShouldDispatchLabs}
                                  prescribeData={prescribeData}
                                />
                              </Form.Item>
                            </div>

                            <div className="lab_test_results">
                              {prescribeData.length > 0 ? (
                                <div className="prescriptionBox">
                                  <h4>Lab Test </h4>
                                </div>
                              ) : null}
                              <ul className="lab_test_mobile list-unstyled">
                                {prescribeData?.map((item, index) => {
                                  return (
                                    <li>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <h6>
                                            <span>{item?.lab_name}</span>{" "}
                                            {item?.description}
                                          </h6>
                                        </div>
                                        <div>
                                          {" "}
                                          <img
                                            style={{ cursor: "pointer" }}
                                            src={RemovePre}
                                            alt=""
                                            className="img-fluid close_mob"
                                            onClick={(e) => {
                                              let modified =
                                                prescribeData?.filter(
                                                  (med, idx) => {
                                                    return (

                                                      med?.description != item?.description
                                                    );
                                                  }
                                                );
                                              setPrescribeData(modified);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </section>

                    <section className="previous_consultation_mob for_accordion_style">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="previous_header">
                            {!patient.previous_consultation?.length > 0 ? (
                              <>
                                <FontAwesomeIcon icon="fa-solid fa-circle" />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon icon="fas fa-check-circle" />
                              </>
                            )}
                            Previous Consultation
                          </Accordion.Header>
                          <Accordion.Body className="prev_consult_mob">
                            {!patient.previous_consultation?.length > 0 ? (
                              <>
                                <p className="text-center no_data_mob_acc_state pt-3">
                                  No Previous Consultation
                                </p>
                              </>
                            ) : (
                              <>
                                <div>
                                  <ul className="header_prev_consult">
                                    <li>Doctor Name</li>
                                    <li>Reason for visit</li>
                                    <li>Details</li>
                                  </ul>
                                  {patient?.previous_consultation?.length > 0
                                    ? patient?.previous_consultation
                                      ?.slice(
                                        0,
                                        showAll
                                          ? patient.previous_consultation
                                            ?.length
                                          : 1
                                      )
                                      .map((item) => {
                                        return (
                                          <>
                                            <ul className="result_prev_consult">
                                              <li>
                                                Dr. {item?.doctor?.name}
                                                <br />
                                                <span>{item?.date}</span>
                                              </li>
                                              <li>
                                                <span>
                                                  {item?.reason_for_visit}
                                                </span>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={(e) =>
                                                    hanldeConsultationView(
                                                      item
                                                    )
                                                  }
                                                  className="view_presc"
                                                >
                                                  View Prescription
                                                </a>
                                              </li>
                                            </ul>
                                          </>
                                        );
                                      })
                                    : null}
                                  <div className="pd-10">
                                    <a
                                      onClick={viewAllPrevConsultation}
                                      disabled={showAll}
                                      className={`view_more1 btn btn-sucsess w-100 d-block ${showAll ? "showAllList" : ""
                                        }`}
                                    >
                                      View More
                                    </a>
                                  </div>
                                </div>
                              </>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </section>
                  </>
                )}
              </main>
            </>
          ) : null}
          {/* ----------------leave consultation modal ----------------------------- */}
          <Modal
            className="leaveConsultationModal"
            title=""
            centered
            visible={modal2Visible}
            onOk={() => completeAppointment()}
            onCancel={(e) => {
              setModal2Visible(false);
            }}
            okText="Yes"
            cancelText="No"
          >
            <div className="col-md-9 m-auto text-center">
              <h5 className=" cancelHeading ff-Nunito color-313131 line-height-35 fw-500">
                Are you sure you want mark this consultation as complete?
                {AppointmentData?.data?.booked_via_subscription != 'Special_Appointment' ? (
                  <div className="hk_go_for_next">
                    <Checkbox onChange={forStartNextConsultation}>
                      Start next video call
                    </Checkbox>
                  </div>
                ) : null}
              </h5>
            </div>
          </Modal>
          {/* ------------------------mark complete popup -------------------------*/}

          <Modal
            className="consultaionEndedModal"
            title=""
            centered
            visible={modal3Visible}
            closable={false}
            // onOk={() => completeAppointment()}
            cancelButtonProps={{ style: { display: "none" } }}
            footer={[
              // <Button className="col-md-9 m-auto" key="info" onClick={() => completeAppointment()}>
              //   Mark Complete
              // </Button>

              <Button
                className="col-md-9 m-auto"
                key="info"
                onClick={() => setAppointmentCompleted(true)}
              >
                MARK AS COMPLETE
              </Button>,
            ]}
          >
            <div className="col-md-9 m-auto text-center">
              <h5
                style={{ fontSize: "28px" }}
                className="ff-Nunito color-313131 line-height-35 fw-500"
              >
                Consultation ended
              </h5>
              <p style={{ fontSize: "18px" }} className="pt-2 pb-2">
                Please mark this consultation as complete.
              </p>
              <div className="hk_go_for_next">
                <Checkbox onChange={forStartNextConsultation2}>
                  Start next video call
                </Checkbox>
              </div>
            </div>
          </Modal>
          {/* ----------------------for instant consulation disable------------------- */}
          <Modal
            className="consultaionEndedModal"
            title=""
            centered
            visible={modal1Visible}
            onOk={() => {
              setModal1Visible(false);
            }}
            cancelButtonProps={{ style: { display: "none" } }}
            footer={[
              <Button
                className="col-md-9 m-auto"
                key="info"
                onClick={() => {
                  setModal1Visible(false);
                }}
              >
                Okay
              </Button>,
            ]}
          >
            <div className="col-md-8 m-auto text-center">
              <img
                src={disableConsultation}
                alt=""
                className="img-fluid mb-3"
              />
              <h5 className="ff-Nunito color-313131 fs-24 line-height-35 fw-500 mb-3">
                Instant Consultation disabled
              </h5>
              <p className="ff-circular fw-300 fs-17 line-height-24 mb-3">
                Your instant consultation bookings will be temporarily disabled.{" "}
              </p>
              <p className="ff-circular fw-300 fs-17 line-height-24">
                Please attend to the next patient in queue.
              </p>
            </div>
          </Modal>
          {/* ----------------consultation about end modal ----------------------------- */}
          <Modal
            className="leaveConsultationModal consultationAboutEnd"
            title=""
            centered
            visible={modal4Visible}
            closable={false}
            onOk={(e) => {
              setModal4Visible(false);
            }}
            okText="OK"
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <div className="col-md-11 m-auto text-center">
              <h5 className="ff-Nunito color-313131 fs-24 line-height-35 fw-500">
                The consultation is about to end
              </h5>
            </div>
          </Modal>

          {/* Cancel Consultation Modal */}

          <Modal
            className="leaveConsultationModal consultationAboutEnd"
            title=""
            centered
            visible={showCancelModal}
            onCancel={(e) => setShowCancelModal(false)}
            okText="Yes"
            cancelText="No"
            closable={true}
            onOk={cancelAppointment}
          >
            <div className="col-md-9 m-auto text-center">
              <h5 className="cancelHeading ff-Nunito color-313131 line-height-35 fw-500 pb-3">
                Are you sure you want to leave this consultation?
              </h5>
            </div>
          </Modal>
          <ToastContainer />
        </Form>
      )}
    </div>
  );
}

export default AppointmentFirstTime;
