import React, { useEffect, useState } from "react";
import { Root, Auth } from "../routing";
import { useAppDispatch, useAppSelector } from "./../redux/hooks";
import { SelectAuth, selectAuthToken, selectIsLoading, selectIsLogin } from "./redux/slice";
import { getAuthToken } from "./redux/thunk";
import {
  setInLocalStorage,
  getFromLocalStorage,
  asynchronouslyGetFromLocal,
} from "./../utils/helperFunctions";
import axios from "axios";
import Cookies from "js-cookie";

const Layout = () => {
  const dispatch = useAppDispatch();
  const selectLayout = useAppSelector(selectAuthToken);
  const selectLogin = useAppSelector(selectIsLogin);
  const isLoading = useAppSelector(selectIsLoading);

  useEffect(() => {
    if (selectLayout?.data?.token) {
      const { token } = selectLayout.data;
      setInLocalStorage("D_APP_TOKEN", token);
    }
  }, [selectLayout]);

  const checkAuth = async () => {
    const user = Cookies.get("token");
    if (user) {
      dispatch(SelectAuth(user));
    } else {
      dispatch(SelectAuth(null));
    }
  };

  useEffect(() => {
    checkAuth();
  }, [Cookies.get("token")]);

  const renderRouting = (isAuth) => {
    switch (isAuth) {
      case true:
        return <Root />;
      case false:
        return <Auth />;
      default:
        return <Auth />;
    }
  };

  return (
    <>
      {isLoading !== null && isLoading ? (
        <div className="loading"></div>
      ) : (
        <div className="_layout_wrapper">{renderRouting(selectLogin)}</div>
      )}
    </>
  );
};

export default Layout;
