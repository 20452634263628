import { post, get } from "../../../utils/httpService";

const SERVICE_URLS = {
  postBankDetails: () => `/bank-detail`,
  getBankDetails: () => `/bank-detail`,
  getEarning: () => `/doctor/earning-details`,
};

export const postBankDetails = (body, params) => {
  return post(SERVICE_URLS.postBankDetails(), body, params);
};

export const getBankDetails = (params) => {
  return get(SERVICE_URLS.getBankDetails(), params);
};

export const getEarning = (params) => {
  return get(SERVICE_URLS.getEarning(), params);
};
