import React from 'react'

import "../../pages/login/Registeration.css";

import { Col, Container, Row } from 'react-bootstrap';

import thankyou from '../../assets/images/png/thankyou.png'

import LogoBg from '../../assets/images/png/LogoMain.png';

import i18n from '../../i18n';
import { useLocation } from 'react-router-dom';




export const Thankyou = () => {

  const location = useLocation();
  const uniqueCode = location.state.uniqueCode;

  return (

    <section className="reg-bg-color ">

        <div className="registration-app h-100-vh">

            <Container>

        <img className="LogoImage pt-4" src={LogoBg} />


                <div className="row align-items-center pt-md-5 mt-md-5">

                <Col md={6} className="md-order-2">

                    <img src={thankyou} className="img-fluid d-md-none" alt="" />

                </Col>

                <Col md={6}>

                    <div className="content text-center">

                        <h1 className="thank-you">{i18n.t('Thank you for registering with us!')}</h1>

                        <hr className="bg-black opacity-1" />

                        <p className="text-uppercase my-4">{i18n.t('Your Application Number is')}</p>

                        {/* <div className="output-numner bg-white rounded d-inline-block py-2 px-4 fw-700">{i18n.t('001A23')}</div> */}

                        <div>

                            <input


                            className='p-2 AppNumber'


                            placeholder={uniqueCode} />


                        </div>

                        <p className="my-4">{i18n.t('We shall contact you shortly.')}<br className="d-none d-lg-block"/> {i18n.t('Please save the application number ')}<br className="d-none d-lg-block"/>{i18n.t('above for your records.')} </p>

                    </div>

                </Col>

                </div>

            </Container>

        </div>

    </section>

  )

}


export default Thankyou
