import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFromLocalStorage } from "../../../utils/helperFunctions";

import { getProfile as getProfileAPI, getUniversities as getUniversitiesAPI, getDegrees as getDegreesAPI, getCities as getCitiesAPI, getSpeciality as getSpecialityAPI, getServices as getServicesAPI, postUpdateProfile as postUpdateProfileAPI } from "./service";

export const getProfile = createAsyncThunk(
  "get/user",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await getProfileAPI(params);
    return response.data;
  }
);

export const getUniversities = createAsyncThunk(
  "get/universities",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
        platform: 'web',
        locale: 1,
      },
    };
    const response = await getUniversitiesAPI(params);
    return response.data;
  }
);

export const getDegrees = createAsyncThunk(
  "get/degrees",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
        platform: 'web',
        locale: 1,
      },
    };
    const response = await getDegreesAPI(params);
    return response.data;
  }
);

export const getCities = createAsyncThunk(
  "get/cities",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
        platform: 'web',
        locale: 1,
      },
    };
    const response = await getCitiesAPI(params);
    return response.data;
  }
);

export const getSpeciality = createAsyncThunk(
  "get/speciality",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
        platform: 'web',
        locale: 1,
      },
    };
    const response = await getSpecialityAPI(params);
    return response.data;
  }
);

export const getServices = createAsyncThunk(
  "get/services",
  async (qs) => {
    // console.log(qs)
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
        platform: 'web',
        locale: 1,
      },
    };
    const response = await getServicesAPI(params, qs);
    return response.data;
  }
);

export const postUpdateProfile = createAsyncThunk(
  "post/updateProfile",
  async (payload) => {
    // console.log(payload, "payload")
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        // "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await postUpdateProfileAPI(payload, params);
    // console.log(response, "response")
    return response.data;
  }
);