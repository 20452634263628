import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFromLocalStorage } from "../../../utils/helperFunctions";

import { postPasswordUpdate as postPasswordUpdateAPI } from "./service";


export const postPasswordUpdate = createAsyncThunk(
  "update/password",
  async (payload) => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await postPasswordUpdateAPI(payload, params);
    return response.data;
  }
);