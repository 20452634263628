import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HeadingWithSpaceLarge } from "../../uiComponents/Headings";
import { Form, Input } from "antd";
import { SimpleButtonSmall } from "../../uiComponents/button";
import { postPasswordUpdate } from "./redux/thunk";
import { useAppDispatch, useAppSelector } from "./../../redux/hooks";
import { selectEmail } from "./redux/slice";
import "./ChangePassword.scss";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

function ChangePassword() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const updatePassword = (values) => {
    // console.log(values);
    const current_password = values.current_password;
    const password = values.password;
    const payload = {
      current_password,
      password,
    };

    if (values.confirm_pass !== password) {
      // console.log(values.confirm_pass, password);
      swal("Error!", "Your current password and new password do not match.", "error");
    }

    else {
      dispatch(postPasswordUpdate(payload))
        .then((val) => {
          if (val.error)
            swal("Error!", "Your Existing Password is incorrect", "error");

          else {
            swal("Success", "Your password has been updated successfully", "success")
              .then((value) => {
                history.push("/");
              });
          }
        });
    }

  };

  return (
    <div className="change_password cover_space">
      <Container>
        <Row>
          <Col lg={12}>
            <HeadingWithSpaceLarge text="CHANGE PASSWORD" />
          </Col>
        </Row>
        <Row>
          <Form layout="vertical" onFinish={updatePassword}>
            <Row>
              <Col md={4}>
                <Form.Item name="current_password" label="Existing Password*">
                  <Input type="text" className="c_input" required />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="password" label="New Password*">
                  <Input type="text" className="c_input" required />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="confirm_pass" label="Confirm Password">
                  <Input type="text" className="c_input" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <SimpleButtonSmall type="submit" text="SAVE CHANGES" />
            </Row>
          </Form>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(ChangePassword);
