import React from "react";
import { LinkContainer, LinkContainerSmall } from "../../uiComponents/button";
import i18n from "../../i18n";
import { SectionInnerHeading } from "../../uiComponents/Headings";
import addIcon from "../../assets/images/svg/add-icon-white.svg";
import { Container, Row, Col } from "react-bootstrap";

function NoClinics(props) {
  return (
    <div className="noClinics mt-5 pt-3">
      {/* <LinkContainer type="submit" text={i18n.t("login_capital")} to="/" /> */}
      {/* <LinkContainerSmall to={URL.instantConsultant} text="ADD NOW" > */}
      {/* <LinkContainerSmall text="ADD NOW" >
        <img src={rightArrow} alt="rightArrow" />
      </LinkContainerSmall> */}
      <Container>
        <Row>
          <Col lg={6}>
            <SectionInnerHeading text="No Hospitals or Clinics have been added yet!" />
          </Col>
        </Row>
        <Row className="btnRow">
          <Col lg={5}>
            {/* <LinkContainerSmall to={URL.doctorListing} text="ADD NOW"> */}
            <LinkContainerSmall text="ADD NOW" to={{ pathname: "/clinic-info", state: { clinic: null, menu: props?.menu } }}>
              <img src={addIcon} alt="addIcon" />
            </LinkContainerSmall>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NoClinics;
