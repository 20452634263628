import React, { useEffect, useState } from "react";
import "./ProfileSetup.scss";
import { Container, Row, Col } from "react-bootstrap";
import circle from "../../assets/images/png/circle.png";
import Sidebar from "../../uiComponents/ProfileSetup/Sidebar/Sidebar";
import StepOne from "../../uiComponents/ProfileSetup/Steps/StepOne";
import profileComp from "../../assets/images/png/profile-completion1.png";
import paginationIcon1 from "../../assets/images/png/deshicon011.png";
import paginationIcon2 from "../../assets/images/png/deshicon02.png";
import paginationIcon3 from "../../assets/images/png/deshicon03.png";
import paginationIcon4 from "../../assets/images/png/deshicon04.png";
import ProfileCompletionIcon from "../../assets/images/svg/ProfileCompletionIcon.svg";
import StepTwo from "../../uiComponents/ProfileSetup/Steps/StepTwo";
import StepThree from "../../uiComponents/ProfileSetup/Steps/StepThree";
import { isMobile } from "react-device-detect";
import MobileSteps from "../../uiComponents/ProfileSetup/MobileSteps";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getCities } from "../updateProfile/redux/thunk";
import { selectCities, selectCityLoader } from "../updateProfile/redux/slice";
import Loader from "../../uiComponents/loader/Loader";
import { Progress, Typography, Space } from "antd";
import API from "../../utils/customAxios";
import { Box, CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import { useEffectOnce } from "react-use";
import zIndex from "@mui/material/styles/zIndex";

function ProfileSetup(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [about, setAbout] = useState(true);
  const [qualification, setQualification] = useState(false);
  const [consultation, setConsultation] = useState(false);
  const [progress, setProgress] = useState("");
  const [percentage, setPercentage] = useState(null);
  const [listner, setListner] = useState(false);

  const cities = useAppSelector(selectCities);
  const citiesLoading = useAppSelector(selectCityLoader);

  const dispatch = useAppDispatch();

  const moveToQualification = () => {
    setAbout(false);
    setQualification(true);
  };

  const moveToConsultation = () => {
    setQualification(false);
    setConsultation(true);
  };
  const totalSteps = 3;

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const fetchProgress = async () => {
    try {
      const response = await API.get(`doctor/progress`);
      if (response?.data?.code === 200) {
        setProgress(response?.data?.data);
        setPercentage(response?.data?.data?.profile_completion);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchProgress();
  }, [listner]);

  //   const percentage = 20;

  useEffectOnce(() => {
    const cookeiGet = Cookies.get("pagestatus");
    const cookeiGetTwo = Cookies.get("pageStatus");

    if (cookeiGet === "qualifications") {
      setAbout(false);
      setQualification(true);
      setConsultation(false);
    } else if (cookeiGetTwo === "consultation") {
      setAbout(false);
      setQualification(false);
      setConsultation(true);
    }
  });
  return (
    <>
      {citiesLoading && <Loader />}
      <section
        className={`pt-120 progressbar ${qualification ? "progressbar50" : ""
          } ${consultation ? "progressbar75" : ""}`}
      >
        <Container fluid className="h-100">
          <Row className="h-100 z-index-relative">
            {!isMobile && (
              <>
                <Col md={3} lg={3} className="zeind">
                  <div className="complate_profile pt-40 pb-30">
                    {/* <div className="circle_box">
                                    <img src={circle} className="img-fluid"></img>
                                    <div className="number_complition">10%</div>



                                </div> */}
                    <div className="chart_style d-flex justify-content-start pb-3">
                      <div class="single-chart custom-progress">
                        <Box position="relative" display="inline-flex">
                          <CircularProgress
                            // style={{color:'#19B3B5'}}
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "100%",
                              boxShadow: "inset 0 0 0px 4px #19B3B5",
                              backgroundColor: "transparent",
                              color: "#19B3B5",
                            }}
                            size={80}
                            thickness={4}
                            value={percentage}
                            variant="determinate"
                          />
                          <Box
                            top={25}
                            left={20}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="block"
                            alignItems="center"
                            justifyContent="center"
                            className="progressBox"
                          >
                            <Typography
                              style={{ fontSize: "12px", color: "#000000" }}
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              {percentage} %
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                      <h6>Complete your profile</h6>
                    </div>
                  </div>
                  <div className="sidebar_profile">
                    <Sidebar progress={progress} />
                  </div>
                </Col>
              </>
            )}
            <Col md={9} lg={9} className={!isMobile ? "ps-0" : ""}>
              <div className="h-100">
                {!isMobile && (
                  <div className="top_pagination">
                    <ul>
                      <li>
                        <div className="box_image">
                          <img src={paginationIcon1} className="img-fluid" />
                        </div>
                        <h4>Sign up</h4>
                        <p>Create an account</p>
                      </li>
                      <li>
                        {/* <div className="box_image">
                                                    <img src={paginationIcon2} className="img-fluid" /> 
                                                </div> */}
                        <div className="box_prfile">
                          <div className="box_image progressUpperBar ">
                            <Box position="static" display="inline-flex">
                              <CircularProgress
                                sx={{
                                  background: "#CFECED",
                                  borderRadius: "100%",
                                  color: "#A9DBDC",
                                }}
                                size={60}
                                value={percentage}
                                variant="determinate"
                              />
                              <Box
                                top={14}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="block"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <img
                                  style={{ width: "18px", height: "20px" }}
                                  src={ProfileCompletionIcon}
                                />
                                <Typography
                                  style={{ fontSize: "12px", color: "#19B3B5" }}
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >
                                  {percentage}%
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <h4>Profile Completion</h4>
                        <p>Complete and submit your profile</p>
                      </li>
                      <li>
                        <div className="box_image">
                          <img src={paginationIcon3} className="img-fluid" />
                        </div>
                        <h4>Profile Verification</h4>
                        <p>Await verification from Meri Sehat</p>
                      </li>
                      <li>
                        <div className="box_image">
                          <img src={paginationIcon4} className="img-fluid" />
                        </div>
                        <h4>Go live</h4>
                        <p>Start earning</p>
                      </li>
                    </ul>
                  </div>
                )}

                {/* <StepOne /> */}
                {/* <StepThree /> */}

                {isMobile ? (
                  <MobileSteps
                    setListner={setListner}
                    moveToQualification={moveToQualification}
                    moveToConsultation={moveToConsultation}
                  />
                ) : (
                  <>
                    <div className="main_step_box pd-main">
                      {about && (
                        <StepOne
                          setListner={setListner}
                          moveToQualification={moveToQualification}
                          setAbout={setAbout}
                          setConsultation={setConsultation}
                          setQualification={setQualification}
                        />
                      )}
                      {qualification && (
                        <StepTwo
                          setListner={setListner}
                          setAbout={setAbout}
                          setConsultation={setConsultation}
                          setQualification={setQualification}
                          moveToConsultation={moveToConsultation}
                        />
                      )}
                      {consultation && (
                        <StepThree
                          setListner={setListner}
                          setConsultation={setConsultation}
                          setQualification={setQualification}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ProfileSetup;
