import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFromLocalStorage } from "../../../utils/helperFunctions";

import { postBankDetails as postBankDetailsAPI, getBankDetails as getBankDetailsAPI, getEarning as getEarningAPI } from "./service";

export const postBankDetails = createAsyncThunk(
  "bank/bankDetails",
  async (payload) => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await postBankDetailsAPI(payload, params);
    return response.data;
  }
);


export const getBankDetails = createAsyncThunk(
  "bank/bankDetails",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await getBankDetailsAPI(params);
    return response.data;
  }
);

export const getEarning = createAsyncThunk(
  "get/Earning",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await getEarningAPI(params);
    return response.data;
  }
);