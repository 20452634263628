import React from 'react'
import OnlineTime from './OnlineTime'

function ClinicTimings(props) {
  const prop = props?.location?.state
  let time = prop?.duration
  let timings = prop?.clinic_timing
  let type = prop?.type
  let menu = prop?.menu
  return (
    <div>
        <OnlineTime menu={menu} time={time} timings={timings} type={type}/>
    </div>
  )
}

export default ClinicTimings