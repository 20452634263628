import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import jazz from "../../assets/images/svg/jazz.svg";
import zong from "../../assets/images/png/zong_logo.png";
import ufone from "../../assets/images/png/ufone-new.png";
import telenor from "../../assets/images/png/telenor-new.png";
import styled from "styled-components";
import { FiChevronRight } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { Divider, Select } from "antd";
import circle1 from "../../assets/images/svg/circle1.svg";
import circle2 from "../../assets/images/svg/revisedCircle.svg";
import circle3 from "../../assets/images/svg/circle3.svg";
import logo from "../../assets/images/svg/englishUrduLogo.svg";
import "./SignupMobile.css";
import { useHistory } from "react-router-dom";
import encryptStorage from "../../utils/encryptStorage";
import API from "../../utils/customAxios";
import Loader from "../../uiComponents/loader/Loader";
import loginDoctors from "../../assets/images/png/loginDoctors.png";
import loginDoctorsMobile from "../../assets/images/png/sign-in-mobile.png";
import numberCheckLoader from "../../assets/images/gif/numberCheckLoader.gif";
import checkMark from "../../assets/images/png/check-mark.png";
import arrowShape from "../../assets/images/svg/ShapeArrow.svg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import $ from 'jquery';

const { Option } = Select;

const DoctorSignIn = () => {
  // const dispatch = useDispatch();
  const [instantPhoneNumber, setInstantPhoneNumber] = useState("");
  const [instantNetwork, setInstantNetwork] = useState("");
  const [instantPhoneError, setInstantPhoneError] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [numberCheckerLoader, setNumberCheckerLoader] = useState(false);
  const [whenNumberCompleted, setWhenNumberCompleted] = useState(false);
  const [newUserName, setNewUserName] = useState(true);
  const [networkError, setnetworkError] = useState();
  const [changed, setChanged] = useState(false);
  const [safari, setSafari] = useState();

  
  const history = useHistory();

  useEffect(() => {
    if (!instantPhoneNumber) {
      setChanged(false);
    }
  });

  useEffect(() => {
    if (!Cookies.get('token')) {
      Cookies.remove("pagestatus");
      Cookies.remove("pageStatus");
    }
  }, [])

  const isSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log({userAgent})
    return userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;
  }; 
  
  useEffect(() => {
    if(isSafari()){
      setSafari(true)
    }
    else{
      setSafari(false)
    }
  }, []);

  const navigateToOtp = async (e) => {
    e.preventDefault();
    setInstantPhoneError("");

    if (instantPhoneNumber.trim() === "") {
      setInstantPhoneError("Please enter a valid phone number");
    } else if (instantNetwork === "") {
      setInstantPhoneError("Please select your network");
    } else if (instantPhoneNumber.trim().length < 10) {
      setInstantPhoneError("Incomplete mobile number");
    } else {
      let phone = `0${instantPhoneNumber}`;
      let network = instantNetwork;

      encryptStorage.setItem("phoneLogin", phone);
      encryptStorage.setItem("networkLogin", network);

      let data = {
        phone,
        network,
      };

      try {
        setApiLoading(true);
        const response = await API.post("/doctor/login-via-phone", data);

        if (response?.data?.code === 200) {
          setApiLoading(false);
          history.push("/doctor-Otp");
        } else {
          setApiLoading(false);
          setApiError(
            response?.data?.message || "Error! Something went wrong..."
          );
        }
      } catch (error) {
        setApiLoading(false);
        setApiError(error?.response?.data?.message || error?.message);
      }
    }
  };

  const instantPhoneChange = (e) => {
    const limit = 10;
    setApiError(null)
    setInstantPhoneNumber(e.target.value.slice(0, limit));
    if (e.target.value?.length < 10) {
      setNumberCheckerLoader(false);
      setInstantPhoneError("Incomplete mobile number");
      setNewUserName(true);
      setChanged(false);
      setWhenNumberCompleted(false);
    }
    if (e.target.value?.length < 2) {
      setnetworkError(false);
    } else {
      setInstantPhoneError("");
      setChanged(false);
    }
    if (e.target.value?.length === 10) {
      numberVerify(e);
    }

    if (e.target.value.includes(".")) {
      setInstantPhoneError(
        "Please enter a valid phone number without decimal points."
      );
    }

    setInstantNetwork("");

    if (e.target.value.startsWith("33") === true) {
      setInstantNetwork("ufone");
      setnetworkError(true);
    }
    if (e.target.value.startsWith("31") === true) {
      setInstantNetwork("zong");
      setnetworkError(true);
    }
    if (e.target.value.startsWith("30") === true) {
      setInstantNetwork("jazz");
      setnetworkError(true);

    }
    if (e.target.value.startsWith("34") === true) {
      setInstantNetwork("telenor");
      setnetworkError(true);
    }
    if (e.target.value.startsWith("32") === true) {
      setInstantNetwork("jazz");
      setnetworkError(true);
    }
    if (e.target.value?.length == 0) {
      setnetworkError(true);
    }
  };

  const numberVerify = async (e) => {
    try {
      const number = `0${e.target.value}`;
      const data = {
        phone: number,
      };
      if (data) {
        setNumberCheckerLoader(true);
        setChanged(false);
        const response = await API.post(`/verify-mobile`, data);
        if (response?.data?.data === true) {
          setNumberCheckerLoader(false);
          setWhenNumberCompleted(true);
          setNewUserName(true);
          setChanged(true);
        } else {
          setNumberCheckerLoader(true);
          setWhenNumberCompleted(false);
          setInstantPhoneError(
            "User Not Found"
          );
          setNewUserName(false);
          setTimeout(() => {
            setNumberCheckerLoader(false);
          }, 5000);
        }
      }
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };


  useEffect(() => {
    // Disable Mouse scrolling
    $('input[type=number]').on('mousewheel', function (e) {
      $(this).blur();
    });

    // Disable keyboard scrolling
    $('input[type=number]').on('keydown', function (e) {
      var key = e.charCode || e.keyCode;
      // Disable Up and Down Arrows on Keyboard
      if (key === 38 || key === 40) {
        e.preventDefault();
      } else {
        return;
      }
    });
  }, []);


 
  return (
    <>
      <StyledContinueNumber className="bgWhiteMobile">
        <div className="header_meriSehat ">
          <Container fluid>
            {apiLoading && <Loader />}

            <Row>
              <Col md={12}>
                <div className="logo_only">
                  {/* <Link to="/"> */}
                  <img src={logo} className="logoEU" alt="Logo" />
                  {/* </Link> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className="align-items-md-center align-items-end singup-mobile-col hk_doctor_login">
            <Col md={5}>
              <Col md={12} className="d-none d-md-block">
                <div>
                  <div className="continue-heading">
                    <h4>Join Pakistan’s largest healthcare platform today</h4>
                    <Divider
                      style={{
                        backgroundColor: "#313131",
                        height: "1px",
                        width: "100%",
                        minWidth: "40%",
                        margin: "8px 0 20px",
                      }}
                      type="horizontal"
                    />
                    <p className="d-none d-md-block">
                      Our Artificial intelligence technology helps you connect
                      with patients across the nation.
                    </p>
                  </div>
                  <div className="description-circle-group">
                    <div className="d-flex mb-3">
                      <img className="img-fluid" src={circle1} alt="1" />
                      <p className="ms-3">
                        Join a community of PMC certified doctors{" "}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <img className="img-fluid" src={circle2} alt="2" />
                      <p className="ms-3">
                        Zero commissions on all appointment bookings*
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <img className="img-fluid" src={circle3} alt="3" />
                      <p className="ms-3">
                        Access to enterprise-level medical software to help your
                        practice grow
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <div className="image-below">
                <img
                  className="img-fluid d-none d-md-block"
                  src={loginDoctors}
                />
                <img className="img-fluid d-md-none" src={loginDoctorsMobile} />
              </div>
            </Col>
            <Col md={2}></Col>
            <Col md={5} className="marginTopEightRem">
              <div className="continue-number-box instant_login_hk">
                <div className="p-4">
                  <div className="d-block text-center">
                    <h3 className=" enter-mobile">
                      Sign in with your Mobile Number
                    </h3>
                    <p className="d-none d-md-block">
                      Enter your mobile number to recieve a one time code.
                    </p>
                  </div>
                  <div className="form_controll form-drop form-Econtrol">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div className="label_and_loader_area">
                        {numberCheckerLoader ? (
                          <>
                            <img src={numberCheckLoader} alt="loader" />
                          </>
                        ) : null}
                        {whenNumberCompleted ? (
                          <>
                            <img
                              src={checkMark}
                              alt="CheckMark"
                              style={{ height: "18px" }}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                    <form noValidate onSubmit={navigateToOtp} className="">
                      <div
                        style={{ display: "flex" }}
                        className={
                          instantPhoneError
                            ? !newUserName
                              ? "hk_number ek_number"
                              : "hk_number ek_number errorBorder"
                            : "hk_number ek_number"
                        }
                      >
                        <div
                          className={
                            instantPhoneError
                              ? !newUserName
                                ? "country_code_hk country_code_ek"
                                : "country_code_hk country_code_ek errorBorderRight"
                              : "country_code_hk country_code_ek"
                          }
                        >
                          {/* <Select
                            defaultValue="+92"
                            className="select-code select-code-ek"
                            // disabled="{isDropdownDisabled}"
                            disabled
                            suffixIcon={
                              <img src={arrowShape} alt="arrow down" />
                            }
                          >
                            <Option value="+92">+92</Option>
                          </Select> */}
                          <div className="number__code">  
                            <input type="number" placeholder="+92" readOnly  /> 
                            
                          </div>
                        </div>

                        <input
                          className="input-number input-number-ek"
                          placeholder="Enter your mobile number"
                          type="number"
                          pattern="[0-9]+"
                          maxlength="10"
                          name="number"
                          value={instantPhoneNumber}
                          onChange={instantPhoneChange}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "."].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          style={{ width: "100%" }}
                        />
                        {instantNetwork ? (
                          <>
                            <Select
                              value={instantNetwork}
                              className="select-country hk_network ek_network"
                              suffixIcon={
                                <img
                                  style={{ paddingRight: "0.8rem" }}
                                  src={arrowShape}
                                  alt="arrow down"
                                />
                              }
                              onChange={(value) => setInstantNetwork(value)}
                              style={{
                                width: 120,
                                border: "none",
                              }}
                            >
                              <Option
                                className="network-height ufone"
                                value="ufone"
                              >
                                {" "}
                                <img src={ufone} alt="ufone" /> <span></span>
                              </Option>
                              <Option
                                className="network-height jazz"
                                value="jazz"
                              >
                                {" "}
                                <img src={jazz} alt="jazz" /> <span></span>
                              </Option>
                              <Option
                                className="network-height telenor"
                                value="telenor"
                              >
                                {" "}
                                <img src={telenor} alt="telenor" />{" "}
                                <span></span>
                              </Option>
                              <Option
                                className="network-height zong"
                                value="zong"
                              >
                                {" "}
                                <img src={zong} alt="zong" /> <span></span>
                              </Option>
                            </Select>
                          </>
                        ) : null}
                      </div>
                      {apiError ? (
                        <>
                          <p className="instant-error-msg"> {apiError} </p>
                        </>
                      ) : ''}
                      {networkError === false ? (
                        <>
                          <p className="instant-error-msg"> Invalid Network </p>
                        </>
                      ) : (
                        <>
                          {networkError && instantPhoneError && (
                            <p
                              className={
                                !newUserName
                                  ? `${safari ? "userNotFoundError instant-error-msg" : "userNotFoundErrorTwo instant-error-msg"} `
                                  : "instant-error-msg"
                              }
                            >
                              {" "}
                              {instantPhoneError}{" "}
                            </p>
                          )}
                        </>
                      )}

                      {/* {error && <p className="instant-error-msg"> {error} </p>} */}
                      <p className="d-md-none fs-14 mt-2">
                        Enter your mobile number to recieve a one time code.
                      </p>

                      <div className="btn-cont p-0">
                        {!changed || (changed && !newUserName) ? (
                          <>
                            <button
                              type="submit"
                              className="review-button mt-4 text-uppercase instant-btn position-relative statenotchanged new_bg_color"
                              disabled={!changed || (changed && !newUserName)}
                            >
                              <span
                                className="leftZeroMobile"
                                style={{ position: "relative", left: "-15px" }}
                              >
                                SEND CODE
                              </span>
                              <span
                                className="instant-chevron instant-code-chev d-none d-md-flex"
                                style={{ height: "53px" }}
                              >
                                <FiChevronRight />
                              </span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="review-button mt-4 text-uppercase instant-btn position-relative statechanged"
                            >
                              <span
                                className="leftZeroMobile"
                                style={{ position: "relative", left: "-15px" }}
                              >
                                SEND CODE
                              </span>
                              <span
                                className="instant-chevron instant-code-chev d-none d-md-flex"
                                style={{ height: "53px" }}
                              >
                                <FiChevronRight />
                              </span>
                            </button>
                          </>
                        )}
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            marginTop: "12px",
                            marginBottom: "0px",
                            display:'flex',
                            justifyContent:'center'
                          }}
                          className="mt-3 ff-circular fw-300"
                        >
                          Don’t have an account?{" "}
                          <Link
                            to="/signup-number"
                           
                          >
                           <p
                             style={{
                              color: "#19B3B5",
                              fontWeight: "500",
                              wordSpacing:'-4px',
                              margin:'0 3px'

                            }}
                           >Sign up</p> 
                          </Link>{" "}
                          here.
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledContinueNumber>
    </>
  );
};

export const StyledContinueNumber = styled.section`
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .enter-mobile {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 45px;
    /* identical to box height, or 150% */
    text-align: center;

    color: #19b3b5;
  }
  .continue-number-box {
    background-color: #fff;
    border-radius: 12px;
  }
  @media only screen and (max-width: 767px) {
    .continue-number-box {
      background: #c9e9ea;
      box-shadow: 0px 3px 23px rgba(0, 0, 0, 0.102);
      border-radius: 20px 20px 0px 0px;
    }
  }
  .continue-heading {
    padding: 0 0px;
    margin-top: 0px;

    h4 {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 45px;
      /* or 125% */

      letter-spacing: 0.01em;

      color: #313131;
      width: 480px;
    }

    p {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 25px;

      color: #404040;
    }
  }

  .description-circle-group {
    margin-left: 0;
    margin-top: 25px;

    p {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 450;
      font-size: 18px;
      line-height: 23px;
      color: #313131;
      display: flex;
      align-items: center;
    }
  }

  .line-divider {
    display: flex;
    justify-content: center;
    padding: 15px 4px 0 4px;

    span {
      margin-top: 10px;
      padding: 0 4px;
    }
  }

  .continue-phone-btn {
    border-radius: 12px;
    background-color: #19b3b5;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53px;
    padding: 0;
    width: 100%;

    .continue-phone-chevron {
      float: right;
      background-color: #078a8e !important;
      font-size: 16px;
      height: 44px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 10px 10px 0px;
      position: absolute;
      right: 0;
      left: auto;
    }
  }

  .boxLineCut {
    border: 0.3px solid #313131;
    height: auto;
    width: 80%;
    margin: auto;
    padding-bottom: 1rem;

    .btn-apple12 {
      width: 100%;
      cursor: pointer;
    }
  }

  .boxLineCut h1 {
    width: 75%;
    margin-top: -15px;
    margin-left: auto;
    margin-right: auto;
    background: #e1f3f4;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }

  .image-below {
    position: absolute;
    left: 137px;
    bottom: 0;
  }

  .doctor-error-msg {
    margin-top: 6px;
    margin-left: 6px;
    background-color: #fee6e6;
    padding: 3px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    color: #b11b1b;
    .bsX {
      cursor: pointer;
      margin-top: 2px;
      font-size: 20px;
    }
  }
`;

export default DoctorSignIn;
