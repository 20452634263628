import React, { useState, useEffect } from "react";
import { Input, Select } from "antd";
import { Col, Row } from "react-bootstrap";
import { HiOutlineArrowDown } from "react-icons/hi";
import { SimpleButtonSmall } from "../button";
import arrowdropdown from "../../assets/images/svg/dropdown-icon.svg";
import swal from "sweetalert";
import API from "../../utils/customAxios";
import { getUserId } from "../../helpers/authHelper";

function LabForm(props) {
  const {setAddLabLoader} = props;

  const [test, setTest] = useState({});
  const [test2, setTest2] = useState({});
  const [selected, setSelected] = useState();
  const [selected2, setSelected2] = useState();
  const [description, setDescription] = useState();
  const { Option } = Select;
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [labtestType, setLabtestType] = useState([]);
  const [TypeError, setTypeError] = useState('');
  const [TestError, setTestError] = useState('');

  console.log({labtestType});

  const [showAddBtn, setShowAddBtn] = useState(false);

  useEffect(() => {
    if(selected) {
      const filteredArray = props.labs.data.filter((item) => item?.id === selected);
      const newArray = [];

      filteredArray.forEach((obj) => {
        const modifiedData = obj?.category;
        newArray.push(modifiedData);
      });

      setLabtestType(newArray);
    }
    

  }, [selected, JSON.stringify(props.labs)])
  

  const handleChange = (e, type, name) => {

    const filteredArray = props.labs.data.filter((item) => item?.id === e);

    console.log({filteredArray})

    const newArray = [];

    filteredArray.forEach((obj) => {
      const modifiedData = obj?.category;
      newArray.push(modifiedData);
    });


    setLabtestType(newArray);

    // if (type === "select") {
    //   if (!selectedOptions.includes(e)) {
    //     setTest({ ...test, [name]: e });
    //     setSelectedOptions([...selectedOptions, e]);
    //   }
    // }
    if (test[name] !== e) {
      setTest({ ...test, [name]: e });
    }
    setSelected(e);
  };

  const handleChange2 = (e, type, name) => {
    setSelected2(e);
    setTest({ ...test, description: e });
  };
  const complete = () => {
     if (!selected || !selected2) {
    setTestError(selected2 ? "" : "Required");
    setTypeError(selected ? "" : "Required");
    setErrorMsg("");
    return;
  }


  else {
 setTestError("");

  const categoryName = labtestType?.[0]?.filter((item) => item.id === selected2)

 if (props?.prescribeData.some((item) => item.description === categoryName?.[0]?.name)) {
  swal("Error!", "You can't add the same lab test twice", "error");
  return;
}
    setTypeError("");
    setErrorMsg("");
    setTest({ ...test, description: selected2 });
    setSelected(null);
    setSelected2(null);
    setDescription(null);
    // setSelected2("")
    
    props.complete(test);
  }

  };

  function handleLabSearch(value) {
    const filteredOptions = labtestType?.filter((item) => {
      return item?.name?.toLowerCase()?.startsWith(value?.toLowerCase());
    })

    if (filteredOptions.length === 0) {
      setShowAddBtn(true);
    }

    else {
      setShowAddBtn(false);
    }
  }

  

  async function addCustomLab(e) {

    if (showAddBtn && e.target.value) {
      const data = {
        name: e.target.value,
        prescription_element_type_id: 2,
        prescription_element_id: selected,
        doctor_id: getUserId()
      };
      try {
        setAddLabLoader(true);
        const response = await API.post("/prescription-elements", data);
        if (response?.data?.code === 200) {
          // console.log(medicineRef?.current?.value, "medRef")
          // medicineRef.current?.blur();
          props?.setShouldDispatchLabs(true);
          setAddLabLoader(false);
          setSelected2(e.target.value);
          // handleChange2(e.target.value, "select", "description")
          setShowAddBtn(false);
          // props?.setShouldDispatchLabs(false);
        }

        else {
          setAddLabLoader(false);
        }

      } catch (error) {
        setAddLabLoader(false);
      }
    }
  }


  return (
    <>
      <Row>
        <Col md={4} className="lab_mobile_title pt-0">
          <p className="labelText fs-14">Lab Test*</p>
          <Select
            dropdownAlign={{ offset: [0, 4] }}
            suffixIcon={<img src={arrowdropdown} alt="dropdown icon" />}
            className="c_select"
            onChange={(e) => handleChange(e, "select", "lab_test")}
            value={selected}
            showSearch
            placeholder="Select Type"
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.startsWith(input?.toLowerCase());
            }}
          >
            {props.labs.data?.map((lab, index) => (
              <Option
                value={lab.id}
                disabled={selectedOptions.includes(lab.id) }
                key={index}
              >
                {lab.name}
              </Option>
            ))}
          </Select>
          {TypeError && (
         <div className="fs-16 position-absolute text-red">{TypeError}</div>

          )}
        </Col>

        <Col md={5} className="labMobile_form">
          <p className="labelText">&nbsp;</p>
          <Select
            dropdownAlign={{ offset: [0, 4] }}
            suffixIcon={
              showAddBtn ? (
                <button className="add-custom-med">Add</button>
              ) : (
                <img src={arrowdropdown} alt />
              )
            }
            className="c_select"
            onChange={(e) => handleChange2(e, "select", "description")}
            value={selected2}
            showSearch
            onSearch={handleLabSearch}
            onClick={(e) => addCustomLab(e)}
            placeholder="Select test"
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.startsWith(input?.toLowerCase());
            }}
            
          >
            {labtestType.length > 0 &&
              labtestType?.[0]?.length > 0 &&
              labtestType?.[0]?.map((lab, index) => (
                <Option
                  value={lab?.id}
                  disabled={selectedOptions.includes(lab?.id) }
                  key={index}
                >
                  {lab?.name}
                </Option>
              ))}
          </Select>
            {TestError && (
             <div className="fs-16 position-absolute text-red">{TestError}</div>
          )}
          {/* <Input onChange={(e) => handleDescriptionChange(e)} value={description} type="text" className="form-control customfeildAddInput" placeholder="Enter test name" style={{ boxShadow: 'none' }} /> */}
        </Col>
        <Col md={3}>
          <div className="flex_end d-block">
            <SimpleButtonSmall
              type="button"
              onClick={complete}
              text="Add Lab Test"
            />
          </div>
        </Col>
      </Row>

      {errorMsg && <div className="mt-3 mb-3 fs-16 text-red">{errorMsg}</div>}
    </>
  );
}

export default React.memo(LabForm);
