import React, { useEffect, useState } from "react";
import NoClinics from "./NoClinics";
import Clinics from "./Clinics";
import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import "./_healthClinic.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectClinicData } from "./redux/slice";
import { getClinicData } from "./redux/thunk";
import { getFromLocalStorage } from "../../utils/helperFunctions";
import loadingGif from "../../assets/images/gif/loader_gif.gif";
import { getUserId } from "../../helpers/authHelper";

function HealthClinic(props) {

  const [hospitals, setHospitals] = useState([])
  const [loading, setLoading] = useState(true)
  const [online, setOnline] = useState(true)

  const dispatch = useAppDispatch();
  const clinicData = useAppSelector(selectClinicData);

  // const id = getFromLocalStorage("D_USER_ID");

  const id = getUserId();

  useEffect(() => {
    dispatch(getClinicData(id));
  }, []);

  useEffect(() => {
    setHospitals(clinicData?.data)
  }, [clinicData]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [hospitals])

  let menuList = [
    {
      link: "/health-clinics",
      name: "Hospital/Clinics",
      props: 'physical',
    },
    {
      link: "/clinic-timing",
      name: "Online Time Slots",
      props: 'online'
    },
  ];

  hospitals?.map((hospital)=> {
    if (hospital?.clinic?.id === 0) {
      menuList.splice(1,1)
    }
  })
  
  return (

    <div className="health_wrapper cover_space2">
      <StickyTab menuList={menuList} />
      <div className="container cover_space3">
        {loading ? (
          <div className="loaderWrapper container">
        <img src={loadingGif} alt="" />
      </div>
        ) : (hospitals ? (
          <div>
            <Clinics menu={menuList} type={props?.location?.state?.type} hospital={hospitals} />
          </div>
        ) : (
          <div>
            <NoClinics menu={menuList} />
          </div>))
        }
      </div>
    </div>
  );
}

export default HealthClinic;
