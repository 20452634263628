import React, { useEffect, useState, useRef } from "react";
// import Slider from "react-slick";
import { Row, Col, Container, Modal } from "react-bootstrap";
import {
    HeadingDesc,
    HeadingDescSmall,
    HeadingDescVsmall,
} from "../../uiComponents/Headings";
import { DatePicker, TimePicker } from "antd";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";
import API from "../../utils/customAxios";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from "moment";
import { FiChevronRight } from "react-icons/fi";
import { generateDobArray, generateDobYears, isAlphabetsOnly, isDigits } from "../../utils/helperFunctions";

import patientIcon from "../../assets/images/png/patient_icon.png";
import circleCheck from "../../assets/images/png/circle-check.png";
import appointmentTypeIcon from "../../assets/images/png/appointment-type_icon.png";
import dateIcon from "../../assets/images/png/date_icon.png";
import timeIcon from "../../assets/images/png/time_icon.png";
import attachmentIcon from "../../assets/images/png/attachment-icon.png";
import whatsappIcon from "../../assets/images/png/whatsapp_icon.png";
import mailIcon from "../../assets/images/png/mail_icon.png";
import { EmailShareButton, WhatsappShareButton } from "react-share";

function AppointmentModal({ setShowAppointmentModal, showAppointmentModal, showAppointmentSuccessModal, setShowAppointmentSuccessModal, currentRecord }) {

    const [nameValue, setNameValue] = useState('');
    const [genderValue, setGenderValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [networkValue, setNetworkValue] = useState('zong');
    const [dayValue, setDayValue] = useState('');
    const [monthValue, setMonthValue] = useState('');
    const [yearValue, setYearValue] = useState('');
    const [weightValue, setWeightValue] = useState('30');
    const [heightValue, setHeightValue] = useState('20');
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [heightError, setHeightError] = useState('');
    const [weightError, setWeightError] = useState('');
    const [dateOfBirthError, setDateOfBirthError] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [show1, setShow1] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [externalLink, setExternalLink] = useState('');
    const [cancel, setCancel] = useState(false);
    const [dateError, setDateError] = useState('');
    const [timeError, setTimeError] = useState('');
    const [selectedTime, setSelectedTime] = useState();
    const [selectedCreatedAppointment, setSelectedCreatedAppointment] = useState();
    const [availableSlots, setAvailableSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [showStartLoader, setShowStartLoader] = useState(false);
    const [todayDate, setDate] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [arrived, setArrived] = useState(false);
    const [appointmentSuccessData, setAppointmentSuccessData] = useState({
        name: '',
        type: '',
        date: '',
        time: ''
    });
    useEffect(() => {
        validateForm();
    }, [nameValue, phoneValue, genderValue, dayValue, monthValue, yearValue, selectedDate, selectedTime, loadingSlots]);
    const handleCloseCreateAppointment = () => {
        setShowAppointmentModal(false);
        setPhoneValue('')
        setNameError('')
        setGenderError('')
        setPhoneError('')
        setDateError('')
        setTimeError('')
        setSelectedTime('')
        setSelectedDate('')
        setSelectedCreatedAppointment('')
    }
    function validateForm() {
        const isValid =
            nameValue &&
            phoneValue &&
            genderValue &&
            dayValue &&
            monthValue &&
            yearValue &&
            selectedDate &&
            selectedTime &&
            !loadingSlots;

        setIsFormValid(isValid);
    }
    useEffect(() => {
        if (currentRecord) {
            setNameValue(currentRecord?.patients);
            setPhoneValue(currentRecord?.phone);
            setGenderValue(currentRecord?.gender);
            setDayValue(currentRecord?.dob.slice(8, 10));
            setMonthValue(currentRecord?.dob.slice(5, 7));
            setYearValue(currentRecord?.dob.slice(0, 4));
        }
    }, [currentRecord]);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
    const todayDateAppointment = new Date().getDate();
    async function createAppointmentHandler(e) {
        e.preventDefault();

        setNameError('')
        setPhoneError('');
        setGenderError('');
        setDateOfBirthError('');
        setHeightError('');
        setWeightError('');
        setGeneralError('');

        // const {name, phone, day, month, year, gender, network, height, weight} = createAppointmentData;

        let name = nameValue;
        let phone = phoneValue;
        let day = dayValue;
        let month = monthValue;
        let year = yearValue;
        let gender = genderValue;
        let network = networkValue;
        let height = heightValue;
        let weight = weightValue;
        let doctor_slot = selectedCreatedAppointment;
        let time = moment(selectedTime, 'hh:mm A').format('HH:mm');
        let date = selectedDate;
        let currDate = moment().format().substring(0, 10);

        let dateSplit = currDate.split("-");

        if (!name) {
            setNameError('Name is Required')
        }

        else if (!isAlphabetsOnly(name)) {
            setNameError("Name is invalid");
        }

        else if (phone.trim().length <= 0) {
            setPhoneError('Phone is required');
        }

        else if (!isDigits(phone) || phone.trim().length !== 11) {
            setPhoneError("Phone is invalid");
        }

        else if (!day || !month || !year) {
            setDateOfBirthError("Date of Birth is invalid");
        }

        else if (!gender) {
            setGenderError("Gender is required");
        }

        else if (!height) {
            setHeightError("Height is required");
        }

        else if (!weight) {
            setWeightError("Weight is requied");
        }

        else if (!date) {
            setDateError("date is requied");
        }
        else if (!time) {
            setTimeError("time is requied");
        }

        else if (year > currentYear ||
            (year === currentYear && month > currentMonth) ||
            (year === currentYear && month === currentMonth && day > todayDate)) {
            setDateOfBirthError("Date is invalid");
        }
        else {
            let payload = {
                name,
                phone,
                network,
                date_of_birth: `${day}-${month}-${year}`,
                gender,
                height,
                weight,
                doctor_slot,
                date,
                time
            };

            try {
                const response = await API.post("/doctor/create-instant-appointment", payload);

                if (response?.data?.code === 200) {
                    const siteUrl = process.env.REACT_APP_DYNAMIC_APPOINTMENT;
                    const appointmentId = response?.data?.data?.appoitment?.id;
                    const token = response?.data?.data?.hash;

                    setExternalLink(`${siteUrl}/dynamic-appointment/${appointmentId}?token=${token}`);

                    let type;

                    if (response?.data?.data?.appoitment?.type === 'instant-consultation') {
                        type = "Instant Consultation"
                    }

                    setAppointmentSuccessData({
                        name: response?.data?.data?.appoitment?.patient_name,
                        type,
                        date: response?.data?.data?.appoitment?.date,
                        time: response?.data?.data?.appoitment?.time
                    })

                    setShowAppointmentModal(false);
                    setShowAppointmentSuccessModal(true);
                }

                else {
                    toast.error(response?.data?.message);
                }

            } catch (error) {
                console.error(error);
                setGeneralError(error?.response?.data?.message || error?.message);
                toast.error(error?.response?.data?.message || error?.message);
            }
        }


    }
    const createAppointmentDate = async (date) => {
        setSelectedDate(date);
    };
    const handleCloseAppointmentSuccess = () => {
        setShowAppointmentSuccessModal(false);
        setPhoneValue('')
        setSelectedTime('')
        setSelectedDate('')
        setSelectedCreatedAppointment('')
        setAvailableSlots(null)
    }
    function nameHandler(e) {
        setNameValue(e.target.value);
    }

    function phoneHandler(e) {
        const inputValue = e.target.value.slice(0, 11); // Limit input to 11 characters
        setPhoneValue(inputValue);
        if (/^\d{0,11}$/.test(inputValue)) {
            console.log(phoneValue, "setPhoneValue")
            setPhoneError('');
        } else {
            setPhoneError('Phone must be exactly 11 digits');
        }
    }

    useEffect(() => {
        if (selectedDate) {
            const fetchAvailableSlots = async () => {
                setLoadingSlots(true);
                try {
                    const response = await API.get(`/doctor-slots?date=${moment(selectedDate).format('DD-MM-YYYY')}`);
                    if (response?.status === 200) {
                        const slots = response?.data?.data;
                        setAvailableSlots(slots);
                        if (slots.length > 0) {
                            const firstSlot = slots[0];
                            setSelectedTime(firstSlot.start_time);
                            setSelectedCreatedAppointment(firstSlot.id);
                        }
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoadingSlots(false);
                }
            };

            fetchAvailableSlots();
        }
    }, [selectedDate]);
    async function copyTextToClipboard(e, text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleOkayClose = () => setCancel(false);
    const handleArrived = () => setArrived(false);

    function submitCancelFeedback() {
        setCancel(false);
    }
    return (
        <>
            {/* Create Appointment Modal  */}

            <Modal
                className="ToGetCenter create-appointment-modal"
                show={showAppointmentModal}
                onHide={handleCloseCreateAppointment}
                onBackdropClick={() => { }}
            >
                <Modal.Header
                    className="border-bottom-0"
                    closeButton
                ></Modal.Header>
                <Modal.Body className="px-4 pt-0">
                    <h4 className="create-appointment-heading">
                        Create Appointment
                    </h4>

                    <p className="create-appointment-text">
                        Please enter the patient details.
                    </p>

                    <form onSubmit={createAppointmentHandler}>
                        <div className="form-area mt-4">
                            <div className="form-group">
                                <label htmlFor="phone">Phone Number*</label>
                                <input type="number" className="form-control" disabled={currentRecord && currentRecord} placeholder="Enter mobile number" value={phoneValue} name="phone" pattern="[0-9]{11}" maxLength={11} onChange={phoneHandler} />
                                {phoneError && (
                                    <p className="text-danger"> {phoneError} </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Name*</label>
                                <input type="text" className="form-control" disabled={currentRecord && currentRecord} maxLength={30} value={nameValue} placeholder="Enter full name" name="name" onChange={(e) => nameHandler(e)} />
                                {nameError && (
                                    <p className="text-danger"> {nameError} </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="gender">Gender*</label>
                                <select type="text" className="form-control selectIcon" disabled={currentRecord && currentRecord} value={genderValue} placeholder="Select Gender" onChange={(e) => {
                                    setGenderValue(e.target.value)
                                }} >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>

                                {genderError && (
                                    <p className="text-danger"> {genderError} </p>
                                )}
                            </div>

                            <div className="form-group">
                                <label htmlFor="dob">Date Of Birth*</label>
                                <div className="d-flex justify-content-between gap15">
                                    <select disabled={currentRecord && currentRecord} type="text" className="form-control selectIcon" value={dayValue} placeholder="Date" onChange={(e) => {
                                        setDayValue(e.target.value)
                                    }} >
                                        {generateDobArray("day").map((item, index) => (
                                            <option key={index} value={item}> {item} </option>
                                        ))}
                                    </select>

                                    <select disabled={currentRecord && currentRecord} type="text" className="form-control selectIcon" placeholder="Month" value={monthValue} onChange={(e) => {
                                        setMonthValue(e.target.value)
                                    }}>
                                        {generateDobArray("month").map((item, index) => (
                                            <option key={index} value={item}> {item} </option>
                                        ))}
                                    </select>

                                    <select disabled={currentRecord && currentRecord} type="text" value={yearValue} className="form-control selectIcon" placeholder="Year" onChange={(e) => {
                                        setYearValue(e.target.value)
                                    }}>
                                        {generateDobYears().map((item, index) => (
                                            <option key={index} value={item}> {item} </option>
                                        ))}
                                    </select>
                                </div>
                                {dateOfBirthError && (
                                    <p className="text-danger"> {dateOfBirthError} </p>
                                )}
                            </div>
                            <div className="form-group datePickerModal">
                                <Row>
                                    <Col lg="6">
                                        <label htmlFor="dob">Date </label>
                                        <DatePicker
                                            selected={selectedDate}
                                            className="form-control"
                                            onChange={createAppointmentDate}
                                            disabledDate={(current) => {
                                                return moment().add(-1, 'days') >= current
                                            }}
                                        />
                                        {dateError && (
                                            <p className="text-danger"> {dateError} </p>
                                        )}
                                    </Col>
                                    <Col lg="6" className="timePicker">
                                        <label htmlFor="dob">Time* </label>
                                        <select type="text" className={`form-control selectIcon ${selectedTime == '' ? 'placeholderr' : 'actualColor'}`} placeholder="Select time"
                                            onChange={(e) => {
                                                const selectedValue = JSON.parse(e.target.value);
                                                setSelectedTime(selectedValue.start_time);
                                                setSelectedCreatedAppointment(selectedValue.id);
                                            }}
                                        >
                                            {/* <option  value="" disabled>Select time</option> */}
                                            {availableSlots?.map((item) => (
                                                <option key={item.id} value={JSON.stringify({ start_time: item.start_time, id: item.id })}>{item?.start_time}</option>
                                            ))}
                                        </select>
                                        {timeError && (
                                            <p className="text-danger timeErrrorPosition"> {timeError} </p>
                                        )}
                                    </Col>
                                </Row>

                            </div>

                        </div>

                        <div className="text-center d-flex justify-content-center mt-4 mb-2">
                            <button
                                style={{ height: '45px', minWidth: '90%' }}
                                // onClick={(e) => writePrescription(e)}
                                className="submit-btn-completed add-record-btn text-uppercase w-100"
                                type="submit"
                            >
                                Create
                                <span className="add-record-chevron">
                                    <FiChevronRight />
                                </span>
                            </button>
                        </div>

                    </form>
                </Modal.Body>
                {/* <Modal.Footer className="border-top-0 row flex-md-nowrap justify-content-center pb-4">
            
            </Modal.Footer> */}
            </Modal>

            {/* Create Appointment Success  */}
            {/* Create Appointment Success  */}

            <Modal
                className="ToGetCenter create-appointment-success"
                show={showAppointmentSuccessModal}
                onHide={handleCloseAppointmentSuccess}
                onBackdropClick={() => { }}
            >
                <Modal.Header
                    className="border-bottom-0"
                    closeButton
                ></Modal.Header>
                <Modal.Body className="px-4 pt-0">
                    <div className="appointment-centered-header text-center">
                        <img src={circleCheck} alt="" />
                        <h4> Appointment Created</h4>
                        <p>Your appointment has been created successfully</p>
                    </div>

                    <div className="appointment-receipt-success mt-4 py-3 px-2">
                        <div className="appointment-receipt-row">
                            <div className="d-flex align-center">
                                <img src={patientIcon} className="mb-0 img-fluid" alt="icon" />
                                <h5 className="ml-2"> Patient</h5>
                            </div>
                            {appointmentSuccessData.name && (
                                <h6>{appointmentSuccessData.name}</h6>
                            )}

                        </div>

                        <div className="appointment-receipt-row">
                            <div className="d-flex align-center">
                                <img src={appointmentTypeIcon} className="mb-0 img-fluid" alt="icon" />
                                <h5 className="ml-2"> Appointment Type</h5>
                            </div>

                            <h6>{appointmentSuccessData.type}</h6>
                        </div>

                        <div className="appointment-receipt-row">
                            <div className="d-flex align-center">
                                <img src={dateIcon} className="mb-0 img-fluid" alt="icon" />
                                <h5 className="ml-2"> Date</h5>
                            </div>
                            <h6>{appointmentSuccessData.date}</h6>
                        </div>

                        <div className="appointment-receipt-row">
                            <div className="d-flex align-items-center">
                                <img src={timeIcon} className="mb-0 img-fluid" alt="icon" />
                                <h5 className="ml-2"> Time</h5>
                            </div>
                            <h6>{moment(appointmentSuccessData.time, 'HH:mm').format('hh:mm A').toLowerCase()}</h6>
                        </div>

                    </div>
                    <h4 className="text-right copy"> Copy Link</h4>

                    <div className="copy-link-container">
                        <img src={attachmentIcon} className="copy-link-icon mb-0 img-fluid" />
                        <input type="text" className="w-100 copy-link-input ps-5" value={externalLink} />
                    </div>

                    <button
                        style={{ height: '45px', minWidth: '100%' }}
                        // onClick={(e) => writePrescription(e)}
                        className=" mt-4 mb-4 submit-btn-completed text-center add-record-btn text-uppercase w-100"
                        onClick={(e) => copyTextToClipboard(e, externalLink)}
                    >
                        Copy Link

                    </button>

                    <div className="share-container">
                        <h4> Share the link via</h4>
                        <WhatsappShareButton separator={`\n`} url={externalLink} crossOrigin="anonymous" className="whatsappIconsShare"> <img style={{ cursor: 'pointer' }} src={whatsappIcon} /> </WhatsappShareButton>
                        <EmailShareButton url={externalLink} subject="Meri Sehat" separator={`\n`}> <img style={{ cursor: 'pointer' }} src={mailIcon} /> </EmailShareButton>

                    </div>


                </Modal.Body>

            </Modal>

            {/* =======================================Confirm Cancel-================================ */}

            <Modal
                className="text-center ToGetCenter cancel-appointment-modal"
                show={cancel}
                onHide={handleOkayClose}
            >
                <Modal.Header
                    className="border-bottom-0"
                    closeButton
                ></Modal.Header>
                <div className="px-5 modal-cancel">
                    <Modal.Body className="pt-0 px-0">
                        <h4 className="fw-600 ff-Nunito">
                            Tell us why you want to cancel
                        </h4>
                        <textarea></textarea>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 row flex-md-nowrap justify-content-center pb-4">
                        <button
                            type="submit"
                            onClick={submitCancelFeedback}
                            className="review-button text-uppercase modalOkay-phone-btn"
                        >
                            submit
                            <span
                                className="modalOkay-phone-chevron"
                                style={{ height: "53px" }}
                            >
                                <FiChevronRight />
                            </span>
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
            {/* =======================================Confirm Cancel-================================ */}
        </>



    )
}

export default AppointmentModal