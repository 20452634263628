import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFromLocalStorage } from "../../../utils/helperFunctions";

import { postClinicRecord as postClinicRecordAPI, getClinics as getClinicsAPI, getClinicData as getClinicDataAPI, deleteClinic as deleteClinicAPI, toggleClinic as toggleClinicAPI, 
  // editClinicRecord as editClinicRecordAPI 
} from "./service";

export const postClinicRecord = createAsyncThunk(
  "add/clinic",
  async (payload) => {
    // console.log(payload)
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await postClinicRecordAPI(payload, params);
    return response.data;
  }
);

// export const editClinicRecord = createAsyncThunk(
//   "edit/clinic",
//   async (payload) => {
//     let id = payload.clinic_id
//     const token = getFromLocalStorage("D_APP_TOKEN");
//     const user_id = getFromLocalStorage("D_USER_ID");
//     const params = {
//       headers: {
//         "user-id": user_id,
//         Authorization: token,
//       },
//     };
//     const response = await editClinicRecordAPI(id, payload, params);
//     return response.data;
//   }
// );

export const getClinics = createAsyncThunk(
  "get/clinic",
  async () => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    // console.log(params, 'params');
    const response = await getClinicsAPI(params);
    return response.data;
  }
);

export const getClinicData = createAsyncThunk(
  "get/clinicData",
  async (id) => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await getClinicDataAPI(id, params);
    return response.data;
  }
);

export const deleteClinic = createAsyncThunk(
  "del/clinic",
  async (id) => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await deleteClinicAPI(id, params);
    return response.data;
  }
);

export const toggleClinic = createAsyncThunk(
  "get/toggleclinic",
  async (id) => {
    const token = getFromLocalStorage("D_APP_TOKEN");
    const user_id = getFromLocalStorage("D_USER_ID");
    const params = {
      headers: {
        "user-id": user_id,
        Authorization: token,
      },
    };
    const response = await toggleClinicAPI(id, params);
    return response.data;
  }
);