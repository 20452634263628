import {
    createSlice,
    current,
    isFulfilled,
    isPending,
    isRejected,
  } from "@reduxjs/toolkit";
  
  import { getClinics, postClinicRecord, getClinicData, deleteClinic, toggleClinic } from "./thunk";
  
  const thunks = [postClinicRecord, getClinics, getClinicData, deleteClinic, toggleClinic];
  
  const initialState = {
    status: "idle",
    Clinic: {},
    ClinicData: {},
    Clinics: {},
    toggleClinic: {}
  };
  
  export const slice = createSlice({
    name: "clinicRecord",
    initialState,
    reducers: {

      setClinic: (state, clinic) => {
        state.Clinics = {...state.Clinics, ...clinic.payload}
        // console.log(state.Clinics)
      },

      delClinic: (state,clinic)=> {
        state.Clinics = state.Clinics.filter((clinics) => clinics.id !== clinic.payload)
      }
   
    },
    extraReducers: (builder) => {
      builder
        .addCase(getClinics.fulfilled, (state, action) => {
          state.status = "idle";
          state.Clinic = action.payload;
        })
        .addCase(getClinicData.fulfilled, (state, action) => {
          state.status = "idle";
          state.ClinicData = action.payload;
        })
        .addCase(toggleClinic.fulfilled, (state, action) => {
          state.status = "idle";
          state.toggleClinic = action.payload
        })
        .addMatcher(isPending(...thunks), (state) => {})
        .addMatcher(isFulfilled(getClinics), (state) => {})
        .addMatcher(isRejected(...thunks), (state, action) => {});
    },
  });
  
  export const { setClinic } = slice.actions;
  export const { delClinic } = slice.actions;
  
  
  export const selectClinic = (state) => state.clinic.Clinic;
  export const selectClinicData = (state) => state.clinic.ClinicData;
  export const selectClinics = (state) => state.clinic.Clinics;
  export const selectToggleClinic = (state) => state.clinic.toggleClinic;
  // export const getClinic = (state) => state.clinic.clinics;

//   export const selectEmail = (state) => state.login.userEmail;
  
  
  export default slice.reducer;
  