import React, { Fragment, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import Login from "../pages/login/login";
import ForgotPassword from "../pages/login/ForgotPassword";
import OtpWhenLoggingIn from "../pages/login/OtpWhenLoggingIn";
import UpdatePassword from "../pages/login/UpdatePassword";
import Navabrr from "../uiComponents/Navbar/Navbarr";
import AppointmentFirstTime from "../pages/appointments/AppointmentFirstTime";
import Authentication from "../pages/mobileAuthentication/Authentication";
import Otp1 from "../pages/Otp1/Otp1";
import Otp2 from "../pages/Otp2/Otp2";
import Thankyou from "../pages/Thankyou/Thankyou";
import ForgotPasswordOtp from "../pages/ForgotPasswordOtp/ForgotPasswordOtp";
import { useState } from "react";
import SideBar from "../uiComponents/sideBar/SideBar";
import SignupMobile from "../pages/login/SignupMobile";
import SignupOtp from "../pages/login/SignupOtp";
import SignupDetails from "../pages/login/SignupDetails";
import PleaseWait from "../pages/login/PleaseWait";
import SignupThankyou from "../pages/login/SignupThankyou";
import DoctorSignIn from "../pages/login/DoctorSignIn";
import DoctorSignInOtp from "../pages/login/DoctorSignInOtp";
import PaymentHistory from "../pages/paymentHistory/PaymentHistory";
import MobileHeader from "../uiComponents/Header/MobileHeader";

function Auth() {
  return (
    <Fragment>
      <Switch>
        <Route path="/login" component={DoctorSignIn} />
        <Route path="/signup-number" component={SignupMobile} />
        <Route path="/signup-otp" component={SignupOtp} />
        {/* <Route path="/doctor-login" component={DoctorSignIn} /> */}
        <Route path="/doctor-Otp" component={DoctorSignInOtp} />
        <Route path="/auth/:token/:id" component={Authentication} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/forgot-password-otp" component={ForgotPasswordOtp} />
        {/* <Route path="/otp" component={OtpWhenLoggingIn} /> */}
        <Route path="/otp1" component={Otp1} />
        <Route path="/otp2" component={Otp2} />
        <Route path="/thank-you" component={Thankyou} />
        <Route path="/update-password" component={UpdatePassword} />
        <Redirect to="/login" from="/" component={Login} />

        {/* <Route path="/oooo" component={AppointmentFirstTime} /> */}
      </Switch>
    </Fragment>
  );
}
function Root(props) {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 830) {
        setMobile(true);
      }
    }
  }, []);

  return (
    <>
      {mobile ? (
        <>
          <SideBar />
          {(window.location.pathname != "/signup-details" && window.location.pathname != "/please-wait" && window.location.pathname != "/thankyou-doctor") ? (
            <>
              <MobileHeader />
            </>
          ) : null}
        </>
      ) : (window.location.pathname != "/signup-details" && window.location.pathname != "/please-wait" && window.location.pathname != "/thankyou-doctor") ? (
        <>
          <Navabrr />
        </>
      ) : (
        <>

        </>
      )}
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}

        <Redirect to="/" from="/login" />
        <Route
          path="*"
          render={() => (
            <div className="w-100 h-100">
              <h1>404 - Page not found</h1>
            </div>
          )}
        />
      </Switch>
    </>
  );
}

export { Auth, Root };
