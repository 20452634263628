import React, { useState, useEffect } from "react";
/* eslint-disable react/no-array-index-key */
import { Row, Col, Container } from "react-bootstrap";
import {
  HeadingDesc,
  HeadingDescSmall,
  HeadingDescVsmall,
  HeadingWithSpaceLarge,
} from "../../uiComponents/Headings";
import tutorialImg from "../../assets/images/png/tutorials-img.png";
import rightarrow from "../../assets/images/svg/right-arrow-border.svg";
import "./Tutorials.scss";
import ReactPlayer from 'react-player'

function Tutorials() {

      
        return (
            <section className="bg-white pt-150">
                <Container>
                    <div className="tutorial-banner">
                        <Row className="align-items-center">
                            <Col md={4} className='offset-md-1'>
                                <img src={tutorialImg} alt='' className="img-fluid"/>
                            </Col>
                            <Col md={7} className=''>
                                <h2 className="ff-Nunito">Tutorials & Guides</h2>
                            </Col>
                        </Row>

                    </div>
                    <Row>
                            <Col md={6}  className="mt-5">
                                <div className="d-flex">
                                    <Col md={6}>
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-after-video ms-4">
                                            <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                            <div class="mt-4 d-flex align-items-center">
                                                <div>
                                                    <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                </div>
                                                <button type="button" class="underline_ancer">Watch Now</button>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                            <Col md={6}  className="mt-5">
                                     <div className="d-flex">
                                        <Col md={6}>
                                            <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                        </Col>
                                        <Col md={6}>
                                            <div className="text-after-video ms-4">
                                                <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                                <div class="mt-4 d-flex align-items-center">
                                                    <div>
                                                        <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                    </div>
                                                    <button type="button" class="underline_ancer">Watch Now</button>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                            </Col>
                            <Col md={6}  className="mt-5">
                                <div className="d-flex">
                                    <Col md={6}>
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-after-video ms-4">
                                            <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                            <div class="mt-4 d-flex align-items-center">
                                                <div>
                                                    <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                </div>
                                                <button type="button" class="underline_ancer">Watch Now</button>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                            <Col md={6}  className="mt-5">
                                     <div className="d-flex">
                                        <Col md={6}>
                                            <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                        </Col>
                                        <Col md={6}>
                                            <div className="text-after-video ms-4">
                                                <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                                <div class="mt-4 d-flex align-items-center">
                                                    <div>
                                                        <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                    </div>
                                                    <button type="button" class="underline_ancer">Watch Now</button>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                            </Col>
                            <Col md={6}  className="mt-5">
                                <div className="d-flex">
                                    <Col md={6}>
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-after-video ms-4">
                                            <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                            <div class="mt-4 d-flex align-items-center">
                                                <div>
                                                    <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                </div>
                                                <button type="button" class="underline_ancer">Watch Now</button>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                            <Col md={6}  className="mt-5">
                                     <div className="d-flex">
                                        <Col md={6}>
                                            <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                        </Col>
                                        <Col md={6}>
                                            <div className="text-after-video ms-4">
                                                <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                                <div class="mt-4 d-flex align-items-center">
                                                    <div>
                                                        <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                    </div>
                                                    <button type="button" class="underline_ancer">Watch Now</button>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                            </Col>
                            <Col md={6}  className="mt-5">
                                <div className="d-flex">
                                    <Col md={6}>
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-after-video ms-4">
                                            <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                            <div class="mt-4 d-flex align-items-center">
                                                <div>
                                                    <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                </div>
                                                <button type="button" class="underline_ancer">Watch Now</button>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                            <Col md={6}  className="mt-5">
                                     <div className="d-flex">
                                        <Col md={6}>
                                            <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUMss' width={'100%'} height={'200px'} /> 
                                        </Col>
                                        <Col md={6}>
                                            <div className="text-after-video ms-4">
                                                <h5 className="mb-2">Lorem ipsum dolor sit amet, consectetur elid sed do?</h5>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</p>
                                                <div class="mt-4 d-flex align-items-center">
                                                    <div>
                                                        <img src={rightarrow} alt="arrow-with-border" class="img-fluid me-3" />
                                                    </div>
                                                    <button type="button" class="underline_ancer">Watch Now</button>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                            </Col>
                        </Row>
                </Container>
            </section>
        );
    }


export default Tutorials;