import { get, post, del } from "../../../utils/httpService";

const SERVICE_URLS = {
  postClinicRecord: () => `/clinic-record`,
  // editClinicRecord: (id) => `/clinic-record/${id}`,
  getClinics: () => `/clinics`,
  getClinicData: (id) => `/doctor/clinic-listing?doctor_id=${id}`,
  deleteClinic: (id) => `/doctor/clinic-delete/${id}`,
  toggleClinic: (id) => `/doctor/clinic-status/${id}`
};

export const postClinicRecord = (body, params) => {
  return post(SERVICE_URLS.postClinicRecord(), body, params);
};

// export const editClinicRecord = (id, body, params) => {
//   return post(SERVICE_URLS.editClinicRecord(id), body, params);
// };

export const getClinics = (params) => {
  return get(SERVICE_URLS.getClinics(), params);
};

export const getClinicData = (id,params) => {
  return get(SERVICE_URLS.getClinicData(id), params);
};

export const deleteClinic = (id, params) => {
  return del(SERVICE_URLS.deleteClinic(id), params);
};

export const toggleClinic = (id,params) => {
  return get(SERVICE_URLS.toggleClinic(id), params);
};