import React, { useEffect, useMemo, useState, useRef } from "react";
import { Select, InputNumber, Form, Input, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import arrowdropdown from "../../assets/images/svg/dropdown-icon.svg";
import "./medform.css";
import swal from "sweetalert";
import uniqBy from "lodash.uniqby";
import API from "../../utils/customAxios";
import arrowLeft from "../../assets/images/png/left-arrow.png";
import arrowRight from "../../assets/images/png/right-arrow.png";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";

function MedForm(props) {
  const { Option } = Select;
  const {
    medicine,
    medTable,
    setMedTable,
    editMedicineStatus,
    setEditMedicineStatus,
    setEditMedicine,
    editMedicine,
    setAddMedicineLoader,
  } = props;

  const [showAddBtn, setShowAddBtn] = useState(false);
  const [medicineTypes, setMedicineTypes] = useState([]);
  const [med, setMed] = useState(null);
  const [medName, setMedName] = useState(null);
  const [typeName, setTypeName] = useState(null);
  const [morningDose, setMorningDose] = useState(null);
  const [afternoonDose, setAfternoonDose] = useState(null);
  const [eveningDose, setEveningDose] = useState(null);
  const [nightDose, setNightDose] = useState(null);
  const [durationNumber, setDurationNumber] = useState(null);
  const [mealStatus, setMealStatus] = useState(null);
  const [userForm] = Form.useForm();

  const medicineRef = useRef();

  function handleMedicineSearch(value) {
    const filteredOptions = medicine.data?.filter((item) => {
      return item?.name?.toLowerCase()?.startsWith(value?.toLowerCase());
    });

    if (filteredOptions.length === 0) {
      setShowAddBtn(true);
    } else {
      setShowAddBtn(false);
    }
  }

  const setMedicineTypesInState = useMemo(() => {
    let data = [];

    if (props.medicine.data?.length > 0) {
      props.medicine.data?.forEach((med) => {
        data.push(med?.medicine_type);
      });

      setMedicineTypes(data);
    }
  }, [JSON.stringify(props?.medicine.data)]);

  const uniqueMedicineTypes = useMemo(() => {
    if (medicineTypes?.length > 0) {
      let uniqueValues = uniqBy(medicineTypes, function (e) {
        return e?.name;
      });

      return uniqueValues;
    }
  }, [JSON.stringify(medicineTypes)]);

  async function addCustomMedicine(e) {
    if (showAddBtn) {
      const data = {
        name: e.target.value,
      };
      try {
        setAddMedicineLoader(true);
        const response = await API.post("/add-medicine", data);
        if (response?.data?.code === 200) {
          // console.log(medicineRef?.current?.value, "medRef")
          // medicineRef.current?.blur();
          props?.setShouldDispatchMedicine(true);
          setAddMedicineLoader(false);
          medicineChange(e.target.value);
          setShowAddBtn(false);
        } else {
          setAddMedicineLoader(false);
        }
      } catch (error) {
        setAddMedicineLoader(false);
      }
    }
  }

  const medicineChange = (value) => {
    setMedName(value);
  };

  const typeChange = (value) => {
    setTypeName(value);
  };

  const changeMorning = (value) => {
    setMorningDose(value);
  };

  const changeAfternoon = (value) => {
    setAfternoonDose(value);
  };

  const changeEvening = (value) => {
    setEveningDose(value);
  };

  const changeNight = (value) => {
    setNightDose(value);
  };

  const durationChange = (value) => {
    setDurationNumber(value);
  };

  const mealChange = (value) => {
    setMealStatus(value);
  };

  const morningRef = useRef(morningDose);
  const afternoonRef = useRef(afternoonDose);
  const eveningRef = useRef(eveningDose);
  const nightRef = useRef(nightDose);

  useEffect(() => {
    morningRef.current = morningDose;
  }, [morningDose]);

  useEffect(() => {
    afternoonRef.current = afternoonDose;
  }, [afternoonDose]);

  useEffect(() => {
    eveningRef.current = eveningDose;
  }, [eveningDose]);

  useEffect(() => {
    nightRef.current = nightDose;
  }, [nightDose]);

  const onFinish = async (value) => {
    const {
      medName,
      typeName,
      morningDose,
      afternoonDose,
      eveningDose,
      nightDose,
      durationNumber,
      mealStatus,
    } = value;

    let found = [];
    medicine?.data?.map((item) => {
      if (item.id === medName) {
        found.push(item.name);
        found.push(item.id);
      }
    });

    value.medName = found[0];
    value.prescription_element_id = found[1];

    // console.log(found, "found");

    let payload = {
      medName: found[0] || medName,
      prescription_element_id:
        found[1] || editMedicine?.prescription_element_id,
      unit: typeName,
      morning: morningDose || morningRef?.current,
      afternoon: afternoonDose || afternoonRef?.current,
      evening: eveningDose || eveningRef?.current,
      night: nightDose || nightRef?.current,
      number_of_days: durationNumber,
      is_after_meal: mealStatus,
    };

    if (medTable?.length === 0) {
      setMedTable([...medTable, payload]);
      userForm.resetFields();
      setMorningDose("");
      setAfternoonDose("");
      setEveningDose("");
      setNightDose("");
      setEditMedicineStatus(false);
      // console.log(medTable, 'medTableee');
    } else if (medTable?.length > 0 && medName && typeName && mealStatus) {
      let duplicateFound = false;
      setMorningDose("");
      setAfternoonDose("");
      setEveningDose("");
      setNightDose("");
      for (let item of medTable) {
        if (
          item?.prescription_element_id === payload?.prescription_element_id
        ) {
          // Compare against payload?.prescription_element_id instead of medName
          duplicateFound = true;
          break;
        }
      }

      if (duplicateFound) {
        swal("Error!", "You can't add similar medicines twice", "error");
      } else {
        setMedTable([...medTable, payload]);
        setMorningDose("");
        setAfternoonDose("");
        setEveningDose("");
        setNightDose("");
        userForm.resetFields();
        setEditMedicineStatus(false);
      }
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Manually trigger the form validation and submit
    userForm
      .validateFields()
      .then(() => {
        userForm.submit();
      })
      .catch((error) => {
        console.error("Form validation failed:", error);
      });
  };

  useEffect(() => {
    if (editMedicine) {
      const formValues = {
        medName: editMedicine?.medName,
        typeName: editMedicine?.unit,
        morningDose: editMedicine?.morning,
        afternoonDose: editMedicine?.afternoon,
        eveningDose: editMedicine?.evening,
        nightDose: editMedicine?.night,
        durationNumber: editMedicine?.number_of_days,
        mealStatus: editMedicine?.is_after_meal,
      };
      userForm.setFieldsValue(formValues);

      setMorningDose(editMedicine?.morning);
      setEveningDose(editMedicine?.evening);
      setAfternoonDose(editMedicine?.afternoon);
      setNightDose(editMedicine?.night);

    }
  }, [editMedicine]);


  const handleDecreasemorning = () => {
    if (morningDose > 0) {
      setMorningDose((prevValue) => prevValue - 1);
    }
  };

  const handleIncreasemorning = () => {
    setMorningDose((prevValue) => prevValue + 1);
  };

  const handleDecreaseafternoon = () => {
    if (afternoonDose > 0) {
      setAfternoonDose((prevValue) => prevValue - 1);
    }
  };

  const handleIncreaseafternoon = () => {
    setAfternoonDose((prevValue) => prevValue + 1);
  };

  const handleDecreaseevening = () => {
    if (eveningDose > 0) {
      setEveningDose((prevValue) => prevValue - 1);
    }
  };

  const handleIncreaseevening = () => {
    setEveningDose((prevValue) => prevValue + 1);
  };

  const handleDecreasenight = () => {
    if (nightDose > 0) {
      setNightDose((prevValue) => prevValue - 1);
    }
  };

  const handleIncreasenight = () => {
    setNightDose((prevValue) => prevValue + 1);
  };

  
  function generateArrayWithoutNumber() {
    let res = [];
    for (let i = 0; i <= 255; i++) {
      if (i >= 48 && i <= 57) {
      } else {
        res.push(String.fromCharCode(i));
      }
    }
    return res;
  }

  const arrayWithoutNumber = useMemo(() => generateArrayWithoutNumber(), []);


  return (
    <>
      <Form
        form={userForm}
        name="basic"
        initialValues={{ name: "" }}
        onFinish={onFinish}
        className="medicine_form01"
      >
        <Row>
          <Col md={5} className="row m-0 px-0 d-md-flex">
            <Col md={7}>
              <p className="labelText fs-14 ps-0">Choose Medicine*</p>
              <Form.Item
                name="medName"
                rules={[{ required: true, message: "Required Medicine" }]}
              >
                <Select
                  ref={medicineRef}
                  dropdownAlign={{ offset: [0, 4] }}
                  style={{ cursor: "pointer" }}
                  showSearch
                  value={medName}
                  className="c_select"
                  placeholder="Select a medicine"
                  onChange={medicineChange}
                  // onSelect={(e) => setMed(e)}
                  onSearch={handleMedicineSearch}
                  suffixIcon={
                    showAddBtn ? (
                      <button className="add-custom-med">Add</button>
                    ) : (
                      <img src={arrowdropdown} alt />
                    )
                  }
                  onClick={(e) => addCustomMedicine(e)}
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toLowerCase()
                      ?.startsWith(input?.toLowerCase());
                  }}
                >
                  {medicine.data?.map((med) => {
                    return (
                      <>
                        <Option value={med?.id} key={med?.id}>
                          {med.name}
                        </Option>
                      </>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={5}>
              <p className="labelText fs-14 ps-0">Types*</p>
              <Form.Item
                name="typeName"
                rules={[{ required: true, message: "Required Medicine Type" }]}
              >
                <Select
                  value={typeName}
                  dropdownAlign={{ offset: [0, 4] }}
                  placeholder="Select"
                  suffixIcon={<img src={arrowdropdown} alt />}
                  onChange={typeChange}
                  className="c_select"
                >
                  {uniqueMedicineTypes?.map((med) => (
                    <Option value={med?.name}> {med?.name} </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Col>
          <Col
            md={3}
            className="row forParentMed mb-1 newPrescriptionChecks m-0 px-0 d-md-flex"
          >
            <Col md={3}>
              {/* block for mobile  */}

              <div className="d-block d-sm-none">
                <p className="labelText fs-14 ps-0">Frequency*</p>
                <div className="requency">
                  <p className="labelText fs-14 ps-0">Morning</p>
                  <Form.Item name="morningDose">
                    <div className="arrow-input">
                      <span onClick={handleDecreasemorning}>
                        <img src={arrowLeft} className="rightarrow" />{" "}
                      </span>
                      <InputNumber
                        className="ms-0"
                        type="number"
                        min={1}
                        max={99}
                        maxLength={2}
                        value={morningDose}
                        onChange={(value) => setMorningDose(parseInt(value))}
                        onKeyDown={(evt) =>
                          arrayWithoutNumber.includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                      <span onClick={handleIncreasemorning}>
                        <img src={arrowRight} className="arrowRight" />
                      </span>
                    </div>
                  </Form.Item>
                </div>

                <div className="requency">
                  <p className="labelText fs-14 ps-0">Afternoon</p>
                  <Form.Item name="afternoonDose">
                    <div className="arrow-input">
                      <span onClick={handleDecreaseafternoon}>
                        <img src={arrowLeft} className="rightarrow" />{" "}
                      </span>
                      <InputNumber
                        className="ms-0"
                        type="number"
                        min={1}
                        max={99}
                        maxLength={2}
                        value={afternoonDose}
                        onChange={(value) =>
                          setAfternoonDose(parseInt(value))
                        }
                        onKeyDown={(evt) =>
                          arrayWithoutNumber.includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />

                      <span onClick={handleIncreaseafternoon}>
                        <img src={arrowRight} className="arrowRight" />
                      </span>
                    </div>
                  </Form.Item>
                </div>

                <div className="requency">
                  <p className="labelText fs-14 ps-0">Evening</p>
                  <Form.Item name="eveningDose">
                    <div className="arrow-input">
                      <span onClick={handleDecreaseevening}>
                        <img src={arrowLeft} className="rightarrow" />{" "}
                      </span>
                      <InputNumber
                        className="ms-0"
                        type="number"
                        min={1}
                        max={99}
                        maxLength={2}
                        value={eveningDose}
                        onChange={(value) =>
                          setEveningDose(parseInt(value))
                        }
                        onKeyDown={(evt) =>
                          arrayWithoutNumber.includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />

                      <span onClick={handleIncreaseevening}>
                        <img src={arrowRight} className="arrowRight" />
                      </span>
                    </div>
                  </Form.Item>
                </div>

                <div className="requency ">
                  <p className="labelText fs-14 ps-0">Night</p>
                  <Form.Item name="nightDose">
                    <div className="arrow-input">
                      <span onClick={handleDecreasenight}>
                        <img src={arrowLeft} className="rightarrow" />{" "}
                      </span>
                      <InputNumber
                        className="ms-0"
                        type="number"
                        min={1}
                        max={99}
                        maxLength={2}
                        value={nightDose}
                        onChange={(value) => setNightDose(parseInt(value))}
                        onKeyDown={(evt) =>
                          arrayWithoutNumber.includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />

                      <span onClick={handleIncreasenight}>
                        <img src={arrowRight} className="arrowRight" />
                      </span>
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className="mobile_design_field d-sm-block d-none">
                <p className="labelText fs-14 ps-0">Morning</p>
                <Form.Item name="morningDose">
                  <InputNumber
                    value={morningDose}
                    min={1}
                    max={99}
                    maxLength={2}
                    onChange={changeMorning}
                    onKeyDown={(evt) =>
                      arrayWithoutNumber.includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                </Form.Item>
              </div>
            </Col>

            <Col md={3} className=" d-sm-block d-none">
              <p className="labelText fs-14 ps-0">afternoon</p>
              <Form.Item name="afternoonDose">
                <InputNumber
                  value={afternoonDose}
                  min={1}
                  max={99}
                  maxLength={2}
                  onChange={changeAfternoon}
                  onKeyDown={(evt) =>
                    arrayWithoutNumber.includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
              </Form.Item>
            </Col>

            <Col md={3} className=" d-sm-block d-none">
              <p className="labelText fs-14 ps-0">Evening</p>
              <Form.Item name="eveningDose">
                <InputNumber
                  value={eveningDose}
                  min={1}
                  max={99}
                  maxLength={2}
                  onChange={changeEvening}
                  onKeyDown={(evt) =>
                    arrayWithoutNumber.includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
              </Form.Item>
            </Col>

            <Col md={3} className=" d-sm-block d-none">
              <p className="labelText fs-14 ps-0">Night</p>
              <Form.Item name="nightDose">
                <InputNumber  
                  max={99}
                  maxLength={2} 
                  min={1} 
                  value={nightDose} 
                  onChange={changeNight}
                  onKeyDown={(evt) =>
                    arrayWithoutNumber.includes(evt.key) &&
                    evt.preventDefault()
                  } />

              </Form.Item>
            </Col>
          </Col>
          <Col md={4} className="row m-0 px-0 d-md-flex pt-0">
            <Col md={6}>
              <p className="labelText fs-14 ps-0">Duration*</p>
              <Form.Item
                name="durationNumber"
                rules={[
                  { required: true, message: "Required Duration Period" },
                ]}
              >
                <Select
                  value={durationNumber}
                  dropdownAlign={{ offset: [0, 4] }}
                  placeholder="Select"
                  suffixIcon={<img src={arrowdropdown} alt />}
                  onChange={durationChange}
                  className="c_select"
                >
                  <Option value="1 day" key={1}>
                    1 Day
                  </Option>
                  <Option value="2 days" key={2}>
                    2 Days
                  </Option>
                  <Option value="3 days" key={3}>
                    3 Days
                  </Option>
                  <Option value="4 days" key={4}>
                    4 Days
                  </Option>
                  <Option value="5 days" key={5}>
                    5 Days
                  </Option>
                  <Option value="6 days" key={6}>
                    6 Days
                  </Option>
                  <Option value="1 week" key={7}>
                    1 week
                  </Option>
                  <Option value="2 weeks" key={8}>
                    2 weeks
                  </Option>
                  <Option value="3 weeks" key={9}>
                    3 weeks
                  </Option>
                  <Option value="4 weeks" key={10}>
                    4 weeks
                  </Option>
                  <Option value="1 month" key={11}>
                    1 month
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={6}>
              <p className="labelText fs-14 ps-0">Instruction*</p>
              <Form.Item
                name="mealStatus"
                rules={[{ required: true, message: "Required Instruction" }]}
              >
                <Select
                  value={mealStatus}
                  dropdownAlign={{ offset: [0, 4] }}
                  placeholder="Select"
                  suffixIcon={<img src={arrowdropdown} alt />}
                  onChange={mealChange}
                  className="c_select"
                >
                  <Option value="0" key={1}>
                    Before Meal
                  </Option>
                  <Option value="1" key={2}>
                    After Meal
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col md={3}>
            <Button
              type="primary"
              htmlType="submit"
              className="simple_btn_small"
              onClick={handleFormSubmit}
            >
              {editMedicineStatus ? "UPDATE MEDICINE" : "ADD MEDICINE"}
            </Button>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </>
  );
}

export default React.memo(MedForm);
