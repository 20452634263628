import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  HeadingDescSmall,
  HeadingWithSpaceLarge,
} from "../../uiComponents/Headings";
import editProfile from "../../assets/images/svg/iconEditProfile.svg";
import { Checkbox, Form, Input, message, Select } from "antd";
import { HiOutlineArrowDown } from "react-icons/hi";
import CheckboxComponent from "../../uiComponents/checkboxComponent/checkboxComponent";
import addIcon from "../../assets/images/svg/add.svg";
import { SimpleButton, SimpleButtonSmall } from "../../uiComponents/button";
import arrowdropdown from "../../assets/images/svg/dropdown-icon.svg";
import EducationComponent from "../../uiComponents/profileComponent/EducationComponent";
import ExperienceComponent from "../../uiComponents/profileComponent/ExperienceComponent";
import Membershipcomponent from "../../uiComponents/profileComponent/Membershipcomponent";
import { useAppDispatch, useAppSelector } from "./../../redux/hooks";
import {
  selectCities,
  selectServices,
  selectSpeciality,
  selectUser,
} from "./redux/slice";
import {
  getCities,
  getProfile,
  getServices,
  getSpeciality,
  postUpdateProfile,
} from "./redux/thunk";
import "./UpdateProfile.scss";
import { isEmpty } from "../../helpers/objectHelper";
import { useForm } from "antd/lib/form/Form";
import { toast, ToastContainer } from "react-toastify";
import { useRef } from "react";
import { useTheme } from "@mui/material/styles";
import ImageUploading from "react-images-uploading";
import Avatar from "@mui/material/Avatar";
import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import { useLocation } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { useEffectOnce } from "react-use";
import axios from "axios";
import API from "../../utils/customAxios";
import { getCurrentUserId } from "../../utils/helperFunctions";
import loadingGif from "../../assets/images/gif/loader_gif.gif";
import { getUniversities } from "./redux/service";
import moment from "moment";
import defProfile from "../../assets/images/png/defprofile.jpg";
import ImageUploader from "react-image-upload";
import "react-image-upload/dist/index.css";
import { getFromLocalStorage } from "../../utils/helperFunctions";



function getStyles(name, personName, theme, city) {
  return {
    fontWeight:
      personName.indexOf(name, city) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function UpdateProfile() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  // const cities = useAppSelector(selectCities);
  const speciality = useAppSelector(selectSpeciality);
  // const services = useAppSelector(selectServices);

  let services_id = [];

  const [form] = useForm();
  const [note, setNote] = useState("");
  // const [specialityID, setSpecialityID] = useState([]);
  const [speciality_id, setspeciality_id] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [serviceIDs, setServiceIDs] = useState([]);
  const [name, setName] = useState();
  const [specialities, setSpecialities] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [specialities1, setSpecialities1] = useState([]);
  const [open, setOpen] = useState(false);

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const [mobile, setMobile] = useState(false);
  const [genderSelected, setGenderSelected] = useState('');


  const aboutRef = useRef(null);

  const children = [];
  let checkboxes = [];

  // User Bio Data States ............

  const [cities, setCities] = useState(null);
  const [services, setServices] = useState([]);
  const [universities, setUniversities] = useState([]);

  const [profileImage, setProfileImage] = useState('');

  useEffectOnce(() => {
    (async () => {
      setLoading(true);

      const userId = getCurrentUserId();

      try {
        const response = await API.get(`/user`);
        if (response?.data?.code === 200) {
          setLoading(false);
          setProfile(response?.data?.data?.user);
          setGenderSelected(response?.data?.data?.user?.gender)
        } else {
          setLoading(false);
          toast.error(response?.data?.message, response?.data?.code)
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message, error?.response?.code)
      }
    })();
  });

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await API.get(`/cities`);
        if (response?.data?.code === 200) {
          setCities(response?.data?.data);

        } else {
          toast.error(response?.data?.message, response?.data?.code)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, error?.response?.code)
      }
    })();
  });

  useEffectOnce(() => {
    (async () => {
      try {
        const response = await API.get(`/specialities`);
        if (response?.data?.code === 200) {
          setSpecialities(response?.data?.data?.specialities);

        } else {
          toast.error(response?.data?.message, response?.data?.code)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, error?.response?.code)
      }
    })();
  })

  useEffectOnce(() => {
    // (async () => {
    //   try {
    //     const response = await API.get(`/universities`);
    //     if (response?.data?.code === 200) {
    //       setUniversities(response?.data?.data);

    //     } else {
    //       toast.error(response?.data?.message, response?.data?.code)
    //     }
    //   } catch (error) {
    //     toast.error(error?.response?.data?.message, error?.response?.code)
    //   }
    // })();
    // dispatch(getUniversities());
  })

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`/services?speciality_id=${profile?.doctor_specialities_full?.[0]?.id}`);
        if (response?.data?.code === 200) {
          setServices(response?.data?.data?.services);

        } else {
          toast.error(response?.data?.message, response?.data?.code)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, error?.response?.code)
      }
    })();

  }, [JSON.stringify(profile)])


  const { Option } = Select;
  const { TextArea } = Input;

  const [education, setEducation] = useState([<EducationComponent id="1" />]);
  const [experience, setExperience] = useState([
    <ExperienceComponent id="1" />,
  ]);
  const [membership, setMembership] = useState([
    <Membershipcomponent id="1" />,
  ]);

  let addMoreEducation = (e) => {
    e.preventDefault();
    setEducation([
      ...education,
      <EducationComponent id={parseInt(education.length) + 1} />,
    ]);
  };

  let addMoreExperience = (e) => {
    e.preventDefault();
    setExperience([
      ...experience,
      <ExperienceComponent id={parseInt(experience.length) + 1} />,
    ]);
  };

  let addMoreMembership = (e) => {
    e.preventDefault();
    setMembership([
      ...membership,
      <Membershipcomponent id={parseInt(membership.length) + 1} />,
    ]);
  };

  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const months_name = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const years = [
    1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991,
    1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
  ];

  const updateProfile = async (values) => {
    console.log(values, "values");
    let formData = new FormData();

    let date = `${values.year}-${values.month}-${values.day}`;
    values.birth_date = moment(date).format("YYYY-MM-DD");

    values.is_physical_consultancy = 1;

    let current_year = new Date().getFullYear();
    let years = Object.keys(values).filter((item) =>
      item.startsWith("year_of_completion")
    );
    let exp = Object.keys(values).filter((item) =>
      item.startsWith("designation")
    );
    let mem = Object.keys(values).filter((item) =>
      item.startsWith("institutionName")
    );

    let education = years.map((y, index) => {
      const degree = values["degree" + (index + 1).toString()];
      const institute = values["institute" + (index + 1).toString()];
      const year_of_completion =
        values["year_of_completion" + (index + 1).toString()];

      const is_completed = year_of_completion < current_year;

      return {
        degree,
        institute,
        year_of_completion,
        is_completed,
      };
    });


    values.education = education.filter(
      (edu) => edu.degree && edu.institute && edu.year_of_completion
    );
    console.log(values.speciality, "education")

    let experience = exp.map((e, index) => {
      return {
        designation: values["designation" + (index + 1).toString()],
        institution_name: values["institution_name" + (index + 1).toString()],
        start_date: values["start_date" + (index + 1).toString()],
        end_date: values["end_date" + (index + 1).toString()],
      };
    });

    values.experience = experience.filter(
      (exp) => exp.designation && exp.institution_name && exp.start_date && exp.end_date
    );

    let membership = mem.map((m, index) => {
      return {
        institutionName: values["institutionName" + (index + 1).toString()],
      };
    });

    values.membership = membership.filter(
      (mem) => mem.institutionName
    );

    values.image = profileImage;
    education = values.education
    // let gender = values.gender
    // Check if gender is empty or undefined


    delete values.day;
    delete values.month;
    delete values.pmc_num;
    delete values.title;
    delete values.year;

    var eduFinal = values.education;
    var birthDate = values.birth_date
    var specialties = values.speciality



    form.validateFields().then(async (values) => {
      // let eduFinal = values.education

      // Ensure that eduFinal is an array
      if (!Array.isArray(eduFinal)) {
        eduFinal = [];
      }

      // Create a new FormData object
      let formData = new FormData();

      formData.append('birth_date', birthDate);
      console.log(birthDate, "birthDate")
      // Append the education array to the formData object
      const educationJson = JSON.stringify(eduFinal);
      console.log(educationJson, "educationJson")

      eduFinal.forEach((educationItem, index) => {
        Object.entries(educationItem).forEach(([key, value]) => {
          formData.append(`education[${index}][${key}]`, value);
        });
      });
      // Iterate over the values object and append each key-value pair to the formData object
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      const response = await API.post('/doctor/update', formData);
      if (response?.payload?.code === 200) {
        message.success(response?.payload?.message);
      } else {
        console.log(response, 'formValues');
      }

      // Rest of your logic to update the profile
    }).catch((errorInfo) => {
      console.log('Validation failed:', errorInfo);
    });

    // dispatch(postUpdateProfile(values?.gender)).then((resp) => {
    //   console.log(values?.gender, "values?.gender")
    //   if (resp?.payload?.code === 200) {
    //     message.success(resp?.payload?.message);
    //   } else {
    //     console.log(resp, 'formValues');
    //   }
    // });
  };

  console.log(profile, "profile")
  form.setFieldsValue({
    title: profile?.role,
    name: profile?.name,
    phone: profile?.phone?.substring(1),
    email: profile?.email,
    city_id: profile?.city_id,
    gender: profile?.gender,
    day: profile?.birth_date?.split("-")[2],
    month: months_name[profile?.birth_date?.split("-")[1] - 1],
    year: profile?.birth_date?.split("-")[0],
    pmc_num: profile?.pmc_no,
    experience_year: profile?.experience_year,
    // speciality: speciality_id?.map((spec) => (spec?.id)),
    education: profile?.education,
    speciality: profile?.doctor_specialities_full?.map((spec) => spec?.id),
    service: profile?.doctor_services_full?.map((serv) => serv?.id),
    about: profile?.about,
    image: profile?.image
    // profile?.doctor_services
  });


  const menuList = [
    {
      link: "#about",
      name: "About",
    },
    {
      link: "#education",
      name: "Education",
    },
    {
      link: "#experience",
      name: "Experience",
    },
    {
      link: "#prof_member",
      name: "Professional Membership",
    },
    {
      link: "#services",
      name: "Services",
    },
  ];

  function getImageFileObject(imageFile) {
    // console.log(imageFile?.file, 'imgFile')
    setProfileImage(imageFile?.file);
  }
  function runAfterImageDelete(file) {
    // console.log({ onDele: file });
  }

  return (
    <>
      {loading ? (
        <div className="loaderWrapper container">
          <img src={loadingGif} alt="" />
        </div>
      ) : (
        <div className="update_profile cover_space">
          {mobile && <StickyTab menuList={menuList} type="a" />}
          <Container>
            {!mobile && (
              <Row>
                <Col lg={12}>
                  <HeadingWithSpaceLarge text="UPDATE PROFILE" />
                </Col>
              </Row>
            )}
            <Row>
              {!mobile && (
                <>
                  {profile?.image ? (
                    <ImageUploader style={{ height: 200, width: 200, backgroundImage: `url("${profile?.image}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                      onFileAdded={(img) => getImageFileObject(img)}
                      onFileRemoved={(img) => runAfterImageDelete(img)}
                    />
                  ) : (
                    <div className="doc_name_div flex_center">
                      <h1 className="doc_name">{name}</h1>
                      <img
                        src={defProfile}
                        alt="editProfile"
                        className="editProfileIcon"
                      />
                    </div>


                  )}
                </>

              )}
            </Row>
            <Row>
              <Form
                layout="vertical"
                onFinish={updateProfile}
                // valuePropName="checked"
                form={form}
              >
                <div
                  className={
                    mobile
                      ? location?.search?.split("?")[1] === "#about" ||
                        typeof location?.search?.split("?")[1] === "undefined"
                        ? "UpdateProfileSection border-0"
                        : "d-none"
                      : "UpdateProfileSection border-0"
                  }
                  id="about"
                >
                  {mobile && (
                    <div className="flex_center">
                      <div className="doc_name_div flex_center">
                        <h1 className="doc_name">{name}</h1>
                        <img

                          src={profile?.image}
                          alt="editProfile"
                          className="editProfileIcon"
                        />
                      </div>
                    </div>
                  )}
                  <Row>
                    <Col md={4}>
                      {/* <Form.Item name="title" label="Title">
                    <Input type="text" className="c_input" />
                  </Form.Item> */}
                      <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                          {
                            required: true,
                            message: "Please select your title!",
                          },
                        ]}
                      >
                        <Select
                          dropdownAlign={{ offset: [0, 4] }}
                          suffixIcon={
                            // <HiOutlineArrowDown color="#29BCC1" />
                            <img src={arrowdropdown} alt />
                          }
                          className="c_select"
                        >
                          <Option value="doctor">Doctor</Option>
                          <Option value="dentist">Dentist</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        name="name"
                        label="Full Name*"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Full Name!",
                          },
                        ]}
                      >
                        <Input type="text" className="c_input" required />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <div className="input_box pt-0">
                        <span>+92</span>
                        <Form.Item
                          name="phone"
                          label="Phone Number*"
                          className="phone_num"
                          rules={
                            [
                              // {
                              //   required: true,
                              //   message: "Please enter your phone number!",
                              // },
                              // {
                              //   pattern: /^(?:\d*)$/,
                              //   message: "Value should contain just number",
                              // },
                              // {
                              //   min: 10,
                              //   max: 10,
                              //   message: "Number should be 10 digit",
                              // },
                            ]
                          }
                        >
                          <Input
                            style={{ backgroundColor: "#fff" }}
                            type="text"
                            className="c_input"
                            disabled={true}
                            required
                          />
                          {/* <p>{profile?.phone.substring(1)}</p> */}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Item name="email" label="Email">
                        <Input
                          disabled={true}
                          style={{ backgroundColor: "#fff" }}
                          type="email"
                          className="c_input"
                        />
                      </Form.Item>
                    </Col>
                    {cities && cities?.length > 0 && (
                      <Col md={4}>
                        <Form.Item
                          name="city_id"
                          label="City*"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your City!",
                            },
                          ]}
                        >

                          <Select
                            dropdownAlign={{ offset: [0, 4] }}
                            suffixIcon={
                              // <HiOutlineArrowDown color="#29BCC1" />
                              <img src={arrowdropdown} alt />
                            }
                            className="c_select"
                          >
                            {cities?.map((city) => (
                              <Option value={city.id} key={city.id}>
                                {city.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    <Col md={4}>
                      <Form.Item
                        name="gender"
                        label="Gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select your gender!",
                          },
                        ]}
                      >
                        <Select dropdownAlign={{ offset: [0, 4] }} suffixIcon={<img src={arrowdropdown} alt />} className="c_select">
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                          <Option value="other">Other</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Row>
                        <Col md={4}>
                          <Form.Item name="day" label="Date of Birth">
                            {/* <div className="flex_start justify_between"> */}
                            <Select
                              dropdownAlign={{ offset: [0, 4] }}
                              suffixIcon={<img src={arrowdropdown} alt />}
                              className="c_select"
                              placeholder="DD"
                              id="day"
                            >
                              {days.map((day, index) => (
                                <Option key={index} value={day}>
                                  {day}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item name="month" label={mobile ? "" : " "}>
                            <Select
                              dropdownAlign={{ offset: [0, 4] }}
                              suffixIcon={
                                // <HiOutlineArrowDown color="#29BCC1" />
                                <img src={arrowdropdown} alt />
                              }
                              className="c_select"
                              placeholder="MM"
                              id="month"
                            >
                              {months.map((month, index) => (
                                <Option key={index} value={month}>
                                  {months_name[month - 1]}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item name="year" label={mobile ? "" : " "}>
                            <Select
                              dropdownAlign={{ offset: [0, 4] }}
                              suffixIcon={<img src={arrowdropdown} alt />}
                              className="c_select"
                              placeholder="YYYY"
                              id="year"
                            >
                              {years.map((year, index) => (
                                <Option key={index} value={year}>
                                  {year}
                                </Option>
                              ))}
                            </Select>
                            {/* </div> */}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <div className="flex_start justify_between">
                        <Form.Item
                          name="pmc_num"
                          label="PMC No*"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your PMC number!",
                            },
                            // {
                            //   pattern: /^(?:\d*)$/,
                            //   message: "Value should contain just number",
                            // },
                          ]}
                        >
                          <Input type="text" className="c_input" />
                        </Form.Item>
                        <Form.Item
                          name="experience_year"
                          label="Years of Experience"
                          rules={[
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Value should contain just number",
                            },
                          ]}
                        >
                          <Input type="text" className="c_input" />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        style={{ cursor: "pointer" }}
                        name="speciality"
                        label="Speciality*"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Speciality!",
                          },
                        ]}
                      >
                        {specialities?.length > 0 ? (
                          <Select
                            style={{ cursor: "pointer" }}
                            dropdownAlign={{ offset: [0, 4] }}
                            suffixIcon={<img src={arrowdropdown} alt />}
                            className="c_select speciality"
                            mode="multiple"
                            showSearch
                            optionFilterProp="children"
                            // defaultValue={user?.data?.user?.doctor_specialities}
                            allowClear
                          // onSelect={(value) => {
                          //   setSpecialityID(value);
                          //   dispatch(getServices(specialityID));
                          // }}
                          >
                            {
                              specialities?.map((name) => (
                                <Option
                                  // onChange={(e) => setSpeciality(e.target.value)}
                                  name="speciality[]"
                                  key={name?.name}
                                  value={name?.id}
                                  style={getStyles(name, personName, theme)}
                                >
                                  {name?.name}
                                  {/* {console.log(personName)} */}
                                </Option>
                              ))}

                            {/* {speciality?.data?.specialities?.map((speciality) => (
                          <Option value={speciality?.id} key={speciality?.id}>
                            {speciality?.name}
                          </Option>
                        ))} */}
                            <Option value="other">Other</Option>
                          </Select>
                        ) : (
                          <Select
                            dropdownAlign={{ offset: [0, 4] }}
                            suffixIcon={<img src={arrowdropdown} alt />}
                            className="c_select"
                          >
                            <Option value="other">Other</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="border-top-about">
                    <HeadingWithSpaceLarge text="ABOUT" />
                    <Form.Item name="about" label="Write a bit about yourself">
                      {/* <TextArea
                    defaultValue={note || ''}
                    onChange={(e) => {
                      // setNote(e.target.value)
                      // this.setNote({note: e.target.value})
                      // setNote(e.target.value)

                      setNote({note: e.target.value})
                      console.log(e.target.value)

                    }}
                    rows={6}
                    className="c_input"

                  /> */}
                      <TextArea defaultValue="" rows={6} className="c_input" name="about" />
                    </Form.Item>
                  </div>
                </div>
                {/* <div className={mobile ? location?.search?.split('?')[1] === "#about" || typeof location?.search?.split('?')[1] === 'undefined' ? "UpdateProfileSection" : "d-none" : "UpdateProfileSection"} id="about">
              <HeadingWithSpaceLarge text="ABOUT" />
              <Form.Item name="about" label="Write a bit about yourself">
                <TextArea
                  defaultValue={note || ''}
                  onChange={(e) => {
                    // setNote(e.target.value)
                    // this.setNote({note: e.target.value})
                    // setNote(e.target.value)

                    setNote({note: e.target.value})
                    console.log(e.target.value)

                  }}
                  rows={6}
                  className="c_input"

                />
            <TextArea
              defaultValue=''
              rows={6}
              className="c_input"
            />
          </Form.Item>
        </div> */}
                <div
                  className={
                    mobile
                      ? location?.search?.split("?")[1] === "#education"
                        ? "UpdateProfileSection"
                        : "d-none"
                      : "UpdateProfileSection"
                  }
                  id="education"
                >
                  <HeadingWithSpaceLarge text="EDUCATION" />
                  {/* <Row>
                <Col md={4}>
                  <Form.Item name="degree" label="Degree Name">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item name="yearOfGrad" label="Year of Graduation">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item name="institutionName" label="Institution Name">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                </Col>
              </Row> */}
                  {education}
                  <SimpleButton
                    bgColor="transparent"
                    onClick={addMoreEducation}
                  >
                    <div className="flex_start">
                      <img src={addIcon} alt="addIcon" />
                      <HeadingDescSmall text="Add more" color="black" />
                    </div>
                  </SimpleButton>
                </div>
                <div
                  className={
                    mobile
                      ? location?.search?.split("?")[1] === "#experience"
                        ? "UpdateProfileSection"
                        : "d-none"
                      : "UpdateProfileSection"
                  }
                  id="experience"
                >
                  <HeadingWithSpaceLarge text="EXPERIENCE" />
                  {/* <Row>
                <Col md={4}>
                  <Form.Item name="designation" label="Designation">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item name="institution_name" label="Institution Name">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                </Col>
                <Col md={2}>
                  <Form.Item name="start_date" label="Start Date">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                </Col>
                <Col md={2}>
                  <Form.Item name="end_date" label="End Date" id="end_date">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                  <div className="flex_end">
                    <CheckboxComponent text="Till date" />
                  </div>
                </Col>
              </Row> */}
                  {experience}
                  <SimpleButton
                    bgColor="transparent"
                    onClick={addMoreExperience}
                  >
                    <div className="flex_start">
                      <img src={addIcon} alt="addIcon" />
                      <HeadingDescSmall text="Add more" color="black" />
                    </div>
                  </SimpleButton>
                </div>
                <div
                  className={
                    mobile
                      ? location?.search?.split("?")[1] === "#prof_member"
                        ? "UpdateProfileSection"
                        : "d-none"
                      : "UpdateProfileSection"
                  }
                  id="prof_member"
                >
                  <HeadingWithSpaceLarge text="PROESSIONAL MEMBERSHIP" />
                  {/* <Row>
                <Col md={4}>
                  <Form.Item name="institutionName" label="Institution Name">
                    <Input type="text" className="c_input" />
                  </Form.Item>
                </Col>
              </Row> */}
                  {membership}
                  <SimpleButton
                    bgColor="transparent"
                    onClick={addMoreMembership}
                  >
                    <div className="flex_start">
                      <img src={addIcon} alt="addIcon" />
                      <HeadingDescSmall text="Add more" color="black" />
                    </div>
                  </SimpleButton>
                </div>
                <div
                  className={
                    mobile
                      ? location?.search?.split("?")[1] === "#services"
                        ? "UpdateProfileSection"
                        : "d-none"
                      : "UpdateProfileSection"
                  }
                  id="services"
                >
                  <HeadingWithSpaceLarge text="SERVICES" />
                  <Row>
                    <Col md={8}>
                      {/* <Form.Item name="service" label="Select Services">
                    <div className="services">
                      {isEmpty(services) === false ? (
                        <div className="checkbox_container">
                          <div>
                          {services?.data?.services.map((ser, index) => (
                            // <CheckboxComponent
                            //   key={index}
                            //   value={service.id}
                            //   text={service.name}
                            //   onChange={onChecked}
                            // />
                            <>
                              <Checkbox
                                key={index}
                                value={ser.id}
                                onChange={onChecked}
                                defaultChecked={profile?.doctor_services?.includes(ser.name)}
                              >
                                {ser.name}
                              </Checkbox>
                            </>
                          ))}
                          <Checkbox.Group options={serv} defaultValue={serv_checked} onChange={onChecked} />
                        </div>
                      ) : (
                        <Checkbox>Others</Checkbox>
                      )}
                      </div>
                    </div>
                  </Form.Item> */}
                      <Form.Item name="service" label="Select Services">
                        {/* {console.log(allServices, 'allServicesZa')} */}
                        {services?.length > 0 ? (
                          <Select
                            dropdownAlign={{ offset: [0, 4] }}
                            suffixIcon={<img src={arrowdropdown} alt />}
                            className="c_select"
                            mode="multiple"
                            showSearch
                            optionFilterProp="children"
                            allowClear

                          // onSelect={(value) => {
                          //   setSpecialityID(old=> [...old,value?.id]);
                          //   // dispatch(getServices(specialityID));
                          // }}
                          // open={open}
                          // onClick={() => setOpen(true)}
                          // onDropdownVisibleChange={(visible) => setOpen(visible)}
                          // defaultValue={user?.data?.user?.doctor_services}
                          >
                            {/* {console.log(services?.[0]?.name, 'servname')} */}
                            {services?.map((ser, index) => (
                              <Option value={ser?.id} key={ser?.name}>
                                {ser?.name}
                              </Option>
                            ))}
                            <Option value="other">Other</Option>
                          </Select>
                        ) : (
                          <Select
                            dropdownAlign={{ offset: [0, 4] }}
                            suffixIcon={<img src={arrowdropdown} alt />}
                            className="c_select"
                          >
                            <Option value="other">Other</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <div className="updateButton">
                    {/* <SimpleButtonSmall type="submit" text="SAVE CHANGES" /> */}
                    <button
                      style={{ opacity: '0.4' }}
                      className="submit-btn-completed add-record-btn text-uppercase w-100 fw-700"
                      disabled
                      type="submit"
                    >
                      Save
                      <span className="add-record-chevron">
                        <FiChevronRight />
                      </span>
                    </button>
                  </div>
                </Row>
              </Form>
              <ToastContainer />
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}

export default React.memo(UpdateProfile);
