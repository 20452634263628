import {
    createSlice,
    current,
    isFulfilled,
    isPending,
    isRejected,
  } from "@reduxjs/toolkit";

  import { postBankDetails, getBankDetails, getEarning } from "./thunk";

  const thunks = [postBankDetails, getBankDetails, getEarning];

  const initialState = {
    status: "idle",
    Bank: {},
    Earning: {},
    isEarningLoading: false
    // userBank: "",
  };

  export const slice = createSlice({
    name: "bankDetail",
    initialState,
    reducers: {

    //   setBank: (state, bank) => {
    //     state.userBank = bank.payload
    //   }
    },
    extraReducers: (builder) => {
      builder
        // .addCase(postBankDetails.fulfilled, (state, action) => {
        //   state.status = "idle";
        //   state.Login = action.payload;
        // })
        .addCase(getBankDetails.fulfilled, (state, action) => {
          state.status = "idle";
          state.Bank = action.payload;
        })
        .addCase(getEarning.fulfilled, (state, action) => {
          state.status = "idle";
          state.Earning = action.payload;

        })
        .addMatcher(isPending(...thunks), (state) => {
        })
        .addMatcher(isFulfilled(getBankDetails), (state) => {})
        .addMatcher(isRejected(...thunks), (state, action) => {});
    },
  });

//   export const { setBank } = slice.actions;


  export const selectBank = (state) => state.earning.Bank;
  // export const selectEarning = (state) => console.log(state.earning.Earning.data);
  export const selectedEarning = (state) => state.earning.Earning.data;
  export const earningStatus = (state) => state.earning.Earning.status;
//   export const selectEmail = (state) => state.login.userEmail;


  export default slice.reducer;
