import { get, post } from "../../../utils/httpService";

const SERVICE_URLS = {
  getProfile: () => `/user`,
  getUniversities: () => `/universities`,
  getDegrees: () => `/degrees`,
  getSpeciality: () => `/specialities`,
  getCities: () => `/cities?search=`,
  getServices: (qs) => `/services?speciality_id=${qs}`,
  postUpdateProfile: () => `/doctor/update`,
};

export const getProfile = (params) => {
  return get(SERVICE_URLS.getProfile(), params);
};

export const getUniversities = (params) => {
  return get(SERVICE_URLS.getUniversities(), params);
};

export const getDegrees = (params) => {
  return get(SERVICE_URLS.getDegrees(), params);
};

export const getCities = (params) => {
  return get(SERVICE_URLS.getCities(), params);
};

export const getSpeciality = (params) => {
  return get(SERVICE_URLS.getSpeciality(), params);
};

export const getServices = (params, qs) => {
  return get(SERVICE_URLS.getServices(qs), params);
};

export const postUpdateProfile = (body, params) => {
  console.table(body);
  return post(SERVICE_URLS.postUpdateProfile(), body, params);
}