import React from "react";
import { Row} from "react-bootstrap";
import { LinkContainerSmall, SimpleButton } from "../../uiComponents/button";
import { HeadingWithSpaceLarge } from "../../uiComponents/Headings";
import addIcon from "../../assets/images/svg/add.svg";
import HospitalCard from "../../uiComponents/card/hospitalCard/HospitalCard";
import { useEffect } from "react";
import { useState } from "react";

function Clinics(props) {
  const { type, hospital, menu} = props;
  const [mobile, setMobile] = useState(false)

  useEffect(()=> {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 600) {
        setMobile(true)
      }
    }
  },[])
  return (
    <div className="clinics">
      <Row>
        <SimpleButton bgColor="transparent">
          <div className="flex_start gap_flex1">
            {!mobile && <HeadingWithSpaceLarge text="ADD HOSPITALS/CLINIC" color="black" />}
            <LinkContainerSmall to={{pathname: "/clinic-info", state: {props:{}, type:type, menu:menu}}}><img src={addIcon} alt="addIcon" /></LinkContainerSmall>
          </div>
        </SimpleButton>
      </Row>
      {Object.entries(hospital).map(([num, hospitals]) => {
        return (
          <Row key={num}>
            <HospitalCard {...hospitals} menu={menu} />
          </Row>
        );
      })}
    </div>
  );
}

export default Clinics;
