import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
// import LoginLogo from "../../assets/images/png/LoginLogo.png"
import OTPInput, { ResendOTP } from "otp-input-react";
import LoginAunty from "../../assets/images/png/girlFile.png";
import LoginAuntyMobile from "../../assets/images/png/opt-mobile-screen.png";
import { FiChevronRight } from "react-icons/fi";
import i18n from "../../i18n";
import { useNavigate, useParams, Link, useHistory } from "react-router-dom";
import { BsX } from "react-icons/bs";
import "./fewMoreDetail.css";
import englishUrduLogo from '../../assets/images/svg/englishUrduLogo.svg';
import Logo from "../../assets/images/png/meri-sehat-logo.png";
import encryptStorage from "../../utils/encryptStorage";
import API from "../../utils/customAxios";
import Loader from "../../uiComponents/loader/Loader";
import { maskPhone } from "../../utils/helperFunctions";
import Cookies from "js-cookie";

const DoctorSignInOtp = () => {
  const history = useHistory();

  // const signupDetails = () => {
  //   history.push('/signup-details')
  // }

  const [Otp, setOtp] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [url, setURL] = useState("");
  const [hideError, setHideError] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [resendOtpError, setResendOtpError] = useState("");

  let phone = encryptStorage.getItem("phoneLogin");
  let maskedPhone = maskPhone(phone);

  let inputStyle = {
    height: "100px",
    width: "100px",
    borderRadius: "10px",
    marginRight: "0px",
    // marginLeft: '8px',
    // border:  '0.5px solid #959494 ',
  };

  const maskNumber = encryptStorage.getItem("phoneLogin");

  let maskContact = maskPhone(maskNumber);

  function handleSubmit(e) {
    e.preventDefault();

    if (Otp.length === 4) {
      (async () => {
        const phone = encryptStorage.getItem("phoneLogin");

        const data = {
          phone: `${phone}`,
          otp: Otp,
        };

        try {
          setApiLoading(true);
          const response = await API.post("/doctor/login-via-phone-otp", data);

          if (response?.data?.code === 200) {
            setApiLoading(false);
            // localStorage.setItem("D_USER_ID", response?.data?.data?.user?.id);
            Cookies.set("user_id", response?.data?.data?.user?.id);
            Cookies.set("token", response?.data?.data?.user?.access_token, {
              expires: 30,
            });

            let link = window.location.href;

            let linkArray = link.split("/");

            linkArray[linkArray.length - 1] = "";

            link = linkArray.join("/");

            window.location.href = link;
          } else {
            setApiLoading(false);
            setOtpError(
              response?.data?.message || "Error! Something went wrong..."
            );
          }
        } catch (error) {
          setApiLoading(false);
          setOtpError(error?.response?.data?.message || error?.message);
        }
      })();
    } else {
      setOtpError("Enter OTP code");
    }
  }

  const renderInstantTime = (remainingTime) => {
    return (
      <span>
        {" "}
        {remainingTime === 0
          ? ""
          : ` 00:${remainingTime < 10 ? `0` : ""}${remainingTime} seconds`}
      </span>
    );
  };

  const renderInstantButton = (buttonProps) => {
    return (
      <button {...buttonProps}>
        {buttonProps.remainingTime === 0 ? (
          <>
            {" "}
            <a
              className="_underline_ancer1"
              style={{ color: "#E9406A", textDecoration: "underline" }}
              onClick={resendOtpHandler}
            >
              Resend SMS
            </a>
            <br /> <p className="mt-1 fw-bold"> Code Expired - Click Resend</p>{" "}
          </>
        ) : (
          "Resend SMS"
        )}
      </button>
    );
  };

  async function resendOtpHandler() {
    try {
      const phone = encryptStorage.getItem("phoneLogin");
      const data = {
        phone,
      };

      const response = await API.post("/resend-otp", data);

      if (response?.data?.code === 200) {
        window.location.reload();
      } else {
        setResendOtpError(
          response?.data?.message || "Your OTP could not be resent..."
        );
      }
    } catch (error) {
      setResendOtpError(error?.response?.data?.message || error?.message);
    }
  }

  const hideOtpError = () => {
    setHideError(true);
  };

  return (
    <>
      <StyledLoginOtp className="signup_otp">
        <div className="header_meriSehat">
          <Container fluid>
            {apiLoading && <Loader />}
            <Row>
              <Col md={12}>
                <div className="logo_only ddd">
                  {/* <Link to="/"> */}
                  <div className="d-none d-sm-block">
                    <img src={Logo} alt="Logo" />
                  </div>
                  <div className="d-block d-sm-none">
                    <img src={englishUrduLogo} alt="Logo" />
                  </div>
                  {/* </Link> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <Col md={6}>
              <div className="login-below">
                <img src={LoginAunty} className="img-fluid d-none d-md-block" />
                <img
                  src={LoginAuntyMobile}
                  className="img-fluid d-md-none mt-4 mb-4"
                />
              </div>
            </Col>
            <Col md={6} className="hk_signin_otp_doctor signup_otp_style">
              <div className="heading-confirm col-lg-5 m-auto mt-4">
                <h3>Confirm your phone number</h3>
              </div>
              {maskedPhone && (
                <div className="mt-3 confirm-paragraph col-lg-7 m-auto d-none d-md-block">
                  <p>
                    An SMS has been sent to the following phone number:{" "}
                    <span> {maskedPhone} </span>
                  </p>
                </div>
              )}

              <form className="form" autoComplete="off" onSubmit={handleSubmit}>
                <div className="px-md-4 otp-login-box">
                  <OTPInput
                    value={Otp}
                    onChange={setOtp}
                    autoFocus
                    hasErrored
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure={false}
                    className={
                      otpError && !hideError
                        ? "input-otp-error otpContainer pb-0"
                        : "otpContainer" && resendOtpError && !hideError
                          ? "input-otp-error otpContainer pb-0"
                          : "otpContainer"
                    }
                    inputStyles={inputStyle}
                  />

                  {otpError && !hideError && (
                    <div
                      className="doctor-error-msg"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="otpErrorState"> {otpError} </p>
                      {/* <BsX onClick={hideOtpError} className="bsX" /> */}
                    </div>
                  )}

                  {resendOtpError && !hideError && (
                    <div
                      className="doctor-error-msg"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p> {resendOtpError} </p>
                      {/* <BsX onClick={hideOtpError} className="bsX" /> */}
                    </div>
                  )}
                  {maskedPhone && (
                    <div className="mt-3 confirm-paragraph col-lg-7 m-auto d-md-none">
                      <p>
                        An SMS has been sent to the following phone number:{" "}
                        <span> {maskedPhone} </span>
                      </p>
                    </div>
                  )}
                  {/* </div> */}
                </div>
                <div className="resend-sms-otp px-4 mt-5">
                  <ResendOTP
                    maxTime={60}
                    className="OtpCounting"
                    style={{ display: "grid", justifyContent: "center" }}
                    renderButton={renderInstantButton}
                    renderTime={renderInstantTime}
                  //    onResendClick={resendOTPHandler}
                  />
                </div>

                <div className="Otp-continue-btn">
                  <button
                    type="submit"
                    diabled={apiLoading}
                    className="review-button text-uppercase loginOtp-phone-btn position-relative"
                  >
                    Continue
                    <span
                      className="loginOtp-phone-chevron"
                      style={{ height: "53px" }}
                    >
                      <FiChevronRight />
                    </span>
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </StyledLoginOtp>
    </>
  );
};

export const StyledLoginOtp = styled.section`
  /* position: relative; */
  .LogoStyled {
    padding-top: 55px;
  }

  .heading-confirm {
    text-align: center;
    // padding: 0 175px;

    h3 {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      /* or 125% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #313131;
    }
  }

  .confirm-paragraph {
    // padding: 0 100px;
    text-align: center;

    p {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      color: #313131;
    }

    span {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #313131;
    }
  }

  .otp-font {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 55px;
    /* identical to box height */

    text-align: center;

    color: #313131;
  }

  .login-below {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .loginOtp-phone-btn {
    border-radius: 12px;
    background-color: #19b3b5;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53px;
    padding: 0;
    width: 100%;
    left: 0;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;

    .loginOtp-phone-chevron {
      float: right;
      background-color: #078a8e !important;
      font-size: 16px;
      height: 44px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 10px 10px 0px;
      position: absolute;
      right: 0;
      left: auto;
    }
  }

  .otp-login-box {
    padding-top: 40px;
  }

  .Otp-continue-btn {
    padding-top: 30px;
    margin-left: 60px;
  }

  .otpContainer {
    display: flex;
    justify-content: center;
    gap: 30px;

    input {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 45px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #313131;
    }
  }

  .doctor-error-msg {
    margin-top: 6px;
    margin-left: 6px;
    // background-color: #fee6e6;
    padding: 3px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    color: #b11b1b;
    width: 85%;
    margin: 0 auto;

    .bsX {
      cursor: pointer;
      margin-top: 2px;
      font-size: 20px;
    }
  }

  .input-otp-error {
    input {
      border: 1px solid #bc0001 !important;
      color: #bc0001;
    }
  }
  @media only screen and (max-width: 767px) {
    .heading-confirm h3 {
      font-size: 20px;
      line-height: 27px;
      margin-top: 25px;
      justify-content: center;
    }
    .otp-login-box {
      padding-top: 0px;
    }
    .doctor-error-msg p {
      justify-content: space-between;
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 450;
      font-size: 15px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #bc0001;
      margin-top: 7px;
      word-spacing: -3px;
     
    }
    .otpContainer input {
      width: 44px !important;
      height: 44px !important;
      border-radius: 5px !important;
      font-family: "Circular Std";
      font-weight: 450;
      font-size: 22px;
      line-height: 150%;
    }
    // .Otp-continue-btn {
    //   width: 100%;
    // }
    .loginOtp-phone-btn .loginOtp-phone-chevron {
      background-color: transparent !important;
      position: relative;
      width: auto;
    }
  }
`;

export default DoctorSignInOtp;
