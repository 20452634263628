import { useParams } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import HealthClinic from "../pages/health-clinics/HealthClinic";
import OnlineTime from "../pages/health-clinics/OnlineTime";
import ClinicInfo from "../pages/health-clinics/ClinicInfo";
import ClinicTimings from "../pages/health-clinics/ClinicTimings";
import Appointments from "../pages/appointments/Appointments";
import AppointmentFirstTime from "../pages/appointments/AppointmentFirstTime";
import AppointmentFollowUp from "../pages/appointments/AppointmentFollowUp";
import PastConsultation from "../pages/appointments/PastConsultation";
import MobileAppointment from "../pages/appointments/MobileAppointment";
import Earnings from "../pages/earnings/Earnings";
import Tutorials from "../pages/tutorials/Tutorials";
import UpdateProfile from "../pages/updateProfile/UpdateProfile";
import ChangePassword from "../pages/changePassword/ChangePassword";
import Authentication from "../pages/mobileAuthentication/Authentication";
import Reviews from "../uiComponents/reviews/Reviews";
import PaymentHistory from "../pages/paymentHistory/PaymentHistory";
import DeductionList from "../pages/DeductionList/DeductionList";
import ProfileSetup from "../pages/profileSetup/ProfileSetup";
import DashboardNew from "../pages/dashboardNew/DashboardNew";
import ProfileUpdates from "../pages/profileUpdate/ProfileUpdates";
import SignupDetails from "../pages/login/SignupDetails";
import PleaseWait from "../pages/login/PleaseWait";
import SignupThankyou from "../pages/login/SignupThankyou";
import SignupFirstThankyou from "../pages/login/SignupFirstThankyou";
import CalendlyAvailability from "../pages/login/CalendlyAvailability";

export const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/auth/:token/:id",
    exact: true,
    component: Authentication,
  },
  {
    path: "/health-clinics",
    exact: true,
    component: HealthClinic,
  },
  {
    path: "/clinic-timing",
    exact: true,
    component: OnlineTime,
  },
  {
    path: "/clinic-info",
    exact: true,
    component: ClinicInfo,
  },
  {
    path: "/clinic-timing",
    exact: true,
    component: ClinicTimings,
  },
  {
    path: "/appointments",
    exact: true,
    component: Appointments,
  },
  {
    path: "/appointment/:id",
    exact: true,
    component: AppointmentFirstTime,
  },
  {
    path: "/appointment-follow-up",
    exact: true,
    component: AppointmentFollowUp,
  },
  {
    path: "/appointment-detail",
    exact: true,
    component: MobileAppointment,
  },
  {
    path: "/past-consultation/:id",
    exact: true,
    component: PastConsultation,
  },
  {
    path: "/earnings",
    exact: true,
    component: Earnings,
  },
  {
    path: "/tutorials",
    exact: true,
    component: Tutorials,
  },
  {
    path: "/update-profile",
    exact: true,
    component: UpdateProfile,
  },
  {
    path: "/change-password",
    exact: true,
    component: ChangePassword,
  },
  {
    path: "/reviews",
    exact: true,
    component: Reviews,
  },
  {
    path: "/payment-history",
    exact: true,
    component: PaymentHistory,
  },
  {
    path: "/deduction-list",
    exact: true,
    component: DeductionList,
  },
  {
    path: "/profile-setup",
    exact: true,
    component: ProfileSetup,
  },
  {
    path: "/dashboard-new",
    exact: true,
    component: DashboardNew,
  }, {
    path: "/profile-update",
    exact: true,
    component: ProfileUpdates,
  },
  {
    path: "/signup-details",
    exact: true,
    component: SignupDetails
  },
  {
    path: "/please-wait",
    exact: true,
    component: PleaseWait
  },
  {
    path: "/thankyou-doctor",
    exact: true,
    component: SignupThankyou
  },
  {
    path: "/thanks-availability",
    exact: true,
    component: SignupFirstThankyou
  },
  {
    path: "/calendly-availability",
    exact: true,
    component: CalendlyAvailability
  }
];

