export const URL = {
  dashboard: "/",
  healthClinic: "/health-clinics",
  appointments: "/appointments",
  earnings: "/earnings",
  tutorials: "/tutorials",
  login: "/login",
  profile: "/update-profile",
  password: "/change-password",
  dashboardNew: "/dashboard-new",
};
