import React, { useEffect, useState } from "react";
import { Select, Form, Input } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import { HeadingWithSpaceLarge } from "../../uiComponents/Headings";
import { LinkContainerSmall, SimpleButton } from "../../uiComponents/button";
import arrowRight from "../../assets/images/svg/arrow-right-white.svg";
// import { Map, Marker } from "google-maps-react";
// import GoogleMapReact from "google-map-react";
// import MapComponent from "../../uiComponents/map/MapComponent"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useAppDispatch, useAppSelector } from "./../../redux/hooks";
import { selectClinic, setClinic } from "./redux/slice";
import { useHistory } from "react-router-dom";
import { HiOutlineArrowDown } from "react-icons/hi";
import "leaflet/dist/leaflet.css";
import { getClinics } from "./redux/thunk";
import { isEmpty } from "../../helpers/objectHelper";
import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import loadingGif from "../../assets/images/gif/loader_gif.gif";

// import { getClinics } from "./redux/service";

function ClinicInfo(props) {
  const type = [];
  // console.log(props);
  try {
    type.push(props?.location?.state?.type);
  } catch {
    type.push(props?.type);
  }
  const [clinicID, setClinicID] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [clinicData, setClinicData] = useState({});
  const [newClinic, setNewClinic] = useState(false);
  const dispatch = useAppDispatch();
  const clinics = useAppSelector(selectClinic);
  const history = useHistory();
  const [clinicForm] = Form.useForm();
  let clinicdata = props?.location?.state?.prop;

  const addClinic = (values) => {
      let clinic_data = {
        clinic_id: clinicID,
        consultation_fee: values?.consultation_fee,
        consultation_duration: values?.consultation_duration,
      };
      if (clinic_data?.clinic_id === "") {
        clinic_data.clinic_id = clinicdata?.id;
      }
      let tim;
      try {
        tim = clinicdata.timing;
      } catch {
        tim = null;
      }
      // console.log(clinicdata, 'ja')
      dispatch(setClinic(clinic_data));
      history.push("/clinic-timing", {
        duration: values?.consultation_duration,
        clinic_timing: clinicdata ? clinicData?.time_slots : [],
        id: clinicdata?.clinic?.id,
        type: type[0],
        menu: menuList,
        fee: values?.consultation_fee,
      });


  };
  const { Option } = Select;

  useEffect(() => {
    dispatch(getClinics());
  }, [dispatch]);

  useEffect(() => {
    // if (clinicData?.name !== clinicdata?.name) {
    setClinicData(clinicdata);
    // }
  }, [clinicData, clinicdata]);

  if (clinicData) {
    clinicForm.setFieldsValue({
      name: clinicData?.clinic?.id,
      address: clinicData?.clinic?.address,
      consultation_fee: clinicData?.consultation_fee,
      consultation_duration: clinicData?.consultation_duration2,
    });
  }

  const menuList = props?.location?.state?.menu;

  return (
    <>
    {Object.keys(clinics).length === 0 ? (
        <div className="loaderWrapper container">
        <img src={loadingGif} alt="" />
      </div>
      ) : (
    <div className="infoClinic cover_space2">
      <StickyTab menuList={menuList} />
      <div className="online_time cover_space3 add_hospital">
        <Container>
          <Row>
            <Col lg={12}>
              <HeadingWithSpaceLarge
                text="HOSPITAL/CLINIC INFORMATION"
                color="black"
              />
            </Col>
          </Row>
          <Form form={clinicForm} layout="vertical" onFinish={addClinic}
          >
            <div className="column_flex justify_between">
              <div className="w-100">
                <Row>
                  <Col lg={4}>
                    <Form.Item name="name" label="Hospital/Clinic Name" rules={[
                      {
                        required: true,
                        message: "Please enter hospital's name"
                      }
                    ]}>
                      {clinicData?.clinic?.id ? (
                        <>
                          <Select dropdownAlign={{ offset: [0, 4] }}
                            suffixIcon={<HiOutlineArrowDown color="#29BCC1" />}
                            className="c_select"
                            defaultValue={clinicData?.clinic?.id}
                            showSearch
                            key={clinicData?.clinic?.id}
                            onSelect={(value) => {
                              if (value === "other") {
                                setClinicID(clinics.data.length + 1);
                                setNewClinic(true);
                              } else {
                                setClinicID(value);
                                const obj = clinics.data.find(
                                  (item) => item.id === value
                                );
                                setClinicAddress(obj.address);
                                // console.log(obj.address);
                                clinicForm.setFieldsValue({
                                  address: obj.address,
                                });
                                setNewClinic(false);
                              }
                            }}
                            filterOption={(input, option) => {
                              return (
                                option?.key?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 ||
                                option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                              );

                            }}

                          >
                            {/* {clinicData?.id ? (
                              <>
                                {clinics?.data?.map((clinic, index) => (
                                  <Option value={clinic.id} key={clinic.id}>
                                    {clinic.name}
                                  </Option>
                                ))}

                                <Option value="other">Other</Option>
                              </>
                            ) : (
                              <> */}
                            {clinics?.data?.map((clinic, index) => (
                              <Option value={clinic.id} key={index}>
                                {clinic.name}
                              </Option>
                            ))}

                            <Option value="other">Other</Option>
                            {/* </>
                            )} */}
                          </Select>
                        </>
                      ) : (
                        <Select dropdownAlign={{ offset: [0, 4] }}
                          suffixIcon={<HiOutlineArrowDown color="#29BCC1" />}
                          className="c_select"
                          showSearch
                          // initalValue={clinicData?.clinic?.id ? clinicData?.clinic?.id : ""}
                          onSelect={(value) => {
                            setClinicAddress(value);
                            setClinicID(value);
                          }}
                          filterOption={(input, option) => {
                            return (
                              option?.key?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 ||
                              option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                            );

                          }}

                        >
                          <Option value={clinicData?.id}>
                            {clinicData?.name}
                          </Option>
                          {clinics?.data?.map((clinic, index) => (
                            <Option value={clinic.id} key={index}>
                              {clinic.name}
                            </Option>
                          ))}

                          <Option value="other">Other</Option>
                        </Select>
                      )}
                    </Form.Item>
                    {newClinic ? (
                      <div>
                        <Form.Item name="name" label="Hospital/Clinic Name">
                          <Input type="text" className="c_input" required />
                        </Form.Item>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <p className="labelText">Hospital/Clinic Name</p>
                  <Input placeholder="" className="c_input" /> */}
                  </Col>
                  <Col lg={8}>
                    <Form.Item name="address" label="Address">
                      {clinicData?.address ? (
                        <Input
                          type="text"
                          className="c_input"


                          value={clinicData?.address}
                        />
                      ) : (
                        <Input
                          type="text"
                          className="c_input"

                          value={clinicAddress}
                        />
                      )}
                    </Form.Item>
                    {/* <p className="labelText">Address</p>
                  <Input placeholder="" className="c_input" /> */}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Item
                      name="consultation_fee"
                      label="Consultation Fees"

                      rules={[
                        {
                          required: true,
                          message: "Please enter your Consultation Fee!",
                        },

                        {
                          pattern: /^(?:\d*)$/,
                          message: "Value should contain just number",
                        },
                        {
                          validator: (_, value) => {
                            if (parseInt(value) > 9999) {
                              return Promise.reject("Consultation fees can't be greater than 4 digits.");
                            }

                            else {
                              return Promise.resolve();
                            }
                           }
                        }

                      ]}
                    >
                      {/* <div className="input_box">
                        <span>Rs.</span> */}
                      {clinicData?.price ? (
                        <Input
                          type="text"
                          className="c_input"

                          value={clinicData?.price}
                        />
                      ) : (
                        <Input type="text" className="c_input"  />
                      )}
                      {/* </div> */}

                    </Form.Item>
                    <Form.Item
                      name="consultation_duration"
                      label="Each Time Slot Duration (in minutes)"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Consultation Duration!",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Value should contain just number",
                        },
                      ]}
                    >
                      {clinicData?.time_slot ? (
                        <Select dropdownAlign={{ offset: [0, 4] }}
                        suffixIcon={<HiOutlineArrowDown color="#29BCC1" />}
                        className="c_select"
                        showSearch
                        defaultValue={clinicData?.time_slot}
                      >
                        <Option value="15">15</Option>
                        <Option value="30">30</Option>
                        </Select>
                      ) : (
                        <Select dropdownAlign={{ offset: [0, 4] }}
                        suffixIcon={<HiOutlineArrowDown color="#29BCC1" />}
                        className="c_select"
                        showSearch

                      >
                        <Option value="15">15</Option>
                        <Option value="30">30</Option>
                        </Select>
                      )}
                    </Form.Item>
                    {/* <p className="labelText">Consultation Fees</p>
                  <Input prefix="Rs. " placeholder="" className="c_input" /> */}
                  </Col>
                  <Col lg={8}>
                    {/* <Map
                    google={props.google}
                    zoom={12}
                    center={{
                      lat: 40.854885,
                      lng: -88.081807
                    }}
                  >
                    <Marker position={{ lat: 48.0, lng: -122.0 }} />
                  </Map> */}
                    <div style={{ height: "100%", width: "100%" }}>
                      {/* <GoogleMapReact
                      bootstrapURLKeys={{ key: "" }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                      />

                    </GoogleMapReact> */}

                      <MapContainer
                        center={[24.892, 67.0747]}
                        zoom={16}
                        scrollWheelZoom={false}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {/* <Marker position={[51.505, -0.09]}>
                        <Popup>
                          A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                      </Marker> */}
                      </MapContainer>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                <Col lg={4}>
                  <Form.Item
                    name="consultation_duration"
                    label="Each Time Slot Duration (in minutes)"
                  >
                    <Input type="number" className="c_input" required />
                  </Form.Item>
                </Col>
              </Row> */}
              </div>
              <div className="confirm_btn">
                <Row>
                  <Col lg={12}>
                    <SimpleButton type="submit" text="NEXT">
                      {/* <LinkContainerSmall text="NEXT" to="/clinic-timing"> */}
                      <img src={arrowRight} alt="arrowRight" />
                      {/* </LinkContainerSmall> */}
                    </SimpleButton>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </Container>
      </div>
    </div>
    )}
    </>
  )
}

export default ClinicInfo;
