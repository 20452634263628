import React, { useState, useEffect } from "react";
/* eslint-disable react/no-array-index-key */
import {
  Row,
  Col,
  Container,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

import {
  HeadingDesc,
  HeadingDescSmall,
  HeadingDescVsmall,
  HeadingWithSpaceLarge,
} from "../../uiComponents/Headings";
import { TableComponent } from "../../uiComponents/tableComponent";
import { LinkContainerSmall, SimpleButton } from "../../uiComponents/button";
import tagIcon from "../../assets/images/svg/platinium-rank.svg";
import {
  RiArrowRightSLine,
  RiArrowLeftSLine,
  RiPoliceCarFill,
} from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { SimpleButtonSmall } from "../../uiComponents/button";
import AddBankModal from "../../uiComponents/modal/addBankModal/AddBankModal";
import ViewInvoiceModal from "../../uiComponents/modal/invoiceModal/InvoiceModal";
import closeIcon from "../../assets/images/svg/close_icon_red.svg";
import downloadIcon from "../../assets/images/png/download.png";
import editIcon from "../../assets/images/svg/edit_icon.svg";
import editIcon11 from "../../assets/images/svg/edit_icon1.svg";
import editIcon1 from "../../assets/images/svg/editicon.svg";
import { isEmpty } from "../../helpers/objectHelper";
import { getEarning } from "./redux/thunk";
import { useAppDispatch, useAppSelector } from "./../../redux/hooks";
import { earningStatus, selectEarning, selectedEarning } from "./redux/slice";
import arrowdropdown from "../../assets/images/svg/dropdown-icon.svg";
import "./Earnings.scss";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import SimpleSlider from "../../uiComponents/sliders/simpleSlider/SimpleSlider";
import axios from "axios";
import {
  getDaysOfMonth,
  getFromLocalStorage,
  getMonthAndYear,
} from "../../utils/helperFunctions";
import swal from "sweetalert";
import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import searchIcon from "../../assets/images/png/search.png";
import Loader from "../../uiComponents/loader/Loader";
import { Form, Input, Select, DatePicker } from "antd";
import API from "../../utils/customAxios";
import { Link } from "evergreen-ui";
import EarningTable from "../../uiComponents/tableComponent/tableComp/EarningTable";
import Cookies from "js-cookie";
import { render } from "@testing-library/react";

function Earnings() {
  let loading = true;
  const dispatch = useAppDispatch();
  const selectEarningDetails = useAppSelector(selectedEarning);
  const status = useAppSelector(earningStatus);

  const [form] = Form.useForm();
  const { Option } = Select;

  const [modal, setModal] = useState("false");
  const [earning_overview, setearning_overview] = useState({});
  const [earning_table, setearning_table] = useState([]);
  const [receivables, setReceivables] = useState({});
  const [appointments, setAppointments] = useState({});
  const [bank_detail, setbank_detail] = useState({});
  const [bank_details, setbank_details] = useState({});
  const [quickSummary, setquickSummary] = useState([]);
  const [appointmentsSummary, setAppointmentsSummary] = useState([]);
  const [earningInfo, setEarningInfo] = useState([]);
  const [tableData, settableData] = useState([]);
  const [datefilter, setDateFilter] = useState([]);
  const [formatedDate, setformatedDate] = useState([]);
  const [buttons, setbuttons] = useState([]);
  const [setTable, setTableSlider] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [ScreenLoading, setScreenLoading] = useState(true);
  const location = useLocation();
  const [earningLoading, setEarningLoading] = useState(false);
  const [earningData, setEarningData] = useState([]);
  const [earningTable, setEarningTable] = useState([]);
  const [earningPage, setEarningPage] = useState(1);
  const [totalEarningCount, setTotalEarningCount] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [appointmentType, setAppointmentType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [earningSearchValue, setEarningSearchValue] = useState("");
  const [currentRecord, setCurrentRecord] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const { RangePicker } = DatePicker;


  const dateFormatList = ['DD/MM/YYYY'];

  const paymentTypeData = [
    {
      value: "1",
      label: "LITE Subscription",
    },
    {
      value: "2",
      label: "PLUS Subscription"
    },
    {
      value: "3",
      label: "PREMIUM Subscription",
    },
    {
      value: "one_time_payment",
      label: "One Time Payment (Credit/debit card)",
    },
    {
      value: "one_time_payment",
      label: "One Time Payment (Easypaisa)",
    },
    {
      value: "one_time_payment",
      label: "One Time Payment (Jazz Cash)",
    },
    {
      value: "one_time_payment",
      label: "One Time Payment (Wallet)",
    },
    {
      value: "one_time_payment",
      label: "One Time Payment (Wallet + Credit/debit card)",
    },
    {
      value: "one_time_payment",
      label: "One Time Payment (Wallet + Easypaisa)",
    },
    {
      value: "one_time_payment",
      label: "One Time Payment (Wallet + Jazz cash)",
    },
  ];

  const tableHeader = [
    { title: "Appt ID", dataIndex: "apptId" },
    { title: "Patient Name", dataIndex: "patientName" },
    {
      title: "Date",
      dataIndex: "date",
      // sorter: {
      //   compare: (a, b) => a.date.localeCompare(b.date),
      //   multiple: 3,
      // },
    },
    {
      title: "Time Slot",
      dataIndex: "time",
      // sorter: {
      //   compare: (a, b) => a.time.localeCompare(b.time),
      //   multiple: 2,
      // },
    },
    {
      title: "Appointment Type",
      dataIndex: "typeText",
      // sorter: {
      //   compare: (a, b) => a.type.localeCompare(b.type),
      //   multiple: 1,
      // },
      render(text, record) {
        return {
          props: {
            style: {
              // color: text.toLowerCase().startsWith("i") ? "#EF6286" : "#29BCC1",
            },
          },
          children: <div className="capitalize">{text}</div>,
        };
      },
    },
    { title: "Amount", 
    dataIndex: "amount",
    
    },
    { title: "Cancellation Fee", dataIndex: "cancellation_fee" },
    { title: "Payment Type", dataIndex: "payment_type" },
    // { title: "Visit Count", dataIndex: "visit_count" },
    {
      title: "Action",
      dataIndex: "consult",
      render(text, record) {
        return {
          children: (
            <div>
              <a
                href="#"
                onClick={() => {
                  setModal("m4");
                  setCurrentRecord(record);
                  console.log({record})
                }}
                style={{ color: "#EF6286", textDecoration: "underline" }}
              >
                View Inovice
              </a>
            </div>
          ),
        };
      },
    },
  ];

  const removeFilters = async (value) => {
    setAppointmentType("");
    setPaymentType("");
    setStartDate(null);
    setEndDate(null);
    setEarningSearchValue("");

    setEarningLoading(true);

    let apiUrl = `/doctor/all-appointment?past`;

    try {
      const response = await API.get(apiUrl);

      setEarningLoading(false);

      if (response?.data?.code === 200) {
        setEarningData(response?.data?.data?.data);
        setTotalEarningCount(response?.data?.data?.total);
      }
    } catch (error) {
      setEarningLoading(false);
    }
  };

  const handleDateRangeChange = async (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);

    let from = dateStrings[0];
    let to = dateStrings[1];

    setEarningLoading(true);

    // console.log(appointmentType, "locke")

    let apiUrl = `/doctor/all-appointment?past&from=${from}&to=${to}`;

    if (appointmentType) apiUrl.concat(`&appointment_type=${appointmentType}`);

    if (paymentType) apiUrl.concat(`&subscription_id=${paymentType}`);

    try {
      const response = await API.get(apiUrl);

      setEarningLoading(false);

      if (response?.data?.code === 200) {
        setEarningData(response?.data?.data?.data);
        setTotalEarningCount(response?.data?.data?.total);
      }
    } catch (error) {
      setEarningLoading(false);
    }
  };

  const handleTotalEarnings = (value) => {
    const totalEarningsInterval = {
      earning_all: "earning_all",
      earning_month: "earning_month",
      earning_today: "earning_today",
      earning_week: "earning_week",
      earning_year: "earning_year",
    };

    const Earnings = earning_overview?.[totalEarningsInterval[value]] || 0;

    setquickSummary([
      {
        heading: "Rs." + earning_overview?.earning_month,
        subheading: "Earnings for this month",
        date: monthYear,
      },
      {
        heading: "Rs." + Earnings,
        subheading: "Total Earnings",
        date: (
          <div>
            <Form layout="vertical" form={form}>
              <Form.Item name="totalEarnings">
                <Select
                  dropdownAlign={{ offset: [0, 4] }}
                  defaultValue="earning_all"
                  suffixIcon={<img src={arrowdropdown} alt />}
                  className="c_select"
                  onChange={handleTotalEarnings}
                >
                  <Option value="earning_today">Today</Option>
                  <Option value="earning_week">Weekly</Option>
                  <Option value="earning_month">Monthly</Option>
                  <Option value="earning_year">Yearly</Option>
                  <Option value="earning_all">Till date</Option>
                </Select>
              </Form.Item>
            </Form>
          </div>
        ),
      },
      {
        heading: "Rs." + receivables?.amount,
        subheading: "Total Receivables",
        subtitle: "You will receive your earnings every 15 days",
        date:
          "Last payout: " +
          moment(receivables?.last_payout)?.format("DD/MM/YYYY"),
      },
    ]);
  };

  const handleAppointmentEarnings = (value) => {
    const intervals = {
      all: "all",
      week: "week",
      month: "month",
      year: "year",
      today: "today",
    };

    const clinicVisits = appointments?.clinic_visits?.[intervals[value]] || 0;
    const doctorNow = appointments?.doctornow?.[intervals[value]] || 0;
    const scheduled = appointments?.scheduled?.[intervals[value]] || 0;

    setAppointmentsSummary([
      {
        heading: "Rs." + scheduled,
        subHeading: "Scheduled video calls",
      },
      {
        heading: "Rs." + doctorNow,
        subHeading: "DoctorNow (Instant video calls)",
      },
      {
        heading: "Rs." + clinicVisits,
        subHeading: "Clinic visits",
      },
    ]);
  };

  useEffect(() => {
    dispatch(getEarning());
    setScreenLoading(false);
    if (typeof window !== "undefined") {
      if (window.innerWidth < 600) {
        setMobile(true);
      }
    }
  }, []);

  useEffect(() => {
    setearning_overview(selectEarningDetails?.earnings);
    setAppointments(selectEarningDetails?.appointments);
    setbank_detail(selectEarningDetails?.bank_details);
    setReceivables(selectEarningDetails?.earnings?.receivables);
  }, [selectEarningDetails]);

  const monthDates = getDaysOfMonth().reverse();
  const monthYear = getMonthAndYear();

  useEffect(() => {
    if (
      typeof earning_overview !== "undefined" &&
      isEmpty(earning_overview) === false
    ) {
      setquickSummary([
        {
          heading: "Rs." + earning_overview?.earning_month,
          subheading: "Earnings for this month",
          date: monthYear,
        },
        {
          heading: "Rs." + earning_overview?.earning_all,
          subheading: "Total Earnings",
          date: (
            <div>
              <Form layout="vertical" form={form}>
                <Form.Item name="totalEarnings">
                  <Select
                    dropdownAlign={{ offset: [0, 4] }}
                    defaultValue="earning_all"
                    suffixIcon={<img src={arrowdropdown} alt />}
                    className="c_select"
                    onChange={handleTotalEarnings}
                  >
                    <Option value="earning_today">Today</Option>
                    <Option value="earning_week">Weekly</Option>
                    <Option value="earning_month">Monthly</Option>
                    <Option value="earning_year">Yearly</Option>
                    <Option value="earning_all">Till date</Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          ),
        },
        {
          heading: "Rs." + receivables?.amount,
          subheading: "Total Receivables",
          subtitle: "You will receive your earnings every 15 days",
          date:
            "Last payout: " +
            moment(receivables?.last_payout)?.format("DD/MM/YYYY"),
        },
      ]);
    }
  }, [earning_overview]);

  useEffect(() => {
    {
      setAppointmentsSummary([
        {
          heading: "Rs." + appointments?.clinic_visits?.all,
          subHeading: "Scheduled video calls",
        },
        {
          heading: "Rs." + appointments?.doctornow?.all,
          subHeading: "DoctorNow (Instant video calls)",
        },
        {
          heading: "Rs." + appointments?.scheduled?.all,
          subHeading: "Clinic visits",
        },
      ]);
    }
  }, [appointments]);

  useEffect(() => {
    let earning_t = [];
    let earning_info = [];
    earning_table?.map((item, index) => {
      const ap_date = item?.date?.split("-");
      var date = new Date(ap_date?.[0], ap_date?.[1] - 1, ap_date?.[2]);
      let earning_date = date?.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      if (item?.time) {
        let ti = item?.time?.split(":");
        let tt = new Date(0, 0, 0, ti[0], ti[1], ti[2]);
        let tim = tt.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
        });
        let obj = {
          key: index,
          patient: item?.patient_name,
          date: earning_date,
          time: tim,
          type: item?.type,
        };
        earning_t.push(obj);
        let objInfo = {
          key: index,
          patient: item?.patient_name,
          date: earning_date,
          time: tim,
          type: item?.type,
          fee: item?.consultation_fee,
        };
        earning_info.push(objInfo);
      } else {
        let obj = {
          key: index,
          patient: item?.patient_name,
          date: earning_date,
          time: item?.time,
          type: item?.type,
        };
        earning_t.push(obj);
      }
      // settableData(earning_t);
    });
    addDays();
    setEarningInfo(earning_info);
  }, [earning_table]);

  useEffect(() => {
    setbank_details(bank_detail);
  }, [bank_detail]);

  // const showResult = (show) => {
  //   if (show === true) {
  //     toast.success("Bank Details Edited Succesfully.")
  //   }
  // }

  const padStart = (val) => {
    return val < 10 ? "0" + val : val;
  };
  const addDays = () => {
    const date = new Date();
    let datesCollection = [];

    for (var i = 0; i < 60; i++) {
      const newDate = new Date(date.getTime() + i * 1000 * 60 * 60 * 24);
      datesCollection.push(
        `${newDate.getFullYear()}/${padStart(
          newDate.getMonth() + 1
        )}/${padStart(newDate.getDate())}/`
      );
    }
    setDateFilter(datesCollection);
  };

  useEffect(() => {
    if (datefilter?.length > 0) {
      setformatedDate(
        datefilter?.map((item, index) => {
          if (index === 0) {
            return (
              moment(new Date(item)).format("ddd") +
              ", " +
              moment(new Date(item)).format("MMM D")
            );
          } else {
            return (
              moment(new Date(item)).format("ddd") +
              ", " +
              moment(new Date(item)).format("MMM D")
            );
          }
        })
      );
      setbuttons([
        datefilter?.map((index) => {
          return (
            <TableComponent
              key={index}
              header={tableHeader}
              data={tableData}
              pagination={false}
            />
          );
        }),
      ]);
      setTableSlider(true);
    }
  }, [datefilter]);

  useEffect(() => {
    setEarningLoading(true);
    API.get(`/doctor/all-appointment?past&page=1`)
      .then((response) => {
        if (response?.data?.code === 200) {
          setEarningLoading(false);
          setEarningData(response?.data?.data?.data);
          setTotalEarningCount(response?.data?.data?.total);
        } else {
          setEarningLoading(false);
        }
      })
      .catch((err) => {
        setEarningLoading(false);
      });
  }, []);

  useEffect(() => {
    if (earningData?.length > 0) {
      let earning_table = [];

      earningData?.forEach((item) => {
        let date = moment(item?.date).format("DD/MM/YYYY");
        let apptId = item?.id;
        let time = item?.appointment_time;
        let patientName = item?.patient_name;
        let type = item?.type;
        let typeText = item?.type_text;
        let amount =  item?.amount_earn === 0 ? '-' : item?.amount_earn;
        let platformFee = item?.platform_fee;
        let progress = item?.progress;
        let cancellation_fee = item?.penalty_charges === 0 ? '-' : item?.penalty_charges;
        let payment_type = item?.payment_type ? item?.payment_type : "N/A";
        let amountToShow = item?.amount;

        console.log({time})
        earning_table.push({
          date,
          apptId,
          time,
          patientName,
          type,
          amount,
          cancellation_fee,
          payment_type,
          platformFee,
          progress,
          typeText,
          amountToShow
        });
      });

      const table_data = earning_table;
      setEarningTable(table_data);
    } else {
      setEarningTable([]);
    }
  }, [JSON.stringify(earningData)]);

  const earningPaginationOptions = {
    pageSize: 10,
    current: earningPage,
    total: totalEarningCount,
    onChange: (page) => {
      setEarningPage(page);

      setEarningLoading(true);
      API.get(`/doctor/all-appointment?past&page=${page}`)
        .then((response) => {
          setEarningLoading(false);
          if (response?.data?.code === 200) {
            setEarningData(response?.data?.data?.data);
            setTotalEarningCount(response?.data?.data?.total);

            let earningResponse = response?.data?.data?.data;
            let earning_table = [];

            if (earningResponse.length > 0) {
              earningResponse.forEach((item) => {
                let date = moment(item?.date).format("DD/MM/YYYY");
                let apptId = item?.id;
                let time = moment(item?.created_at).format("hh:mm:ss a");
                let patientName = item?.patient_name;
                let platformFee = item?.platform_fee;
                let progress = item?.progress;
                let type = item?.type;
                let typeText = item?.type_text;
                let amount =  item?.amount_earn === 0 ? '-' : item?.amount_earn;
                let cancellation_fee = item?.penalty_charges === 0 ? '-' : item?.penalty_charges;
                let payment_type = item?.payment_type
                  ? item?.payment_type
                  : "N/A";
                let amountToShow = item?.amount;

                earning_table.push({
                  date,
                  apptId,
                  time,
                  patientName,
                  type,
                  amount,
                  cancellation_fee,
                  payment_type,
                  platformFee,
                  progress,
                  typeText,
                  amountToShow
                });
              });

              const table_data = earning_table;
              setEarningTable(table_data);
            }
          }
        })
        .catch((err) => {
          setEarningLoading(false);
        });
    },
  };

  async function handleAppointmentTypeChange(value) {
    setAppointmentType(value);
    setEarningLoading(true);

    let from;
    let to;

    if (startDate)
      from = moment(startDate).format().toString().substring(0, 10);
    if (endDate) to = moment(endDate).format().toString().substring(0, 10);

    let apiUrl = `/doctor/all-appointment?past&appointment_type=${value}`;

    if (from && to) apiUrl.concat(`&from=${from}&to=${to}`);

    if (paymentType) apiUrl.concat(`&subscription_id=${paymentType}`);

    try {
      const response = await API.get(apiUrl);

      setEarningLoading(false);

      if (response?.data?.code === 200) {
        setEarningData(response?.data?.data?.data);
        setTotalEarningCount(response?.data?.data?.total);
      }
    } catch (error) {
      setEarningLoading(false);
    }
  }

  async function handlePaymentTypeChange(value) {
    setPaymentType(value);
    setEarningLoading(true);

    let from;
    let to;

    if (startDate)
      from = moment(startDate).format().toString().substring(0, 10);
    if (endDate) to = moment(endDate).format().toString().substring(0, 10);

    let apiUrl = `/doctor/all-appointment?past&subscription_id=${value}`;

    if (from && to) apiUrl.concat(`&from=${from}&to=${to}`);

    if (appointmentType) apiUrl.concat(`&appointment_type=${appointmentType}`);

    try {
      const response = await API.get(apiUrl);

      setEarningLoading(false);

      if (response?.data?.code === 200) {
        setEarningData(response?.data?.data?.data);
        setTotalEarningCount(response?.data?.data?.total);
      }
    } catch (error) {
      setEarningLoading(false);
    }
  }

  async function earningSearch(event) {

    if (
      event.type === "click" ||
      (event.type === "keydown" && event.key === "Enter")
    ) {
      // if mouse click or enter key
      // console.log(event.target.value, "logan")

      // get input value from state earningSearchValue
      if (earningSearchValue) {
        setEarningLoading(true);

        let apiUrl = `/doctor/all-appointment?past&search=${earningSearchValue}`;

        if (startDate && endDate) {
          let from = moment(startDate).format().toString().substring(0, 10);
          let to = moment(endDate).format().toString().substring(0, 10);

          apiUrl.concat(`&from=${from}&to=${to}`);
        }

        if (paymentType) apiUrl.concat(`&subscription_id=${paymentType}`);

        if (appointmentType)
          apiUrl.concat(`&appointment_type=${appointmentType}`);

        try {
          const response = await API.get(apiUrl);

          setEarningLoading(false);

          if (response?.data?.code === 200) {
            setEarningData(response?.data?.data?.data);
            setTotalEarningCount(response?.data?.data?.total);
          }
        } catch (error) {
          setEarningLoading(false);
        }
          setEarningLoading(false);

      }
    }
  }

  const menuList = [
    {
      link: "#overview",
      name: "Overview",
    },
    {
      link: "#earnings",
      name: "Your Earnings",
    },
    {
      link: "#bank_details",
      name: "Bank Details",
    },
  ];

  async function downloadAppointments() {
    setDownloadLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/doctor/all-appointment-download?is_html=1&is_download=1`, {
      method: 'GET',
      headers: {
        'Authorization': Cookies.get("token"),
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(response => response.blob())
      .then(response => {
        const blob = new Blob([response], { type: 'application/csv' });
        const downloadUrl = URL.createObjectURL(blob);
        setDownloadLoader(false);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "file.csv";
        document.body.appendChild(a);
        a.click();
      })
  }


  //  function deleteBankDetails() {

  //   const token = getFromLocalStorage("D_APP_TOKEN");
  //   const user_id = getFromLocalStorage("D_USER_ID");


  //   axios.delete(`${process.env.REACT_APP_BASE_URL}/bank-detail`)
  //     .then(() => {
  //       swal("Success!", "Your Bank details have been deleted successfully.", "success")
  //         .then((value) => {
  //           window.location.reload();
  //         });
  //     })
  //     .catch((err) => {
  //       swal("Error!", "Your Bank details could not be deleted.", "error");

  //     });
  // }


  return (
    <section className="earnings dash_wrapper cover_space earningDashboardSet">
      {mobile && <StickyTab menuList={menuList} type="a" />}
      {(ScreenLoading || earningLoading || downloadLoader) && <Loader />}
      <div className="container-fluid mt-5 paddingSameLR">
        {!mobile && (
          <Row>
            <Col lg={12}>
              {/* <HeadingWithSpaceLarge text="EARNINGS OVERVIEW" /> */}
            </Col>
          </Row>
        )}
        <div className="d-flex align-items-center justify-content-between earningHeadingTop mb-3">
          <HeadingDescSmall className="text-uppercase" text={"Earnings"} />

          <LinkContainerSmall
            className="text-uppercase btn historyPaymentBtn"
            to={{ pathname: "/payment-history" }}
          >
            {" "}
            Payment History
          </LinkContainerSmall>
        </div>
        {quickSummary.length === 0 ? (
          <Loader />
        ) : (
          <>
            <Row className="mobile_row">
              <Col
                lg={12}
                className={
                  mobile
                    ? location?.search?.split("?")[1] === "#overview" ||
                      typeof location?.search?.split("?")[1] === "undefined"
                      ? "mt-3"
                      : "d-none"
                    : "mt-3"
                }
                id="overview"
              >
                <div className="quickSummaryContainer row">
                  {quickSummary?.map((summary, index) => {
                    const { heading, subheading, date, subtitle } = summary;

                    if (typeof heading === "string") {
                      return (
                        <Col md={4}>
                          <div key={index} className="quickSummaryBox mb-4 bottomRsFixess">
                            {!mobile ? (
                              <div className="card d-block">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-block">
                                    <HeadingDescSmall className='fs-24 EarningsTitles' text={subheading} />
                                  </div>

                                  <HeadingDescVsmall className='lastPayout' text={date} />
                                </div>
                                <h4 className="subtitle">{subtitle}</h4>

                                <h3 className="d-flex justify-content-end">
                                  {heading}
                                </h3>
                                {/* <HeadingDesc text={heading} /> */}
                              </div>
                            ) : (
                              <div className="card">
                                <div className="flex_start mobile_box">
                                  <div>
                                    <HeadingDescSmall text={subheading} />
                                  <h4 style={{fontSize:'12px'}} className="subtitle">{subtitle}</h4>


                                    <div className=" d-none  d-sm-block">
                                      <HeadingDescVsmall text={date} />
                                    </div>
                                  </div>
                                  <div>
                                    <div className=" d-block d-sm-none">
                                      <HeadingDescVsmall  text={date} />
                                    </div>
                                    <HeadingDesc text={heading} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                      );
                    }
                  })}
                </div>
              </Col>
              {appointmentsSummary?.length === 0 ? (
                <Loader />
              ) : (
                <div>
                  <Col md={12} className="mb-4">
                    <div className="white_color_div d-flex flex-row hide_mobile ">
                      <Col md={2} xs={7} className="or-1">
                        <h5 className="heading_desc_small appointmentsEarns">
                          {" "}
                          Appointment <br />
                          Earnings
                        </h5>
                      </Col>
                      <Col md={9} xs={12} className="or-3">
                        <Row>
                          {appointmentsSummary?.map((items, index) => {
                            const { heading, subHeading } = items;
                            return (
                              <>
                                <Col
                                  md={4}
                                  className="videoCallBoxx align-items-center"
                                >
                                  <div className="d-block singleBoxCalls px-3 py-2">
                                    <h6 className="mb-2 belowApointmentsCards">{subHeading}</h6>
                                    <h3
                                      className="d-flex justify-content-start"
                                      style={{ color: "#ef6286" }}
                                    >
                                      {heading}
                                    </h3>
                                  </div>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </Col>
                      <Col md={1} xs={4} className="or-2 ms-auto">
                        <div>
                          <Form layout="vertical" form={form}>
                            <Form.Item name="appointmentsName">
                              <Select
                                dropdownAlign={{ offset: [0, 4] }}
                                // placeholder={"Select Bank"}
                                defaultValue="all"
                                suffixIcon={<img src={arrowdropdown} alt />}
                                className="c_select"
                                onChange={handleAppointmentEarnings}
                              >
                                <Option value="today">Today</Option>
                                <Option value="week">Weekly</Option>
                                <Option value="month">Monthly</Option>
                                <Option value="year">Yearly</Option>
                                <Option value="all">Till date</Option>
                              </Select>
                            </Form.Item>
                          </Form>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </div>
              )}
            </Row>
          </>
        )}

        <Row className="mobile_row2 desktop">
          <Col
            lg={12}
            className={
              mobile
                ? location?.search?.split("?")[1] === "#bank_details"
                  ? "mt-3"
                  : "d-none"
                : ""
            }
            id="bank_details"
          >
            <div className="white_color_div no_bank">
              <div className="w-100 d-flex align-items-center justify-content-between bank-detail">
                <HeadingWithSpaceLarge text="BANK DETAILS" />
                <div className="flex_center">
                  {bank_details ? (
                        <a>
                    <img
                      src={editIcon11}
                      alt="editIcon"
                      onClick={() => setModal("m2")}
                    />
                  </a>
                  ) : null }
                  {/* <a className="pink_div">
                          <img src={closeIcon} alt="closeIcon" onClick={deleteBankDetails} />
                    </a> */}

                  <AddBankModal
                    data={bank_details}
                    show={modal === "m2"}
                    type="edit"
                    close={() => setModal(false)}
                  />

                  <ViewInvoiceModal
                    show={modal === "m4"}
                    close={() => setModal(false)}
                    currentRecord={currentRecord}
                  />
                </div>
              </div>

              {!bank_details ? (
                <div className="w-100 text-center">
                  <SimpleButtonSmall
                    text="Add bank details"
                    className="addBankDetails"
                    onClick={() => setModal("m1")}
                  />
                  <AddBankModal
                    show={modal === "m1"}
                    close={() => setModal(false)}
                    type="add"
                  />
                </div>
              ) : (
                <div className="column_flex2 row">
                  <div className="column_flex col-md-3">
                    <HeadingDescVsmall text="Bank Name" />
                    <HeadingDescVsmall text={bank_details?.bank_name} />
                  </div>
                  <div className="column_flex col-md-3">
                    <HeadingDescVsmall text="Account Title" />
                    <HeadingDescVsmall text={bank_details?.account_name} />
                  </div>
                  <div className="column_flex col-md-3">
                    <HeadingDescVsmall text="Account Number" />
                    <HeadingDescVsmall text={bank_details?.account_number} />
                  </div>

                  <div className="column_flex col-md-3">
                    <HeadingDescVsmall text="IBAN" />
                    <div className="flex_center justify_between">
                      <HeadingDescVsmall text={bank_details?.iban_number} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row
          className={
            mobile
              ? location?.search?.split("?")[1] === "#earnings"
                ? "mt-3"
                : " "
              : "mt-3"
          }
          id="earnings"
        >
          {mobile ? (
            <>
              <Col xs={12}>
                <div className="row mb-3 earning_accordian ">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item className="earningWhiteAccordian" eventKey="0">
                      <Accordion.Header>
                        <Row className="align-items-center">
                          <Col md={2} xs={8} className=" ">
                            <h6 className="headingWithSpaceLarge earningsFontFamily  icon_edit_bank">
                              {" "}
                              Appointment Earnings
                            </h6>
                          </Col>
                          <Col md={1} xs={4} className=" text-end ">
                            <div>
                              <Form layout="vertical" form={form}>
                                <Form.Item name="appointmentsName">
                                  <Select
                                    dropdownAlign={{ offset: [0, 4] }}
                                    // placeholder={"Select Bank"}
                                    defaultValue="all"
                                    suffixIcon={<img src={arrowdropdown} alt />}
                                    className="c_select"
                                    onChange={handleAppointmentEarnings}
                                  >
                                    <Option value="today">Today</Option>
                                    <Option value="week">Weekly</Option>
                                    <Option value="month">Monthly</Option>
                                    <Option value="year">Yearly</Option>
                                    <Option value="all">Till date</Option>
                                  </Select>
                                </Form.Item>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col md={9} xs={12} className="or-3">
                            <Row>
                              {appointmentsSummary?.map((items, index) => {
                                const { heading, subHeading } = items;
                                return (
                                  <>
                                    <Col
                                      md={4}
                                      className="videoCallBoxx align-items-center"
                                    >
                                      <div className="d-block singleBoxCalls px-3 py-2">
                                        <h6 className="mb-2">{subHeading}</h6>
                                        <h3
                                          className="d-flex justify-content-start earningsFontFamily"
                                          style={{ color: "#ef6286" }}
                                        >
                                          {heading}
                                        </h3>
                                      </div>
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
              <Col xs={12}>
                <div className=" row align-items-center justify-content-between bank-detail ">
                  <Col lg={12} className="accordian_earning_results">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="w-100">
                            <h6 class="headingWithSpaceLarge earningsFontFamily icon_edit_bank">
                              Bank Details
                              <img
                                src={editIcon1}
                                alt="editIcon"
                                // onClick={() => setModal("m2")}
                                onClick={(e) => {
                                  e.stopPropagation(); // Stop the event from propagating to the parent element
                                  setModal("m2");
                                }}
                              />
                            </h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {bank_details ? (
                            <>
                              <ul className="patient_list_earning">
                                <li>
                                  <HeadingDescVsmall text="Bank Name" />
                                  <p>
                                    <HeadingDescVsmall
                                      text={bank_details?.bank_name}
                                    />
                                  </p>
                                </li>
                                <li>
                                  <HeadingDescVsmall text="Account Title" />
                                  <p>
                                    <HeadingDescVsmall
                                      text={bank_details?.account_name}
                                    />
                                  </p>
                                </li>
                                <li>
                                  <HeadingDescVsmall text="Account Number" />

                                  <p>
                                    {" "}
                                    <HeadingDescVsmall
                                      text={bank_details?.account_number}
                                    />
                                  </p>
                                </li>

                                <li>
                                  <HeadingDescVsmall text="IBAN" />

                                  <p>
                                    <div className="flex_center justify_between">
                                      <HeadingDescVsmall
                                        text={bank_details?.iban_number}
                                      />
                                    </div>
                                  </p>
                                </li>
                              </ul>
                            </>
                          ) : (
                            <>
                              <div className="w-100 text-center">
                                <AddBankModal
                                  show={modal === "m1"}
                                  close={() => setModal(false)}
                                  type="add"
                                />
                              </div>
                              <div className="button_bank_details">
                                <Button className='earningsFontFamily' onClick={() => setModal("m2")}>
                                  Add bank details
                                </Button>
                              </div>
                            </>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </div>
              </Col>
              <div className="reviews mobile_review">
                {earningInfo?.map((earnings) => (
                  <div className="single_reviews">
                    <div className="flex_center justify-content-between align-items-end">
                      <div className="column_flex">
                        <HeadingDesc text={earnings?.patient} />
                        <HeadingDescSmall
                          text={earnings?.date + " - " + earnings?.time}
                        />
                        <HeadingDescSmall
                          text={earnings?.type}
                          color={
                            earnings?.type?.includes("online") ? "blue" : "pink"
                          }
                        />
                      </div>
                      <div className="right_arrow">
                        <HeadingDesc text={"Rs. " + earnings?.fee} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="overflow_div">
                <div className="">
                  <div className="historyHeading mb-3  align-items-center">
                    <Row>
                      <Col xs={6}>
                        <h4>History</h4>
                      </Col>
                      <Col xs={6} className="text-end">
                        <span className="download_btn ms-0 earningsFontFamily downloadBtnBarr justify-content-end" onClick={downloadAppointments}>
                          <img src={downloadIcon} className="img-fluid" />
                          Download
                        </span>
                      </Col>

                      <Col
                        xs={12}
                        className="search-bar mobile_earning_dashboard"
                      >
                        <div className="btn_search searchBarButton">
                          <InputGroup>
                            <FormControl 
                            onKeyDown={earningSearch}
                            value={earningSearchValue}
                            onChange={(e) => setEarningSearchValue(e.target.value)} placeholder="Search by Patient name, Patient ID..." />
                          </InputGroup>
                          <span className="icon_search">
                            <img onClick={earningSearch} src={searchIcon} className="img-fluid" />
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row className="align-items-center mb-3">
                    <Col lg={12} className="accordian_earning_mobile forArrow">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="arrowIconss">
                            {" "}
                            <h6 class="headingWithSpaceLarge earningsFontFamily icon_edit_bank filtesr">
                              Filters
                              {/* <img src={editIcon1} alt="editIcon" /> */}
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={12} className="mb-15">
                                <div className="date_filter d-flex align-items-center dateTimeCalen">
                                  <span className="from_01">From</span>
                                  <DatePicker.RangePicker
                                    value={[startDate, endDate]}
                                    onChange={handleDateRangeChange}
                                    clearIcon={null}
                                    format={dateFormatList}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} className="mb-15">
                                <div className="d-flex justify-content-between">
                                  <div className="mt-2 appointment_mobile1 ">
                                    <h6 className="earningsFontFamily">Appointment Type</h6>
                                  </div>
                                  <div className="ms-1 appointment_mobile">
                                    <Form layout="vertical" form={form}>
                                      <Form.Item name="appointmentsType">
                                        <Select
                                          dropdownAlign={{ offset: [0, 12] }}
                                          defaultValue="select type"
                                          suffixIcon={
                                            <img src={arrowdropdown} alt />
                                          }
                                          className="c_select"
                                          value={appointmentType}
                                          onChange={(value) =>
                                            handleAppointmentTypeChange(value)
                                          }
                                        >
                                          <Option value="clinicVisit">
                                            Clinic Visit
                                          </Option>
                                          <Option value="scheduledCall">
                                            Scheduled Video Call
                                          </Option>
                                          <Option value="instant-consultation">
                                            Instant Video Call
                                          </Option>
                                        </Select>
                                      </Form.Item>
                                    </Form>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12}>
                                <div className="d-flex">
                                  <div className="mt-2 payment_type_mobile1">
                                    <h6 style={{whiteSpace:'nowrap'}} className="headingSameAll">
                                      Payment Type
                                    </h6>
                                  </div>
                                  <div className="ms-1 w-100">
                                    <Form layout="vertical" form={form}>
                                      <Form.Item name="paymentType">
                                        <Select
                                          dropdownAlign={{ offset: [0, 12] }}
                                          defaultValue="Select type"
                                          suffixIcon={
                                            <img src={arrowdropdown} alt />
                                          }
                                          className="c_select appointmentTypeSelect"
                                        >
                                          {paymentTypeData.map((option) => (
                                            <Option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Form>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {appointmentType ||
                      paymentType ||
                      startDate ||
                      endDate ? (
                        <Link onClick={removeFilters} className="remove_filter earningsFontFamily">
                          Remove all filters
                        </Link>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col lg={12} className="accordian_earning_results">
                      {/* {console.log(earningTable, "tbb")} */}
                      {earningTable.length > 0 ? (
                        earningTable.map((item) => (
                          <Accordion>
                            <Accordion.Item>
                              <Accordion.Header>
                                <div className="app_id_details">
                                  <div className="d-flex app_id_boxes">
                                    <div className="box-1">
                                      <h5>
                                        Appointment ID:{" "}
                                        <span>{item?.apptId}</span>
                                      </h5>
                                      <p>{item?.date}</p>
                                    </div>
                                    <div className="box-2">
                                      <h6>{item?.amount}</h6>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul className="patient_list_earning">
                                  <li>
                                    <h6>Patient Name</h6>
                                    <p>{item?.patientName}</p>
                                  </li>
                                  <li>
                                    <h6>Time Slot</h6>
                                    <p>{item?.time}</p>
                                  </li>
                                  <li>
                                    <h6>Appointment Type</h6>
                                    <p>{item?.type}</p>
                                  </li>
                                  <li>
                                    <h6>Cancellation Fee</h6>
                                    <p>{item?.cancellation_fee}</p>
                                  </li>
                                  <li>
                                    <h6>Payment Type</h6>
                                    <p>{item?.payment_type}</p>
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ))
                      ) : (
                        <div className="mt-2 mb-3 NoEarnings">
                          <p className="earningsFontFamily" >No earnings added</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <div className="overflow_div">
              <div className="mt-4">
                <div className="historyHeading mb-4">
                  <h4 className="earningsFontFamily">History</h4>
                </div>
                <Row className="align-items-center mb-3">
                  <Col lg={3}>
                    <div className="date_filter d-flex align-items-center dateTimeCalen">
                      <span className="from_01 earningsFontFamily">From</span>
                      <DatePicker.RangePicker
                        value={[startDate, endDate]}
                        onChange={handleDateRangeChange}
                        clearIcon={null}
                        format={dateFormatList}
                      />
                    </div>
                  </Col>
                  <Col lg={7} className="">
                    <div className="row  align-items-center">
                      <Col lg={5} className="ps-0">
                        <div className="d-flex align-items-center">
                          <div className="mt-2">
                            <h6 style={{whiteSpace:'nowrap',marginBottom:'8px'}} className="headingSameAll earningsFontFamily">Appointment Type</h6>
                          </div>
                          <div className="ms-1 w-70">
                            <Form layout="vertical" form={form}>
                              <Form.Item name="appointmentsType">
                                <Select
                                  dropdownAlign={{ offset: [0, 12] }}
                                  defaultValue="Select type"
                                  suffixIcon={<img src={arrowdropdown} alt />}
                                  className="c_select earningsFontFamily appointmentTypeSelect"
                                  value={appointmentType}
                                  onChange={(value) =>
                                    handleAppointmentTypeChange(value)
                                  }
                                >
                                  <Option value="clinicVisit">
                                    Clinic Visit
                                  </Option>
                                  <Option value="scheduledCall">
                                    Scheduled Video Call
                                  </Option>
                                  <Option value="instant-consultation">
                                    Instant Video Call
                                </Option>
                                </Select>
                              </Form.Item>
                            </Form>
                          </div>
                        </div>
                      </Col>


                      <Col lg={5} className="px-0 selectPaymentType" >
                        <div className="d-flex  align-items-center">
                          <div className="mt-2">
                            <h6 style={{marginBottom:'8px'}} className="headingSameAll earningsFontFamily">Payment Type</h6>
                          </div>
                          <div className="ms-1  w-70">
                            <Form layout="vertical" form={form}>
                              <Form.Item name="paymentType">
                                <Select
                                  dropdownAlign={{ offset: [0, 12] }}
                                  defaultValue="Select type"
                                  suffixIcon={<img src={arrowdropdown} alt />}
                                  className="c_select appointmentTypeSelect"
                                  value={paymentType}
                                  onChange={(value) =>
                                    handlePaymentTypeChange(value)
                                  }
                                >
                                  {paymentTypeData.map((option) => (
                                    <Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Form>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <span
                          onClick={downloadAppointments}
                          className="download_btn ms-0 downloadBtnBarr earningsFontFamily"
                        >
                          <img src={downloadIcon} className="img-fluid " />
                          Download
                        </span>
                      </Col>
                    </div>
                  </Col>
                  <Col lg={2} className="search-bar">
                    <div className="btn_search searchBarButton">
                      {/* <InputGroup>
                        <FormControl placeholder="Search by Transaction ID..." />
                      </InputGroup> */}
                      <input
                        type="text"
                        className="form-control searchField"
                        placeholder="Search by Patient name, Patient ID..."
                        onKeyDown={earningSearch}
                        value={earningSearchValue}
                        onChange={(e) => setEarningSearchValue(e.target.value)}
                      />
                      <span className="icon_search">
                        <img
                          src={searchIcon}
                          className="img-fluid"
                          onClick={earningSearch}
                        />
                      </span>
                    </div>
                  </Col>
                </Row>

                {appointmentType || paymentType || startDate || endDate ? (
                  <div className="Removefilters mb-3 xx">
                    <p className="earningsFontFamily" onClick={removeFilters}>Remove all filters</p>
                  </div>
                ) : null}

                {/* <SimpleSlider
                    date={formatedDate}
                    header={tableHeader}
                    bodyData={earningTable}
                    type="earnings"
                  >

                  </SimpleSlider> */}
                <div className="_table_border">
                  <EarningTable
                    header={tableHeader}
                    data={earningTable}
                    pagination={earningPaginationOptions}
                    className="earningsTableComp"
                  />
                </div>
              </div>
            </div>
          )}
        </Row>
      </div>
      <div></div>
      <label className=" earning_toast toast_success">
        <ToastContainer
          position="top-center"
          autoClose={500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </label>
    </section>
  );
}

export default Earnings;
